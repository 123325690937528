import React from 'react';
import styled, { css } from 'styled-components';
import colors from '../../../theme/colors';
import CandidateHeaderButtonIcon from './CandidateHeaderButtonIcon';

const StyledShowStatus = styled.div<{ disabled?: boolean }>`
  display: flex;
  min-width: 17rem;

  cursor: pointer;

  border-left: 1px solid ${colors.gray_light_l2};
  padding: 0.2rem 2.6rem;

  justify-content: center;
  align-items: center;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

const StyledText = styled.div`
  margin-left: 1rem;

  font-size: 1.6rem;
  line-height: 2.4rem;
`;

interface CandidateHeaderButtonProps {
  onClick: () => void;
  text: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  disabled?: boolean;
}

const CandidateHeaderButton: React.FC<CandidateHeaderButtonProps> = ({
  onClick,
  text,
  icon,
  disabled = false,
  children,
}) => {
  return (
    <StyledShowStatus disabled={disabled} onClick={disabled ? () => null : onClick}>
      <CandidateHeaderButtonIcon Icon={icon} />

      <StyledText>{text}</StyledText>
      {children}
    </StyledShowStatus>
  );
};

export default CandidateHeaderButton;
