import React from 'react';
import RecruiterListItem from './RecruiterListItem';
import { RecruiterListItemProps } from './RecruiterListItem/RecruiterListItem';
import { CardsContainer, Container, FooterContainer, RecruitersCount, PageContainer } from './RecruiterList.styled';
import Pagination, { PaginationState } from 'elitegrad-common/src/components/pagination';

export interface RecruiterListProps {
  recruiters: RecruiterListItemProps[] | null;
  pagination: PaginationState;
  onChangePaginationOffset: (page: number) => void;
}

const RecruiterList: React.FC<RecruiterListProps> = ({ recruiters, onChangePaginationOffset, pagination }) => {
  return (
    <PageContainer>
      <Container>
        <CardsContainer>
          {recruiters?.map((recruiter) => (
            <RecruiterListItem
              twilio_phone={recruiter.twilio_phone}
              id={recruiter.id}
              key={recruiter.id}
              email={recruiter.email}
              fullName={recruiter.fullName}
              company={recruiter.company}
            />
          ))}
        </CardsContainer>
      </Container>
      <FooterContainer>
        <RecruitersCount>{`Total Recruiters (${recruiters?.length})`}</RecruitersCount>
        <Pagination pagination={pagination} onPageChange={onChangePaginationOffset} />
      </FooterContainer>
    </PageContainer>
  );
};

export default RecruiterList;
