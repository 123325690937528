import styled from 'styled-components';
import colors from '../../../../../theme/colors';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 5rem 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'logo degree'
    'logo university'
    'logo gpa';
  grid-column-gap: 0.2rem;

  margin: 1rem 0;
  min-height: 7.2rem;
`;

export const Logo = styled.img`
  grid-area: logo;

  width: 4.5rem;
  height: 4.5rem;
`;

export const DegreeName = styled.div`
  grid-area: degree;

  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.4rem;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const DegreeYear = styled.span`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1rem;

  color: ${colors.gray_dark};
`;

export const GPAHonor = styled.div`
  grid-area: gpa;
`;

export const University = styled.div`
  grid-area: university;
`;

export const UniversityName = styled.span`
  margin-right: 0.4rem;

  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.4rem;

  color: ${colors.gray_dark};
`;
