import React from 'react';
import { WhereQueryInterface } from '../../../queries/queryUtils';
import { useFilter } from '../../../utils/useFilter';
import BooleanFilterItem from '../BooleanFilterItem';

export const generateQuery = (needSponsorship: boolean): WhereQueryInterface => {
  const querySponsorshipFilter: WhereQueryInterface = {
    enabled: needSponsorship,
    operator: '_and',
    whereQuery: { need_sponsor: { _eq: true } },
  };
  return querySponsorshipFilter;
};

const SponsorshipFilter: React.FC<{ name: string }> = ({ name }) => {
  const { storeFilterStatus, filterValue } = useFilter(name);

  const setFilterValue = (needSponsorship: boolean) => {
    storeFilterStatus(generateQuery(needSponsorship), needSponsorship);
  };

  return (
    <BooleanFilterItem on={!!filterValue} filterTitle={'Require Visa Sponsorship'} setFilterValue={setFilterValue} />
  );
};

export default SponsorshipFilter;
