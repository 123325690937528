import gql from 'graphql-tag';

import { InterestRegionSelectorParts } from './fragments.gql';

export default gql`
  query GetInterestRegionSelection($region: String) {
    grad_interest_region_selection(where: { value: { _ilike: $region } }, order_by: { value: asc }) {
      ...InterestRegionSelectorParts
    }
  }
  ${InterestRegionSelectorParts}
`;
