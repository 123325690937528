import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import GET_TECHNICAL_LANGUAGE_SELECTION from '../../../queries/getComputerLanguageSelection.gql';
import { GetComputerLanguageSelection } from '../../../generated/GetComputerLanguageSelection';
import { WhereQueryInterface } from '../../../queries/queryUtils';
import { selectionAsComputerLanguageSelection } from '../../../transformers/computerLanguageSelection';
import ComputerLanguageSelection from '../../../types/ComputerLanguageSelection';
import { FilterOption } from '../../../types/FilterValueType';
import { useFilter } from '../../../utils/useFilter';
import MultiCheckFilter from '../MultiCheckFilter';

export interface ComputerLanguageFilterProps {
  name: string;
}

export const generateQuery = (options: FilterOption[]): WhereQueryInterface => {
  const optionsValues = options.map(({ filterValue }) => filterValue);
  const queryGroupFilter: WhereQueryInterface = {
    enabled: !!optionsValues.length,
    operator: '_and',
    whereQuery: { computer_languages: { language: { _in: optionsValues } } },
  };
  return queryGroupFilter;
};

const ComputerLanguageFilter: React.FC<ComputerLanguageFilterProps> = ({ name }) => {
  const { storeFilterStatus, filterValue, clearFilter } = useFilter(name);

  const { data, loading } = useQuery<GetComputerLanguageSelection>(GET_TECHNICAL_LANGUAGE_SELECTION);

  const setFilterValue = (options: FilterOption[]) => storeFilterStatus(generateQuery(options), options);

  const options: FilterOption[] = useMemo(() => {
    const rolesTypes: ComputerLanguageSelection[] =
      data?.grad_computer_language_selection.map(selectionAsComputerLanguageSelection) || [];
    return rolesTypes.map(({ language }) => ({
      filterValue: language,
      displayValue: language,
      id: language,
    }));
  }, [data]);

  return loading ? (
    <Skeleton height={589} />
  ) : (
    <MultiCheckFilter
      filterTitle={'Technical Languages'}
      options={options}
      defaultOptions={(filterValue as FilterOption[]) || []}
      setFilterValue={setFilterValue}
      clearFilter={clearFilter}
      allSelection={false}
    />
  );
};
export default ComputerLanguageFilter;
