import React from 'react';
import styled, { css } from 'styled-components';

import colors from '../theme/colors';

interface StyledButtonProps {
  big: boolean;
  light: boolean;
  dark: boolean;
  submit: boolean;
  disabled?: boolean;
}

const StyledButton = styled.button.attrs<StyledButtonProps>(({ submit }) => ({
  type: submit ? 'submit' : 'button',
}))<StyledButtonProps>`
  margin: auto 0;
  border-radius: 6px;
  border-style: solid;
  border-color: ${colors.white};
  min-width: 8rem;
  padding: 0.5rem 1rem;
  font-family: 'Roboto';
  background-color: ${colors.red};
  cursor: pointer;

  font-size: 1.4rem;
  line-height: 1.6rem;
  color: ${colors.white};

  :focus {
    outline: none;
  }

  ${({ big }) =>
    big &&
    css`
      min-height: 4rem;
      min-width: 11rem;
      height: 3.4rem;

      font-weight: 700;
    `}

  ${({ light }) =>
    light &&
    css`
      border-color: ${colors.red};
      border-width: 1px;

      color: ${colors.red};

      background-color: ${colors.white};
    `}

    ${({ dark }) =>
    dark &&
    css`
      background-color: ${colors.black};
    `}

      
      ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;

      opacity: 0.2;
    `}
`;

export interface PrimaryButtonProps {
  text?: string;
  big?: boolean;
  light?: boolean;
  dark?: boolean;
  submit?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  big = false,
  light = false,
  dark = false,
  submit = false,
  disabled = false,
  text,
  children,
  onClick,
  ...props
}) => {
  return (
    <StyledButton
      disabled={disabled}
      dark={dark}
      big={big}
      light={light}
      submit={submit}
      onClick={() => onClick && onClick()}
      {...props}
    >
      {text}
      {children}
    </StyledButton>
  );
};

export default PrimaryButton;
