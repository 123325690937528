import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { GetTemplates } from '../../../generated/GetTemplates';
import GET_TEMPLATES from '../../../queries/getTemplates.gql';
import { asTemplate } from '../../../transformers/templates';
import TemplateList from './TemplateList';

interface TemplateListControllerProps {
  editMode: boolean;
  setEditMode: (arg: boolean) => void;
  templateToEdit: number;
  setTemplateToEdit: (arg: number) => void;
}

const TemplateListController: React.FC<TemplateListControllerProps> = ({
  editMode,
  setEditMode,
  templateToEdit,
  setTemplateToEdit,
}) => {
  const { data, loading } = useQuery<GetTemplates>(GET_TEMPLATES);
  const templates = useMemo(() => {
    return data?.recruit_sms_template.map(asTemplate) || [];
  }, [data]);

  return (
    <TemplateList
      templateToEdit={templateToEdit}
      setTemplateToEdit={setTemplateToEdit}
      editMode={editMode}
      setEditMode={setEditMode}
      loading={loading}
      templates={templates}
    />
  );
};

export default TemplateListController;
