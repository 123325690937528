import React from 'react';
import { useQuery } from '@apollo/client';

import { asGroup } from '../../../transformers/groups';
import GET_GROUPS from '../../../queries/getGroups.gql';
import { GetGroups } from '../../../generated/GetGroups';
import { WhereQueryInterface } from '../../../queries/queryUtils';

import { FilterOption } from '../../../types/FilterValueType';
import MultiCheckFilter from '../../../components/filters/MultiCheckFilter';
import Group from '../../../types/Group';
import { useFilter } from '../../../utils/useFilter';

export interface GroupFilterProps {
  name: string;
}

export const generateQuery = (options: FilterOption[]): WhereQueryInterface => {
  const optionsIds = options.map(({ id }) => id || '');
  const queryGroupFilter: WhereQueryInterface = {
    enabled: !!optionsIds.length,
    operator: '_and',
    whereQuery: { grad_group_members: { grad_group_id: { _in: optionsIds } } },
  };
  return queryGroupFilter;
};

const GroupFilter: React.FC<GroupFilterProps> = ({ name }) => {
  const { storeFilterStatus, filterValue, clearFilter } = useFilter(name);

  const { data } = useQuery<GetGroups>(GET_GROUPS, { fetchPolicy: 'network-only' });

  const setFilterValue = (options: FilterOption[]) => storeFilterStatus(generateQuery(options), options);

  const groups: Group[] = data?.recruit_grad_group.map((gp) => asGroup(gp)) || [];
  const optionsGroups: FilterOption[] = groups.map(({ id, name }) => ({
    filterValue: name,
    displayValue: name,
    id: id,
  }));

  return (
    <MultiCheckFilter
      filterTitle={'Groups'}
      options={optionsGroups}
      defaultOptions={(filterValue as FilterOption[]) || []}
      setFilterValue={setFilterValue}
      clearFilter={clearFilter}
    />
  );
};

export default GroupFilter;
