export const Paths = {
  root: '/',
  authCallback: '/auth/callback',
  authLogin: '/auth/login',
  authDebug: '/auth/debug',
  authLogout: '/auth/logout',
  home: '/candidates',
  candidates: '/candidates',
  candidate: '/candidates/:userId',
  recruiters: '/recruiters/',
  recruitersEdit: '/recruiters/:id/edit',
  recruitersNew: '/recruiters/new',
  conversations: '/conversations',
  conversation: '/conversations/:id',
  conversationNew: '/conversations/new',
};

export const candidatePath = (userId: string): string => `/candidates/${userId}`;

export const messagingPath = (id: string) => `/conversations/${id}`;

export const goToStudentApp = process.env.REACT_APP_LINK_STUDENT || '';

export const goToRecruitApp = process.env.REACT_APP_LINK_RECRUITER || '';
