import styled from 'styled-components';

export const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  padding: 2rem 4.6rem;
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(28rem, 1fr));
  gap: 0.8rem 1.8rem;
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 4% 2% 4%;
  align-items: center;
`;

export const RecruitersCount = styled.span`
  font-family: 'Roboto';
  font-size: 1.2rem;
  line-height: 1.4rem;
  font-weight: bold;
`;
