import Conversation from '../types/Conversation';
import { GetConversations_recruit_sms_correspondent } from '../generated/GetConversations';
import moment from 'moment';
import { asRecipient } from './recipient';

const asConversation = (conversationParts: GetConversations_recruit_sms_correspondent): Conversation => {
  const dateSeen = conversationParts.last_read_timestamp;
  const lastReceivedDate = conversationParts.last_incoming_timestamp;

  const lastReadDate = moment(conversationParts.last_read_timestamp);

  const seen =
    lastReadDate.isValid() && moment(lastReceivedDate).isValid()
      ? moment(conversationParts.last_read_timestamp).isAfter(lastReceivedDate)
      : true;

  return {
    id: conversationParts.correspondent_user_id,

    dateSeen,
    seen,
    lastSentDate: conversationParts.last_outgoing_timestamp,
    lastReceivedDate,
    lastMessageDate: conversationParts.last_message_timestamp,
    recipient: asRecipient(conversationParts.grad_profile),
  };
};

export { asConversation };
