import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSubscription } from '@apollo/client';
import debounce from 'lodash.debounce';

import ConversationList from './ConversationList';
import { GetConversations, GetConversationsVariables } from '../../../generated/GetConversations';
import GET_CONVERSATIONS from '../../../queries/getConversations.gql';
import { asConversation } from '../../../transformers/conversation';
import Conversation from '../../../types/Conversation';

const ConversationListController: React.FC<{
  generateMessagingPath: (id: string) => string;
}> = ({ generateMessagingPath }) => {
  const [localLoading, setLocalLoading] = useState(false);
  const [name, setName] = useState('% %');
  const [searchInput, setSearchInput] = useState('');

  const { data, loading } = useSubscription<GetConversations, GetConversationsVariables>(GET_CONVERSATIONS, {
    onSubscriptionData: () => setLocalLoading(false),
    variables: { name, limit: 20 },
  });

  const { id: candidateId } = useParams<{ id: string }>();

  const conversations = data?.recruit_sms_correspondent.map((sms) => asConversation(sms));

  const debouncedGetConversations = useCallback(
    debounce((value: string) => {
      setName(`%${value}%`);
    }, 600),
    [setName],
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLocalLoading(true);
      debouncedGetConversations(searchInput);
    }, 700);
    return () => clearTimeout(timeoutId);
  }, [searchInput]);

  return (
    <ConversationList
      candidateId={candidateId}
      conversations={conversations as Conversation[]}
      loading={localLoading || loading}
      onSearchChange={setSearchInput}
      generateMessagingPath={generateMessagingPath}
    />
  );
};

export default ConversationListController;
