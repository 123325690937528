import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { asCandidateWithProfileImage } from 'elitegrad-common/src/transformers/candidates';
import GET_CANDIDATE from 'elitegrad-common/src/queries/getCandidate.gql';
import { GetCandidate, GetCandidateVariables } from 'elitegrad-common/src/generated/GetCandidate';
import Candidate from 'elitegrad-common/src/components/candidate/CandidateDetail/CandidateDetail';
import Header from 'elitegrad-common/src/components/Header';
import Page from '../Page';
import SpinnerIsoType from 'elitegrad-common/src/components/SpinnerIsoType';
import NotFoundView from 'elitegrad-common/src/components/NotFoundView';
import CandidateHeader from 'elitegrad-common/src/components/candidate/CandidateDetail/CandidateHeader';

const CandidateDetailController: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();

  const { data, loading: loadingCandidate, refetch } = useQuery<GetCandidate, GetCandidateVariables>(GET_CANDIDATE, {
    skip: !userId,
    variables: { admin: true, userId: userId || '' },
  });

  const candidate = data?.grad_profile.map((c) => asCandidateWithProfileImage(c))[0] || null;

  if (loadingCandidate || !candidate) {
    const backComponent = <Header backLink={true} />;
    return (
      <Page headerComponent={backComponent}>
        {loadingCandidate ? <SpinnerIsoType text="Loading" /> : <NotFoundView />}
      </Page>
    );
  }

  return (
    <Page headerComponent={<CandidateHeader candidate={candidate} refetch={refetch} isAdmin={true} />}>
      <Candidate candidate={candidate} isAdmin={true} />
    </Page>
  );
};

export default CandidateDetailController;
