import React from 'react';

import { StudentInformationContainer, StudentInformationValue } from './StudentInformation.styled';

interface StudentInformationProps {
  title: string;
  values: string[];
}

const generateValueString = (value: string, position: number, maxPosition: number) =>
  `${value}${position < maxPosition - 1 ? ',' : ''}`;

const StudentInformation: React.FC<StudentInformationProps> = ({ title, values }) => {
  return (
    <StudentInformationContainer>
      {title}:
      {values.map((value, index) => (
        <StudentInformationValue key={value}>
          {generateValueString(value, index, values.length)}
        </StudentInformationValue>
      ))}
    </StudentInformationContainer>
  );
};

export default StudentInformation;
