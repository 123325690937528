import React from 'react';
import TemplateListModal from './TemplateListModal';

interface TemplateAdministrationProps {
  toggle: (arg: boolean) => void;
}

const TemplateAdministration: React.FC<TemplateAdministrationProps> = ({ toggle }) => {
  return <TemplateListModal setModalOpen={toggle} />;
};

export default TemplateAdministration;
