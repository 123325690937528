import React from 'react';
import { colors } from '../../../theme';
import styled, { css } from 'styled-components';

const StyledCheckBox = styled.span`
  position: relative;

  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0.5em 0 0;
`;

interface StyledCustomCheckBoxProps {
  checked?: boolean;
  black?: boolean;
}

const StyledCustomCheckBox = styled.span<StyledCustomCheckBoxProps>`
  position: absolute;

  width: 1.2rem;
  height: 1.2rem;

  border-radius: 2px;
  border: solid 1px ${colors.black};

  ${({ checked }) =>
    checked &&
    css`
      background-color: ${colors.black};
    `}
  :after {
    position: absolute;
    content: '';
    top: 0rem;
    left: 0.3rem;
    height: 0;
    width: 0;

    border-radius: 4px;
    border: 0.5px solid ${colors.white};
    border-width: 0 1.5px 1.5px 0;
    transform: rotate(0deg) scale(0);
    opacity: 1;

    ${({ checked }) =>
      checked &&
      css`
        transform: rotate(45deg) scale(1);
        width: 0.3rem;
        height: 0.7rem;
        background-color: transparent;
        border-radius: 0;
      `}
  }
`;

export interface CheckBoxProps {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  black?: boolean;
}

const CheckBox: React.FC<CheckBoxProps> = ({ checked, onChange = () => undefined, ...props }) => {
  return (
    <StyledCheckBox {...props}>
      <StyledCustomCheckBox checked={checked} onClick={() => onChange(!checked)} />
    </StyledCheckBox>
  );
};

export default CheckBox;
