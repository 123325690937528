import React from 'react';
import styled from 'styled-components';
import Switch from '../Switch';

import FilterTitle from './FilterTitle';

const StyledFilterItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 1rem 0;
`;

interface BooleanFilterItemProps {
  filterTitle: string;
  filterActive?: boolean;
  on: boolean;
  setFilterValue: (status: boolean) => void;
}

const BooleanFilterItem: React.FC<BooleanFilterItemProps> = ({ filterTitle, filterActive, on, setFilterValue }) => {
  return (
    <StyledFilterItem>
      <FilterTitle title={filterTitle} filterActive={filterActive} />
      <Switch on={on} onChange={setFilterValue} />
    </StyledFilterItem>
  );
};

export default BooleanFilterItem;
