import React from 'react';
import styled from 'styled-components';

import CurrentJobTag from '../../components/CurrentJobTag';
import WorkExperience from '../../types/WorkExperience';
import { colors } from '../../theme';
import { formatMonthYear } from '../../utils/dateUtils';

const StyledWorkExperience = styled.div`
  padding: 1rem;

  overflow-y: auto;
  overflow-x: hidden;
  overflow-wrap: break-word;
`;

const StyledCurrentJobTag = styled(CurrentJobTag)`
  margin-left: 0.2rem;
  font-family: 'RobotoBlack';
`;

const StyledDatePeriod = styled.div<{ big: boolean }>`
  display: flex;
  align-items: center;
  font-size: ${({ big }) => (big ? '1.2rem' : '0.8rem')};
  line-height: ${({ big }) => (big ? '1.3rem' : '1.1rem')};
  margin-bottom: 0.3rem;

  color: ${colors.gray};
`;

const StyledCompany = styled.div<{ big: boolean }>`
  font-size: ${({ big }) => (big ? '1.4rem' : '1.2rem')};
  line-height: ${({ big }) => (big ? '1.7rem' : '1.5rem')};

  color: ${colors.black};

  font-weight: bold;
  margin-left: 0.8rem;
  margin-bottom: 0.2rem;
`;

const StyledLocation = styled.div<{ big: boolean }>`
  font-size: ${({ big }) => (big ? '1.4rem' : '1.1rem')};
  line-height: ${({ big }) => (big ? '1.8rem' : '1.6rem')};

  color: ${colors.gray_dark};
`;

const StyledPosition = styled.div<{ big: boolean }>`
  font-size: ${({ big }) => (big ? '1.6rem' : '1.3rem')};
  line-height: ${({ big }) => (big ? '1.8rem' : '1.8rem')};

  color: ${colors.gray_dark};
`;

const StyledPositionDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
`;

interface CandidateWorkExperienceProps {
  workExperience: WorkExperience;
  big?: boolean;
}

const CandidateWorkExperience: React.FC<CandidateWorkExperienceProps> = ({ workExperience, big = false }) => {
  return (
    <StyledWorkExperience>
      <StyledDatePeriod big={big}>
        {workExperience.startDate && `${formatMonthYear(workExperience.startDate)}`}
        {workExperience.startDate && workExperience.endDate && ' - '}
        {workExperience.endDate && `${formatMonthYear(workExperience.endDate)}`}
        {!workExperience.endDate && ' -'}
        <StyledCurrentJobTag workExperience={workExperience} inCard />
      </StyledDatePeriod>
      <StyledPositionDetails>
        <StyledPosition big={big}>{workExperience.position}</StyledPosition>
        <StyledCompany big={big}>{workExperience.company}</StyledCompany>
      </StyledPositionDetails>
      <StyledLocation big={big}>
        {workExperience.city}
        {workExperience.city && workExperience.state && ', '}
        {workExperience.state}
        {workExperience.state && workExperience.country && ', '}
        {workExperience.country}
      </StyledLocation>
    </StyledWorkExperience>
  );
};

export default CandidateWorkExperience;
