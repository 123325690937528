import gql from 'graphql-tag';

import { TemplateParts } from './fragments.gql';

export default gql`
  query GetTemplates {
    recruit_sms_template {
      ...TemplateParts
    }
  }
  ${TemplateParts}
`;
