import Candidate from '../types/Candidate';
import Group from '../types/Group';
import { CandidateImageOnlyParts } from '../generated/CandidateImageOnlyParts';
import { CandidateParts } from '../generated/CandidateParts';
import { CandidatePartsAdmin } from '../generated/CandidatePartsAdmin';
import { GetEventAttendees_recruit_event_event_registrations_profile as EventAttendee } from '../generated/GetEventAttendees';

import { asDegree } from './degrees';
import { asFunFact } from './funFacts';
import { fromGroupMembership } from './groups';
import { asInterestFirm } from './interestFirms';
import { asInterestJobType } from './interestJobTypes';
import { asInterestRegion } from './interestRegions';
import { asTest } from './tests';
import { asWorkExperience } from './workExperiences';
import { asInterestRole } from './interestRoles';
import { asComputerLanguage } from './computerLanguage';
import { asSpokenLanguage } from './spokenLanguage';
import { asEmployerPreferences } from './emproyerPreferences';
import { asAward } from './awards';

const asCandidatesWithProfileImages = (
  parts: (CandidateParts | CandidatePartsAdmin)[],
  imageOnlyParts: CandidateImageOnlyParts[],
): Candidate[] => {
  const imagesMap: { [key: string]: string } = imageOnlyParts.reduce(
    (map, parts) => ({
      ...map,
      ...{ [parts.user_id]: parts.profile_image },
    }),
    {},
  );

  return parts.map((p) => ({
    ...asCandidate(p),
    photo: imagesMap[p.user_id] || null,
  }));
};

const asCandidate = (parts: CandidatePartsAdmin | CandidateParts | EventAttendee): Candidate => {
  const funFacts = parts.fun_facts.map(asFunFact).map((o) => o.description);
  const interestFirmTypes = parts.interest_firms.map(asInterestFirm).map((o) => o.firmType);
  const employerPreferences = parts.employer_preferences.map(asEmployerPreferences).map((o) => o.preference);
  const interestJobTypes = parts.interest_job_types.map(asInterestJobType).map((o) => o.jobType);
  const interestRegions = parts.interest_regions.map(asInterestRegion).map((o) => o.region);
  const interestRoles = parts.interest_roles.map(asInterestRole).map((o) => o.roleType);
  const computerLanguages = parts.computer_languages.map(asComputerLanguage).map((o) => o.language);
  const spokenLanguages = parts.spoken_languages.map(asSpokenLanguage).map((o) => o.language);

  const degrees = parts.degrees.map(asDegree);
  const tests = parts.test_scores.map(asTest);
  const workExperiences = parts.work_experiences.map(asWorkExperience);
  const achievements = parts.achievements.map((achieve) => achieve.description);
  const volunteers = parts.volunteers.map((volun) => volun.description);
  const awards = parts.awards.map(asAward); 

  const allGroupsParts = 'grad_group_members' in parts ? parts.grad_group_members : [];

  const groups = allGroupsParts.length
    ? (allGroupsParts
        .map((gradGroupMember) => fromGroupMembership(gradGroupMember))
        .filter((gm) => gm !== null) as Group[])
    : null;

  const blockedParts =
    'blocked' in parts
      ? { blocked: parts.blocked ? parts.blocked : false, blockedReason: parts.blocked ? parts?.blocked_reason : null }
      : {};

  return {
    firstname: parts.firstname,
    middlename: parts.middlename || null,
    lastname: parts.lastname,
    fullname: parts.fullname,
    email: parts.email,
    comm_preference: parts.comm_preference,
    hometown: parts.hometown,
    yearsOfRelevantWork: parts.years_of_relevant_work,
    mobile: parts.mobile,
    tests: tests,
    degrees: degrees,
    photo: null,
    title: parts.title,
    needSponsor: parts.need_sponsor,
    visaExpiration: null,
    visaStatus: null,
    visaType: null,
    authorizedToWork: parts.authorized_to_work,
    studentAthlete: parts.student_athlete,
    resume: null,
    hasCV: Boolean(parts.upload_docs_aggregate?.aggregate?.count),
    funFacts,
    groups,
    employerPreferences,
    interestFirmTypes,
    interestJobTypes,
    interestRegions,
    interestRoles,
    spokenLanguages,
    workExperiences,
    userId: parts.user_id,
    legal_residence_country: parts.legal_residence_country?.country || null,
    computerLanguages,
    achievements,
    volunteers,
    awards,
    ...blockedParts,
  };
};

const asCandidateWithProfileImage = (
  parts: (CandidatePartsAdmin | CandidateParts | EventAttendee) & CandidateImageOnlyParts,
): Candidate => {
  return { ...asCandidate(parts), photo: parts.profile_image };
};

export { asCandidate, asCandidatesWithProfileImages, asCandidateWithProfileImage };
