import React, { useEffect, useState } from 'react';
import { useGetRecruitersListAdminQuery } from 'elitegrad-common/src/generated/graphql';
import Recruiters from './Recruiters';
import { FilterProps } from './FilterSide';
import { INITIAL_OFFSET, PAGE_SIZE } from 'elitegrad-common/src/components/pagination/PaginationState';

const RecruitersController: React.FC = () => {
  const [recruiterFilters, setRecruiterFilters] = useState<FilterProps>({
    email: null,
    company: null,
    fullname: null,
  });
  const [paginationOffset, setPaginationOffset] = useState(INITIAL_OFFSET);

  const {
    data: dataRecruiters,
    loading: loadingRecruiters,
    fetchMore,
  } = useGetRecruitersListAdminQuery({
    variables: {
      ...recruiterFilters,
      limit: PAGE_SIZE,
      offset: paginationOffset,
    },
  });

  const pagination = {
    count: dataRecruiters?.recruit_recruiter_aggregate?.aggregate?.count || 0,
    offset: paginationOffset,
    limit: PAGE_SIZE,
  };

  const onClearFilter = () => {
    setRecruiterFilters({ fullname: null, company: null, email: null });
  };

  const onApplyFilter = (values: FilterProps) => {
    setRecruiterFilters(values);
  };

  const onChangePaginationOffset = (offset: number) => {
    setPaginationOffset(offset);
  };

  useEffect(() => {
    if (fetchMore) {
      fetchMore({
        variables: { ...recruiterFilters, limit: PAGE_SIZE, offset: paginationOffset },
      });
    }
  }, [paginationOffset, fetchMore, recruiterFilters]);

  const recruiters =
    dataRecruiters?.recruit_recruiter?.map((recruiter) => ({
      fullName: recruiter.fullname,
      email: recruiter.email || '',
      twilio_phone: recruiter?.twilio_phone || '',
      company: recruiter?.account?.company?.name || '',
      id: recruiter.user_id || '',
    })) || null;

  return (
    <Recruiters
      recruiters={recruiters}
      onChangePaginationOffset={onChangePaginationOffset}
      pagination={pagination}
      onApplyFilter={onApplyFilter}
      onClearFilter={onClearFilter}
      loadingRecruiters={loadingRecruiters}
    />
  );
};

export default RecruitersController;
