import React from 'react';

import FullTimeInterestTag from 'elitegrad-common/src/components/FullTimeInterestTag';
import InternshipInterestTag from 'elitegrad-common/src/components/InternshipInterestTag';
import Candidate, { isFullTime, isInternship } from 'elitegrad-common/src/types/Candidate';
import CandidateCardDotMenu from './CandidateCardDotMenu';
import CheckboxCard from 'elitegrad-common/src/components/CheckboxCard';
import { candidatePath } from 'routes';
import { Link } from 'react-router-dom';
import Degree, { getMajorNameFilter } from 'elitegrad-common/src/types/Degree';
import { formatYear } from 'elitegrad-common/src/utils/dateUtils';
import Avatar from 'elitegrad-common/src/components/candidate/Avatar';

import {
  StyledHeader,
  StyledCandidateDetails,
  StyledDegreeYear,
  StyledTags,
  StyledTopBar,
  CurrentDegreeName,
  CurrentDegreeUniversity,
} from './CandidateCardHeader.styled';

export interface CandidateCardHeaderProps {
  candidate: Candidate;
  currentDegree: Degree;

  toggleSelection?: (status: boolean) => void;
  selected: boolean;
  refetch: () => void;
}

const CandidateCardHeader: React.FC<CandidateCardHeaderProps> = ({
  candidate,
  currentDegree,
  toggleSelection,
  selected,
  refetch,
}) => {
  return (
    <StyledHeader>
      <StyledTopBar>
        {toggleSelection && <CheckboxCard onChange={toggleSelection} checked={selected} />}

        <StyledTags>
          {(isFullTime(candidate) && <FullTimeInterestTag />) || (isInternship(candidate) && <InternshipInterestTag />)}
        </StyledTags>
        <CandidateCardDotMenu refetch={refetch} candidate={candidate} />
      </StyledTopBar>

      <Link to={candidatePath(candidate.userId)}>
        <Avatar photo={candidate.photo} isSelected={selected} />
      </Link>
      <Link to={candidatePath(candidate.userId)}>
        <StyledCandidateDetails>
          {candidate.fullname}
          {candidate.title ? `, ${candidate.title}` : ''}
        </StyledCandidateDetails>
      </Link>
      <CurrentDegreeName>
        {currentDegree?.degreeName && getMajorNameFilter(currentDegree.concentrations) !== undefined
          ? currentDegree.degreeName + ' in ' + getMajorNameFilter(currentDegree.concentrations)
          : ''}
      </CurrentDegreeName>
      <StyledDegreeYear>
        <CurrentDegreeUniversity>
          {currentDegree?.university?.name !== undefined ? `${currentDegree.university?.name}, ` : ''}
        </CurrentDegreeUniversity>
        {currentDegree?.completeDate && formatYear(currentDegree.completeDate)}
      </StyledDegreeYear>
    </StyledHeader>
  );
};

export default CandidateCardHeader;
