import React from 'react';

import Message from '../../../types/Message';
import styled, { css } from 'styled-components';
import { colors } from '../../../theme';
import { toHoursDate } from '../../../utils/dateUtils';
import MessageStatusIndicator from './MessageStatusIndicator';

const StyledHours = styled.div`
  font-size: 1.2rem;
  line-height: 1.2rem;
  align-self: flex-end;
  color: ${colors.gray_dark};
  margin-top: 0.2rem;
`;

const StyledTextBlob = styled.div<{ inc: boolean }>`
  position: relative;
  align-self: flex-end;
  margin: 1rem;
  margin-left: ${({ inc }) => (inc ? '5rem' : 'auto')};
  margin-right: ${({ inc }) => (inc ? 'auto' : '5rem')};
  text-align: ${({ inc }) => (inc ? 'left' : 'right')};
  width: fit-content;
  min-width: 10rem;
  max-width: 46rem;
  border-radius: 8px;
  background-color: ${({ inc }) => (inc ? colors.white : colors.beta300)};
  box-shadow: 0px 2px 6px rgba(10, 22, 70, 0.1);

  ::after {
    content: ' ';
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    ${({ inc }) =>
      inc
        ? css`
            left: -1rem;
          `
        : css`
            right: -1rem;
          `}

    border: 10px solid;
    border-color: transparent;
    border-top-color: ${({ inc }) => (inc ? colors.white : colors.blue)};
  }
`;

const StyledTextContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  line-height: 1.4rem;
  padding: 1rem;
  min-height: 2rem;
`;

interface MessageListItemProps {
  message: Message;
}

const MessageListItem: React.FC<MessageListItemProps> = ({ message, ...props }) => {
  return (
    <StyledTextBlob inc={message.fromCandidate} {...props}>
      <StyledTextContent>
        {!message.fromCandidate && <div>{<MessageStatusIndicator message={message} />}</div>}
        <div>{message.content}</div>
        <StyledHours>{toHoursDate(message.dateSent)}</StyledHours>
      </StyledTextContent>
    </StyledTextBlob>
  );
};

export default MessageListItem;
