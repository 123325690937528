import React from 'react';
import TemplatePickerController from '../template/TemplatePickerController';
import MarkupTagList from '../markup/MarkupTagList';
import {
  Container,
  StyledMessageSender,
  StyledSMS,
  StyledSendButton,
  StyledTemplatePicker,
  StyledSendIcon,
  StyledTextAreaInput,
  IconsContainer,
} from './MessageSender.styled';

interface MessageSenderProps {
  disabled: boolean;
  textArea: string;
  onChange: (text: string) => void;
  onSend: () => void;
  markupList: { name: string; value: string }[];
  onInsertMarkup: (markup: string) => void;
  hasMarkup?: boolean;
  popperId: string;
  big?: boolean;
}

const MessageSender: React.FC<MessageSenderProps> = ({
  disabled,
  textArea,
  onChange,
  onSend,
  markupList,
  onInsertMarkup,
  hasMarkup,
  popperId,
  big = false,
}) => (
  <Container>
    <StyledMessageSender big={big}>
      <StyledSMS>
        <StyledTextAreaInput placeholder="Type something..." value={textArea} onChange={onChange} />
      </StyledSMS>
      <IconsContainer>
        {hasMarkup && <MarkupTagList markupList={markupList} onInsertMarkup={onInsertMarkup} />}
        <StyledTemplatePicker>
          <TemplatePickerController popperId={popperId} handleClick={onChange} />
        </StyledTemplatePicker>
        <StyledSendButton disabled={disabled || textArea.length === 0} onClick={onSend}>
          <StyledSendIcon />
        </StyledSendButton>
      </IconsContainer>
    </StyledMessageSender>
  </Container>
);

export default MessageSender;
