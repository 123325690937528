import React from 'react';
import { useQuery } from '@apollo/client';

import { asGroup } from '../../transformers/groups';
import Candidate from '../../types/Candidate';

import GET_GROUPS from '../../queries/getGroups.gql';
import { GetGroups } from '../../generated/GetGroups';
import GroupModal, { GroupModalProps } from './GroupModal';

export interface GroupModalController extends Pick<GroupModalProps, 'setModalOpen'> {
  candidates: Candidate[];
  refetch: () => void;
}

const GroupModalController: React.FC<GroupModalController> = ({ setModalOpen, candidates, refetch }) => {
  const { data, loading } = useQuery<GetGroups>(GET_GROUPS, { fetchPolicy: 'network-only' });

  const groups = data?.recruit_grad_group.map((gp) => asGroup(gp)) || [];

  return (
    <GroupModal
      refetch={refetch}
      groups={groups}
      setModalOpen={setModalOpen}
      loadingGroups={loading}
      candidates={candidates}
    />
  );
};

export default GroupModalController;
