import styled from 'styled-components';
import { Info, SendPlane } from 'elitegrad-common/src/assets/vectors';
import { colors } from 'elitegrad-common/src/theme';

export const Card = styled.div`
  position: relative;
  flex-grow: 1;
  padding: 2.5rem 1.9rem 2rem 1.7rem;
  border-radius: 13px;
  border: 1px solid ${colors.gray_light_l7};
  background-color: ${colors.white};

  @media (max-width: 1440px) {
    padding: 1.8rem 1.3rem 1.6rem 1.1rem;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 20rem;

  @media (max-width: 1020px) {
    max-width: 17rem;
  }
`;

export const Separator = styled.div`
  margin: 0.4rem 0;
  margin: 1.4rem 0 1.1rem;
  height: 0.1rem;
  background-color: ${colors.gray_light_l7};
`;

export const FullName = styled.h1`
  font-size: 2rem;
  font-family: 'Roboto';
  line-height: 2.1rem;
  margin-top: 0.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  text-decoration: inherit;
  color: inherit;

  @media (max-width: 1440px) {
    font-size: 1.8rem;
  }
`;

export const Company = styled.span`
  font-family: 'Roboto';
  font-size: 1.4rem;
  line-height: 1.4rem;
  color: ${colors.light_black_l2};
  font-weight: bold;

  @media (max-width: 1440px) {
    font-size: 1.2rem;
  }
`;

export const ContactInfo = styled.div`
  font-family: 'Roboto';
  font-size: 1.4rem;
  color: ${colors.gray_extraLight_l2};
  margin-left: 0.9rem;
  align-self: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 1440px) {
    font-size: 1.2rem;
  }
`;

export const InfoIcon = styled(Info)`
  grid-area: icon;
  width: 2rem;
  height: 2rem;
`;

export const SendIcon = styled(SendPlane)`
  grid-area: icon;
  width: 2rem;
  height: 2rem;
`;

export const InformationRow = styled.div`
  display: flex;
  width: 100%;
  max-width: 28rem;
`;
