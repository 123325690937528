/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * unique or primary key constraints on table "admin.adminuser"
 */
export enum admin_adminuser_constraint {
  adminuser_pkey = "adminuser_pkey",
}

/**
 * update columns of table "admin.adminuser"
 */
export enum admin_adminuser_update_column {
  email = "email",
  fullname = "fullname",
  twilio_phone = "twilio_phone",
  user_id = "user_id",
}

/**
 * unique or primary key constraints on table "grad.achievement"
 */
export enum grad_achievement_constraint {
  achievement_achievement_id_key = "achievement_achievement_id_key",
  achievement_pkey = "achievement_pkey",
}

/**
 * update columns of table "grad.achievement"
 */
export enum grad_achievement_update_column {
  achievement_id = "achievement_id",
  description = "description",
  user_id = "user_id",
}

/**
 * unique or primary key constraints on table "grad.award"
 */
export enum grad_award_constraint {
  award_award_id_key = "award_award_id_key",
  award_pkey = "award_pkey",
}

/**
 * update columns of table "grad.award"
 */
export enum grad_award_update_column {
  award_category = "award_category",
  award_id = "award_id",
  award_name = "award_name",
  degree_id = "degree_id",
  user_id = "user_id",
  work_experience_id = "work_experience_id",
  year_received = "year_received",
}

/**
 * unique or primary key constraints on table "grad.bookmark_job"
 */
export enum grad_bookmark_job_constraint {
  bookmark_job_bookmark_job_id_key = "bookmark_job_bookmark_job_id_key",
  bookmark_job_pkey = "bookmark_job_pkey",
}

/**
 * update columns of table "grad.bookmark_job"
 */
export enum grad_bookmark_job_update_column {
  bookmark_job_id = "bookmark_job_id",
  job_posting_id = "job_posting_id",
  timestamp = "timestamp",
  user_id = "user_id",
}

/**
 * unique or primary key constraints on table "grad.computer_language"
 */
export enum grad_computer_language_constraint {
  computer_language_pkey = "computer_language_pkey",
  computer_language_user_id_language_key = "computer_language_user_id_language_key",
}

/**
 * update columns of table "grad.computer_language"
 */
export enum grad_computer_language_update_column {
  computer_language_id = "computer_language_id",
  language = "language",
  user_id = "user_id",
}

/**
 * unique or primary key constraints on table "grad.concentration"
 */
export enum grad_concentration_constraint {
  concentration_concentration_id_key = "concentration_concentration_id_key",
  concentration_pkey = "concentration_pkey",
  concentration_user_id_degree_id_type_val_key = "concentration_user_id_degree_id_type_val_key",
}

/**
 * update columns of table "grad.concentration"
 */
export enum grad_concentration_update_column {
  concentration_id = "concentration_id",
  degree_id = "degree_id",
  type = "type",
  user_id = "user_id",
  val = "val",
}

/**
 * unique or primary key constraints on table "grad.country_selection"
 */
export enum grad_country_selection_constraint {
  country_selection_pkey = "country_selection_pkey",
}

/**
 * update columns of table "grad.country_selection"
 */
export enum grad_country_selection_update_column {
  country = "country",
  country_selection_id = "country_selection_id",
}

/**
 * unique or primary key constraints on table "grad.degree"
 */
export enum grad_degree_constraint {
  degree_degreeid_key = "degree_degreeid_key",
  degree_pkey = "degree_pkey",
}

/**
 * unique or primary key constraints on table "grad.degree_fill_in_university"
 */
export enum grad_degree_fill_in_university_constraint {
  degree_fill_in_university_degree_id_key = "degree_fill_in_university_degree_id_key",
  degree_fill_in_university_pkey = "degree_fill_in_university_pkey",
}

/**
 * update columns of table "grad.degree_fill_in_university"
 */
export enum grad_degree_fill_in_university_update_column {
  address = "address",
  degree_id = "degree_id",
  general_phone = "general_phone",
  institution_name = "institution_name",
  user_id = "user_id",
}

/**
 * update columns of table "grad.degree"
 */
export enum grad_degree_update_column {
  complete_month = "complete_month",
  complete_month_numeric = "complete_month_numeric",
  complete_year = "complete_year",
  completed = "completed",
  current = "current",
  degree = "degree",
  degree_id = "degree_id",
  degree_level = "degree_level",
  gpa = "gpa",
  gpa_not_applicable = "gpa_not_applicable",
  gpa_outof = "gpa_outof",
  start_month = "start_month",
  start_month_numeric = "start_month_numeric",
  start_year = "start_year",
  university_selection_id = "university_selection_id",
  user_id = "user_id",
}

/**
 * unique or primary key constraints on table "grad.employer_preference"
 */
export enum grad_employer_preference_constraint {
  employer_preference_pkey = "employer_preference_pkey",
  employer_preference_user_id_preference_key = "employer_preference_user_id_preference_key",
}

/**
 * update columns of table "grad.employer_preference"
 */
export enum grad_employer_preference_update_column {
  employer_preference_id = "employer_preference_id",
  preference = "preference",
  user_id = "user_id",
}

/**
 * unique or primary key constraints on table "grad.event_registration"
 */
export enum grad_event_registration_constraint {
  event_registration_event_id_user_id_key = "event_registration_event_id_user_id_key",
  event_registration_pkey = "event_registration_pkey",
}

/**
 * update columns of table "grad.event_registration"
 */
export enum grad_event_registration_update_column {
  event_id = "event_id",
  event_registration_id = "event_registration_id",
  timestamp = "timestamp",
  user_id = "user_id",
}

/**
 * unique or primary key constraints on table "grad.fun_fact"
 */
export enum grad_fun_fact_constraint {
  fun_fact_fun_fact_id_key = "fun_fact_fun_fact_id_key",
  fun_fact_pkey = "fun_fact_pkey",
}

/**
 * update columns of table "grad.fun_fact"
 */
export enum grad_fun_fact_update_column {
  description = "description",
  fun_fact_id = "fun_fact_id",
  user_id = "user_id",
}

/**
 * unique or primary key constraints on table "grad.interest_firm"
 */
export enum grad_interest_firm_constraint {
  interest_firm_interest_firm_id_key = "interest_firm_interest_firm_id_key",
  interest_firm_pkey = "interest_firm_pkey",
  interest_firm_user_id_firm_type_key = "interest_firm_user_id_firm_type_key",
}

/**
 * update columns of table "grad.interest_firm"
 */
export enum grad_interest_firm_update_column {
  firm_type = "firm_type",
  interest_firm_id = "interest_firm_id",
  user_id = "user_id",
}

/**
 * unique or primary key constraints on table "grad.interest_job_type"
 */
export enum grad_interest_job_type_constraint {
  interest_job_type_interest_job_type_id_key = "interest_job_type_interest_job_type_id_key",
  interest_job_type_pkey = "interest_job_type_pkey",
  interest_job_type_user_id_job_type_key = "interest_job_type_user_id_job_type_key",
}

/**
 * update columns of table "grad.interest_job_type"
 */
export enum grad_interest_job_type_update_column {
  interest_job_type_id = "interest_job_type_id",
  job_type = "job_type",
  user_id = "user_id",
}

/**
 * unique or primary key constraints on table "grad.interest_region"
 */
export enum grad_interest_region_constraint {
  interest_region_interest_region_id_key = "interest_region_interest_region_id_key",
  interest_region_pkey = "interest_region_pkey",
  interest_region_user_id_region_key = "interest_region_user_id_region_key",
}

/**
 * update columns of table "grad.interest_region"
 */
export enum grad_interest_region_update_column {
  interest_region_id = "interest_region_id",
  region = "region",
  user_id = "user_id",
}

/**
 * unique or primary key constraints on table "grad.interest_role"
 */
export enum grad_interest_role_constraint {
  interest_role_pkey = "interest_role_pkey",
}

/**
 * update columns of table "grad.interest_role"
 */
export enum grad_interest_role_update_column {
  interest_role_id = "interest_role_id",
  role = "role",
  user_id = "user_id",
}

/**
 * unique or primary key constraints on table "grad.profile_combine"
 */
export enum grad_profile_combine_constraint {
  profile_combine_pkey = "profile_combine_pkey",
}

/**
 * update columns of table "grad.profile_combine"
 */
export enum grad_profile_combine_update_column {
  content = "content",
  user_id = "user_id",
}

/**
 * unique or primary key constraints on table "grad.profile"
 */
export enum grad_profile_constraint {
  profile_pkey = "profile_pkey",
  profile_userid_key = "profile_userid_key",
}

/**
 * update columns of table "grad.profile"
 */
export enum grad_profile_update_column {
  authorized_to_work = "authorized_to_work",
  birth_date = "birth_date",
  blocked = "blocked",
  blocked_reason = "blocked_reason",
  comm_preference = "comm_preference",
  creation_timestamp = "creation_timestamp",
  email = "email",
  email_verified = "email_verified",
  firstname = "firstname",
  fullname = "fullname",
  hometown = "hometown",
  lastname = "lastname",
  legal_residence_country_id = "legal_residence_country_id",
  middlename = "middlename",
  minority_search = "minority_search",
  mobile = "mobile",
  mobile_valid = "mobile_valid",
  need_sponsor = "need_sponsor",
  prior_internship = "prior_internship",
  profile_image = "profile_image",
  profile_verified = "profile_verified",
  ssn = "ssn",
  student_athlete = "student_athlete",
  title = "title",
  user_id = "user_id",
  visa_expiration = "visa_expiration",
  visa_status = "visa_status",
  visa_type = "visa_type",
  will_relocate = "will_relocate",
  wizard_completed = "wizard_completed",
  wizard_completion_timestamp = "wizard_completion_timestamp",
  years_of_relevant_work = "years_of_relevant_work",
}

/**
 * unique or primary key constraints on table "grad.raise_hand"
 */
export enum grad_raise_hand_constraint {
  raise_hand_job_posting_id_user_id_key = "raise_hand_job_posting_id_user_id_key",
  raise_hand_pkey = "raise_hand_pkey",
  raise_hand_raise__hand_id_key = "raise_hand_raise__hand_id_key",
}

/**
 * update columns of table "grad.raise_hand"
 */
export enum grad_raise_hand_update_column {
  job_posting_id = "job_posting_id",
  raise__hand_id = "raise__hand_id",
  timestamp = "timestamp",
  user_id = "user_id",
}

/**
 * unique or primary key constraints on table "grad.spoken_language"
 */
export enum grad_spoken_language_constraint {
  spoken_language_pkey = "spoken_language_pkey",
  spoken_language_spoken_language_id_key = "spoken_language_spoken_language_id_key",
  spoken_language_user_id_language_key = "spoken_language_user_id_language_key",
}

/**
 * update columns of table "grad.spoken_language"
 */
export enum grad_spoken_language_update_column {
  language = "language",
  spoken_language_id = "spoken_language_id",
  user_id = "user_id",
}

/**
 * unique or primary key constraints on table "grad.test_score"
 */
export enum grad_test_score_constraint {
  test_score_pkey = "test_score_pkey",
  test_score_test_score_id_key = "test_score_test_score_id_key",
  test_score_user_id_test_key = "test_score_user_id_test_key",
  test_score_user_id_test_score_id_key = "test_score_user_id_test_score_id_key",
}

/**
 * unique or primary key constraints on table "grad.test_score_detail"
 */
export enum grad_test_score_detail_constraint {
  test_score_detail_pkey = "test_score_detail_pkey",
  test_score_detail_test_score_detail_id_key = "test_score_detail_test_score_detail_id_key",
  test_score_detail_user_id_test_score_id_detail_key = "test_score_detail_user_id_test_score_id_detail_key",
}

/**
 * update columns of table "grad.test_score_detail"
 */
export enum grad_test_score_detail_update_column {
  detail = "detail",
  outof = "outof",
  score = "score",
  test = "test",
  test_score_detail_id = "test_score_detail_id",
  test_score_id = "test_score_id",
  user_id = "user_id",
}

/**
 * update columns of table "grad.test_score"
 */
export enum grad_test_score_update_column {
  date = "date",
  outof = "outof",
  score = "score",
  test = "test",
  test_score_id = "test_score_id",
  user_id = "user_id",
}

/**
 * unique or primary key constraints on table "grad.university_selection"
 */
export enum grad_university_selection_constraint {
  university_selection_dapip_id_key = "university_selection_dapip_id_key",
  university_selection_pkey = "university_selection_pkey",
  university_selection_university_selection_id_key = "university_selection_university_selection_id_key",
}

/**
 * update columns of table "grad.university_selection"
 */
export enum grad_university_selection_update_column {
  address = "address",
  dapip_id = "dapip_id",
  general_phone = "general_phone",
  hidden = "hidden",
  lat = "lat",
  lng = "lng",
  location_name = "location_name",
  location_type = "location_type",
  logo_url = "logo_url",
  parent_dapip_id = "parent_dapip_id",
  parent_name = "parent_name",
  university_selection_id = "university_selection_id",
}

/**
 * unique or primary key constraints on table "grad.upload_doc"
 */
export enum grad_upload_doc_constraint {
  upload_doc_pkey = "upload_doc_pkey",
  upload_doc_upload_doc_id_key = "upload_doc_upload_doc_id_key",
}

/**
 * update columns of table "grad.upload_doc"
 */
export enum grad_upload_doc_update_column {
  filename = "filename",
  hex_content = "hex_content",
  upload_doc_id = "upload_doc_id",
  user_id = "user_id",
}

/**
 * unique or primary key constraints on table "grad.user_activity_summary"
 */
export enum grad_user_activity_summary_constraint {
  user_activity_summary_pkey = "user_activity_summary_pkey",
}

/**
 * update columns of table "grad.user_activity_summary"
 */
export enum grad_user_activity_summary_update_column {
  last_edit = "last_edit",
  last_page_view = "last_page_view",
  page_views_last_30d = "page_views_last_30d",
  page_views_last_7d = "page_views_last_7d",
  user_id = "user_id",
}

/**
 * unique or primary key constraints on table "grad.volunteer"
 */
export enum grad_volunteer_constraint {
  volunteer_pkey = "volunteer_pkey",
  volunteer_volunteer_id_key = "volunteer_volunteer_id_key",
}

/**
 * update columns of table "grad.volunteer"
 */
export enum grad_volunteer_update_column {
  description = "description",
  user_id = "user_id",
  volunteer_id = "volunteer_id",
}

/**
 * unique or primary key constraints on table "grad.work_experience"
 */
export enum grad_work_experience_constraint {
  work_experience_pkey = "work_experience_pkey",
  work_experience_work_experience_id_key = "work_experience_work_experience_id_key",
}

/**
 * update columns of table "grad.work_experience"
 */
export enum grad_work_experience_update_column {
  city = "city",
  company = "company",
  country = "country",
  current = "current",
  description = "description",
  division = "division",
  end_month = "end_month",
  end_month_numeric = "end_month_numeric",
  end_year = "end_year",
  job_type = "job_type",
  position = "position",
  relevant = "relevant",
  start_month = "start_month",
  start_month_numeric = "start_month_numeric",
  start_year = "start_year",
  state = "state",
  user_id = "user_id",
  work_experience_id = "work_experience_id",
}

/**
 * column ordering options
 */
export enum order_by {
  asc = "asc",
  asc_nulls_first = "asc_nulls_first",
  asc_nulls_last = "asc_nulls_last",
  desc = "desc",
  desc_nulls_first = "desc_nulls_first",
  desc_nulls_last = "desc_nulls_last",
}

/**
 * unique or primary key constraints on table "recruit.account"
 */
export enum recruit_account_constraint {
  account_account_id_key = "account_account_id_key",
  account_pkey = "account_pkey",
}

/**
 * update columns of table "recruit.account"
 */
export enum recruit_account_update_column {
  account_id = "account_id",
  account_name = "account_name",
}

/**
 * unique or primary key constraints on table "recruit.company"
 */
export enum recruit_company_constraint {
  company_account_id_key = "company_account_id_key",
  company_pkey = "company_pkey",
}

/**
 * unique or primary key constraints on table "recruit.company_subindustry"
 */
export enum recruit_company_subindustry_constraint {
  company_subindustry_pkey = "company_subindustry_pkey",
}

/**
 * update columns of table "recruit.company_subindustry"
 */
export enum recruit_company_subindustry_update_column {
  company_id = "company_id",
  company_subindustry_id = "company_subindustry_id",
  value = "value",
}

/**
 * update columns of table "recruit.company"
 */
export enum recruit_company_update_column {
  account_id = "account_id",
  address = "address",
  company_id = "company_id",
  description = "description",
  industry = "industry",
  logo = "logo",
  name = "name",
  num_employees = "num_employees",
  phone = "phone",
  photo = "photo",
  website = "website",
}

/**
 * unique or primary key constraints on table "recruit.event"
 */
export enum recruit_event_constraint {
  event_pkey = "event_pkey",
}

/**
 * update columns of table "recruit.event"
 */
export enum recruit_event_update_column {
  account_id = "account_id",
  address = "address",
  capacity = "capacity",
  creation_timestamp = "creation_timestamp",
  creator_id = "creator_id",
  description = "description",
  end_datetime = "end_datetime",
  end_timestamp = "end_timestamp",
  event_id = "event_id",
  event_type = "event_type",
  event_url = "event_url",
  image = "image",
  registration_count = "registration_count",
  show_event = "show_event",
  start_datetime = "start_datetime",
  start_timestamp = "start_timestamp",
  timezone = "timezone",
  title = "title",
}

/**
 * unique or primary key constraints on table "recruit.grad_group"
 */
export enum recruit_grad_group_constraint {
  grad_group_pkey = "grad_group_pkey",
  grad_group_recruiter_userid_grad_group_id_key = "grad_group_recruiter_userid_grad_group_id_key",
}

/**
 * unique or primary key constraints on table "recruit.grad_group_member"
 */
export enum recruit_grad_group_member_constraint {
  grad_group_member_pkey = "grad_group_member_pkey",
}

/**
 * update columns of table "recruit.grad_group_member"
 */
export enum recruit_grad_group_member_update_column {
  grad_group_id = "grad_group_id",
  recruiter_user_id = "recruiter_user_id",
  student_user_id = "student_user_id",
}

/**
 * update columns of table "recruit.grad_group"
 */
export enum recruit_grad_group_update_column {
  grad_group_id = "grad_group_id",
  group_name = "group_name",
  recruiter_user_id = "recruiter_user_id",
}

/**
 * unique or primary key constraints on table "recruit.job_posting"
 */
export enum recruit_job_posting_constraint {
  job_posting_job_posting_id_key = "job_posting_job_posting_id_key",
  job_posting_pkey = "job_posting_pkey",
}

/**
 * update columns of table "recruit.job_posting"
 */
export enum recruit_job_posting_update_column {
  account_id = "account_id",
  active = "active",
  creation_date = "creation_date",
  creator_id = "creator_id",
  deadline = "deadline",
  description = "description",
  firm = "firm",
  job_posting_id = "job_posting_id",
  job_type = "job_type",
  modified_date = "modified_date",
  position_title = "position_title",
  public = "public",
  publishing_date = "publishing_date",
  region = "region",
}

/**
 * unique or primary key constraints on table "recruit.job_requirement"
 */
export enum recruit_job_requirement_constraint {
  job_requirement_pkey = "job_requirement_pkey",
}

/**
 * update columns of table "recruit.job_requirement"
 */
export enum recruit_job_requirement_update_column {
  boolean_value = "boolean_value",
  job_posting_id = "job_posting_id",
  job_requirement_id = "job_requirement_id",
  max_range_value = "max_range_value",
  min_range_value = "min_range_value",
  number_value = "number_value",
  requirement_name = "requirement_name",
  requirement_type = "requirement_type",
}

/**
 * unique or primary key constraints on table "recruit.job_requirement_value"
 */
export enum recruit_job_requirement_value_constraint {
  job_requirement_value_pkey = "job_requirement_value_pkey",
}

/**
 * update columns of table "recruit.job_requirement_value"
 */
export enum recruit_job_requirement_value_update_column {
  job_requirement_id = "job_requirement_id",
  job_requirement_value_id = "job_requirement_value_id",
  label = "label",
  value = "value",
  value_id = "value_id",
}

/**
 * unique or primary key constraints on table "recruit.recruiter"
 */
export enum recruit_recruiter_constraint {
  recruiter_account_id_user_id_key = "recruiter_account_id_user_id_key",
  recruiter_pkey = "recruiter_pkey",
  recruiter_userid_key = "recruiter_userid_key",
}

/**
 * update columns of table "recruit.recruiter"
 */
export enum recruit_recruiter_update_column {
  account_id = "account_id",
  email = "email",
  fullname = "fullname",
  twilio_phone = "twilio_phone",
  user_id = "user_id",
}

/**
 * unique or primary key constraints on table "recruit.sms_message"
 */
export enum recruit_sms_message_constraint {
  sms_message_pkey = "sms_message_pkey",
}

/**
 * unique or primary key constraints on table "recruit.sms_message_correspondent"
 */
export enum recruit_sms_message_correspondent_constraint {
  sms_message_correspondent_pkey = "sms_message_correspondent_pkey",
}

/**
 * update columns of table "recruit.sms_message_correspondent"
 */
export enum recruit_sms_message_correspondent_update_column {
  correspondent_user_id = "correspondent_user_id",
  error_message = "error_message",
  sms_message_correspondent_id = "sms_message_correspondent_id",
  sms_message_id = "sms_message_id",
}

/**
 * update columns of table "recruit.sms_message"
 */
export enum recruit_sms_message_update_column {
  content = "content",
  incoming = "incoming",
  recruiter_user_id = "recruiter_user_id",
  sms_message_id = "sms_message_id",
  timestamp = "timestamp",
}

/**
 * expression to compare columns of type Boolean. All fields are combined with logical 'AND'.
 */
export interface Boolean_comparison_exp {
  _eq?: boolean | null;
  _gt?: boolean | null;
  _gte?: boolean | null;
  _in?: boolean[] | null;
  _is_null?: boolean | null;
  _lt?: boolean | null;
  _lte?: boolean | null;
  _neq?: boolean | null;
  _nin?: boolean[] | null;
}

/**
 * expression to compare columns of type Int. All fields are combined with logical 'AND'.
 */
export interface Int_comparison_exp {
  _eq?: number | null;
  _gt?: number | null;
  _gte?: number | null;
  _in?: number[] | null;
  _is_null?: boolean | null;
  _lt?: number | null;
  _lte?: number | null;
  _neq?: number | null;
  _nin?: number[] | null;
}

/**
 * expression to compare columns of type String. All fields are combined with logical 'AND'.
 */
export interface String_comparison_exp {
  _eq?: string | null;
  _gt?: string | null;
  _gte?: string | null;
  _ilike?: string | null;
  _in?: string[] | null;
  _is_null?: boolean | null;
  _like?: string | null;
  _lt?: string | null;
  _lte?: string | null;
  _neq?: string | null;
  _nilike?: string | null;
  _nin?: string[] | null;
  _nlike?: string | null;
  _nsimilar?: string | null;
  _similar?: string | null;
}

/**
 * Boolean expression to filter rows from the table "admin.adminuser". All fields are combined with a logical 'AND'.
 */
export interface admin_adminuser_bool_exp {
  _and?: (admin_adminuser_bool_exp | null)[] | null;
  _not?: admin_adminuser_bool_exp | null;
  _or?: (admin_adminuser_bool_exp | null)[] | null;
  email?: String_comparison_exp | null;
  fullname?: String_comparison_exp | null;
  sms_messages?: recruit_sms_message_bool_exp | null;
  twilio_phone?: String_comparison_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "admin.adminuser"
 */
export interface admin_adminuser_insert_input {
  email?: string | null;
  fullname?: string | null;
  sms_messages?: recruit_sms_message_arr_rel_insert_input | null;
  twilio_phone?: string | null;
  user_id?: string | null;
}

/**
 * input type for inserting object relation for remote table "admin.adminuser"
 */
export interface admin_adminuser_obj_rel_insert_input {
  data: admin_adminuser_insert_input;
  on_conflict?: admin_adminuser_on_conflict | null;
}

/**
 * on conflict condition type for table "admin.adminuser"
 */
export interface admin_adminuser_on_conflict {
  constraint: admin_adminuser_constraint;
  update_columns: admin_adminuser_update_column[];
  where?: admin_adminuser_bool_exp | null;
}

/**
 * expression to compare columns of type date. All fields are combined with logical 'AND'.
 */
export interface date_comparison_exp {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

/**
 * order by aggregate values of table "grad.achievement"
 */
export interface grad_achievement_aggregate_order_by {
  count?: order_by | null;
  max?: grad_achievement_max_order_by | null;
  min?: grad_achievement_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "grad.achievement"
 */
export interface grad_achievement_arr_rel_insert_input {
  data: grad_achievement_insert_input[];
  on_conflict?: grad_achievement_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "grad.achievement". All fields are combined with a logical 'AND'.
 */
export interface grad_achievement_bool_exp {
  _and?: (grad_achievement_bool_exp | null)[] | null;
  _not?: grad_achievement_bool_exp | null;
  _or?: (grad_achievement_bool_exp | null)[] | null;
  achievement_id?: String_comparison_exp | null;
  description?: String_comparison_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "grad.achievement"
 */
export interface grad_achievement_insert_input {
  achievement_id?: string | null;
  description?: string | null;
  user_id?: string | null;
}

/**
 * order by max() on columns of table "grad.achievement"
 */
export interface grad_achievement_max_order_by {
  achievement_id?: order_by | null;
  description?: order_by | null;
  user_id?: order_by | null;
}

/**
 * order by min() on columns of table "grad.achievement"
 */
export interface grad_achievement_min_order_by {
  achievement_id?: order_by | null;
  description?: order_by | null;
  user_id?: order_by | null;
}

/**
 * on conflict condition type for table "grad.achievement"
 */
export interface grad_achievement_on_conflict {
  constraint: grad_achievement_constraint;
  update_columns: grad_achievement_update_column[];
  where?: grad_achievement_bool_exp | null;
}

/**
 * order by aggregate values of table "grad.award"
 */
export interface grad_award_aggregate_order_by {
  avg?: grad_award_avg_order_by | null;
  count?: order_by | null;
  max?: grad_award_max_order_by | null;
  min?: grad_award_min_order_by | null;
  stddev?: grad_award_stddev_order_by | null;
  stddev_pop?: grad_award_stddev_pop_order_by | null;
  stddev_samp?: grad_award_stddev_samp_order_by | null;
  sum?: grad_award_sum_order_by | null;
  var_pop?: grad_award_var_pop_order_by | null;
  var_samp?: grad_award_var_samp_order_by | null;
  variance?: grad_award_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "grad.award"
 */
export interface grad_award_arr_rel_insert_input {
  data: grad_award_insert_input[];
  on_conflict?: grad_award_on_conflict | null;
}

/**
 * order by avg() on columns of table "grad.award"
 */
export interface grad_award_avg_order_by {
  year_received?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "grad.award". All fields are combined with a logical 'AND'.
 */
export interface grad_award_bool_exp {
  _and?: (grad_award_bool_exp | null)[] | null;
  _not?: grad_award_bool_exp | null;
  _or?: (grad_award_bool_exp | null)[] | null;
  award_category?: String_comparison_exp | null;
  award_id?: String_comparison_exp | null;
  award_name?: String_comparison_exp | null;
  degree_id?: String_comparison_exp | null;
  user_id?: String_comparison_exp | null;
  work_experience_id?: String_comparison_exp | null;
  year_received?: numeric_comparison_exp | null;
}

/**
 * input type for inserting data into table "grad.award"
 */
export interface grad_award_insert_input {
  award_category?: string | null;
  award_id?: string | null;
  award_name?: string | null;
  degree_id?: string | null;
  user_id?: string | null;
  work_experience_id?: string | null;
  year_received?: any | null;
}

/**
 * order by max() on columns of table "grad.award"
 */
export interface grad_award_max_order_by {
  award_category?: order_by | null;
  award_id?: order_by | null;
  award_name?: order_by | null;
  degree_id?: order_by | null;
  user_id?: order_by | null;
  work_experience_id?: order_by | null;
  year_received?: order_by | null;
}

/**
 * order by min() on columns of table "grad.award"
 */
export interface grad_award_min_order_by {
  award_category?: order_by | null;
  award_id?: order_by | null;
  award_name?: order_by | null;
  degree_id?: order_by | null;
  user_id?: order_by | null;
  work_experience_id?: order_by | null;
  year_received?: order_by | null;
}

/**
 * on conflict condition type for table "grad.award"
 */
export interface grad_award_on_conflict {
  constraint: grad_award_constraint;
  update_columns: grad_award_update_column[];
  where?: grad_award_bool_exp | null;
}

/**
 * order by stddev() on columns of table "grad.award"
 */
export interface grad_award_stddev_order_by {
  year_received?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "grad.award"
 */
export interface grad_award_stddev_pop_order_by {
  year_received?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "grad.award"
 */
export interface grad_award_stddev_samp_order_by {
  year_received?: order_by | null;
}

/**
 * order by sum() on columns of table "grad.award"
 */
export interface grad_award_sum_order_by {
  year_received?: order_by | null;
}

/**
 * order by var_pop() on columns of table "grad.award"
 */
export interface grad_award_var_pop_order_by {
  year_received?: order_by | null;
}

/**
 * order by var_samp() on columns of table "grad.award"
 */
export interface grad_award_var_samp_order_by {
  year_received?: order_by | null;
}

/**
 * order by variance() on columns of table "grad.award"
 */
export interface grad_award_variance_order_by {
  year_received?: order_by | null;
}

/**
 * input type for inserting array relation for remote table "grad.bookmark_job"
 */
export interface grad_bookmark_job_arr_rel_insert_input {
  data: grad_bookmark_job_insert_input[];
  on_conflict?: grad_bookmark_job_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "grad.bookmark_job". All fields are combined with a logical 'AND'.
 */
export interface grad_bookmark_job_bool_exp {
  _and?: (grad_bookmark_job_bool_exp | null)[] | null;
  _not?: grad_bookmark_job_bool_exp | null;
  _or?: (grad_bookmark_job_bool_exp | null)[] | null;
  bookmark_job_id?: String_comparison_exp | null;
  job_posting_id?: String_comparison_exp | null;
  timestamp?: timestamptz_comparison_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "grad.bookmark_job"
 */
export interface grad_bookmark_job_insert_input {
  bookmark_job_id?: string | null;
  job_posting_id?: string | null;
  timestamp?: any | null;
  user_id?: string | null;
}

/**
 * on conflict condition type for table "grad.bookmark_job"
 */
export interface grad_bookmark_job_on_conflict {
  constraint: grad_bookmark_job_constraint;
  update_columns: grad_bookmark_job_update_column[];
  where?: grad_bookmark_job_bool_exp | null;
}

/**
 * order by aggregate values of table "grad.computer_language"
 */
export interface grad_computer_language_aggregate_order_by {
  count?: order_by | null;
  max?: grad_computer_language_max_order_by | null;
  min?: grad_computer_language_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "grad.computer_language"
 */
export interface grad_computer_language_arr_rel_insert_input {
  data: grad_computer_language_insert_input[];
  on_conflict?: grad_computer_language_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "grad.computer_language". All fields are combined with a logical 'AND'.
 */
export interface grad_computer_language_bool_exp {
  _and?: (grad_computer_language_bool_exp | null)[] | null;
  _not?: grad_computer_language_bool_exp | null;
  _or?: (grad_computer_language_bool_exp | null)[] | null;
  computer_language_id?: String_comparison_exp | null;
  language?: String_comparison_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "grad.computer_language"
 */
export interface grad_computer_language_insert_input {
  computer_language_id?: string | null;
  language?: string | null;
  user_id?: string | null;
}

/**
 * order by max() on columns of table "grad.computer_language"
 */
export interface grad_computer_language_max_order_by {
  computer_language_id?: order_by | null;
  language?: order_by | null;
  user_id?: order_by | null;
}

/**
 * order by min() on columns of table "grad.computer_language"
 */
export interface grad_computer_language_min_order_by {
  computer_language_id?: order_by | null;
  language?: order_by | null;
  user_id?: order_by | null;
}

/**
 * on conflict condition type for table "grad.computer_language"
 */
export interface grad_computer_language_on_conflict {
  constraint: grad_computer_language_constraint;
  update_columns: grad_computer_language_update_column[];
  where?: grad_computer_language_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "grad.concentration"
 */
export interface grad_concentration_arr_rel_insert_input {
  data: grad_concentration_insert_input[];
  on_conflict?: grad_concentration_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "grad.concentration". All fields are combined with a logical 'AND'.
 */
export interface grad_concentration_bool_exp {
  _and?: (grad_concentration_bool_exp | null)[] | null;
  _not?: grad_concentration_bool_exp | null;
  _or?: (grad_concentration_bool_exp | null)[] | null;
  concentration_id?: String_comparison_exp | null;
  degree_id?: String_comparison_exp | null;
  type?: String_comparison_exp | null;
  user_id?: String_comparison_exp | null;
  val?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "grad.concentration"
 */
export interface grad_concentration_insert_input {
  concentration_id?: string | null;
  degree_id?: string | null;
  type?: string | null;
  user_id?: string | null;
  val?: string | null;
}

/**
 * on conflict condition type for table "grad.concentration"
 */
export interface grad_concentration_on_conflict {
  constraint: grad_concentration_constraint;
  update_columns: grad_concentration_update_column[];
  where?: grad_concentration_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "grad.country_selection". All fields are combined with a logical 'AND'.
 */
export interface grad_country_selection_bool_exp {
  _and?: (grad_country_selection_bool_exp | null)[] | null;
  _not?: grad_country_selection_bool_exp | null;
  _or?: (grad_country_selection_bool_exp | null)[] | null;
  country?: String_comparison_exp | null;
  country_selection_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "grad.country_selection"
 */
export interface grad_country_selection_insert_input {
  country?: string | null;
  country_selection_id?: string | null;
}

/**
 * input type for inserting object relation for remote table "grad.country_selection"
 */
export interface grad_country_selection_obj_rel_insert_input {
  data: grad_country_selection_insert_input;
  on_conflict?: grad_country_selection_on_conflict | null;
}

/**
 * on conflict condition type for table "grad.country_selection"
 */
export interface grad_country_selection_on_conflict {
  constraint: grad_country_selection_constraint;
  update_columns: grad_country_selection_update_column[];
  where?: grad_country_selection_bool_exp | null;
}

/**
 * ordering options when selecting data from "grad.country_selection"
 */
export interface grad_country_selection_order_by {
  country?: order_by | null;
  country_selection_id?: order_by | null;
}

/**
 * order by aggregate values of table "grad.degree"
 */
export interface grad_degree_aggregate_order_by {
  avg?: grad_degree_avg_order_by | null;
  count?: order_by | null;
  max?: grad_degree_max_order_by | null;
  min?: grad_degree_min_order_by | null;
  stddev?: grad_degree_stddev_order_by | null;
  stddev_pop?: grad_degree_stddev_pop_order_by | null;
  stddev_samp?: grad_degree_stddev_samp_order_by | null;
  sum?: grad_degree_sum_order_by | null;
  var_pop?: grad_degree_var_pop_order_by | null;
  var_samp?: grad_degree_var_samp_order_by | null;
  variance?: grad_degree_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "grad.degree"
 */
export interface grad_degree_arr_rel_insert_input {
  data: grad_degree_insert_input[];
  on_conflict?: grad_degree_on_conflict | null;
}

/**
 * order by avg() on columns of table "grad.degree"
 */
export interface grad_degree_avg_order_by {
  complete_month_numeric?: order_by | null;
  complete_year?: order_by | null;
  gpa?: order_by | null;
  gpa_outof?: order_by | null;
  start_month_numeric?: order_by | null;
  start_year?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "grad.degree". All fields are combined with a logical 'AND'.
 */
export interface grad_degree_bool_exp {
  _and?: (grad_degree_bool_exp | null)[] | null;
  _not?: grad_degree_bool_exp | null;
  _or?: (grad_degree_bool_exp | null)[] | null;
  awards?: grad_award_bool_exp | null;
  complete_month?: String_comparison_exp | null;
  complete_month_numeric?: Int_comparison_exp | null;
  complete_year?: numeric_comparison_exp | null;
  completed?: Boolean_comparison_exp | null;
  concentrations?: grad_concentration_bool_exp | null;
  current?: Boolean_comparison_exp | null;
  degree?: String_comparison_exp | null;
  degree_id?: String_comparison_exp | null;
  degree_level?: String_comparison_exp | null;
  fill_in_university?: grad_degree_fill_in_university_bool_exp | null;
  gpa?: numeric_comparison_exp | null;
  gpa_not_applicable?: Boolean_comparison_exp | null;
  gpa_outof?: numeric_comparison_exp | null;
  start_month?: String_comparison_exp | null;
  start_month_numeric?: Int_comparison_exp | null;
  start_year?: numeric_comparison_exp | null;
  university?: grad_university_selection_bool_exp | null;
  university_selection_id?: String_comparison_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * Boolean expression to filter rows from the table "grad.degree_fill_in_university". All fields are combined with a logical 'AND'.
 */
export interface grad_degree_fill_in_university_bool_exp {
  _and?: (grad_degree_fill_in_university_bool_exp | null)[] | null;
  _not?: grad_degree_fill_in_university_bool_exp | null;
  _or?: (grad_degree_fill_in_university_bool_exp | null)[] | null;
  address?: String_comparison_exp | null;
  degree_id?: String_comparison_exp | null;
  general_phone?: String_comparison_exp | null;
  institution_name?: String_comparison_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "grad.degree_fill_in_university"
 */
export interface grad_degree_fill_in_university_insert_input {
  address?: string | null;
  degree_id?: string | null;
  general_phone?: string | null;
  institution_name?: string | null;
  user_id?: string | null;
}

/**
 * input type for inserting object relation for remote table "grad.degree_fill_in_university"
 */
export interface grad_degree_fill_in_university_obj_rel_insert_input {
  data: grad_degree_fill_in_university_insert_input;
  on_conflict?: grad_degree_fill_in_university_on_conflict | null;
}

/**
 * on conflict condition type for table "grad.degree_fill_in_university"
 */
export interface grad_degree_fill_in_university_on_conflict {
  constraint: grad_degree_fill_in_university_constraint;
  update_columns: grad_degree_fill_in_university_update_column[];
  where?: grad_degree_fill_in_university_bool_exp | null;
}

/**
 * input type for inserting data into table "grad.degree"
 */
export interface grad_degree_insert_input {
  awards?: grad_award_arr_rel_insert_input | null;
  complete_month?: string | null;
  complete_month_numeric?: number | null;
  complete_year?: any | null;
  completed?: boolean | null;
  concentrations?: grad_concentration_arr_rel_insert_input | null;
  current?: boolean | null;
  degree?: string | null;
  degree_id?: string | null;
  degree_level?: string | null;
  fill_in_university?: grad_degree_fill_in_university_obj_rel_insert_input | null;
  gpa?: any | null;
  gpa_not_applicable?: boolean | null;
  gpa_outof?: any | null;
  start_month?: string | null;
  start_month_numeric?: number | null;
  start_year?: any | null;
  university?: grad_university_selection_obj_rel_insert_input | null;
  university_selection_id?: string | null;
  user_id?: string | null;
}

/**
 * Boolean expression to filter rows from the table "grad.degree_latest". All fields are combined with a logical 'AND'.
 */
export interface grad_degree_latest_bool_exp {
  _and?: (grad_degree_latest_bool_exp | null)[] | null;
  _not?: grad_degree_latest_bool_exp | null;
  _or?: (grad_degree_latest_bool_exp | null)[] | null;
  complete_month?: String_comparison_exp | null;
  complete_month_numeric?: Int_comparison_exp | null;
  complete_year?: numeric_comparison_exp | null;
  completed?: Boolean_comparison_exp | null;
  current?: Boolean_comparison_exp | null;
  degree?: String_comparison_exp | null;
  degree_id?: String_comparison_exp | null;
  degree_level?: String_comparison_exp | null;
  gpa?: numeric_comparison_exp | null;
  gpa_not_applicable?: Boolean_comparison_exp | null;
  gpa_outof?: numeric_comparison_exp | null;
  start_month?: String_comparison_exp | null;
  start_month_numeric?: Int_comparison_exp | null;
  start_year?: numeric_comparison_exp | null;
  university_selection_id?: String_comparison_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * ordering options when selecting data from "grad.degree_latest"
 */
export interface grad_degree_latest_order_by {
  complete_month?: order_by | null;
  complete_month_numeric?: order_by | null;
  complete_year?: order_by | null;
  completed?: order_by | null;
  current?: order_by | null;
  degree?: order_by | null;
  degree_id?: order_by | null;
  degree_level?: order_by | null;
  gpa?: order_by | null;
  gpa_not_applicable?: order_by | null;
  gpa_outof?: order_by | null;
  start_month?: order_by | null;
  start_month_numeric?: order_by | null;
  start_year?: order_by | null;
  university_selection_id?: order_by | null;
  user_id?: order_by | null;
}

/**
 * order by max() on columns of table "grad.degree"
 */
export interface grad_degree_max_order_by {
  complete_month?: order_by | null;
  complete_month_numeric?: order_by | null;
  complete_year?: order_by | null;
  degree?: order_by | null;
  degree_id?: order_by | null;
  degree_level?: order_by | null;
  gpa?: order_by | null;
  gpa_outof?: order_by | null;
  start_month?: order_by | null;
  start_month_numeric?: order_by | null;
  start_year?: order_by | null;
  university_selection_id?: order_by | null;
  user_id?: order_by | null;
}

/**
 * order by min() on columns of table "grad.degree"
 */
export interface grad_degree_min_order_by {
  complete_month?: order_by | null;
  complete_month_numeric?: order_by | null;
  complete_year?: order_by | null;
  degree?: order_by | null;
  degree_id?: order_by | null;
  degree_level?: order_by | null;
  gpa?: order_by | null;
  gpa_outof?: order_by | null;
  start_month?: order_by | null;
  start_month_numeric?: order_by | null;
  start_year?: order_by | null;
  university_selection_id?: order_by | null;
  user_id?: order_by | null;
}

/**
 * on conflict condition type for table "grad.degree"
 */
export interface grad_degree_on_conflict {
  constraint: grad_degree_constraint;
  update_columns: grad_degree_update_column[];
  where?: grad_degree_bool_exp | null;
}

/**
 * order by stddev() on columns of table "grad.degree"
 */
export interface grad_degree_stddev_order_by {
  complete_month_numeric?: order_by | null;
  complete_year?: order_by | null;
  gpa?: order_by | null;
  gpa_outof?: order_by | null;
  start_month_numeric?: order_by | null;
  start_year?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "grad.degree"
 */
export interface grad_degree_stddev_pop_order_by {
  complete_month_numeric?: order_by | null;
  complete_year?: order_by | null;
  gpa?: order_by | null;
  gpa_outof?: order_by | null;
  start_month_numeric?: order_by | null;
  start_year?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "grad.degree"
 */
export interface grad_degree_stddev_samp_order_by {
  complete_month_numeric?: order_by | null;
  complete_year?: order_by | null;
  gpa?: order_by | null;
  gpa_outof?: order_by | null;
  start_month_numeric?: order_by | null;
  start_year?: order_by | null;
}

/**
 * order by sum() on columns of table "grad.degree"
 */
export interface grad_degree_sum_order_by {
  complete_month_numeric?: order_by | null;
  complete_year?: order_by | null;
  gpa?: order_by | null;
  gpa_outof?: order_by | null;
  start_month_numeric?: order_by | null;
  start_year?: order_by | null;
}

/**
 * order by var_pop() on columns of table "grad.degree"
 */
export interface grad_degree_var_pop_order_by {
  complete_month_numeric?: order_by | null;
  complete_year?: order_by | null;
  gpa?: order_by | null;
  gpa_outof?: order_by | null;
  start_month_numeric?: order_by | null;
  start_year?: order_by | null;
}

/**
 * order by var_samp() on columns of table "grad.degree"
 */
export interface grad_degree_var_samp_order_by {
  complete_month_numeric?: order_by | null;
  complete_year?: order_by | null;
  gpa?: order_by | null;
  gpa_outof?: order_by | null;
  start_month_numeric?: order_by | null;
  start_year?: order_by | null;
}

/**
 * order by variance() on columns of table "grad.degree"
 */
export interface grad_degree_variance_order_by {
  complete_month_numeric?: order_by | null;
  complete_year?: order_by | null;
  gpa?: order_by | null;
  gpa_outof?: order_by | null;
  start_month_numeric?: order_by | null;
  start_year?: order_by | null;
}

/**
 * order by aggregate values of table "grad.employer_preference"
 */
export interface grad_employer_preference_aggregate_order_by {
  count?: order_by | null;
  max?: grad_employer_preference_max_order_by | null;
  min?: grad_employer_preference_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "grad.employer_preference"
 */
export interface grad_employer_preference_arr_rel_insert_input {
  data: grad_employer_preference_insert_input[];
  on_conflict?: grad_employer_preference_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "grad.employer_preference". All fields are combined with a logical 'AND'.
 */
export interface grad_employer_preference_bool_exp {
  _and?: (grad_employer_preference_bool_exp | null)[] | null;
  _not?: grad_employer_preference_bool_exp | null;
  _or?: (grad_employer_preference_bool_exp | null)[] | null;
  employer_preference_id?: String_comparison_exp | null;
  preference?: String_comparison_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "grad.employer_preference"
 */
export interface grad_employer_preference_insert_input {
  employer_preference_id?: string | null;
  preference?: string | null;
  user_id?: string | null;
}

/**
 * order by max() on columns of table "grad.employer_preference"
 */
export interface grad_employer_preference_max_order_by {
  employer_preference_id?: order_by | null;
  preference?: order_by | null;
  user_id?: order_by | null;
}

/**
 * order by min() on columns of table "grad.employer_preference"
 */
export interface grad_employer_preference_min_order_by {
  employer_preference_id?: order_by | null;
  preference?: order_by | null;
  user_id?: order_by | null;
}

/**
 * on conflict condition type for table "grad.employer_preference"
 */
export interface grad_employer_preference_on_conflict {
  constraint: grad_employer_preference_constraint;
  update_columns: grad_employer_preference_update_column[];
  where?: grad_employer_preference_bool_exp | null;
}

/**
 * order by aggregate values of table "grad.event_registration"
 */
export interface grad_event_registration_aggregate_order_by {
  count?: order_by | null;
  max?: grad_event_registration_max_order_by | null;
  min?: grad_event_registration_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "grad.event_registration"
 */
export interface grad_event_registration_arr_rel_insert_input {
  data: grad_event_registration_insert_input[];
  on_conflict?: grad_event_registration_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "grad.event_registration". All fields are combined with a logical 'AND'.
 */
export interface grad_event_registration_bool_exp {
  _and?: (grad_event_registration_bool_exp | null)[] | null;
  _not?: grad_event_registration_bool_exp | null;
  _or?: (grad_event_registration_bool_exp | null)[] | null;
  event?: recruit_event_bool_exp | null;
  event_id?: String_comparison_exp | null;
  event_registration_id?: String_comparison_exp | null;
  profile?: grad_profile_bool_exp | null;
  timestamp?: timestamptz_comparison_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "grad.event_registration"
 */
export interface grad_event_registration_insert_input {
  event?: recruit_event_obj_rel_insert_input | null;
  event_id?: string | null;
  event_registration_id?: string | null;
  profile?: grad_profile_obj_rel_insert_input | null;
  timestamp?: any | null;
  user_id?: string | null;
}

/**
 * order by max() on columns of table "grad.event_registration"
 */
export interface grad_event_registration_max_order_by {
  event_id?: order_by | null;
  event_registration_id?: order_by | null;
  timestamp?: order_by | null;
  user_id?: order_by | null;
}

/**
 * order by min() on columns of table "grad.event_registration"
 */
export interface grad_event_registration_min_order_by {
  event_id?: order_by | null;
  event_registration_id?: order_by | null;
  timestamp?: order_by | null;
  user_id?: order_by | null;
}

/**
 * on conflict condition type for table "grad.event_registration"
 */
export interface grad_event_registration_on_conflict {
  constraint: grad_event_registration_constraint;
  update_columns: grad_event_registration_update_column[];
  where?: grad_event_registration_bool_exp | null;
}

/**
 * order by aggregate values of table "grad.fun_fact"
 */
export interface grad_fun_fact_aggregate_order_by {
  count?: order_by | null;
  max?: grad_fun_fact_max_order_by | null;
  min?: grad_fun_fact_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "grad.fun_fact"
 */
export interface grad_fun_fact_arr_rel_insert_input {
  data: grad_fun_fact_insert_input[];
  on_conflict?: grad_fun_fact_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "grad.fun_fact". All fields are combined with a logical 'AND'.
 */
export interface grad_fun_fact_bool_exp {
  _and?: (grad_fun_fact_bool_exp | null)[] | null;
  _not?: grad_fun_fact_bool_exp | null;
  _or?: (grad_fun_fact_bool_exp | null)[] | null;
  description?: String_comparison_exp | null;
  fun_fact_id?: String_comparison_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "grad.fun_fact"
 */
export interface grad_fun_fact_insert_input {
  description?: string | null;
  fun_fact_id?: string | null;
  user_id?: string | null;
}

/**
 * order by max() on columns of table "grad.fun_fact"
 */
export interface grad_fun_fact_max_order_by {
  description?: order_by | null;
  fun_fact_id?: order_by | null;
  user_id?: order_by | null;
}

/**
 * order by min() on columns of table "grad.fun_fact"
 */
export interface grad_fun_fact_min_order_by {
  description?: order_by | null;
  fun_fact_id?: order_by | null;
  user_id?: order_by | null;
}

/**
 * on conflict condition type for table "grad.fun_fact"
 */
export interface grad_fun_fact_on_conflict {
  constraint: grad_fun_fact_constraint;
  update_columns: grad_fun_fact_update_column[];
  where?: grad_fun_fact_bool_exp | null;
}

/**
 * order by aggregate values of table "grad.interest_firm"
 */
export interface grad_interest_firm_aggregate_order_by {
  count?: order_by | null;
  max?: grad_interest_firm_max_order_by | null;
  min?: grad_interest_firm_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "grad.interest_firm"
 */
export interface grad_interest_firm_arr_rel_insert_input {
  data: grad_interest_firm_insert_input[];
  on_conflict?: grad_interest_firm_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "grad.interest_firm". All fields are combined with a logical 'AND'.
 */
export interface grad_interest_firm_bool_exp {
  _and?: (grad_interest_firm_bool_exp | null)[] | null;
  _not?: grad_interest_firm_bool_exp | null;
  _or?: (grad_interest_firm_bool_exp | null)[] | null;
  firm_type?: String_comparison_exp | null;
  interest_firm_id?: String_comparison_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "grad.interest_firm"
 */
export interface grad_interest_firm_insert_input {
  firm_type?: string | null;
  interest_firm_id?: string | null;
  user_id?: string | null;
}

/**
 * order by max() on columns of table "grad.interest_firm"
 */
export interface grad_interest_firm_max_order_by {
  firm_type?: order_by | null;
  interest_firm_id?: order_by | null;
  user_id?: order_by | null;
}

/**
 * order by min() on columns of table "grad.interest_firm"
 */
export interface grad_interest_firm_min_order_by {
  firm_type?: order_by | null;
  interest_firm_id?: order_by | null;
  user_id?: order_by | null;
}

/**
 * on conflict condition type for table "grad.interest_firm"
 */
export interface grad_interest_firm_on_conflict {
  constraint: grad_interest_firm_constraint;
  update_columns: grad_interest_firm_update_column[];
  where?: grad_interest_firm_bool_exp | null;
}

/**
 * order by aggregate values of table "grad.interest_job_type"
 */
export interface grad_interest_job_type_aggregate_order_by {
  count?: order_by | null;
  max?: grad_interest_job_type_max_order_by | null;
  min?: grad_interest_job_type_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "grad.interest_job_type"
 */
export interface grad_interest_job_type_arr_rel_insert_input {
  data: grad_interest_job_type_insert_input[];
  on_conflict?: grad_interest_job_type_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "grad.interest_job_type". All fields are combined with a logical 'AND'.
 */
export interface grad_interest_job_type_bool_exp {
  _and?: (grad_interest_job_type_bool_exp | null)[] | null;
  _not?: grad_interest_job_type_bool_exp | null;
  _or?: (grad_interest_job_type_bool_exp | null)[] | null;
  interest_job_type_id?: String_comparison_exp | null;
  job_type?: String_comparison_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "grad.interest_job_type"
 */
export interface grad_interest_job_type_insert_input {
  interest_job_type_id?: string | null;
  job_type?: string | null;
  user_id?: string | null;
}

/**
 * order by max() on columns of table "grad.interest_job_type"
 */
export interface grad_interest_job_type_max_order_by {
  interest_job_type_id?: order_by | null;
  job_type?: order_by | null;
  user_id?: order_by | null;
}

/**
 * order by min() on columns of table "grad.interest_job_type"
 */
export interface grad_interest_job_type_min_order_by {
  interest_job_type_id?: order_by | null;
  job_type?: order_by | null;
  user_id?: order_by | null;
}

/**
 * on conflict condition type for table "grad.interest_job_type"
 */
export interface grad_interest_job_type_on_conflict {
  constraint: grad_interest_job_type_constraint;
  update_columns: grad_interest_job_type_update_column[];
  where?: grad_interest_job_type_bool_exp | null;
}

/**
 * order by aggregate values of table "grad.interest_region"
 */
export interface grad_interest_region_aggregate_order_by {
  count?: order_by | null;
  max?: grad_interest_region_max_order_by | null;
  min?: grad_interest_region_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "grad.interest_region"
 */
export interface grad_interest_region_arr_rel_insert_input {
  data: grad_interest_region_insert_input[];
  on_conflict?: grad_interest_region_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "grad.interest_region". All fields are combined with a logical 'AND'.
 */
export interface grad_interest_region_bool_exp {
  _and?: (grad_interest_region_bool_exp | null)[] | null;
  _not?: grad_interest_region_bool_exp | null;
  _or?: (grad_interest_region_bool_exp | null)[] | null;
  interest_region_id?: String_comparison_exp | null;
  region?: String_comparison_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "grad.interest_region"
 */
export interface grad_interest_region_insert_input {
  interest_region_id?: string | null;
  region?: string | null;
  user_id?: string | null;
}

/**
 * order by max() on columns of table "grad.interest_region"
 */
export interface grad_interest_region_max_order_by {
  interest_region_id?: order_by | null;
  region?: order_by | null;
  user_id?: order_by | null;
}

/**
 * order by min() on columns of table "grad.interest_region"
 */
export interface grad_interest_region_min_order_by {
  interest_region_id?: order_by | null;
  region?: order_by | null;
  user_id?: order_by | null;
}

/**
 * on conflict condition type for table "grad.interest_region"
 */
export interface grad_interest_region_on_conflict {
  constraint: grad_interest_region_constraint;
  update_columns: grad_interest_region_update_column[];
  where?: grad_interest_region_bool_exp | null;
}

/**
 * order by aggregate values of table "grad.interest_role"
 */
export interface grad_interest_role_aggregate_order_by {
  count?: order_by | null;
  max?: grad_interest_role_max_order_by | null;
  min?: grad_interest_role_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "grad.interest_role"
 */
export interface grad_interest_role_arr_rel_insert_input {
  data: grad_interest_role_insert_input[];
  on_conflict?: grad_interest_role_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "grad.interest_role". All fields are combined with a logical 'AND'.
 */
export interface grad_interest_role_bool_exp {
  _and?: (grad_interest_role_bool_exp | null)[] | null;
  _not?: grad_interest_role_bool_exp | null;
  _or?: (grad_interest_role_bool_exp | null)[] | null;
  interest_role_id?: String_comparison_exp | null;
  role?: String_comparison_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "grad.interest_role"
 */
export interface grad_interest_role_insert_input {
  interest_role_id?: string | null;
  role?: string | null;
  user_id?: string | null;
}

/**
 * order by max() on columns of table "grad.interest_role"
 */
export interface grad_interest_role_max_order_by {
  interest_role_id?: order_by | null;
  role?: order_by | null;
  user_id?: order_by | null;
}

/**
 * order by min() on columns of table "grad.interest_role"
 */
export interface grad_interest_role_min_order_by {
  interest_role_id?: order_by | null;
  role?: order_by | null;
  user_id?: order_by | null;
}

/**
 * on conflict condition type for table "grad.interest_role"
 */
export interface grad_interest_role_on_conflict {
  constraint: grad_interest_role_constraint;
  update_columns: grad_interest_role_update_column[];
  where?: grad_interest_role_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "grad.profile". All fields are combined with a logical 'AND'.
 */
export interface grad_profile_bool_exp {
  _and?: (grad_profile_bool_exp | null)[] | null;
  _not?: grad_profile_bool_exp | null;
  _or?: (grad_profile_bool_exp | null)[] | null;
  achievements?: grad_achievement_bool_exp | null;
  activity_summary?: grad_user_activity_summary_bool_exp | null;
  authorized_to_work?: Boolean_comparison_exp | null;
  awards?: grad_award_bool_exp | null;
  birth_date?: date_comparison_exp | null;
  blocked?: Boolean_comparison_exp | null;
  blocked_reason?: String_comparison_exp | null;
  combine?: grad_profile_combine_bool_exp | null;
  comm_preference?: String_comparison_exp | null;
  computer_languages?: grad_computer_language_bool_exp | null;
  creation_timestamp?: timestamptz_comparison_exp | null;
  degree_latest?: grad_degree_latest_bool_exp | null;
  degrees?: grad_degree_bool_exp | null;
  email?: String_comparison_exp | null;
  email_verified?: Boolean_comparison_exp | null;
  employer_preferences?: grad_employer_preference_bool_exp | null;
  event_registrations?: grad_event_registration_bool_exp | null;
  firstname?: String_comparison_exp | null;
  fullname?: String_comparison_exp | null;
  fun_facts?: grad_fun_fact_bool_exp | null;
  grad_group_members?: recruit_grad_group_member_bool_exp | null;
  hometown?: String_comparison_exp | null;
  interest_firms?: grad_interest_firm_bool_exp | null;
  interest_job_types?: grad_interest_job_type_bool_exp | null;
  interest_regions?: grad_interest_region_bool_exp | null;
  interest_roles?: grad_interest_role_bool_exp | null;
  lastname?: String_comparison_exp | null;
  legal_residence_country?: grad_country_selection_bool_exp | null;
  legal_residence_country_id?: String_comparison_exp | null;
  middlename?: String_comparison_exp | null;
  minority_search?: Boolean_comparison_exp | null;
  mobile?: String_comparison_exp | null;
  mobile_valid?: Boolean_comparison_exp | null;
  need_sponsor?: Boolean_comparison_exp | null;
  prior_internship?: Boolean_comparison_exp | null;
  profile_image?: String_comparison_exp | null;
  profile_verified?: Boolean_comparison_exp | null;
  raise_hands?: grad_raise_hand_bool_exp | null;
  spoken_languages?: grad_spoken_language_bool_exp | null;
  ssn?: String_comparison_exp | null;
  student_athlete?: Boolean_comparison_exp | null;
  test_scores?: grad_test_score_bool_exp | null;
  title?: String_comparison_exp | null;
  upload_docs?: grad_upload_doc_bool_exp | null;
  user_id?: String_comparison_exp | null;
  visa_expiration?: date_comparison_exp | null;
  visa_status?: String_comparison_exp | null;
  visa_type?: String_comparison_exp | null;
  volunteers?: grad_volunteer_bool_exp | null;
  will_relocate?: Boolean_comparison_exp | null;
  wizard_completed?: Boolean_comparison_exp | null;
  wizard_completion_timestamp?: timestamptz_comparison_exp | null;
  work_experiences?: grad_work_experience_bool_exp | null;
  years_of_relevant_work?: String_comparison_exp | null;
}

/**
 * Boolean expression to filter rows from the table "grad.profile_combine". All fields are combined with a logical 'AND'.
 */
export interface grad_profile_combine_bool_exp {
  _and?: (grad_profile_combine_bool_exp | null)[] | null;
  _not?: grad_profile_combine_bool_exp | null;
  _or?: (grad_profile_combine_bool_exp | null)[] | null;
  content?: String_comparison_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "grad.profile_combine"
 */
export interface grad_profile_combine_insert_input {
  content?: string | null;
  user_id?: string | null;
}

/**
 * input type for inserting object relation for remote table "grad.profile_combine"
 */
export interface grad_profile_combine_obj_rel_insert_input {
  data: grad_profile_combine_insert_input;
  on_conflict?: grad_profile_combine_on_conflict | null;
}

/**
 * on conflict condition type for table "grad.profile_combine"
 */
export interface grad_profile_combine_on_conflict {
  constraint: grad_profile_combine_constraint;
  update_columns: grad_profile_combine_update_column[];
  where?: grad_profile_combine_bool_exp | null;
}

/**
 * ordering options when selecting data from "grad.profile_combine"
 */
export interface grad_profile_combine_order_by {
  content?: order_by | null;
  user_id?: order_by | null;
}

/**
 * input type for inserting data into table "grad.profile"
 */
export interface grad_profile_insert_input {
  achievements?: grad_achievement_arr_rel_insert_input | null;
  activity_summary?: grad_user_activity_summary_obj_rel_insert_input | null;
  authorized_to_work?: boolean | null;
  awards?: grad_award_arr_rel_insert_input | null;
  birth_date?: any | null;
  blocked?: boolean | null;
  blocked_reason?: string | null;
  combine?: grad_profile_combine_obj_rel_insert_input | null;
  comm_preference?: string | null;
  computer_languages?: grad_computer_language_arr_rel_insert_input | null;
  creation_timestamp?: any | null;
  degrees?: grad_degree_arr_rel_insert_input | null;
  email?: string | null;
  email_verified?: boolean | null;
  employer_preferences?: grad_employer_preference_arr_rel_insert_input | null;
  event_registrations?: grad_event_registration_arr_rel_insert_input | null;
  firstname?: string | null;
  fullname?: string | null;
  fun_facts?: grad_fun_fact_arr_rel_insert_input | null;
  grad_group_members?: recruit_grad_group_member_arr_rel_insert_input | null;
  hometown?: string | null;
  interest_firms?: grad_interest_firm_arr_rel_insert_input | null;
  interest_job_types?: grad_interest_job_type_arr_rel_insert_input | null;
  interest_regions?: grad_interest_region_arr_rel_insert_input | null;
  interest_roles?: grad_interest_role_arr_rel_insert_input | null;
  lastname?: string | null;
  legal_residence_country?: grad_country_selection_obj_rel_insert_input | null;
  legal_residence_country_id?: string | null;
  middlename?: string | null;
  minority_search?: boolean | null;
  mobile?: string | null;
  mobile_valid?: boolean | null;
  need_sponsor?: boolean | null;
  prior_internship?: boolean | null;
  profile_image?: string | null;
  profile_verified?: boolean | null;
  raise_hands?: grad_raise_hand_arr_rel_insert_input | null;
  spoken_languages?: grad_spoken_language_arr_rel_insert_input | null;
  ssn?: string | null;
  student_athlete?: boolean | null;
  test_scores?: grad_test_score_arr_rel_insert_input | null;
  title?: string | null;
  upload_docs?: grad_upload_doc_arr_rel_insert_input | null;
  user_id?: string | null;
  visa_expiration?: any | null;
  visa_status?: string | null;
  visa_type?: string | null;
  volunteers?: grad_volunteer_arr_rel_insert_input | null;
  will_relocate?: boolean | null;
  wizard_completed?: boolean | null;
  wizard_completion_timestamp?: any | null;
  work_experiences?: grad_work_experience_arr_rel_insert_input | null;
  years_of_relevant_work?: string | null;
}

/**
 * input type for inserting object relation for remote table "grad.profile"
 */
export interface grad_profile_obj_rel_insert_input {
  data: grad_profile_insert_input;
  on_conflict?: grad_profile_on_conflict | null;
}

/**
 * on conflict condition type for table "grad.profile"
 */
export interface grad_profile_on_conflict {
  constraint: grad_profile_constraint;
  update_columns: grad_profile_update_column[];
  where?: grad_profile_bool_exp | null;
}

/**
 * ordering options when selecting data from "grad.profile"
 */
export interface grad_profile_order_by {
  achievements_aggregate?: grad_achievement_aggregate_order_by | null;
  activity_summary?: grad_user_activity_summary_order_by | null;
  authorized_to_work?: order_by | null;
  awards_aggregate?: grad_award_aggregate_order_by | null;
  birth_date?: order_by | null;
  blocked?: order_by | null;
  blocked_reason?: order_by | null;
  combine?: grad_profile_combine_order_by | null;
  comm_preference?: order_by | null;
  computer_languages_aggregate?: grad_computer_language_aggregate_order_by | null;
  creation_timestamp?: order_by | null;
  degree_latest?: grad_degree_latest_order_by | null;
  degrees_aggregate?: grad_degree_aggregate_order_by | null;
  email?: order_by | null;
  email_verified?: order_by | null;
  employer_preferences_aggregate?: grad_employer_preference_aggregate_order_by | null;
  event_registrations_aggregate?: grad_event_registration_aggregate_order_by | null;
  firstname?: order_by | null;
  fullname?: order_by | null;
  fun_facts_aggregate?: grad_fun_fact_aggregate_order_by | null;
  grad_group_members_aggregate?: recruit_grad_group_member_aggregate_order_by | null;
  hometown?: order_by | null;
  interest_firms_aggregate?: grad_interest_firm_aggregate_order_by | null;
  interest_job_types_aggregate?: grad_interest_job_type_aggregate_order_by | null;
  interest_regions_aggregate?: grad_interest_region_aggregate_order_by | null;
  interest_roles_aggregate?: grad_interest_role_aggregate_order_by | null;
  lastname?: order_by | null;
  legal_residence_country?: grad_country_selection_order_by | null;
  legal_residence_country_id?: order_by | null;
  middlename?: order_by | null;
  minority_search?: order_by | null;
  mobile?: order_by | null;
  mobile_valid?: order_by | null;
  need_sponsor?: order_by | null;
  prior_internship?: order_by | null;
  profile_image?: order_by | null;
  profile_verified?: order_by | null;
  raise_hands_aggregate?: grad_raise_hand_aggregate_order_by | null;
  spoken_languages_aggregate?: grad_spoken_language_aggregate_order_by | null;
  ssn?: order_by | null;
  student_athlete?: order_by | null;
  test_scores_aggregate?: grad_test_score_aggregate_order_by | null;
  title?: order_by | null;
  upload_docs_aggregate?: grad_upload_doc_aggregate_order_by | null;
  user_id?: order_by | null;
  visa_expiration?: order_by | null;
  visa_status?: order_by | null;
  visa_type?: order_by | null;
  volunteers_aggregate?: grad_volunteer_aggregate_order_by | null;
  will_relocate?: order_by | null;
  wizard_completed?: order_by | null;
  wizard_completion_timestamp?: order_by | null;
  work_experiences_aggregate?: grad_work_experience_aggregate_order_by | null;
  years_of_relevant_work?: order_by | null;
}

/**
 * order by aggregate values of table "grad.raise_hand"
 */
export interface grad_raise_hand_aggregate_order_by {
  count?: order_by | null;
  max?: grad_raise_hand_max_order_by | null;
  min?: grad_raise_hand_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "grad.raise_hand"
 */
export interface grad_raise_hand_arr_rel_insert_input {
  data: grad_raise_hand_insert_input[];
  on_conflict?: grad_raise_hand_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "grad.raise_hand". All fields are combined with a logical 'AND'.
 */
export interface grad_raise_hand_bool_exp {
  _and?: (grad_raise_hand_bool_exp | null)[] | null;
  _not?: grad_raise_hand_bool_exp | null;
  _or?: (grad_raise_hand_bool_exp | null)[] | null;
  job_posting?: recruit_job_posting_bool_exp | null;
  job_posting_id?: String_comparison_exp | null;
  profile?: grad_profile_bool_exp | null;
  raise__hand_id?: String_comparison_exp | null;
  timestamp?: timestamptz_comparison_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "grad.raise_hand"
 */
export interface grad_raise_hand_insert_input {
  job_posting?: recruit_job_posting_obj_rel_insert_input | null;
  job_posting_id?: string | null;
  profile?: grad_profile_obj_rel_insert_input | null;
  raise__hand_id?: string | null;
  timestamp?: any | null;
  user_id?: string | null;
}

/**
 * order by max() on columns of table "grad.raise_hand"
 */
export interface grad_raise_hand_max_order_by {
  job_posting_id?: order_by | null;
  raise__hand_id?: order_by | null;
  timestamp?: order_by | null;
  user_id?: order_by | null;
}

/**
 * order by min() on columns of table "grad.raise_hand"
 */
export interface grad_raise_hand_min_order_by {
  job_posting_id?: order_by | null;
  raise__hand_id?: order_by | null;
  timestamp?: order_by | null;
  user_id?: order_by | null;
}

/**
 * on conflict condition type for table "grad.raise_hand"
 */
export interface grad_raise_hand_on_conflict {
  constraint: grad_raise_hand_constraint;
  update_columns: grad_raise_hand_update_column[];
  where?: grad_raise_hand_bool_exp | null;
}

/**
 * order by aggregate values of table "grad.spoken_language"
 */
export interface grad_spoken_language_aggregate_order_by {
  count?: order_by | null;
  max?: grad_spoken_language_max_order_by | null;
  min?: grad_spoken_language_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "grad.spoken_language"
 */
export interface grad_spoken_language_arr_rel_insert_input {
  data: grad_spoken_language_insert_input[];
  on_conflict?: grad_spoken_language_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "grad.spoken_language". All fields are combined with a logical 'AND'.
 */
export interface grad_spoken_language_bool_exp {
  _and?: (grad_spoken_language_bool_exp | null)[] | null;
  _not?: grad_spoken_language_bool_exp | null;
  _or?: (grad_spoken_language_bool_exp | null)[] | null;
  language?: String_comparison_exp | null;
  spoken_language_id?: String_comparison_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "grad.spoken_language"
 */
export interface grad_spoken_language_insert_input {
  language?: string | null;
  spoken_language_id?: string | null;
  user_id?: string | null;
}

/**
 * order by max() on columns of table "grad.spoken_language"
 */
export interface grad_spoken_language_max_order_by {
  language?: order_by | null;
  spoken_language_id?: order_by | null;
  user_id?: order_by | null;
}

/**
 * order by min() on columns of table "grad.spoken_language"
 */
export interface grad_spoken_language_min_order_by {
  language?: order_by | null;
  spoken_language_id?: order_by | null;
  user_id?: order_by | null;
}

/**
 * on conflict condition type for table "grad.spoken_language"
 */
export interface grad_spoken_language_on_conflict {
  constraint: grad_spoken_language_constraint;
  update_columns: grad_spoken_language_update_column[];
  where?: grad_spoken_language_bool_exp | null;
}

/**
 * order by aggregate values of table "grad.test_score"
 */
export interface grad_test_score_aggregate_order_by {
  avg?: grad_test_score_avg_order_by | null;
  count?: order_by | null;
  max?: grad_test_score_max_order_by | null;
  min?: grad_test_score_min_order_by | null;
  stddev?: grad_test_score_stddev_order_by | null;
  stddev_pop?: grad_test_score_stddev_pop_order_by | null;
  stddev_samp?: grad_test_score_stddev_samp_order_by | null;
  sum?: grad_test_score_sum_order_by | null;
  var_pop?: grad_test_score_var_pop_order_by | null;
  var_samp?: grad_test_score_var_samp_order_by | null;
  variance?: grad_test_score_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "grad.test_score"
 */
export interface grad_test_score_arr_rel_insert_input {
  data: grad_test_score_insert_input[];
  on_conflict?: grad_test_score_on_conflict | null;
}

/**
 * order by avg() on columns of table "grad.test_score"
 */
export interface grad_test_score_avg_order_by {
  outof?: order_by | null;
  score?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "grad.test_score". All fields are combined with a logical 'AND'.
 */
export interface grad_test_score_bool_exp {
  _and?: (grad_test_score_bool_exp | null)[] | null;
  _not?: grad_test_score_bool_exp | null;
  _or?: (grad_test_score_bool_exp | null)[] | null;
  date?: date_comparison_exp | null;
  outof?: Int_comparison_exp | null;
  score?: Int_comparison_exp | null;
  test?: String_comparison_exp | null;
  test_score_details?: grad_test_score_detail_bool_exp | null;
  test_score_id?: String_comparison_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * input type for inserting array relation for remote table "grad.test_score_detail"
 */
export interface grad_test_score_detail_arr_rel_insert_input {
  data: grad_test_score_detail_insert_input[];
  on_conflict?: grad_test_score_detail_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "grad.test_score_detail". All fields are combined with a logical 'AND'.
 */
export interface grad_test_score_detail_bool_exp {
  _and?: (grad_test_score_detail_bool_exp | null)[] | null;
  _not?: grad_test_score_detail_bool_exp | null;
  _or?: (grad_test_score_detail_bool_exp | null)[] | null;
  detail?: String_comparison_exp | null;
  outof?: Int_comparison_exp | null;
  score?: Int_comparison_exp | null;
  test?: String_comparison_exp | null;
  test_score_detail_id?: String_comparison_exp | null;
  test_score_id?: String_comparison_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "grad.test_score_detail"
 */
export interface grad_test_score_detail_insert_input {
  detail?: string | null;
  outof?: number | null;
  score?: number | null;
  test?: string | null;
  test_score_detail_id?: string | null;
  test_score_id?: string | null;
  user_id?: string | null;
}

/**
 * on conflict condition type for table "grad.test_score_detail"
 */
export interface grad_test_score_detail_on_conflict {
  constraint: grad_test_score_detail_constraint;
  update_columns: grad_test_score_detail_update_column[];
  where?: grad_test_score_detail_bool_exp | null;
}

/**
 * input type for inserting data into table "grad.test_score"
 */
export interface grad_test_score_insert_input {
  date?: any | null;
  outof?: number | null;
  score?: number | null;
  test?: string | null;
  test_score_details?: grad_test_score_detail_arr_rel_insert_input | null;
  test_score_id?: string | null;
  user_id?: string | null;
}

/**
 * order by max() on columns of table "grad.test_score"
 */
export interface grad_test_score_max_order_by {
  date?: order_by | null;
  outof?: order_by | null;
  score?: order_by | null;
  test?: order_by | null;
  test_score_id?: order_by | null;
  user_id?: order_by | null;
}

/**
 * order by min() on columns of table "grad.test_score"
 */
export interface grad_test_score_min_order_by {
  date?: order_by | null;
  outof?: order_by | null;
  score?: order_by | null;
  test?: order_by | null;
  test_score_id?: order_by | null;
  user_id?: order_by | null;
}

/**
 * on conflict condition type for table "grad.test_score"
 */
export interface grad_test_score_on_conflict {
  constraint: grad_test_score_constraint;
  update_columns: grad_test_score_update_column[];
  where?: grad_test_score_bool_exp | null;
}

/**
 * order by stddev() on columns of table "grad.test_score"
 */
export interface grad_test_score_stddev_order_by {
  outof?: order_by | null;
  score?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "grad.test_score"
 */
export interface grad_test_score_stddev_pop_order_by {
  outof?: order_by | null;
  score?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "grad.test_score"
 */
export interface grad_test_score_stddev_samp_order_by {
  outof?: order_by | null;
  score?: order_by | null;
}

/**
 * order by sum() on columns of table "grad.test_score"
 */
export interface grad_test_score_sum_order_by {
  outof?: order_by | null;
  score?: order_by | null;
}

/**
 * order by var_pop() on columns of table "grad.test_score"
 */
export interface grad_test_score_var_pop_order_by {
  outof?: order_by | null;
  score?: order_by | null;
}

/**
 * order by var_samp() on columns of table "grad.test_score"
 */
export interface grad_test_score_var_samp_order_by {
  outof?: order_by | null;
  score?: order_by | null;
}

/**
 * order by variance() on columns of table "grad.test_score"
 */
export interface grad_test_score_variance_order_by {
  outof?: order_by | null;
  score?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "grad.university_selection". All fields are combined with a logical 'AND'.
 */
export interface grad_university_selection_bool_exp {
  _and?: (grad_university_selection_bool_exp | null)[] | null;
  _not?: grad_university_selection_bool_exp | null;
  _or?: (grad_university_selection_bool_exp | null)[] | null;
  address?: String_comparison_exp | null;
  dapip_id?: String_comparison_exp | null;
  general_phone?: String_comparison_exp | null;
  hidden?: Boolean_comparison_exp | null;
  lat?: numeric_comparison_exp | null;
  lng?: numeric_comparison_exp | null;
  location_name?: String_comparison_exp | null;
  location_type?: String_comparison_exp | null;
  logo_url?: String_comparison_exp | null;
  parent_dapip_id?: String_comparison_exp | null;
  parent_name?: String_comparison_exp | null;
  university_selection_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "grad.university_selection"
 */
export interface grad_university_selection_insert_input {
  address?: string | null;
  dapip_id?: string | null;
  general_phone?: string | null;
  hidden?: boolean | null;
  lat?: any | null;
  lng?: any | null;
  location_name?: string | null;
  location_type?: string | null;
  logo_url?: string | null;
  parent_dapip_id?: string | null;
  parent_name?: string | null;
  university_selection_id?: string | null;
}

/**
 * input type for inserting object relation for remote table "grad.university_selection"
 */
export interface grad_university_selection_obj_rel_insert_input {
  data: grad_university_selection_insert_input;
  on_conflict?: grad_university_selection_on_conflict | null;
}

/**
 * on conflict condition type for table "grad.university_selection"
 */
export interface grad_university_selection_on_conflict {
  constraint: grad_university_selection_constraint;
  update_columns: grad_university_selection_update_column[];
  where?: grad_university_selection_bool_exp | null;
}

/**
 * order by aggregate values of table "grad.upload_doc"
 */
export interface grad_upload_doc_aggregate_order_by {
  count?: order_by | null;
  max?: grad_upload_doc_max_order_by | null;
  min?: grad_upload_doc_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "grad.upload_doc"
 */
export interface grad_upload_doc_arr_rel_insert_input {
  data: grad_upload_doc_insert_input[];
  on_conflict?: grad_upload_doc_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "grad.upload_doc". All fields are combined with a logical 'AND'.
 */
export interface grad_upload_doc_bool_exp {
  _and?: (grad_upload_doc_bool_exp | null)[] | null;
  _not?: grad_upload_doc_bool_exp | null;
  _or?: (grad_upload_doc_bool_exp | null)[] | null;
  filename?: String_comparison_exp | null;
  hex_content?: String_comparison_exp | null;
  upload_doc_id?: String_comparison_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "grad.upload_doc"
 */
export interface grad_upload_doc_insert_input {
  filename?: string | null;
  hex_content?: string | null;
  upload_doc_id?: string | null;
  user_id?: string | null;
}

/**
 * order by max() on columns of table "grad.upload_doc"
 */
export interface grad_upload_doc_max_order_by {
  filename?: order_by | null;
  hex_content?: order_by | null;
  upload_doc_id?: order_by | null;
  user_id?: order_by | null;
}

/**
 * order by min() on columns of table "grad.upload_doc"
 */
export interface grad_upload_doc_min_order_by {
  filename?: order_by | null;
  hex_content?: order_by | null;
  upload_doc_id?: order_by | null;
  user_id?: order_by | null;
}

/**
 * on conflict condition type for table "grad.upload_doc"
 */
export interface grad_upload_doc_on_conflict {
  constraint: grad_upload_doc_constraint;
  update_columns: grad_upload_doc_update_column[];
  where?: grad_upload_doc_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "grad.user_activity_summary". All fields are combined with a logical 'AND'.
 */
export interface grad_user_activity_summary_bool_exp {
  _and?: (grad_user_activity_summary_bool_exp | null)[] | null;
  _not?: grad_user_activity_summary_bool_exp | null;
  _or?: (grad_user_activity_summary_bool_exp | null)[] | null;
  last_edit?: timestamptz_comparison_exp | null;
  last_page_view?: timestamptz_comparison_exp | null;
  page_views_last_30d?: Int_comparison_exp | null;
  page_views_last_7d?: Int_comparison_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "grad.user_activity_summary"
 */
export interface grad_user_activity_summary_insert_input {
  last_edit?: any | null;
  last_page_view?: any | null;
  page_views_last_30d?: number | null;
  page_views_last_7d?: number | null;
  user_id?: string | null;
}

/**
 * input type for inserting object relation for remote table "grad.user_activity_summary"
 */
export interface grad_user_activity_summary_obj_rel_insert_input {
  data: grad_user_activity_summary_insert_input;
  on_conflict?: grad_user_activity_summary_on_conflict | null;
}

/**
 * on conflict condition type for table "grad.user_activity_summary"
 */
export interface grad_user_activity_summary_on_conflict {
  constraint: grad_user_activity_summary_constraint;
  update_columns: grad_user_activity_summary_update_column[];
  where?: grad_user_activity_summary_bool_exp | null;
}

/**
 * ordering options when selecting data from "grad.user_activity_summary"
 */
export interface grad_user_activity_summary_order_by {
  last_edit?: order_by | null;
  last_page_view?: order_by | null;
  page_views_last_30d?: order_by | null;
  page_views_last_7d?: order_by | null;
  user_id?: order_by | null;
}

/**
 * order by aggregate values of table "grad.volunteer"
 */
export interface grad_volunteer_aggregate_order_by {
  count?: order_by | null;
  max?: grad_volunteer_max_order_by | null;
  min?: grad_volunteer_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "grad.volunteer"
 */
export interface grad_volunteer_arr_rel_insert_input {
  data: grad_volunteer_insert_input[];
  on_conflict?: grad_volunteer_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "grad.volunteer". All fields are combined with a logical 'AND'.
 */
export interface grad_volunteer_bool_exp {
  _and?: (grad_volunteer_bool_exp | null)[] | null;
  _not?: grad_volunteer_bool_exp | null;
  _or?: (grad_volunteer_bool_exp | null)[] | null;
  description?: String_comparison_exp | null;
  user_id?: String_comparison_exp | null;
  volunteer_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "grad.volunteer"
 */
export interface grad_volunteer_insert_input {
  description?: string | null;
  user_id?: string | null;
  volunteer_id?: string | null;
}

/**
 * order by max() on columns of table "grad.volunteer"
 */
export interface grad_volunteer_max_order_by {
  description?: order_by | null;
  user_id?: order_by | null;
  volunteer_id?: order_by | null;
}

/**
 * order by min() on columns of table "grad.volunteer"
 */
export interface grad_volunteer_min_order_by {
  description?: order_by | null;
  user_id?: order_by | null;
  volunteer_id?: order_by | null;
}

/**
 * on conflict condition type for table "grad.volunteer"
 */
export interface grad_volunteer_on_conflict {
  constraint: grad_volunteer_constraint;
  update_columns: grad_volunteer_update_column[];
  where?: grad_volunteer_bool_exp | null;
}

/**
 * order by aggregate values of table "grad.work_experience"
 */
export interface grad_work_experience_aggregate_order_by {
  avg?: grad_work_experience_avg_order_by | null;
  count?: order_by | null;
  max?: grad_work_experience_max_order_by | null;
  min?: grad_work_experience_min_order_by | null;
  stddev?: grad_work_experience_stddev_order_by | null;
  stddev_pop?: grad_work_experience_stddev_pop_order_by | null;
  stddev_samp?: grad_work_experience_stddev_samp_order_by | null;
  sum?: grad_work_experience_sum_order_by | null;
  var_pop?: grad_work_experience_var_pop_order_by | null;
  var_samp?: grad_work_experience_var_samp_order_by | null;
  variance?: grad_work_experience_variance_order_by | null;
}

/**
 * input type for inserting array relation for remote table "grad.work_experience"
 */
export interface grad_work_experience_arr_rel_insert_input {
  data: grad_work_experience_insert_input[];
  on_conflict?: grad_work_experience_on_conflict | null;
}

/**
 * order by avg() on columns of table "grad.work_experience"
 */
export interface grad_work_experience_avg_order_by {
  end_month_numeric?: order_by | null;
  end_year?: order_by | null;
  start_month_numeric?: order_by | null;
  start_year?: order_by | null;
}

/**
 * Boolean expression to filter rows from the table "grad.work_experience". All fields are combined with a logical 'AND'.
 */
export interface grad_work_experience_bool_exp {
  _and?: (grad_work_experience_bool_exp | null)[] | null;
  _not?: grad_work_experience_bool_exp | null;
  _or?: (grad_work_experience_bool_exp | null)[] | null;
  awards?: grad_award_bool_exp | null;
  city?: String_comparison_exp | null;
  company?: String_comparison_exp | null;
  country?: String_comparison_exp | null;
  current?: Boolean_comparison_exp | null;
  description?: String_comparison_exp | null;
  division?: String_comparison_exp | null;
  end_month?: String_comparison_exp | null;
  end_month_numeric?: Int_comparison_exp | null;
  end_year?: numeric_comparison_exp | null;
  job_type?: String_comparison_exp | null;
  position?: String_comparison_exp | null;
  relevant?: Boolean_comparison_exp | null;
  start_month?: String_comparison_exp | null;
  start_month_numeric?: Int_comparison_exp | null;
  start_year?: numeric_comparison_exp | null;
  state?: String_comparison_exp | null;
  user_id?: String_comparison_exp | null;
  work_experience_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "grad.work_experience"
 */
export interface grad_work_experience_insert_input {
  awards?: grad_award_arr_rel_insert_input | null;
  city?: string | null;
  company?: string | null;
  country?: string | null;
  current?: boolean | null;
  description?: string | null;
  division?: string | null;
  end_month?: string | null;
  end_month_numeric?: number | null;
  end_year?: any | null;
  job_type?: string | null;
  position?: string | null;
  relevant?: boolean | null;
  start_month?: string | null;
  start_month_numeric?: number | null;
  start_year?: any | null;
  state?: string | null;
  user_id?: string | null;
  work_experience_id?: string | null;
}

/**
 * order by max() on columns of table "grad.work_experience"
 */
export interface grad_work_experience_max_order_by {
  city?: order_by | null;
  company?: order_by | null;
  country?: order_by | null;
  description?: order_by | null;
  division?: order_by | null;
  end_month?: order_by | null;
  end_month_numeric?: order_by | null;
  end_year?: order_by | null;
  job_type?: order_by | null;
  position?: order_by | null;
  start_month?: order_by | null;
  start_month_numeric?: order_by | null;
  start_year?: order_by | null;
  state?: order_by | null;
  user_id?: order_by | null;
  work_experience_id?: order_by | null;
}

/**
 * order by min() on columns of table "grad.work_experience"
 */
export interface grad_work_experience_min_order_by {
  city?: order_by | null;
  company?: order_by | null;
  country?: order_by | null;
  description?: order_by | null;
  division?: order_by | null;
  end_month?: order_by | null;
  end_month_numeric?: order_by | null;
  end_year?: order_by | null;
  job_type?: order_by | null;
  position?: order_by | null;
  start_month?: order_by | null;
  start_month_numeric?: order_by | null;
  start_year?: order_by | null;
  state?: order_by | null;
  user_id?: order_by | null;
  work_experience_id?: order_by | null;
}

/**
 * on conflict condition type for table "grad.work_experience"
 */
export interface grad_work_experience_on_conflict {
  constraint: grad_work_experience_constraint;
  update_columns: grad_work_experience_update_column[];
  where?: grad_work_experience_bool_exp | null;
}

/**
 * order by stddev() on columns of table "grad.work_experience"
 */
export interface grad_work_experience_stddev_order_by {
  end_month_numeric?: order_by | null;
  end_year?: order_by | null;
  start_month_numeric?: order_by | null;
  start_year?: order_by | null;
}

/**
 * order by stddev_pop() on columns of table "grad.work_experience"
 */
export interface grad_work_experience_stddev_pop_order_by {
  end_month_numeric?: order_by | null;
  end_year?: order_by | null;
  start_month_numeric?: order_by | null;
  start_year?: order_by | null;
}

/**
 * order by stddev_samp() on columns of table "grad.work_experience"
 */
export interface grad_work_experience_stddev_samp_order_by {
  end_month_numeric?: order_by | null;
  end_year?: order_by | null;
  start_month_numeric?: order_by | null;
  start_year?: order_by | null;
}

/**
 * order by sum() on columns of table "grad.work_experience"
 */
export interface grad_work_experience_sum_order_by {
  end_month_numeric?: order_by | null;
  end_year?: order_by | null;
  start_month_numeric?: order_by | null;
  start_year?: order_by | null;
}

/**
 * order by var_pop() on columns of table "grad.work_experience"
 */
export interface grad_work_experience_var_pop_order_by {
  end_month_numeric?: order_by | null;
  end_year?: order_by | null;
  start_month_numeric?: order_by | null;
  start_year?: order_by | null;
}

/**
 * order by var_samp() on columns of table "grad.work_experience"
 */
export interface grad_work_experience_var_samp_order_by {
  end_month_numeric?: order_by | null;
  end_year?: order_by | null;
  start_month_numeric?: order_by | null;
  start_year?: order_by | null;
}

/**
 * order by variance() on columns of table "grad.work_experience"
 */
export interface grad_work_experience_variance_order_by {
  end_month_numeric?: order_by | null;
  end_year?: order_by | null;
  start_month_numeric?: order_by | null;
  start_year?: order_by | null;
}

/**
 * expression to compare columns of type numeric. All fields are combined with logical 'AND'.
 */
export interface numeric_comparison_exp {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

/**
 * Boolean expression to filter rows from the table "recruit.account". All fields are combined with a logical 'AND'.
 */
export interface recruit_account_bool_exp {
  _and?: (recruit_account_bool_exp | null)[] | null;
  _not?: recruit_account_bool_exp | null;
  _or?: (recruit_account_bool_exp | null)[] | null;
  account_id?: String_comparison_exp | null;
  account_name?: String_comparison_exp | null;
  company?: recruit_company_bool_exp | null;
  insert_company?: recruit_company_bool_exp | null;
  recruiters?: recruit_recruiter_bool_exp | null;
}

/**
 * input type for inserting data into table "recruit.account"
 */
export interface recruit_account_insert_input {
  account_id?: string | null;
  account_name?: string | null;
  company?: recruit_company_obj_rel_insert_input | null;
  insert_company?: recruit_company_arr_rel_insert_input | null;
  recruiters?: recruit_recruiter_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "recruit.account"
 */
export interface recruit_account_obj_rel_insert_input {
  data: recruit_account_insert_input;
  on_conflict?: recruit_account_on_conflict | null;
}

/**
 * on conflict condition type for table "recruit.account"
 */
export interface recruit_account_on_conflict {
  constraint: recruit_account_constraint;
  update_columns: recruit_account_update_column[];
  where?: recruit_account_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "recruit.company"
 */
export interface recruit_company_arr_rel_insert_input {
  data: recruit_company_insert_input[];
  on_conflict?: recruit_company_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "recruit.company". All fields are combined with a logical 'AND'.
 */
export interface recruit_company_bool_exp {
  _and?: (recruit_company_bool_exp | null)[] | null;
  _not?: recruit_company_bool_exp | null;
  _or?: (recruit_company_bool_exp | null)[] | null;
  account?: recruit_account_bool_exp | null;
  account_id?: String_comparison_exp | null;
  address?: String_comparison_exp | null;
  company_id?: Int_comparison_exp | null;
  description?: String_comparison_exp | null;
  industry?: String_comparison_exp | null;
  logo?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  num_employees?: String_comparison_exp | null;
  phone?: String_comparison_exp | null;
  photo?: String_comparison_exp | null;
  subindustries?: recruit_company_subindustry_bool_exp | null;
  website?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "recruit.company"
 */
export interface recruit_company_insert_input {
  account?: recruit_account_obj_rel_insert_input | null;
  account_id?: string | null;
  address?: string | null;
  company_id?: number | null;
  description?: string | null;
  industry?: string | null;
  logo?: string | null;
  name?: string | null;
  num_employees?: string | null;
  phone?: string | null;
  photo?: string | null;
  subindustries?: recruit_company_subindustry_arr_rel_insert_input | null;
  website?: string | null;
}

/**
 * input type for inserting object relation for remote table "recruit.company"
 */
export interface recruit_company_obj_rel_insert_input {
  data: recruit_company_insert_input;
  on_conflict?: recruit_company_on_conflict | null;
}

/**
 * on conflict condition type for table "recruit.company"
 */
export interface recruit_company_on_conflict {
  constraint: recruit_company_constraint;
  update_columns: recruit_company_update_column[];
  where?: recruit_company_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "recruit.company_subindustry"
 */
export interface recruit_company_subindustry_arr_rel_insert_input {
  data: recruit_company_subindustry_insert_input[];
  on_conflict?: recruit_company_subindustry_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "recruit.company_subindustry". All fields are combined with a logical 'AND'.
 */
export interface recruit_company_subindustry_bool_exp {
  _and?: (recruit_company_subindustry_bool_exp | null)[] | null;
  _not?: recruit_company_subindustry_bool_exp | null;
  _or?: (recruit_company_subindustry_bool_exp | null)[] | null;
  company?: recruit_company_bool_exp | null;
  company_id?: Int_comparison_exp | null;
  company_subindustry_id?: Int_comparison_exp | null;
  value?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "recruit.company_subindustry"
 */
export interface recruit_company_subindustry_insert_input {
  company?: recruit_company_obj_rel_insert_input | null;
  company_id?: number | null;
  company_subindustry_id?: number | null;
  value?: string | null;
}

/**
 * on conflict condition type for table "recruit.company_subindustry"
 */
export interface recruit_company_subindustry_on_conflict {
  constraint: recruit_company_subindustry_constraint;
  update_columns: recruit_company_subindustry_update_column[];
  where?: recruit_company_subindustry_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "recruit.event". All fields are combined with a logical 'AND'.
 */
export interface recruit_event_bool_exp {
  _and?: (recruit_event_bool_exp | null)[] | null;
  _not?: recruit_event_bool_exp | null;
  _or?: (recruit_event_bool_exp | null)[] | null;
  account?: recruit_account_bool_exp | null;
  account_id?: String_comparison_exp | null;
  address?: String_comparison_exp | null;
  capacity?: Int_comparison_exp | null;
  creation_timestamp?: timestamptz_comparison_exp | null;
  creator_id?: String_comparison_exp | null;
  description?: String_comparison_exp | null;
  end_datetime?: timestamp_comparison_exp | null;
  end_timestamp?: timestamptz_comparison_exp | null;
  event_id?: String_comparison_exp | null;
  event_registrations?: grad_event_registration_bool_exp | null;
  event_type?: String_comparison_exp | null;
  event_url?: String_comparison_exp | null;
  image?: String_comparison_exp | null;
  recruiter?: recruit_recruiter_bool_exp | null;
  registration_count?: Int_comparison_exp | null;
  show_event?: Boolean_comparison_exp | null;
  start_datetime?: timestamp_comparison_exp | null;
  start_timestamp?: timestamptz_comparison_exp | null;
  timezone?: String_comparison_exp | null;
  title?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "recruit.event"
 */
export interface recruit_event_insert_input {
  account?: recruit_account_obj_rel_insert_input | null;
  account_id?: string | null;
  address?: string | null;
  capacity?: number | null;
  creation_timestamp?: any | null;
  creator_id?: string | null;
  description?: string | null;
  end_datetime?: any | null;
  end_timestamp?: any | null;
  event_id?: string | null;
  event_registrations?: grad_event_registration_arr_rel_insert_input | null;
  event_type?: string | null;
  event_url?: string | null;
  image?: string | null;
  recruiter?: recruit_recruiter_obj_rel_insert_input | null;
  registration_count?: number | null;
  show_event?: boolean | null;
  start_datetime?: any | null;
  start_timestamp?: any | null;
  timezone?: string | null;
  title?: string | null;
}

/**
 * input type for inserting object relation for remote table "recruit.event"
 */
export interface recruit_event_obj_rel_insert_input {
  data: recruit_event_insert_input;
  on_conflict?: recruit_event_on_conflict | null;
}

/**
 * on conflict condition type for table "recruit.event"
 */
export interface recruit_event_on_conflict {
  constraint: recruit_event_constraint;
  update_columns: recruit_event_update_column[];
  where?: recruit_event_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "recruit.grad_group"
 */
export interface recruit_grad_group_arr_rel_insert_input {
  data: recruit_grad_group_insert_input[];
  on_conflict?: recruit_grad_group_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "recruit.grad_group". All fields are combined with a logical 'AND'.
 */
export interface recruit_grad_group_bool_exp {
  _and?: (recruit_grad_group_bool_exp | null)[] | null;
  _not?: recruit_grad_group_bool_exp | null;
  _or?: (recruit_grad_group_bool_exp | null)[] | null;
  grad_group_id?: String_comparison_exp | null;
  grad_group_members?: recruit_grad_group_member_bool_exp | null;
  group_name?: String_comparison_exp | null;
  recruiter_user_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "recruit.grad_group"
 */
export interface recruit_grad_group_insert_input {
  grad_group_id?: string | null;
  grad_group_members?: recruit_grad_group_member_arr_rel_insert_input | null;
  group_name?: string | null;
  recruiter_user_id?: string | null;
}

/**
 * order by aggregate values of table "recruit.grad_group_member"
 */
export interface recruit_grad_group_member_aggregate_order_by {
  count?: order_by | null;
  max?: recruit_grad_group_member_max_order_by | null;
  min?: recruit_grad_group_member_min_order_by | null;
}

/**
 * input type for inserting array relation for remote table "recruit.grad_group_member"
 */
export interface recruit_grad_group_member_arr_rel_insert_input {
  data: recruit_grad_group_member_insert_input[];
  on_conflict?: recruit_grad_group_member_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "recruit.grad_group_member". All fields are combined with a logical 'AND'.
 */
export interface recruit_grad_group_member_bool_exp {
  _and?: (recruit_grad_group_member_bool_exp | null)[] | null;
  _not?: recruit_grad_group_member_bool_exp | null;
  _or?: (recruit_grad_group_member_bool_exp | null)[] | null;
  grad_group?: recruit_grad_group_bool_exp | null;
  grad_group_id?: String_comparison_exp | null;
  profile?: grad_profile_bool_exp | null;
  recruiter_user_id?: String_comparison_exp | null;
  student_user_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "recruit.grad_group_member"
 */
export interface recruit_grad_group_member_insert_input {
  grad_group?: recruit_grad_group_obj_rel_insert_input | null;
  grad_group_id?: string | null;
  profile?: grad_profile_obj_rel_insert_input | null;
  recruiter_user_id?: string | null;
  student_user_id?: string | null;
}

/**
 * order by max() on columns of table "recruit.grad_group_member"
 */
export interface recruit_grad_group_member_max_order_by {
  grad_group_id?: order_by | null;
  recruiter_user_id?: order_by | null;
  student_user_id?: order_by | null;
}

/**
 * order by min() on columns of table "recruit.grad_group_member"
 */
export interface recruit_grad_group_member_min_order_by {
  grad_group_id?: order_by | null;
  recruiter_user_id?: order_by | null;
  student_user_id?: order_by | null;
}

/**
 * on conflict condition type for table "recruit.grad_group_member"
 */
export interface recruit_grad_group_member_on_conflict {
  constraint: recruit_grad_group_member_constraint;
  update_columns: recruit_grad_group_member_update_column[];
  where?: recruit_grad_group_member_bool_exp | null;
}

/**
 * input type for inserting object relation for remote table "recruit.grad_group"
 */
export interface recruit_grad_group_obj_rel_insert_input {
  data: recruit_grad_group_insert_input;
  on_conflict?: recruit_grad_group_on_conflict | null;
}

/**
 * on conflict condition type for table "recruit.grad_group"
 */
export interface recruit_grad_group_on_conflict {
  constraint: recruit_grad_group_constraint;
  update_columns: recruit_grad_group_update_column[];
  where?: recruit_grad_group_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "recruit.job_posting". All fields are combined with a logical 'AND'.
 */
export interface recruit_job_posting_bool_exp {
  _and?: (recruit_job_posting_bool_exp | null)[] | null;
  _not?: recruit_job_posting_bool_exp | null;
  _or?: (recruit_job_posting_bool_exp | null)[] | null;
  account_id?: String_comparison_exp | null;
  active?: Boolean_comparison_exp | null;
  bookmark_jobs?: grad_bookmark_job_bool_exp | null;
  creation_date?: date_comparison_exp | null;
  creator_id?: String_comparison_exp | null;
  deadline?: date_comparison_exp | null;
  description?: String_comparison_exp | null;
  firm?: String_comparison_exp | null;
  job_posting_id?: String_comparison_exp | null;
  job_requirements?: recruit_job_requirement_bool_exp | null;
  job_type?: String_comparison_exp | null;
  modified_date?: date_comparison_exp | null;
  position_title?: String_comparison_exp | null;
  public?: Boolean_comparison_exp | null;
  publishing_date?: date_comparison_exp | null;
  raise_hands?: grad_raise_hand_bool_exp | null;
  region?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "recruit.job_posting"
 */
export interface recruit_job_posting_insert_input {
  account_id?: string | null;
  active?: boolean | null;
  bookmark_jobs?: grad_bookmark_job_arr_rel_insert_input | null;
  creation_date?: any | null;
  creator_id?: string | null;
  deadline?: any | null;
  description?: string | null;
  firm?: string | null;
  job_posting_id?: string | null;
  job_requirements?: recruit_job_requirement_arr_rel_insert_input | null;
  job_type?: string | null;
  modified_date?: any | null;
  position_title?: string | null;
  public?: boolean | null;
  publishing_date?: any | null;
  raise_hands?: grad_raise_hand_arr_rel_insert_input | null;
  region?: string | null;
}

/**
 * input type for inserting object relation for remote table "recruit.job_posting"
 */
export interface recruit_job_posting_obj_rel_insert_input {
  data: recruit_job_posting_insert_input;
  on_conflict?: recruit_job_posting_on_conflict | null;
}

/**
 * on conflict condition type for table "recruit.job_posting"
 */
export interface recruit_job_posting_on_conflict {
  constraint: recruit_job_posting_constraint;
  update_columns: recruit_job_posting_update_column[];
  where?: recruit_job_posting_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "recruit.job_requirement"
 */
export interface recruit_job_requirement_arr_rel_insert_input {
  data: recruit_job_requirement_insert_input[];
  on_conflict?: recruit_job_requirement_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "recruit.job_requirement". All fields are combined with a logical 'AND'.
 */
export interface recruit_job_requirement_bool_exp {
  _and?: (recruit_job_requirement_bool_exp | null)[] | null;
  _not?: recruit_job_requirement_bool_exp | null;
  _or?: (recruit_job_requirement_bool_exp | null)[] | null;
  boolean_value?: Boolean_comparison_exp | null;
  job_posting?: recruit_job_posting_bool_exp | null;
  job_posting_id?: String_comparison_exp | null;
  job_requirement_id?: String_comparison_exp | null;
  max_range_value?: numeric_comparison_exp | null;
  min_range_value?: numeric_comparison_exp | null;
  number_value?: numeric_comparison_exp | null;
  requirement_name?: String_comparison_exp | null;
  requirement_type?: String_comparison_exp | null;
  values?: recruit_job_requirement_value_bool_exp | null;
}

/**
 * input type for inserting data into table "recruit.job_requirement"
 */
export interface recruit_job_requirement_insert_input {
  boolean_value?: boolean | null;
  job_posting?: recruit_job_posting_obj_rel_insert_input | null;
  job_posting_id?: string | null;
  job_requirement_id?: string | null;
  max_range_value?: any | null;
  min_range_value?: any | null;
  number_value?: any | null;
  requirement_name?: string | null;
  requirement_type?: string | null;
  values?: recruit_job_requirement_value_arr_rel_insert_input | null;
}

/**
 * input type for inserting object relation for remote table "recruit.job_requirement"
 */
export interface recruit_job_requirement_obj_rel_insert_input {
  data: recruit_job_requirement_insert_input;
  on_conflict?: recruit_job_requirement_on_conflict | null;
}

/**
 * on conflict condition type for table "recruit.job_requirement"
 */
export interface recruit_job_requirement_on_conflict {
  constraint: recruit_job_requirement_constraint;
  update_columns: recruit_job_requirement_update_column[];
  where?: recruit_job_requirement_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "recruit.job_requirement_value"
 */
export interface recruit_job_requirement_value_arr_rel_insert_input {
  data: recruit_job_requirement_value_insert_input[];
  on_conflict?: recruit_job_requirement_value_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "recruit.job_requirement_value". All fields are combined with a logical 'AND'.
 */
export interface recruit_job_requirement_value_bool_exp {
  _and?: (recruit_job_requirement_value_bool_exp | null)[] | null;
  _not?: recruit_job_requirement_value_bool_exp | null;
  _or?: (recruit_job_requirement_value_bool_exp | null)[] | null;
  job_requirement?: recruit_job_requirement_bool_exp | null;
  job_requirement_id?: String_comparison_exp | null;
  job_requirement_value_id?: String_comparison_exp | null;
  label?: String_comparison_exp | null;
  value?: String_comparison_exp | null;
  value_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "recruit.job_requirement_value"
 */
export interface recruit_job_requirement_value_insert_input {
  job_requirement?: recruit_job_requirement_obj_rel_insert_input | null;
  job_requirement_id?: string | null;
  job_requirement_value_id?: string | null;
  label?: string | null;
  value?: string | null;
  value_id?: string | null;
}

/**
 * on conflict condition type for table "recruit.job_requirement_value"
 */
export interface recruit_job_requirement_value_on_conflict {
  constraint: recruit_job_requirement_value_constraint;
  update_columns: recruit_job_requirement_value_update_column[];
  where?: recruit_job_requirement_value_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "recruit.recruiter"
 */
export interface recruit_recruiter_arr_rel_insert_input {
  data: recruit_recruiter_insert_input[];
  on_conflict?: recruit_recruiter_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "recruit.recruiter". All fields are combined with a logical 'AND'.
 */
export interface recruit_recruiter_bool_exp {
  _and?: (recruit_recruiter_bool_exp | null)[] | null;
  _not?: recruit_recruiter_bool_exp | null;
  _or?: (recruit_recruiter_bool_exp | null)[] | null;
  account?: recruit_account_bool_exp | null;
  account_id?: String_comparison_exp | null;
  email?: String_comparison_exp | null;
  fullname?: String_comparison_exp | null;
  grad_groups?: recruit_grad_group_bool_exp | null;
  sms_messages?: recruit_sms_message_bool_exp | null;
  twilio_phone?: String_comparison_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "recruit.recruiter"
 */
export interface recruit_recruiter_insert_input {
  account?: recruit_account_obj_rel_insert_input | null;
  account_id?: string | null;
  email?: string | null;
  fullname?: string | null;
  grad_groups?: recruit_grad_group_arr_rel_insert_input | null;
  sms_messages?: recruit_sms_message_arr_rel_insert_input | null;
  twilio_phone?: string | null;
  user_id?: string | null;
}

/**
 * input type for inserting object relation for remote table "recruit.recruiter"
 */
export interface recruit_recruiter_obj_rel_insert_input {
  data: recruit_recruiter_insert_input;
  on_conflict?: recruit_recruiter_on_conflict | null;
}

/**
 * on conflict condition type for table "recruit.recruiter"
 */
export interface recruit_recruiter_on_conflict {
  constraint: recruit_recruiter_constraint;
  update_columns: recruit_recruiter_update_column[];
  where?: recruit_recruiter_bool_exp | null;
}

/**
 * input type for inserting array relation for remote table "recruit.sms_message"
 */
export interface recruit_sms_message_arr_rel_insert_input {
  data: recruit_sms_message_insert_input[];
  on_conflict?: recruit_sms_message_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "recruit.sms_message". All fields are combined with a logical 'AND'.
 */
export interface recruit_sms_message_bool_exp {
  _and?: (recruit_sms_message_bool_exp | null)[] | null;
  _not?: recruit_sms_message_bool_exp | null;
  _or?: (recruit_sms_message_bool_exp | null)[] | null;
  adminuser?: admin_adminuser_bool_exp | null;
  content?: String_comparison_exp | null;
  correspondents?: recruit_sms_message_correspondent_bool_exp | null;
  incoming?: Boolean_comparison_exp | null;
  recruiter?: recruit_recruiter_bool_exp | null;
  recruiter_user_id?: String_comparison_exp | null;
  sms_message_id?: Int_comparison_exp | null;
  timestamp?: timestamptz_comparison_exp | null;
}

/**
 * input type for inserting array relation for remote table "recruit.sms_message_correspondent"
 */
export interface recruit_sms_message_correspondent_arr_rel_insert_input {
  data: recruit_sms_message_correspondent_insert_input[];
  on_conflict?: recruit_sms_message_correspondent_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "recruit.sms_message_correspondent". All fields are combined with a logical 'AND'.
 */
export interface recruit_sms_message_correspondent_bool_exp {
  _and?: (recruit_sms_message_correspondent_bool_exp | null)[] | null;
  _not?: recruit_sms_message_correspondent_bool_exp | null;
  _or?: (recruit_sms_message_correspondent_bool_exp | null)[] | null;
  correspondent_user_id?: String_comparison_exp | null;
  error_message?: String_comparison_exp | null;
  grad?: grad_profile_bool_exp | null;
  message?: recruit_sms_message_bool_exp | null;
  sms_message_correspondent_id?: Int_comparison_exp | null;
  sms_message_id?: Int_comparison_exp | null;
}

/**
 * input type for inserting data into table "recruit.sms_message_correspondent"
 */
export interface recruit_sms_message_correspondent_insert_input {
  correspondent_user_id?: string | null;
  error_message?: string | null;
  grad?: grad_profile_obj_rel_insert_input | null;
  message?: recruit_sms_message_obj_rel_insert_input | null;
  sms_message_correspondent_id?: number | null;
  sms_message_id?: number | null;
}

/**
 * on conflict condition type for table "recruit.sms_message_correspondent"
 */
export interface recruit_sms_message_correspondent_on_conflict {
  constraint: recruit_sms_message_correspondent_constraint;
  update_columns: recruit_sms_message_correspondent_update_column[];
  where?: recruit_sms_message_correspondent_bool_exp | null;
}

/**
 * input type for inserting data into table "recruit.sms_message"
 */
export interface recruit_sms_message_insert_input {
  adminuser?: admin_adminuser_obj_rel_insert_input | null;
  content?: string | null;
  correspondents?: recruit_sms_message_correspondent_arr_rel_insert_input | null;
  incoming?: boolean | null;
  recruiter?: recruit_recruiter_obj_rel_insert_input | null;
  recruiter_user_id?: string | null;
  sms_message_id?: number | null;
  timestamp?: any | null;
}

/**
 * input type for inserting object relation for remote table "recruit.sms_message"
 */
export interface recruit_sms_message_obj_rel_insert_input {
  data: recruit_sms_message_insert_input;
  on_conflict?: recruit_sms_message_on_conflict | null;
}

/**
 * on conflict condition type for table "recruit.sms_message"
 */
export interface recruit_sms_message_on_conflict {
  constraint: recruit_sms_message_constraint;
  update_columns: recruit_sms_message_update_column[];
  where?: recruit_sms_message_bool_exp | null;
}

/**
 * expression to compare columns of type timestamp. All fields are combined with logical 'AND'.
 */
export interface timestamp_comparison_exp {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

/**
 * expression to compare columns of type timestamptz. All fields are combined with logical 'AND'.
 */
export interface timestamptz_comparison_exp {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
