import React from 'react';
import styled from 'styled-components';

import Page from '../Page';
import { colors } from 'elitegrad-common/src/theme';
import ConversationListController from 'elitegrad-common/src/components/messaging/conversations/ConversationListController';
import { messagingPath } from 'routes';
import SpinnerIsoType from 'elitegrad-common/src/components/SpinnerIsoType';
import { Redirect } from 'react-router-dom';
import { useGetCurrentConversationsQuery } from 'elitegrad-common/src/generated/graphql';

const StyledMessaging = styled.div`
  display: block;
  height: 100%;

  & > * {
    height: 100vh;
  }
`;

const StyledLeft = styled.div`
  display: block;

  height: 100%;

  border: 1px solid ${colors.gray_light};
`;

const EmptyChatPlaceholder = styled.div`
  display: block;
  height: 100%;

  border: 1px solid transparent;
`;

const Messaging: React.FC = () => {
  const { data, loading } = useGetCurrentConversationsQuery({ variables: { limit: 20 } });

  if (loading) {
    return <SpinnerIsoType text={'Searching existing conversations'} />;
  }

  if (data && data.recruit_sms_correspondent?.length > 0) {
    return <Redirect to={messagingPath(data.recruit_sms_correspondent[0].correspondent_user_id)} />;
  }

  return (
    <Page>
      <StyledMessaging>
        <StyledLeft>
          <ConversationListController generateMessagingPath={messagingPath} />
        </StyledLeft>
        <EmptyChatPlaceholder />
      </StyledMessaging>
    </Page>
  );
};

export default Messaging;
