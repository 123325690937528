import React from 'react';
import { WhereQueryInterface } from '../../../queries/queryUtils';
import { useFilter } from '../../../utils/useFilter';
import BooleanFilterItem from '../BooleanFilterItem';

export const generateQuery = (isAthlete: boolean): WhereQueryInterface => {
  const queryAthleteFilter: WhereQueryInterface = {
    enabled: isAthlete,
    operator: '_and',
    whereQuery: { student_athlete: { _eq: true } },
  };
  return queryAthleteFilter;
};

const AthleteFilter: React.FC<{ name: string }> = ({ name }) => {
  const { storeFilterStatus, filterValue } = useFilter(name);

  const setFilterValue = (isAthlete: boolean) => storeFilterStatus(generateQuery(isAthlete), isAthlete);
  return (
    <BooleanFilterItem
      on={!!filterValue}
      filterTitle={'Student Athlete'}
      setFilterValue={setFilterValue}
      filterActive={!!filterValue}
    />
  );
};

export default AthleteFilter;
