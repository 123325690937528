import React from 'react';

import RecruiterImpersonationDialog from './RecruiterImpersonationDialog';
import { RecruiterImpersonationMessageProps } from './RecruiterImpersonationDialog';
import { goToRecruitApp } from 'routes';

type RecruiterImpersonationDialogController = Omit<RecruiterImpersonationMessageProps, 'onContinueImpersonation'>;
const RecruiterImpersonationDialogController: React.FC<RecruiterImpersonationDialogController> = ({
  fullname,
  userId,
}) => {
  const onContinueImpersonation = () => {
    window.open(`${goToRecruitApp}/imperson/${userId}`, '_blank');
  };

  return (
    <RecruiterImpersonationDialog
      fullname={fullname}
      userId={userId}
      onContinueImpersonation={onContinueImpersonation}
    />
  );
};

export default RecruiterImpersonationDialogController;
