import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';

import ChatRecipientIdentity from './ChatRecipientIdentity';
import GET_RECIPIENT_BY_ID from '../../../queries/getRecipientById.gql';
import DELETE_CONVERSATION from '../../../queries/deleteConversation.gql';

import { GetRecipientById, GetRecipientByIdVariables } from '../../../generated/GetRecipientById';
import { DeleteConversation, DeleteConversationVariables } from '../../../generated/DeleteConversation';
import { asRecipient } from '../../../transformers/recipient';
import SpinnerIsoType from '../../SpinnerIsoType';
import { useAuth } from '../../../utils/react-auth';

const ChatRecipientIdentityController: React.FC<{
  candidateId?: string;
  generateCandidatePath: (candidateId: string) => string;
  setStudentProfileOpen: (arg: boolean) => void;
}> = ({ candidateId, generateCandidatePath, setStudentProfileOpen }) => {
  const { id } = useParams<{ id: string }>();
  const { user } = useAuth();
  const history = useHistory();
  const { data, loading } = useQuery<GetRecipientById, GetRecipientByIdVariables>(GET_RECIPIENT_BY_ID, {
    skip: !(candidateId || id),
    variables: { userId: candidateId || id },
  });

  const [deleteConversation] = useMutation<DeleteConversation, DeleteConversationVariables>(DELETE_CONVERSATION, {
    onCompleted: () => history.push('/conversations'),
  });

  if (loading) return <SpinnerIsoType text={'Loading messages'} />;
  if (data?.grad_profile.length === 0) return <SpinnerIsoType text={'Sorry, something went wrong'} />;

  const recipient = data?.grad_profile.map((c) => asRecipient(c))[0];

  if (!recipient) return <SpinnerIsoType text={'Sorry, something went wrong'} />;

  const handleDeleteChat = () => {
    deleteConversation({
      variables: {
        candidateId: candidateId || id,
        recruiterId: user?.sub,
      },
    });
  };

  return (
    <ChatRecipientIdentity
      setStudentProfileOpen={setStudentProfileOpen}
      recipient={recipient}
      candidatePath={generateCandidatePath(recipient.id)}
      handleDeleteChat={handleDeleteChat}
    />
  );
};

export default ChatRecipientIdentityController;
