import React from 'react';
import WithSeparator from 'react-with-separator';
import styled from 'styled-components';
import HorizontalRuler from '../HorizontalRuler';

const Wrapper = styled.div`
  & > *:last-child {
    margin-bottom: 0;
  }
`;

const Name = styled.div`
  margin-bottom: 1rem;
  font-family: 'RobotoMedium';
  font-size: 1.5rem;
  line-height: 1.6rem;
`;

interface FiltersGroupProps {
  name?: string;
  noSeparator?: boolean;
  noRuler?: boolean;
}

const FiltersGroup: React.FC<FiltersGroupProps> = ({ name = null, noRuler = false, noSeparator = false, children }) => {
  const separator = <HorizontalRuler />;

  return (
    <Wrapper>
      {name && <Name>{name}</Name>}
      {!noRuler && <HorizontalRuler highlighted />}

      <WithSeparator separator={!noSeparator ? separator : <></>}>{children}</WithSeparator>
      {separator}
    </Wrapper>
  );
};

export default FiltersGroup;
