import styled from 'styled-components';
import { colors } from '../../../../theme';

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1px 4px;
  border-radius: 4px;
  width: auto;
  height: 2rem;
  background-color: ${colors.beta600};
  text-transform: uppercase;
  color: ${colors.white};
  font-family: 'RobotoBlack';
  font-size: 1.1rem;
  line-height: 1.3rem;

  &:hover {
    cursor: pointer;
  }
`;
