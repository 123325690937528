import gql from 'graphql-tag';
import { TemplateParts } from './fragments.gql';

export default gql`
  mutation UpdateTemplate($templateId: Int!, $content: String!, $title: String!) {
    update_recruit_sms_template(
      where: { sms_template_id: { _eq: $templateId } }
      _set: { text: $content, title: $title }
    ) {
      returning {
        ...TemplateParts
      }
    }
  }
  ${TemplateParts}
`;
