import React, { useState } from 'react';

import { CandidateNoTests, CandidateWorkExperience } from 'elitegrad-common/src/components/candidate';

import CollapsibleCardSection from 'elitegrad-common/src/components/candidates/CollapsibleCardSection';
import {
  CandidateNoWorkExperiences,
  CandidateNoTechnical,
  CandidateNoFirmTypes,
} from 'elitegrad-common/src/components/candidate';
import CandidateType from 'elitegrad-common/src/types/Candidate';
import { getDegreesSortedByCompleteDate } from 'elitegrad-common/src/types/Degree';
import { getTestsSortedByPriority } from 'elitegrad-common/src/types/Test';
import TypeWorkExperience, { sortByDate as sortWorkExperiencesByDate } from 'elitegrad-common/src/types/WorkExperience';
import CandidateTestScoreSat from 'elitegrad-common/src/components/candidate/CandidateTestScoreSat';
import CandidateTestScoreOthers from 'elitegrad-common/src/components/candidate/CandidateTestScoreOthers';

import CandidateCardHeader from './CandidateCardHeader';
import CandidateCardInterests from 'elitegrad-common/src/components/candidate/CandidateCardInterests';

import {
  StyledCandidateCardBorder,
  StyledCandidateCard,
  CardExpandingIcon,
  CurrentGPA,
  LimitGPA,
  NoGPA,
  ResumeSection,
  ResumeIcon,
  ExperienceTitle,
} from './CandidateCard.styled';
import styled from 'styled-components';
import HorizontalRuler from 'elitegrad-common/src/components/HorizontalRuler';

import { ExpandingIcon } from 'elitegrad-common/src/assets/icons';
import { DownloadResumeRed } from 'elitegrad-common/src/assets/vectors';

import { useLazyQuery } from '@apollo/client';
import DOWNLOAD_CV from 'elitegrad-common/src/queries/downloadCV.gql';
import { DownloadCV, DownloadCVVariables } from 'elitegrad-common/src/generated/DownloadCV';

const StyledHorizontalRuler = styled(HorizontalRuler)`
  margin-bottom: 0.5rem;
`;

export interface CandidateCardProps {
  candidate: CandidateType;
  selected: boolean;
  editSelection?: (add: boolean) => void;
  refetch: () => void;
  isExpanded?: boolean;
  isCVCard?: boolean;
}

const CandidateCard: React.FC<CandidateCardProps> = ({
  candidate,
  editSelection,
  selected,
  refetch,
  isExpanded = true,
  isCVCard,
}) => {
  const [expandedStatus, setExpandedStatus] = useState<boolean>(isExpanded);
  const [currentDegree] = getDegreesSortedByCompleteDate(candidate.degrees);
  const workExperiences = sortWorkExperiencesByDate(candidate.workExperiences) as TypeWorkExperience[];
  const [satAct, ...otherTests] = getTestsSortedByPriority(candidate.tests);

  const toggleSelection = editSelection ? editSelection : undefined;

  const [downloadCV] = useLazyQuery<DownloadCV, DownloadCVVariables>(DOWNLOAD_CV, {
    variables: { userId: candidate.userId },
    onCompleted: (data) => {
      const { hex_content: content, filename } = data?.grad_upload_doc[0] || {};
      if (!content || !filename) return;
      const a = document.createElement('a');
      a.href = content;
      a.download = filename;
      a.click();
    },
  });

  return (
    <StyledCandidateCardBorder selected={selected}>
      <StyledCandidateCard isExpanded={expandedStatus}>
        <CandidateCardHeader
          refetch={refetch}
          toggleSelection={(checked: boolean) => {
            if (toggleSelection) {
              toggleSelection(checked);
            }
          }}
          candidate={candidate}
          currentDegree={currentDegree}
          selected={selected}
        />
        <CurrentGPA>
          {currentDegree && !currentDegree.gpaNotApplicable ? (
            <>
              {currentDegree.gpa}
              <LimitGPA>/{currentDegree.gpaOutOf} GPA</LimitGPA>
            </>
          ) : (
            <NoGPA>N / A</NoGPA>
          )}
        </CurrentGPA>
        <StyledHorizontalRuler />
        {isCVCard ? (
          <ResumeSection onClick={() => downloadCV()}>
            <ResumeIcon src={DownloadResumeRed} />
            Download Resume
          </ResumeSection>
        ) : (
          <CollapsibleCardSection title="Test score">
            {satAct && <CandidateTestScoreSat satTest={satAct} />}
            {otherTests.length > 0 && <CandidateTestScoreOthers tests={otherTests} />}
            {candidate.tests.length === 0 && <CandidateNoTests />}
          </CollapsibleCardSection>
        )}
        {candidate.computerLanguages?.length > 0 ? (
          <>
            <StyledHorizontalRuler />
            <CandidateCardInterests title="Technical languages" interests={candidate.computerLanguages} />
          </>
        ) : (
          <>
            <StyledHorizontalRuler />
            <ExperienceTitle>Technical languages</ExperienceTitle>
            <CandidateNoTechnical />
          </>
        )}
        {expandedStatus && (
          <>
            {isCVCard && (
              <CollapsibleCardSection title="Test score">
                {satAct && <CandidateTestScoreSat satTest={satAct} />}
                {otherTests.length > 0 && <CandidateTestScoreOthers tests={otherTests} />}
                {candidate.tests.length === 0 && <CandidateNoTests />}
              </CollapsibleCardSection>
            )}
            {Boolean(workExperiences.length) ? (
              <>
                <StyledHorizontalRuler />
                <CollapsibleCardSection title="Work Experience">
                  {workExperiences.map((we) => (
                    <CandidateWorkExperience workExperience={we} key={we.id} />
                  ))}
                </CollapsibleCardSection>
              </>
            ) : (
              <>
                <StyledHorizontalRuler />
                <ExperienceTitle>Work Experience</ExperienceTitle>
                <CandidateNoWorkExperiences />
              </>
            )}
            {candidate.interestFirmTypes?.length > 0 ? (
              <>
                <StyledHorizontalRuler />
                <CandidateCardInterests title="Firms of Interest" interests={candidate.interestFirmTypes} />
              </>
            ) : (
              <>
                <StyledHorizontalRuler />
                <ExperienceTitle>Firms of Interest</ExperienceTitle>
                <CandidateNoFirmTypes />
              </>
            )}
          </>
        )}
      </StyledCandidateCard>
      <CardExpandingIcon
        src={ExpandingIcon}
        onClick={() => setExpandedStatus(!expandedStatus)}
        isExpanded={expandedStatus}
      />
    </StyledCandidateCardBorder>
  );
};

export default CandidateCard;
