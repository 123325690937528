import styled from 'styled-components';
import { colors } from 'elitegrad-common/src/theme';

export const StyledCount = styled.div`
  margin-right: 1rem;
  font-size: 1.2rem;
  line-height: 1.2rem;

  color: ${colors.gray_dark};

  &&& {
    padding: 0 2rem 1rem;
  }
`;

export const StyledPaginationWrapper = styled.div`
  text-align: center;
`;

export const SubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 3rem 1rem 0;
`;
