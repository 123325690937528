import React, { useState, useEffect } from 'react';
import { WhereQueryInterface } from '../../../queries/queryUtils';
import { useFilter } from '../../../utils/useFilter';

import FreeTextFilter from '../FreeTextFilter';

export interface CombineFilterProps {
  name: string;
  placeholder?: string;
  textArea?: boolean;
}

export const generateQuery = (value: string): WhereQueryInterface => {
  const words = value.split(',').map((word) => ({ content: { _ilike: `%${word.trim()}%` } }));

  const queryCombineFilter: WhereQueryInterface = {
    enabled: !!value.length,
    operator: '_and',
    whereQuery: { combine: { _and: words } },
  };

  return queryCombineFilter;
};

const CombineFilter: React.FC<CombineFilterProps> = ({ name, placeholder = '', textArea }) => {
  const { storeFilterStatus, filterValue } = useFilter(name);
  // this state purpose is to intoduce a delay to avoid server overload
  const [filter, setFilter] = useState<string>(filterValue as string || '');

  useEffect(() => {
    const timeoutId = setTimeout(() => storeFilterStatus(generateQuery(filter), filter), 700);
    return () => clearTimeout(timeoutId);
  }, [filter]);

  useEffect(() => {
    !filterValue && setFilter('');
  }, [filterValue]);
  
  return (
    <FreeTextFilter
      placeholder={placeholder}
      defaultValue={(filter as string) || ''}
      setFilterValue={setFilter}
      textArea={textArea}
    />
  );
};

export default CombineFilter;
