export enum MessageStatus {
  SUCCESS = 'success',
  SENDING = 'sending',
  ERROR = 'error',
}

interface Message {
  fromCandidate: boolean;
  dateSent: Date;
  content: string;
  id: number;
  error: string | null;
  status: MessageStatus;
}

export default Message;
