import styled from 'styled-components';

import colors from 'elitegrad-common/src/theme/colors';

export const StyledCandidateCardBorder = styled.div<{ selected?: boolean }>`
  width: 25.5rem;
  height: fit-content;
  padding: 0.2rem 0.2rem 0.6rem;
  margin: 1rem 0.5rem;

  border: none;
  border-radius: 13px;

  background-color: ${({ selected }) => (selected ? colors.red : colors.neo_gray)};
`;

export const StyledCandidateCard = styled.div<{ isExpanded?: boolean }>`
  position: relative;
  min-height: 47rem;
  height: auto;
  padding: 1rem 1rem 5rem 1rem;
  ${({ isExpanded }) => !isExpanded && 'padding-bottom: 1rem;'};

  border: none;
  border-radius: 13px;

  background-color: ${colors.white};
`;

export const CardExpandingIcon = styled.img<{ isExpanded: boolean }>`
  display: flex;
  margin: 0 auto;
  margin-top: 0.5rem;

  cursor: pointer;
  ${({ isExpanded }) => isExpanded && `transform: rotate(180deg)`};
`;

export const CurrentGPA = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: ${colors.red};
  font-size: 1.6rem;
  font-weight: bold;
  padding: 0.3rem 0 0.8rem;
`;

export const LimitGPA = styled.div`
  display: flex;
  justify-content: center;
  color: ${colors.red};
  font-size: 1.2rem;
  font-weight: normal;
  margin-left: 0.3rem;
  margin-bottom: 0.1rem;
`;

export const NoGPA = styled.div`
  visibility: hidden;
`;

export const ResumeSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8rem;
  color: ${colors.red};
  font-size: 1.4rem;
  font-weight: bold;
  cursor: pointer;
`;

export const ResumeIcon = styled.img`
  height: 5rem;
`;

export const ExperienceTitle = styled.div`
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.4rem;

  text-transform: capitalize;
`;
