import styled from 'styled-components';

import colors from 'elitegrad-common/src/theme/colors';

export const StyledCandidateCardBorder = styled.div`
  width: 100%;
  height: 100%;
  border: none;
  overflow-y: auto;
  background-color: ${colors.white};
  padding: 0 3rem;
  border-top: 1px solid ${colors.gray_light};
`;

export const StyledCandidateCard = styled.div<{ isExpanded?: boolean }>`
  position: relative;

  height: 100%;
  padding: 1rem 1rem 5rem 1rem;
  ${({ isExpanded }) => !isExpanded && 'padding-bottom: 1rem;'};

  border: none;
  border-radius: 13px;

  background-color: ${colors.white};
`;

export const CurrentGPA = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: ${colors.red};
  font-size: 1.6rem;
  font-weight: bold;
  padding: 0.3rem 0 0.8rem;
`;

export const LimitGPA = styled.div`
  display: flex;
  justify-content: center;
  color: ${colors.red};
  font-size: 1.2rem;
  font-weight: normal;
  margin-left: 0.3rem;
  margin-bottom: 0.1rem;
`;

export const NoGPA = styled.div`
  visibility: hidden;
`;

export const ResumeSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8rem;
  color: ${colors.red};
  font-size: 1.4rem;
  font-weight: bold;
  cursor: pointer;
`;

export const ResumeIcon = styled.img`
  height: 5rem;
`;

export const Spacing = styled.div`
  height: 2rem;
`;

export const ExperienceTitle = styled.div<{ isEmptySection?: boolean }>`
  font-family: 'RobotoBlack';
  font-size: 1.4rem;
  color: ${({ isEmptySection }) => (isEmptySection ? colors.gray_light_l2 : colors.black)};
`;
