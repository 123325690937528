import React from 'react';
import { useQuery } from '@apollo/client';

import GET_DEGREE_SELECTION from '../../../queries/getDegreeSelection.gql';
import { GetDegreeSelection } from '../../../generated/GetDegreeSelection';
import { WhereQueryInterface } from '../../../queries/queryUtils';
import { asDegreeSelection } from '../../../transformers/degreeSelection';
import DegreeSelection from '../../../types/DegreeSelection';
import { FilterOption } from '../../../types/FilterValueType';
import { useFilter } from '../../../utils/useFilter';
import Spinner from '../../Spinner';
import MultiCheckGroupedFilter, { GroupedFilterOptions } from '../MultiCheckGroupedFilter';

export interface GroupedDegreeFilterProps {
  name: string;
}

export const generateQuery = (options: FilterOption[]): WhereQueryInterface => {
  const optionsValues = options.map(({ filterValue }) => filterValue);
  const queryGroupedDegreeFilter: WhereQueryInterface = {
    enabled: !!optionsValues.length,
    operator: '_and',
    whereQuery: {
      degree_latest: { degree: { _in: optionsValues } },
    },
  };
  return queryGroupedDegreeFilter;
};

const GroupedDegreeFilter: React.FC<GroupedDegreeFilterProps> = ({ name }) => {
  const { storeFilterStatus, filterValue, clearFilter } = useFilter(name);

  const { data, loading } = useQuery<GetDegreeSelection>(GET_DEGREE_SELECTION);

  const setFilterValue = (options: FilterOption[]) => storeFilterStatus(generateQuery(options), options);

  const degreeSelections: DegreeSelection[] = data?.grad_degree_selection.map((ds) => asDegreeSelection(ds)) || [];
  const groupedOptions: GroupedFilterOptions = degreeSelections.reduce(
    (accum: { [group: string]: FilterOption[] }, degree: DegreeSelection) => {
      const { id, degreeName, description, degreeLevel } = degree;
      const newOption = {
        filterValue: degreeName,
        displayValue: `${degreeName}: ${description}`,
        id: id,
      };
      const level: string = degreeLevel;
      return {
        ...accum,
        [degreeLevel]: !accum[level] ? [newOption] : [...accum[level], newOption],
      };
    },
    {},
  ) as GroupedFilterOptions;

  return loading ? (
    <Spinner />
  ) : (
    <MultiCheckGroupedFilter
      clearFilter={clearFilter}
      filterTitle={'Degree filter'}
      options={groupedOptions}
      setFilterValue={setFilterValue}
      defaultOptions={(filterValue as FilterOption[]) || []}
    />
  );
};

export default GroupedDegreeFilter;
