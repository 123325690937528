import gql from 'graphql-tag';

export default gql`
  mutation DeleteConversation($candidateId: String, $recruiterId: String) {
    delete_recruit_sms_correspondent(
      where: { recruiter_user_id: { _eq: $recruiterId }, _and: { correspondent_user_id: { _eq: $candidateId } } }
    ) {
      returning {
        correspondent_user_id
      }
    }
  }
`;
