import gql from 'graphql-tag';

import { RecipientParts } from './fragments.gql';

export default gql`
  query GetRecipientsByName($name: String, $limit: Int) {
    grad_profile(where: { fullname: { _ilike: $name } }, limit: $limit) {
      ...RecipientParts
    }
  }
  ${RecipientParts}
`;
