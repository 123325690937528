import React, { useState } from 'react';
import styled from 'styled-components';

import Modal from '../../../components/Modal';
import TemplateListController from './TemplateListController';
import TemplateListItemNewController from './TemplateListItemNewController';
import { colors } from '../../../theme';

export interface TemplateListModalProps {
  onClick?: () => void;
  setModalOpen: (status: boolean) => void;
}

const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 0.8rem 1.6rem;
  font-size: 1.8rem;
  font-family: 'RobotoMedium';
  border-bottom: 1px solid ${colors.gray_light_l10};
`;

const TemplateListModal: React.FC<TemplateListModalProps> = ({ setModalOpen }) => {
  const [saveMode, setSaveMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [templateToEdit, setTemplateToEdit] = useState(0);

  return (
    <Modal heightAuto setModalOpen={setModalOpen}>
      {!saveMode ? (
        <>
          {!editMode ? (
            <>
              <StyledTitle>
                Template List
                <TemplateListItemNewController saveMode={saveMode} setSaveMode={setSaveMode} />
              </StyledTitle>
              <TemplateListController
                templateToEdit={templateToEdit}
                setTemplateToEdit={setTemplateToEdit}
                editMode={editMode}
                setEditMode={setEditMode}
              />
            </>
          ) : (
            <TemplateListController
              templateToEdit={templateToEdit}
              setTemplateToEdit={setTemplateToEdit}
              editMode={editMode}
              setEditMode={setEditMode}
            />
          )}
        </>
      ) : (
        <TemplateListItemNewController saveMode={saveMode} setSaveMode={setSaveMode} />
      )}
    </Modal>
  );
};

export default TemplateListModal;
