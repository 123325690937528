import styled from 'styled-components';
import { colors } from '../../../../theme';

export const StudentInformationContainer = styled.div`
  font-family: 'RobotoBlack';
  font-size: 1.3rem;

  margin-bottom: 1.5rem;
`;

export const StudentInformationValue = styled.span`
  font-family: Roboto;
  color: ${colors.gray_dark};
  margin-left: 0.5rem;
  word-break: break-word;
`;

export const StudentInformationValueLink = styled.a`
  font-family: Roboto;
  color: ${colors.gray_dark};
  margin-left: 0.5rem;
  word-break: break-word;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

export const ReadMore = styled.span`
  text-decoration: underline;
  font-family: 'Roboto';
  color: ${colors.gray_dark};
  margin-left: 0.3rem;
  cursor: pointer;
`;
