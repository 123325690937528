import React from 'react';
import { colors } from '../../../theme';
import styled from 'styled-components';

const StyledTemplateItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  color: ${colors.white};
  border-radius: 4px;
  padding: 1rem 1.1rem;
  background-color: ${colors.black};
  font-family: 'Helvetica Neue';
`;

const StyledTitle = styled.div`
  text-align: right;
  line-height: 1.7rem;
  font-size: 1.4rem;
  font-weight: 700;

  cursor: pointer;
`;

const TemplateListItemNew: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <StyledTemplateItem>
      <StyledTitle onClick={onClick}>New Template</StyledTitle>
    </StyledTemplateItem>
  );
};

export default TemplateListItemNew;
