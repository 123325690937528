import styled, { css } from 'styled-components';
import { colors } from '../../../theme';
import PrimaryButton from '../../../components/PrimaryButton';
import TextAreaInput from '../../../components/TextAreaInput';
import { SendPlane } from '../../../assets/vectors';

export const StyledMessageSender = styled.div<{ big?: boolean }>`
  display: grid;
  grid-template-rows: ${({ big }) => (big ? '12rem 3.2rem' : '8rem 3.2rem')};
  margin-top: 1rem;
  background: ${colors.beta_50_white_gray};
  border: 1px solid ${colors.beta300};
  border-radius: 10px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 3.5rem;
`;

export const StyledTextAreaInput = styled(TextAreaInput)`
  &&& {
    height: 100%;
    resize: none;
    border-style: none;
    padding: 0;
    border-bottom: 1px solid ${colors.beta300};
    background: ${colors.beta_50_white_gray};
    border-radius: 0;
  }
`;

export const StyledSendButton = styled(PrimaryButton)<{ disabled: boolean }>`
  align-self: flex-end;
  background: none;
  position: absolute;
  right: 1rem;
  top: -0.5rem;
  padding: 0;
  border: none;
  min-width: unset;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

export const StyledSendIcon = styled(SendPlane)`
  height: 3.2rem;
  width: 3.2rem;
  fill: #b5becd;
  ::selection {
    background: none;
  }
`;

export const StyledSMS = styled.div`
  position: relative;

  margin: 1rem;
  margin-bottom: 0.5rem;
`;

export const StyledTemplatePicker = styled.div`
  position: absolute;
  top: 0em;
  right: 4rem;
  & > * > * {
    padding: 2.2rem 1.9rem 0;
    background-position: 0rem -0.2rem;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  position: relative;
  background: #fbfbfb;
  border-radius: 10px;
`;
