import React, { createRef, useState } from 'react';
import MessageSender from './MessageSender';
import { useAuth, User } from '../../../utils/react-auth';
import { InsertSMSMessage, InsertSMSMessageVariables } from '../../../generated/InsertSMSMessage';
import INSERT_SMS_MESSAGE from '../../../queries/InsertSMSMessage.gql';
import { useMutation } from '@apollo/client';
import { getMarkups, hasCustomMarkups, replaceMarkup } from '../markup/markup.utils';

interface Candidate {
  id: string;
  firstname?: string;
}

interface MessageSenderControllerProps {
  candidates: Candidate[];
  onSend?: () => void;
  hasMarkup?: boolean;
  popperId: string;
  setModalOpen?: (status: boolean) => void;
  big?: boolean;
}

const MessageSenderController: React.FC<MessageSenderControllerProps> = ({
  candidates,
  onSend,
  hasMarkup,
  popperId,
  setModalOpen,
  big = false,
}) => {
  const [textArea, setTextArea] = useState<string>('');
  const ref = createRef<HTMLTextAreaElement>();
  const getCursorPosition = () => ref.current?.selectionStart || 0;

  const { user } = useAuth() as { user: User };

  const [sendSMS] = useMutation<InsertSMSMessage, InsertSMSMessageVariables>(INSERT_SMS_MESSAGE);

  const disableSend = candidates.length === 0;

  const onChange = (text: string) => {
    setTextArea(text);
  };

  const onInsertMarkup = (markup: string) => {
    const cursorPosition = getCursorPosition();
    const textBeforecursorPosition = textArea.substring(0, cursorPosition);
    const textAfterCursorPosition = textArea.substring(cursorPosition, textArea.length);
    setTextArea(textBeforecursorPosition + markup + textAfterCursorPosition);
  };

  const MESSAGE_MARKUPS = getMarkups();

  const sendSMSToCorrespondents = () => {
    sendSMS({
      variables: {
        content: textArea,
        recruiter_user_id: user.sub,
        correspondents: candidates.map((candidate) => ({ correspondent_user_id: candidate.id })),
      },
    });
  };

  const sendMarkupSMSToCorrespondents = () => {
    candidates.forEach((candidate) => {
      sendSMS({
        variables: {
          content: replaceMarkup(textArea, 'First name', candidate.firstname || ''),
          recruiter_user_id: user.sub,
          correspondents: [{ correspondent_user_id: candidate.id }],
        },
      });
    });
  };

  const onClickSend = () => {
    if (disableSend) return;
    if (!hasCustomMarkups(textArea)) {
      sendSMSToCorrespondents();
    } else {
      sendMarkupSMSToCorrespondents();
    }
    if (onSend) onSend();
    setTextArea('');
    setModalOpen && setModalOpen(false);
  };

  return (
    <MessageSender
      popperId={popperId}
      disabled={disableSend}
      textArea={textArea}
      markupList={MESSAGE_MARKUPS}
      onChange={onChange}
      onSend={onClickSend}
      onInsertMarkup={onInsertMarkup}
      hasMarkup={hasMarkup}
      inputRef={ref}
      big={big}
    />
  );
};

export default MessageSenderController;
