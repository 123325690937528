import React from 'react';
import styled, { css } from 'styled-components';

import colors from '../theme/colors';

export enum LabelPosition {
  Top = 'top',
  Right = 'right',
}

interface StyledLabelProps {
  labelPosition: LabelPosition;
  bold?: boolean;
}

const StyledLabel = styled.label<StyledLabelProps>`
  display: flex;
  flex-direction: column;
  font-family: ${({ bold }) => (bold ? 'RobotoMedium' : 'Roboto')};
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: ${colors.black};

  ${({ labelPosition }) =>
    labelPosition === LabelPosition.Right &&
    css`
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-end;
    `}
`;

const StyledLabelText = styled.div<StyledLabelProps>`
  ${({ labelPosition }) =>
    labelPosition === LabelPosition.Top &&
    css`
      margin-bottom: 1rem;
    `}

  ${({ labelPosition }) =>
    labelPosition === LabelPosition.Right &&
    css`
      margin-left: 0.8rem;
    `}
`;

export interface FormInputProps {
  label?: string;
  bold?: boolean;
  labelPosition?: LabelPosition;
}

const FormInput: React.FC<FormInputProps> = ({ label, bold = false, labelPosition = LabelPosition.Top, children }) => {
  return (
    <StyledLabel bold={bold} labelPosition={labelPosition}>
      <StyledLabelText labelPosition={labelPosition}>{label}</StyledLabelText>
      {children}
    </StyledLabel>
  );
};

export default FormInput;
