import React, { useState } from 'react';
import styled from 'styled-components';

import { DefaultCandidatePhoto } from '../../../assets/images';
import { usePopper } from '../../../utils/usePopper';

import colors from '../../../theme/colors';
import Recipient from '../../../types/Recipient';
import { Link } from 'react-router-dom';
import { AvatarShape, AvatarPlaceholder } from '../../../assets/vectors';
import ChatHeaderPopper from './ChatHeaderPopper';

const ImageShadow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6.5rem;
  height: 6.8rem;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 1)),
    url(${AvatarPlaceholder});
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
`;

const Photo = styled.img`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  mask: url(${AvatarShape});
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 92%;
  position: absolute;
  background: transparent;
`;

const StyledCandidateInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  margin: 0 1rem;
  margin-left: 1.7rem;
`;

const StyledTextLow = styled.div`
  font-size: 1.2rem;
  line-height: 1.2rem;

  color: ${colors.gray_dark};
`;

const StyledChatRecipientIdentity = styled(Link)`
  display: grid;
  grid-template-columns: 6.5rem 1fr 5rem;
  grid-template-rows: 10rem;
  margin-left: 2rem;
  align-items: center;
  cursor: pointer;
`;

const RecipientFullName = styled.div`
  font-family: 'RobotoMedium';
  font-size: 2.4rem;
  line-height: 2.8rem;
  color: ${colors.beta_lighten_15};
`;

const Options = styled.div`
  font-size: 2rem;
  padding: 1rem;
  transform: rotate(90deg);
  display: flex;
  justify-content: center;
`;

const PopperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;

const PopperItem = styled.span`
  font-size: 1.6rem;
  width: 100%;
  line-height: 1.9rem;
  padding: 1rem 2rem;

  :hover {
    background-color: ${colors.beta200};
  }
`;

interface ChatRecipientIdentityProps {
  recipient: Recipient;
  candidatePath: string;
  setStudentProfileOpen: (arg: boolean) => void;
  handleDeleteChat: () => void;
}

const ChatRecipientIdentity: React.FC<ChatRecipientIdentityProps> = ({
  recipient,
  candidatePath,
  setStudentProfileOpen,
  handleDeleteChat,
}) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);

  const { popperOpen, togglePopper } = usePopper('profile-and-delete-header-popper');
  return (
    <StyledChatRecipientIdentity to={candidatePath}>
      <ImageShadow>
        <Photo src={recipient.photo || DefaultCandidatePhoto} />
      </ImageShadow>
      <StyledCandidateInformation>
        <RecipientFullName>{recipient.fullname}</RecipientFullName>
        <StyledTextLow>
          {recipient.title ? `${recipient.title}, ` : ''}
          {recipient?.degrees?.[0]?.degree} {recipient?.degrees?.[0]?.gpa ? ` - ${recipient?.degrees?.[0]?.gpa}` : ''}
          {recipient?.degrees?.[0]?.university?.location_name
            ? ` - ${recipient?.degrees?.[0]?.university?.location_name}`
            : ''}
        </StyledTextLow>
      </StyledCandidateInformation>
      <Options
        ref={setReferenceElement}
        onClick={() => {
          togglePopper();
        }}
      >
        ...
      </Options>
      <ChatHeaderPopper show={popperOpen} referenceElement={referenceElement}>
        <PopperContainer>
          <PopperItem
            onClick={() => {
              togglePopper();
              setStudentProfileOpen(true);
            }}
          >
            Student Profile
          </PopperItem>
          <PopperItem
            onClick={() => {
              togglePopper();
              handleDeleteChat();
            }}
          >
            Delete chat
          </PopperItem>
        </PopperContainer>
      </ChatHeaderPopper>
    </StyledChatRecipientIdentity>
  );
};

export default ChatRecipientIdentity;
