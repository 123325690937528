export default {
  black: '#000000',
  light_black: '#282828',
  light_black_l2: '#1c2125',
  light_black_l3: '#383838',
  red: '#ee151b',
  pink_light: '#fef6f6',
  green: '#38A169',
  gray: '#848484',
  gray_2: '#718096',
  gray_dark: '#4a4a4a',
  gray_dark_2: '#373D42',
  gray_extraLight: '#f6f6f6',
  gray_extraLight_l2: '#9498a0',
  gray_light: '#dddddd',
  gray_light_l1: '#eeeeee',
  gray_light_l2: '#dcdcdc',
  gray_light_l3: '#E2E8F0',
  gray_light_l4: '#f9f9f9',
  gray_light_l5: '#EFEFEF',
  gray_light_l6: '#BEBEBE',
  gray_light_l7: '#b5becd',
  beta_50_white_gray: '#FBFBFB',
  beta_700_gray_dark: '#54595C',

  gray_light_l8: '#E1E1E1',
  gray_light_l9: '#878787',
  gray_light_l10: '#F4F4F4',
  gray_l1: '#aaaaaa',
  gray_l2: '#F2F2F2',
  gray_l3: '#A8A8A8',
  gray600: '#4A5568',
  gray_g3: '#C2C2C2',
  blue: '#D7DFED',
  white: '#ffffff',
  green_light: '#81BB4E',
  alpha_400: 'e95f57',
  dark_red: '#DC352C',
  dark_red_l2: '#E53E3E',
  neo_gray: '#DCDCDC',
  gray_light_l11: '#F1F1F1',

  green400: '#48BB78',
  green600: '#38B2AC',
  beta200: '#E5ECF3',
  beta300: '#D7DFED',
  beta600: '#73777B',
  beta_lighten_80: '#E6E6E6',
  beta_lighten_15: '#404040',
  beta_lighten_45: '#8D8D8D',
  beta_lighten_60: '#B3B3B3',
  beta_lighten_75: '#D9D9D9',
  beta_200: '#E5ECF3',

};
