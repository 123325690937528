import React, { useState } from 'react';
import styled from 'styled-components';
import WithSeparator from 'react-with-separator';
import HorizontalRuler from '../../components/HorizontalRuler';

import { colors } from '../../theme';
import Group from '../../types/Group';

import SearchInputText from '../SearchInputText';

import GroupModalOption from './GroupModalOption';
import GroupModalNewGroupController from './GroupModalNewGroupController';
import Candidate from '../../types/Candidate';
import GroupModalFooterButtonsController from './GroupModalFooterButtonsController';
import Modal from '../../components/Modal';
import SpinnerIsoType from '../SpinnerIsoType';

const StyledGroupHeader = styled.div`
  margin: 2.5rem auto 2.8rem 1.6rem;
  font-family: 'RobotoMedium';
  font-size: 1.8rem;
  line-height: 2.1rem;
`;

const StyledGroupOptions = styled.div`
  max-height: 45rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 7.3rem;
  overflow-y: auto;
`;

const StyledSearchInputText = styled(SearchInputText)`
  width: auto;
  margin: 1rem 2rem;

  border: 1px solid ${colors.gray_light};
  border-radius: 8px;
  height: 4.5rem;
  font-family: 'Roboto';
  font-size: 1.6rem;
  line-height: 1.9rem;
  padding: 1.2rem;
  padding-left: 3.5rem;

  background-color: ${colors.white};

  ::placeholder {
    color: ${colors.gray_g3};
  }
`;

const StyledNewControllerDiv = styled.div`
  margin-left: 2rem;
  margin-right: 2rem;
  display: flex;
  align-items: center;
`;

export interface GroupModalProps {
  candidates: Candidate[];
  loadingGroups: boolean;
  setModalOpen: (status: boolean) => void;
  groups: Group[];
  refetch: () => void;
}

const filterGroups = (groups: Group[], textFilter: string): Group[] => {
  return groups.filter((u) => u.name.toLowerCase().includes(textFilter.toLowerCase()));
};

const GroupModal: React.FC<GroupModalProps> = ({ setModalOpen, groups, loadingGroups, candidates, refetch }) => {
  const [groupFilter, setGroupFilter] = useState('');
  const [selectedGroupsIds, setSelectedGroupsIds] = useState(
    candidates.length === 1 && candidates[0].groups ? candidates[0].groups.map(({ id }) => id) : [],
  );
  const [toDeleteGroups, setToDeleteGroups] = useState<Group[]>([]);

  const filteredGroups = filterGroups(groups, groupFilter);

  const handleCancelOnClick = () => {
    setModalOpen(false);
  };

  const addGroupToSelect = (newGroupId: string) => {
    setSelectedGroupsIds([newGroupId, ...selectedGroupsIds]);
  };

  const generateToggleSelectForGroup = ({ id: newGroupId }: Group) => {
    return (selected: boolean) => {
      const newSelectedGroupsIds = !selected
        ? selectedGroupsIds.filter((id: string) => id !== newGroupId)
        : [newGroupId, ...selectedGroupsIds];
      setSelectedGroupsIds(newSelectedGroupsIds);
    };
  };

  const generateToggleDeleteForGroup = (group: Group) => {
    return (isDelete: boolean) => {
      const newToDeleteGroups = isDelete
        ? toDeleteGroups.filter(({ id }) => id !== group.id)
        : [group, ...toDeleteGroups];
      setToDeleteGroups(newToDeleteGroups);
    };
  };

  return (
    <Modal setModalOpen={setModalOpen}>
      <StyledGroupHeader>Add to Group</StyledGroupHeader>
      <HorizontalRuler />
      <StyledSearchInputText placeholder="Search Groups" value={groupFilter} onChange={setGroupFilter} />

      <StyledNewControllerDiv>
        <GroupModalNewGroupController addSelectedGroupId={addGroupToSelect} />
      </StyledNewControllerDiv>

      <StyledGroupOptions>
        {loadingGroups ? (
          <SpinnerIsoType text="Loading" />
        ) : (
          <WithSeparator separator={<HorizontalRuler light />}>
            {filteredGroups.map((group) => (
              <GroupModalOption
                group={group}
                key={group.id}
                isSelected={selectedGroupsIds.includes(group.id)}
                toggleSelect={generateToggleSelectForGroup(group)}
                toggleDelete={generateToggleDeleteForGroup(group)}
              />
            ))}
          </WithSeparator>
        )}
      </StyledGroupOptions>

      <GroupModalFooterButtonsController
        groupIds={selectedGroupsIds}
        candidates={candidates}
        setModalStatus={setModalOpen}
        toDeleteGroups={toDeleteGroups}
        handleCancelOnClick={handleCancelOnClick}
        refetch={refetch}
      />
    </Modal>
  );
};

export default GroupModal;
