import React, { Ref } from 'react';
import styled, { css } from 'styled-components';
import colors from '../theme/colors';

interface StyledTextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  greyish?: boolean;
}

const StyledTextInput = styled.input<StyledTextInputProps>`
  width: 100%;
  height: 3.5rem;
  padding: 0.8rem 1rem;

  border: 1px solid ${colors.gray_l3};
  border-radius: 4px;
  font-family: 'Roboto';
  background-color: transparent;

  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6rem;
  color: ${colors.black};

  :focus {
    outline: none;
  }

  :placeholder {
    color: ${colors.gray_l1};
    font-size: 1.2rem;
  }

  ${({ greyish }) =>
    greyish &&
    css`
      border: none;

      background-color: ${colors.gray_light_l1};

      font-size: 1.6rem;
      line-height: 1.9rem;
      color: ${colors.gray};
    `}
`;

export interface TextInputProps {
  value?: string;
  greyish?: boolean;
  placeholder?: string;
  type?: string;
  onChange?: (value: string) => void;
  inputRef?: Ref<HTMLInputElement>;
}

const TextInput: React.FC<TextInputProps> = ({
  greyish = false,
  placeholder = '',
  type = 'text',
  value = '',
  onChange = () => undefined,
  inputRef,
  ...props
}) => {
  return (
    <StyledTextInput
      {...props}
      type={type}
      value={value}
      placeholder={placeholder}
      greyish={greyish}
      onChange={(e) => onChange(e.target.value)}
      ref={inputRef}
    />
  );
};

interface HookTextInputProps {
  name: string;
  type?: string;
  register: Ref<HTMLInputElement>;
}

export const HookTextInput: React.FC<HookTextInputProps> = ({ name, type, register }) => (
  <StyledTextInput type={type} ref={register} name={name} />
);

export default TextInput;
