import gql from 'graphql-tag';

import { ConversationParts } from './fragments.gql';

export default gql`
  mutation UpdateConversationSeenTime(
    $recruiter_user_id: String!
    $correspondent_user_id: String!
    $last_read_timestamp: timestamptz!
  ) {
    update_recruit_sms_correspondent(
      where: { correspondent_user_id: { _eq: $correspondent_user_id }, recruiter_user_id: { _eq: $recruiter_user_id } }

      _set: { last_read_timestamp: $last_read_timestamp }
    ) {
      returning {
        ...ConversationParts
      }
    }
  }
  ${ConversationParts}
`;
