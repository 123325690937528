import React from 'react';
import styled from 'styled-components';

import colors from '../theme/colors';

const StyledFulltimeTag = styled.span`
  padding: 0.3rem 1.2rem;

  border-radius: 3px;
  background-color: ${colors.black};

  font-size: 0.9rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: ${colors.white};
`;

const FullTimeInterestTag: React.FC = (props) => {
  return <StyledFulltimeTag {...props}>Full Time</StyledFulltimeTag>;
};

export default FullTimeInterestTag;
