import React, { useState } from 'react';

import PrimaryButton from 'elitegrad-common/src/components/PrimaryButton';
import SecondaryButton from 'elitegrad-common/src/components/SecondaryButton';
import HeaderPopper from 'elitegrad-common/src/components/HeaderPopper';
import { usePopper } from 'elitegrad-common/src/utils/usePopper';

import { Container, IconPeopleArrows, PopperContent, ShowStatus } from './RecruiterImpersonationDialog.styled';

export interface RecruiterImpersonationMessageProps {
  onContinueImpersonation: () => void;
  fullname: string;
  userId: string;
}

const RecruiterImpersonationDialog: React.FC<RecruiterImpersonationMessageProps> = ({
  onContinueImpersonation,
  fullname,
  userId,
}) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);

  const { popperOpen, togglePopper, setPopperStatus } = usePopper(userId);

  const onSave = () => {
    onContinueImpersonation();
    setPopperStatus(false);
  };

  return (
    <Container>
      <ShowStatus onClick={togglePopper} ref={setReferenceElement}>
        <IconPeopleArrows />
      </ShowStatus>
      <HeaderPopper referenceElement={referenceElement} show={popperOpen}>
        <PopperContent>
          <div>Log in as {fullname}?</div>
          <div>
            <SecondaryButton big onClick={() => setPopperStatus(false)} text={'No, Cancel'} />
            <PrimaryButton dark big onClick={onSave} text={'Continue'} />
          </div>
        </PopperContent>
      </HeaderPopper>
    </Container>
  );
};

export default RecruiterImpersonationDialog;
