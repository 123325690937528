import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import GET_UNIVERSITY_SELECTION from '../../../queries/GetUniversitySelection.gql';
import { WhereQueryInterface } from '../../../queries/queryUtils';
import { asUniversity } from '../../../transformers/universities';
import { FilterOption } from '../../../types/FilterValueType';
import University from '../../../types/University';
import { useFilter } from '../../../utils/useFilter';
import MultiCheckFilterAsync from '../MultiCheckFilterAsync';
import { GetUniversityStat, GetUniversityStatVariables } from 'src/generated/GetUniversityStat';

export interface UniversityFilterProps {
  name: string;
}

export const generateQuery = (options: FilterOption[]): WhereQueryInterface => {
  const optionsIds = options.map(({ id }) => id);
  const queryUniversityFilter: WhereQueryInterface = {
    enabled: !!optionsIds.length,
    operator: '_and',
    whereQuery: { degrees: { university_selection_id: { _in: optionsIds } } },
  };
  return queryUniversityFilter;
};

const UniversityFilter: React.FC<UniversityFilterProps> = ({ name }) => {
  const { storeFilterStatus, filterValue, clearFilter } = useFilter(name);

  const [textFilterValue, setTextFilterValue] = useState('');
  const [localLoading, setLocalLoading] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState<FilterOption[]>((filterValue as FilterOption[]) || []);

  const [getUniversities, { data: universitySelection, loading }] = useLazyQuery<
    GetUniversityStat,
    GetUniversityStatVariables
  >(GET_UNIVERSITY_SELECTION, {
    onCompleted: () => {
      setLocalLoading(false);
    },
    onError: () => {
      setLocalLoading(false);
    },
  });

  useEffect(() => {
    getUniversities({ variables: { location_name: `% %` } });
  }, [getUniversities]);

  const handleTextFilter = (value: string) => {
    setLocalLoading(true);
    setTextFilterValue(value);

    setTimeout(() => {
      setLocalLoading(true);
      getUniversities({ variables: { location_name: `%${value.replace(/\W+/g, () => '%')}%` } });
    }, 1000);
  };

  const setFilterValue = (options: FilterOption[]) => storeFilterStatus(generateQuery(options), options);

  const groups: University[] = universitySelection?.grad_university_stat.map((gp) => asUniversity(gp)) || [];
  const optionsGroups: FilterOption[] = groups.map(({ id, name }) => ({
    filterValue: name,
    displayValue: name,
    id: id,
  }));

  const optionsAndSelections = [
    ...optionsGroups.filter(({ id }) => !selectedOptions.some(({ id: selectedId }) => selectedId === id)),
  ];

  return (
    <>
      {loading ? (
        <Skeleton height={303} />
      ) : (
        <MultiCheckFilterAsync
          loading={localLoading}
          setTextFilter={handleTextFilter}
          textFilter={textFilterValue}
          filterTitle={'University'}
          options={optionsAndSelections}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          setFilterValue={setFilterValue}
          clearFilter={clearFilter}
        />
      )}
    </>
  );
};

export default UniversityFilter;
