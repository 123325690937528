import React, { useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import ConversationListItem from './ConversationListItem';
import Conversation from '../../../types/Conversation';
import { EditCheck, ChevronDown, SendMessage } from '../../../assets/vectors';
import colors from '../../../theme/colors';
import ConversationListSearch from './ConversationListSearch';
import SpinnerIsoType from '../../SpinnerIsoType';
import { ChatModalNew } from '../../messaging';
import { usePopper } from '../../../utils/usePopper';
import ConversationHeaderPopper from './ConversationHeaderPopper';
import TemplateAdministration from '../template/TemplateAdministration';

const StyledConversationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  border-bottom: 1px solid ${colors.gray_light};
`;

const StyledConversationsHeader = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 36rem;
  padding: 2rem 2rem 0 2rem;
  border-bottom: 1px solid ${colors.gray_light};
`;
const StyledConversationsList = styled.div`
  display: grid;
  grid-template-rows: 10rem 1fr;

  height: 100%;
  max-width: 36rem;
`;

const StyledTitle = styled.div`
  margin: auto 0;
  font-family: 'RobotoMedium';
  font-size: 2.4rem;
  line-height: 2.8rem;
`;

const SendIcon = styled(SendMessage)`
  fill: ${colors.gray_dark_2};
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.5rem;
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const StyledNewChat = styled.div`
  margin: auto;
  margin-left: 1rem;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
`;

const StyledPlusSign = styled(EditCheck)`
  width: 2rem;
`;

const StyledArrow = styled(ChevronDown)`
  width: 2rem;
`;

const EmptyConversationList = styled.div`
  display: flex;
  height: 100%;
  margin: 1rem 1rem;
  font-size: 1.1rem;
`;

const PopperContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;

interface ConversationListProps {
  candidateId?: string;
  conversations?: Conversation[];
  loading: boolean;
  onSearchChange: (name: string) => void;
  generateMessagingPath: (userId: string) => string;
}

const ConversationList: React.FC<ConversationListProps> = ({
  loading,
  conversations = [],
  onSearchChange,
  generateMessagingPath,
}) => {
  const [newChatModal, setNewChatModal] = useState<boolean>(false);
  const [templateModal, setTemplateModal] = useState<boolean>(false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);

  const { popperOpen, togglePopper } = usePopper('template-popper');
  return (
    <StyledConversationsList>
      <StyledConversationsHeader>
        <Row>
          <StyledTitle>Messages</StyledTitle>
          <IconsContainer>
            <StyledNewChat ref={setReferenceElement} onClick={() => togglePopper()}>
              <StyledArrow />
            </StyledNewChat>

            <StyledNewChat onClick={() => setNewChatModal(true)}>
              <StyledPlusSign />
            </StyledNewChat>
          </IconsContainer>
          {newChatModal && <ChatModalNew setModalOpen={setNewChatModal} candidates={[]} />}
          <ConversationHeaderPopper show={popperOpen} referenceElement={referenceElement}>
            <PopperContainer
              onClick={() => {
                togglePopper();
                setTemplateModal(true);
              }}
            >
              <SendIcon />
              Administration Message Template
            </PopperContainer>
          </ConversationHeaderPopper>
        </Row>
        <ConversationListSearch onChange={onSearchChange} />
        {templateModal && <TemplateAdministration toggle={setTemplateModal} />}
      </StyledConversationsHeader>
      <StyledConversationsContainer>
        {loading ? (
          <SpinnerIsoType text={'Searching existing conversations'} />
        ) : Boolean(conversations.length) ? (
          conversations.map((conversation) => (
            <NavLink
              to={() => generateMessagingPath(conversation.id)}
              key={conversation.id}
              activeStyle={{ backgroundColor: colors.beta200 }}
              style={{ borderBottom: `1px solid ${colors.beta_lighten_80}` }}
            >
              <ConversationListItem conversation={conversation} />
            </NavLink>
          ))
        ) : (
          <EmptyConversationList>
            You don’t have any conversations yet. Select “New Chat” to start your first one!
          </EmptyConversationList>
        )}
      </StyledConversationsContainer>
    </StyledConversationsList>
  );
};

export default ConversationList;
