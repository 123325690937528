import React from 'react';
import styled from 'styled-components';

import TestDetail from '../../types/TestDetail';
import { colors } from '../../theme';

export const DetailList = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.2rem;
  padding: 1rem 0.5rem;

  text-align: center;

  & > *::after {
    content: '';
    background: ${colors.neo_gray};
    position: absolute;
    bottom: 25%;
    right: 0;
    height: 50%;
    width: 0.8px;
  }

  & > *:last-child::after {
    width: 0px;
  }
`;

export const Detail = styled.div<{ big: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: ${({ big }) => (big ? '1.6rem' : '1.4rem')};
  line-height: 1.1rem;
  padding: 0 1.8rem;
  text-transform: capitalize;
`;

export const DetailTitle = styled.div`
  font-size: 1rem;
`;
export const DetailScore = styled.div`
  font-weight: bold;
  margin-bottom: 0.3rem;
`;
interface CandidateTestDetailProps {
  details: TestDetail[];
  big?: boolean;
}

const CandidateTestScoreDetails: React.FC<CandidateTestDetailProps> = ({ details, big = false }) => {
  return (
    <DetailList>
      {details.map((d) => (
        <Detail big={big} key={d.id}>
          <DetailScore>{d.score}</DetailScore>
          <DetailTitle>{d.type}</DetailTitle>
        </Detail>
      ))}
    </DetailList>
  );
};

export default CandidateTestScoreDetails;
