import React from 'react';
import styled from 'styled-components';
import { colors } from '../theme';

import BackLink from '../components/BackLink';
import ViewTitle from './ViewTitle';

const StyledHeader = styled.div<{ dark?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  height: 6.1rem;
  padding: 1rem 2rem;

  background-color: ${({ dark }) => (dark ? colors.gray_extraLight : colors.white)};

  & > :first-child {
    margin: auto 0;
  }
`;

interface HeaderProps {
  title?: string;
  backLink?: boolean;
  dark?: boolean;
  big?: boolean;
}

const Header: React.FC<HeaderProps> = ({ dark, backLink, title, children, big }) => {
  return (
    <StyledHeader dark={dark}>
      {title && <ViewTitle big={big}>{title}</ViewTitle>}
      {backLink && <BackLink />}
      {children}
    </StyledHeader>
  );
};

export default Header;
