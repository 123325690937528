import React from 'react';
import styled from 'styled-components';
import ExitCross from '../components/ExitCross';

import { colors } from '../theme';

const StyledModal = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.4);
`;

const StyledModalContent = styled.div<{ heightAuto?: boolean; width?: string }>`
  position: absolute;
  overflow-y: auto;
  left: 50%;
  top: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;

  width: ${({ width }) => width || '58rem'};
  height: ${({ heightAuto }) => (heightAuto ? 'auto' : '67rem')};

  background: ${colors.white};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;

const StyledCross = styled(ExitCross)`
  z-index: 3;
`;

export interface ModalProps {
  setModalOpen: (status: boolean) => void;
  heightAuto?: boolean;
  width?: string;
  crossPosition?: Record<string, string>;
}

const Modal: React.FC<ModalProps> = ({ setModalOpen, heightAuto = false, width, crossPosition, children }) => {
  return (
    <StyledModal>
      <StyledModalContent heightAuto={heightAuto} width={width}>
        <StyledCross top={crossPosition?.top} right={crossPosition?.right} onClick={() => setModalOpen(false)} />

        {children}
      </StyledModalContent>
    </StyledModal>
  );
};

export default Modal;
