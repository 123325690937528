import React from 'react';

import MultiCheckFilterOption from './MultiCheckFilterOption';
import styled from 'styled-components';
import CollapsibleSection from '../CollapsibleSection';
import { FilterOption } from '../../types/FilterValueType';

const StyledGroupedOptions = styled.div`
  max-height: 20rem;

  overflow-y: hidden;
  overflow-x: hidden;
`;

export interface MultiCheckGroupedFilterGroupProps {
  groupTitle: string;
  options: FilterOption[];
  selectedOptions: FilterOption[];
  generateToggleOption: (option: FilterOption) => (add: boolean) => void;
  isSelectedAll: boolean;
  onSelectAll: () => void;
  defaultCollapsed?: boolean;
}

const MultiCheckGroupedFilterGroup: React.FC<MultiCheckGroupedFilterGroupProps> = ({
  options,
  selectedOptions,
  generateToggleOption,
  groupTitle,
  isSelectedAll,
  onSelectAll,
  defaultCollapsed = false,
}) => {
  return (
    <StyledGroupedOptions>
      <CollapsibleSection
        defaultCollapsed={defaultCollapsed}
        title={groupTitle}
        isSelectedAll={isSelectedAll}
        onSelectAll={onSelectAll}
      >
        {selectedOptions.map((option) => {
          return (
            <MultiCheckFilterOption
              checked={true}
              option={option}
              key={option.id}
              toggleOption={generateToggleOption(option)}
            />
          );
        })}
        {options.map((option) => {
          const checked = selectedOptions.some(({ id }) => option.id === id);
          return (
            <MultiCheckFilterOption
              checked={checked}
              option={option}
              key={option.id}
              toggleOption={generateToggleOption(option)}
            />
          );
        })}
      </CollapsibleSection>
    </StyledGroupedOptions>
  );
};

export default MultiCheckGroupedFilterGroup;
