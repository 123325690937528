import Message, { MessageStatus } from '../types/Message';
import {
  GetMessages_recruit_sms_message,
  GetMessages_recruit_sms_message_correspondents,
} from '../generated/GetMessages';

const asMessageStatus = (correspondent?: GetMessages_recruit_sms_message_correspondents) => {
  if (correspondent === undefined) return MessageStatus.ERROR;
  const error = correspondent.error_message;
  if (error === null) return MessageStatus.SENDING;
  if (error === MessageStatus.SUCCESS) return MessageStatus.SUCCESS;

  return MessageStatus.ERROR;
};

const asMessage = (messageParts: GetMessages_recruit_sms_message): Message => {
  const correspondent = messageParts.correspondents[0];
  const error = correspondent?.error_message || null;
  return {
    fromCandidate: messageParts.incoming,
    dateSent: messageParts.timestamp,
    content: messageParts.content,
    id: messageParts.sms_message_id,
    status: asMessageStatus(correspondent),
    error,
  };
};

export { asMessage };
