import colors from 'elitegrad-common/src/theme/colors';
import styled, { css } from 'styled-components';

export const FilterSideBar = styled.div`
  height: 100vh;
  min-width: 22rem;
  width: 22rem;
  padding: 1.8rem 1.1rem;

  background-color: ${colors.gray_light_l1};

  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0;
`;

export const FilterButtonsContainer = styled.div`
  display: flex;
`;

export const ButtonApply = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.dark_red};
  color: ${colors.white};
  cursor: pointer;
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.6rem;
  width: 6rem;
  height: 2rem;
  border-radius: 10rem;
  margin-top: 3.2rem;
`;

export const ButtonClear = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.dark_red};
  color: ${colors.white};
  cursor: pointer;
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.6rem;
  width: 6rem;
  height: 2rem;
  border-radius: 10rem;
  margin-left: 0.8rem;
  margin-top: 3.2rem;
`;

export const FilterTitle = styled.div<{ first?: boolean }>`
  font-family: 'Roboto';
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: ${colors.light_black};
  margin-top: 1.5rem;

  ${({ first }) =>
    first &&
    css`
      margin-top: 3.8rem;
    `}
`;
