import Recipient from '../types/Recipient';
import { RecipientParts } from '../generated/RecipientParts';
import Candidate from '../types/Candidate';

const asRecipientCandidate = (candidate: Candidate): Recipient => {
  return {
    firstname: candidate.firstname,
    middlename: candidate.middlename || '',
    lastname: candidate.lastname,
    fullname: candidate.fullname,
    title: candidate.title || '',
    photo: candidate.photo,
    id: candidate.userId,
  };
};

const asRecipient = (recipient: RecipientParts): Recipient => {
  return {
    firstname: recipient.firstname,
    middlename: recipient.middlename || '',
    lastname: recipient.lastname,
    fullname: recipient.fullname,
    title: recipient.title || '',
    photo: recipient.profile_image,
    id: recipient.user_id,
    degrees: recipient?.degrees || [],
  };
};

export { asRecipient, asRecipientCandidate };
