import React from 'react';

import CandidateType from 'elitegrad-common/src/types/Candidate';
import CandidateCard from '../CandidateCard';

import { StyledCandidatesGrid } from './CandidatesGrid.styled';

interface CandidatesGridProps {
  candidates: CandidateType[];
  editSelection?: (candidate: CandidateType, add: boolean) => void;
  selectedCandidates?: CandidateType[];
  refetch: () => void;
  defaultCollapsedCards?: boolean;
  hasCVCards?: boolean;
}

const CandidatesGrid: React.FC<CandidatesGridProps> = ({
  candidates,
  editSelection,
  selectedCandidates = [],
  refetch,
  defaultCollapsedCards = false,
  hasCVCards,
}) => {
  return (
    <StyledCandidatesGrid hasCVCards={hasCVCards}>
      {candidates.map((c) => (
        <CandidateCard
          refetch={refetch}
          selected={selectedCandidates.some(({ userId }) => userId === c.userId)}
          candidate={c}
          key={c.userId}
          editSelection={editSelection ? (add: boolean) => editSelection(c, add) : undefined}
          isExpanded={!defaultCollapsedCards}
          isCVCard={hasCVCards}
        />
      ))}
    </StyledCandidatesGrid>
  );
};

export default CandidatesGrid;
