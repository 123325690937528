import gql from 'graphql-tag';

import { RecipientParts } from './fragments.gql';

export default gql`
  query GetRecipientById($userId: String) {
    grad_profile(where: { user_id: { _eq: $userId } }) {
      ...RecipientParts
    }
  }
  ${RecipientParts}
`;
