import React, { useState, SyntheticEvent, useCallback } from 'react';

import CandidateType from '../../../types/Candidate';
import GroupModalController from '../../../components/groups/GroupModalController';
import { ChatModalNew } from '../../../components/messaging';
import {
  ClearSelection,
  Wrapper,
  StyledMenuMoreAddGroupWhite,
  StyledMenuMoreSendMessageWhite,
  StyledMenuMoreDownloadCVWhite,
  StyledSecondaryButton,
  ButtonContainer,
  ButtonGroupContainer,
  ButtonContent,
} from './CandidateGridFooter.styled';

interface CandidateGridFooterProps {
  selectedCandidates: CandidateType[];
  clearSelection: () => void;
  onClickDownloadCvs: () => void;
  refetch: () => void;
  cvsCount: number;
  downloadingCVs: boolean;
  admin?: boolean;
}

const CandidateGridFooter: React.FC<CandidateGridFooterProps> = ({
  selectedCandidates,
  onClickDownloadCvs,
  clearSelection,
  downloadingCVs,
  refetch,
  cvsCount,
  admin = false,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenMessaging, setModalOpenMessaging] = useState(false);

  const selectedCount = selectedCandidates.length;
  const displayFooter = !!selectedCount;

  const handleMenuClick = () => setModalOpen((m) => !m);

  const handleMenuMessagingClick = () => setModalOpenMessaging((m) => !m);

  const handleMenuDownloadCvs = () => onClickDownloadCvs();

  const handlePropagation = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  const downloadCVsButtonMessage =
    cvsCount < 1 ? 'None selected candidates has a CV' : `Download CVs of selected candidate`;

  if (!displayFooter) return null;

  return (
    <Wrapper onClick={handlePropagation}>
      <ClearSelection onClick={clearSelection}>Clear Selection</ClearSelection>
      <ButtonGroupContainer>
        {!admin && (
          <>
            <ButtonContainer>
              <StyledSecondaryButton onClick={handleMenuClick}>
                <ButtonContent>
                  <StyledMenuMoreAddGroupWhite />
                  {`Add ${selectedCount} student${selectedCount > 1 ? 's' : ''} to group`}
                </ButtonContent>
              </StyledSecondaryButton>
            </ButtonContainer>
            <ButtonContainer>
              <StyledSecondaryButton onClick={handleMenuMessagingClick}>
                <ButtonContent>
                  <StyledMenuMoreSendMessageWhite />
                  {`Send ${selectedCount} message${selectedCount > 1 ? 's' : ''} to candidate${
                    selectedCount > 1 ? 's' : ''
                  } `}
                </ButtonContent>
              </StyledSecondaryButton>
            </ButtonContainer>
          </>
        )}
        <ButtonContainer>
          <StyledSecondaryButton onClick={handleMenuDownloadCvs} disabled={cvsCount < 1 || downloadingCVs}>
            <ButtonContent>
              <StyledMenuMoreDownloadCVWhite />
              {downloadCVsButtonMessage}
            </ButtonContent>
          </StyledSecondaryButton>
        </ButtonContainer>
      </ButtonGroupContainer>

      {modalOpenMessaging && <ChatModalNew setModalOpen={setModalOpenMessaging} candidates={selectedCandidates} />}
      {modalOpen && (
        <GroupModalController
          refetch={() => {
            setModalOpen(false);
            refetch();
            clearSelection();
          }}
          setModalOpen={setModalOpen}
          candidates={selectedCandidates}
        />
      )}
    </Wrapper>
  );
};

export default CandidateGridFooter;
