import React, { useState } from 'react';

import CandidateType from '../../../types/Candidate';
import CandidateHeaderButton from './CandidateHeaderButton';
import GroupModalController from '../../groups/GroupModalController';
import { Group } from '../../../assets/icons';

interface CandidateGroupButtonProps {
  candidate: CandidateType;
  refetch: () => void;
}

const CandidateGroupButton: React.FC<CandidateGroupButtonProps> = ({ candidate, refetch }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOnClickAddGroup = () => setIsModalOpen((modalState) => !modalState);

  return (
    <CandidateHeaderButton onClick={handleOnClickAddGroup} text="Add to group" icon={Group}>
      {isModalOpen && (
        <GroupModalController setModalOpen={setIsModalOpen} candidates={[candidate]} refetch={() => null} />
      )}
    </CandidateHeaderButton>
  );
};

export default CandidateGroupButton;
