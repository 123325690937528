import React from 'react';

import Header from 'elitegrad-common/src/components/Header';
import Pagination, { PaginationState } from 'elitegrad-common/src/components/pagination';
import SpinnerIsoType from 'elitegrad-common/src/components/SpinnerIsoType';
import ShowPhotosSwitch from 'elitegrad-common/src/components/ShowPhotosSwitch';
import Candidate from 'elitegrad-common/src/types/Candidate';
import Button from 'elitegrad-common/src/components/PrimaryButton';
import FilterSideBar from 'elitegrad-common/src/components/FilterSideBar';
import CandidateGridFooter from 'elitegrad-common/src/components/candidates/CandidateGridFooter';

import Page from 'views/Page';
import CandidatesGrid from '../CandidatesGrid';

import { StyledCount, StyledPaginationWrapper, SubHeader } from './CandidatesList.styled';
import CandidateListOrderBy, {
  CandidateListOrderByProps,
} from 'elitegrad-common/src/components/candidates/CandidateListOrderBy';

export interface CandidatesProps extends CandidateListOrderByProps {
  refetch: () => void;
  cvsCount: number;
  selectedCandidates: Candidate[];
  allCandidatesSelected: boolean;
  candidates: Candidate[];
  loadingCandidates: boolean;
  downloadingCVs: boolean;
  editSelection: (candidate: Candidate, add: boolean) => void;
  clearSelection: () => void;
  onClickDownloadCvs: () => void;
  pagination: PaginationState;
  onChangePaginationOffset: (offset: number) => void;
  selectAllCandidates: () => void;
  removeAllCandidates: () => void;
}

const CandidatesList: React.FC<CandidatesProps> = ({
  candidates,
  loadingCandidates,
  downloadingCVs,
  editSelection,
  selectedCandidates,
  clearSelection,
  onClickDownloadCvs,
  cvsCount,
  pagination,
  onChangePaginationOffset,
  selectAllCandidates,
  removeAllCandidates,
  allCandidatesSelected,
  refetch,
  setOrder,
  currentOrder,
}) => {
  const footer = selectedCandidates ? (
    <CandidateGridFooter
      downloadingCVs={downloadingCVs}
      refetch={refetch}
      clearSelection={clearSelection}
      selectedCandidates={selectedCandidates}
      onClickDownloadCvs={onClickDownloadCvs}
      cvsCount={cvsCount}
      admin
    />
  ) : undefined;

  const hasSelectedCandidates = Boolean(selectedCandidates.length) || allCandidatesSelected;

  const headerComponent = (
    <Header title={'Candidates'}>
      <ShowPhotosSwitch />
    </Header>
  );
  return (
    <Page extraSidebar={<FilterSideBar />} headerComponent={headerComponent} footer={footer}>
      {loadingCandidates ? (
        <SpinnerIsoType text="Loading" />
      ) : (
        <>
          <SubHeader>
            <StyledCount>
              {loadingCandidates
                ? ''
                : `Showing ${candidates.length} candidate${candidates.length === 1 ? '' : 's'} of ${pagination.count}`}
            </StyledCount>
            <CandidateListOrderBy currentOrder={currentOrder} setOrder={setOrder} />
            <Button onClick={() => (hasSelectedCandidates ? removeAllCandidates() : selectAllCandidates())}>
              {hasSelectedCandidates ? 'Remove all' : 'Select all'}
            </Button>
          </SubHeader>
          <CandidatesGrid
            editSelection={editSelection}
            refetch={refetch}
            candidates={candidates}
            selectedCandidates={selectedCandidates}
          />
          <StyledPaginationWrapper>
            <Pagination pagination={pagination} onPageChange={onChangePaginationOffset} />
          </StyledPaginationWrapper>
        </>
      )}
    </Page>
  );
};

export default CandidatesList;
