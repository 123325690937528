import React, { ReactNode, useState } from 'react';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import { colors } from '../../../theme';

const StyledPopper = styled.div`
  z-index: 1;

  width: 32rem;
  padding: 2rem 0;

  background-color: ${colors.white};
  border-radius: 8px;
  border: solid 1px ${colors.gray_light};
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  &[data-popper-placement^='bottom'] > #arrow {
    top: -5px;
  }
`;

const StyledArrow = styled.div`
  &,
  &:before {
    position: absolute;
    width: 0.8rem;
    height: 0.8rem;
    z-index: -1;
  }

  ::before {
    border: solid 1px ${colors.gray_light};
    border-top: none;
    border-right: none;
    background-color: ${colors.white};

    content: '';
    transform: rotate(135deg);
  }
`;

interface ChatHeaderPopperProps {
  show: boolean;
  referenceElement: HTMLElement | null;
  children: ReactNode;
}

const ChatHeaderPopper: React.FC<ChatHeaderPopperProps> = ({ show, referenceElement, children }) => {
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      { name: 'offset', options: { offset: [0, 8] } },
      { name: 'preventOverflow', options: { padding: 10 } },
    ],
  });

  if (!show) return null;
  return (
    <StyledPopper ref={setPopperElement} style={styles.popper} {...attributes.popper}>
      <StyledArrow id="arrow" ref={setArrowElement} style={styles.arrow} {...attributes.arrow} />
      {children}
    </StyledPopper>
  );
};

export default ChatHeaderPopper;
