import JsZip from 'jszip';
import FileSaver from 'file-saver';

export const base64ToBlob = (base64: string, type: string): Blob => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; ++i) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return new Blob([bytes], { type });
};

export interface FileToZip {
  fileName: string;
  blob: Blob;
}

export const downloadAllBlobFilesAsZip = (fileName: string, files: FileToZip[]) => {
  const zip = JsZip();
  files.forEach((file) => {
    zip.file(`${file.fileName}`, file.blob);
  });
  zip.generateAsync({ type: 'blob' }).then((zipFile) => {
    const currentDate = new Date().getTime();
    const fileNameDated = `${fileName} - ${currentDate}.zip`;
    return FileSaver.saveAs(zipFile, fileNameDated);
  });
};

export const downloadFile = (filename: string, hex_content: string) => {
  const a = document.createElement('a');
  a.href = hex_content;
  a.download = filename;
  a.click();
};

export const previewFile = (hex_content: string, type: string) => {
  const blob = base64ToBlob(hex_content.replace('data:application/pdf;base64,', ''), type);
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  a.click();
};
