import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useAuth } from '../../utils/react-auth';

import INSERT_GROUP_MEMBERS from '../../queries/insertGroupMembers.gql';
import REMOVE_GROUP_MEMBERS from '../../queries/removeGroupMembers.gql';
import REMOVE_GROUPS from '../../queries/removeGroups.gql';
import GET_CANDIDATES from '../../queries/getCandidates.gql';
import GET_GROUPS from '../../queries/getGroups.gql';

import { InsertGroupMembers, InsertGroupMembersVariables } from '../../generated/InsertGroupMembers';
import { RemoveGroupMembers, RemoveGroupMembersVariables } from '../../generated/RemoveGroupMembers';
import { RemoveGroups, RemoveGroupsVariables } from '../../generated/RemoveGroups';
import { GetCandidates } from '../../generated/GetCandidates';
import { GetGroups } from '../../generated/GetGroups';

import GroupModalFooterButtons from './GroupModalFooterButtons';
import Group from '../../types/Group';
import CandidateType from '../../types/Candidate';
import Spinner from '../Spinner';

interface GroupModalFooterButtonsControllerProps {
  groupIds: string[] | null;
  candidates: CandidateType[];
  toDeleteGroups?: Group[];
  setModalStatus: (status: boolean) => void;
  handleCancelOnClick: () => void;
  refetch: () => void;
}
const GroupModalFooterButtonsController: React.FC<GroupModalFooterButtonsControllerProps> = ({
  groupIds,
  candidates,
  toDeleteGroups = [],
  setModalStatus,
  handleCancelOnClick,
  refetch,
}) => {
  const { user } = useAuth();

  const [removeGroupsMutation] = useMutation<RemoveGroups, RemoveGroupsVariables>(REMOVE_GROUPS, {
    update: (cache, { data }) => {
      try {
        const removedGroups = data?.delete_recruit_grad_group?.returning;
        if (!removedGroups) return;

        const getGroupData = cache.readQuery<GetGroups>({ query: GET_GROUPS });
        if (!getGroupData) return;

        const removedGroupsIds = removedGroups.map(({ grad_group_id }) => grad_group_id);

        const newGroups = getGroupData.recruit_grad_group.filter(
          ({ grad_group_id }) => !removedGroupsIds.includes(grad_group_id),
        );

        cache.writeQuery({
          query: GET_GROUPS,
          data: {
            recruit_grad_group: newGroups,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
  });

  const [removeFromGroupMutation] = useMutation<RemoveGroupMembers, RemoveGroupMembersVariables>(REMOVE_GROUP_MEMBERS);

  const [addToGroupMutation] = useMutation<InsertGroupMembers, InsertGroupMembersVariables>(INSERT_GROUP_MEMBERS);

  const removeUsersFromGroups = useCallback(async () => {
    if (!user || candidates.length < 1) return;

    const candidateGroupIds = candidates
      .filter(({ groups }) => groups && groups.length > 0)
      .map(({ userId }) => userId) as string[];

    if (candidateGroupIds && candidateGroupIds.length > 0) {
      const removeVariables = {
        recruiterId: user.sub,
        candidatesIds: candidateGroupIds,
      };

      await removeFromGroupMutation({ variables: removeVariables });
    }
    refetch();
  }, [candidates, removeFromGroupMutation, user, refetch]);

  const removeGroups = useCallback(async () => {
    const toRemoveIds = toDeleteGroups?.map(({ id }) => id);
    if (toRemoveIds && toRemoveIds.length) {
      await removeGroupsMutation({ variables: { groupsIds: toRemoveIds } });
    }
    refetch();
  }, [removeGroupsMutation, toDeleteGroups, refetch]);

  const addCandidatesToGroup = useCallback(async () => {
    if (!user || !candidates.length) return;
    const toDeleteGroupIds = toDeleteGroups?.map(({ id }) => id);

    const validGroupIds = groupIds?.filter((id) => !toDeleteGroupIds.includes(id)) || [];

    if (groupIds && validGroupIds) {
      const groupMembers = candidates
        .map(({ userId }) => ({
          recruiter_user_id: user.sub,
          student_user_id: userId,
        }))
        .flatMap((elem) => validGroupIds.map((groupId) => ({ ...elem, grad_group_id: groupId })));

      await addToGroupMutation({
        variables: {
          groupMembers,
        },
      });
    }
    refetch();
  }, [candidates, groupIds, addToGroupMutation, toDeleteGroups, user, refetch]);

  const handleOnSave = useCallback(async () => {
    await removeUsersFromGroups();
    await removeGroups();
    await addCandidatesToGroup();
    refetch();
    setModalStatus(false);
  }, [setModalStatus, removeUsersFromGroups, removeGroups, addCandidatesToGroup, refetch]);

  if (!user) return <Spinner />;

  return (
    <GroupModalFooterButtons toDeleteGroups={toDeleteGroups} onSave={handleOnSave} onCancel={handleCancelOnClick} />
  );
};

export default GroupModalFooterButtonsController;
