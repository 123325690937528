import styled from 'styled-components';

const ViewTitle = styled.h1<{ big?: boolean }>`
  font-size: ${({ big }) => (big ? '3rem' : '2.4rem')};
  font-family: 'RobotoBold';

  line-height: 2.8rem;
`;

export default ViewTitle;
