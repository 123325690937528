import React from 'react';

import FullTimeInterestTag from 'elitegrad-common/src/components/FullTimeInterestTag';
import InternshipInterestTag from 'elitegrad-common/src/components/InternshipInterestTag';
import Candidate, { isFullTime, isInternship } from 'elitegrad-common/src/types/Candidate';
import Degree, { getMajorNameFilter } from 'elitegrad-common/src/types/Degree';
import { formatYear } from 'elitegrad-common/src/utils/dateUtils';
import Avatar from 'elitegrad-common/src/components/candidate/Avatar';

import {
  StyledHeader,
  StyledCandidateDetails,
  StyledDegreeYear,
  StyledTags,
  StyledTopBar,
  CurrentDegreeName,
  CurrentDegreeUniversity,
} from './CandidateCardHeader.styled';
import { Link } from 'react-router-dom';
import { Paths } from 'routes';

export interface CandidateCardHeaderProps {
  candidate: Candidate;
  currentDegree: Degree;
}

const CandidateCardHeader: React.FC<CandidateCardHeaderProps> = ({ candidate, currentDegree }) => {
  return (
    <StyledHeader>
      <StyledTopBar>
        <StyledTags>
          {(isFullTime(candidate) && <FullTimeInterestTag />) || (isInternship(candidate) && <InternshipInterestTag />)}
        </StyledTags>
      </StyledTopBar>
      <Link to={`${Paths.candidates}/${candidate.userId}`}>
        <Avatar photo={candidate.photo} isSelected />
      </Link>
      <StyledCandidateDetails>
        <Link to={`${Paths.candidates}/${candidate.userId}`}>{candidate.fullname}</Link>
        {candidate.title ? `, ${candidate.title}` : ''}
      </StyledCandidateDetails>
      <CurrentDegreeName>
        {currentDegree.degreeName + ' in ' + getMajorNameFilter(currentDegree.concentrations)}
      </CurrentDegreeName>
      <StyledDegreeYear>
        <CurrentDegreeUniversity>
          {currentDegree.university ? `${currentDegree.university?.name}, ` : ''}
        </CurrentDegreeUniversity>
        {currentDegree.completeDate && formatYear(currentDegree.completeDate)}
      </StyledDegreeYear>
    </StyledHeader>
  );
};

export default CandidateCardHeader;
