import Degree from './Degree';
import Group from './Group';
import JobType from './JobType';
import CV from './CV';
import Test from './Test';
import WorkExperience from './WorkExperience';
import Award from './Award';

interface Candidate {
  firstname: string;
  middlename: string | null;
  lastname: string;
  fullname: string;
  comm_preference: string;
  email: string | null;
  hometown: string | null;
  yearsOfRelevantWork: string | null;
  mobile: string | null;
  degrees: Degree[];
  tests: Test[];
  photo: string | null;
  resume?: CV | null;
  title: string | null;
  needSponsor: boolean | null;
  visaExpiration: Date | null;
  visaStatus: string | null;
  visaType: string | null;
  authorizedToWork: boolean | null;
  studentAthlete: boolean;
  groups: Group[] | null;
  funFacts: string[];
  interestFirmTypes: string[];
  employerPreferences: string[];
  interestJobTypes: string[];
  interestRegions: string[];
  interestRoles: string[];
  spokenLanguages: string[];
  computerLanguages: string[];
  workExperiences: WorkExperience[];
  userId: string;
  blocked?: boolean;
  hasCV?: boolean;
  achievements: string[];
  volunteers: string[];
  blockedReason?: string | null;
  awards: Award[];
  legal_residence_country?: string | null;
}

const isInternship = (candidate: Candidate) => {
  return candidate.interestJobTypes.includes(JobType.INTERNSHIP);
};

const isFullTime = (candidate: Candidate) => {
  return candidate.interestJobTypes.includes(JobType.FULL_TIME);
};

export { isInternship, isFullTime };

export default Candidate;
