import React, { useEffect, useState } from 'react';

import { INITIAL_OFFSET, PAGE_SIZE } from 'elitegrad-common/src/components/pagination/PaginationState';
import { asCandidatesWithProfileImages } from 'elitegrad-common/src/transformers/candidates';
import { usePhoto } from 'elitegrad-common/src/utils/usePhoto';
import CandidateType from 'elitegrad-common/src/types/Candidate';
import { GetCandidatesVariables } from 'elitegrad-common/src/generated/GetCandidates';
import { generateWhereQuery } from 'elitegrad-common/src/queries/queryUtils';
import { useFilterQueries, usePagination } from 'elitegrad-common/src/utils/useFilter';

import { useCountCVByCandidates, useDownloadCandidateByUsersId, useFetchCandidates } from './CandidatesList.hook';
import CandidatesList from './CandidatesList';
import { removeUsersWithoutResumeFromQuery } from './CandidatesList.utils';
import {
  ORDER_BY_OPTIONS,
  DES,
  DirectionsTypes,
} from 'elitegrad-common/src/components/candidates/CandidateListOrderBy';

const CandidatesListController: React.FC = () => {
  const [selectedCandidates, setSelectedCandidates] = useState<CandidateType[]>([]);
  const [areAllSelected, setAreAllSelected] = useState<boolean>(false);
  const [downloadingCVs, setDownloadingCVs] = useState<boolean>(false);
  const [orderOption, setOrderOption] = useState<{ orderBy: string; direction: DirectionsTypes }>({
    orderBy: ORDER_BY_OPTIONS.creation_timestamp.id,
    direction: DES,
  });
  const { setOffSet, getOffset } = usePagination();
  const { getFilterQueries } = useFilterQueries();
  const preDynamicQuery = Object.keys(getFilterQueries()).length > 0 ? generateWhereQuery(getFilterQueries()) : null;
  const dynamicQuery = removeUsersWithoutResumeFromQuery(preDynamicQuery);
  const order = ORDER_BY_OPTIONS[orderOption.orderBy][orderOption.direction];

  const getCandidatesVariables: GetCandidatesVariables = {
    ...(dynamicQuery && { dynamicQuery }),
    order_by: order,
    limit: PAGE_SIZE,
    offset: getOffset(),
    admin: true,
  };

  const [fetchCVsCountByUsersId, { data: dataCountResumes }] = useCountCVByCandidates();
  const fetchCVsByUsersId = useDownloadCandidateByUsersId(selectedCandidates, setDownloadingCVs);

  const handleOnDownloadCvsClick = () => {
    setDownloadingCVs(true);
    const userIds = selectedCandidates.map((candidate) => candidate.userId);
    fetchCVsByUsersId({
      variables: {
        userIds,
      },
    });
  };
  const { showPhoto } = usePhoto();
  const useFetchCandidatesOnComplete = () => {
    if (areAllSelected) setSelectedCandidates(allCandidates);

    const userIds = selectedCandidates.map((cand) => cand.userId);
    fetchCVsCountByUsersId({
      variables: {
        userIds,
      },
    });
  };

  const [{ data, loading, refetch }, imagesData] = useFetchCandidates(
    getCandidatesVariables,
    showPhoto,
    useFetchCandidatesOnComplete,
  );

  const [{ data: dataAllCandidates }, allCandidatesImage] = useFetchCandidates(
    { admin: true, order_by: order, ...(dynamicQuery && { dynamicQuery }) },
    showPhoto,
    useFetchCandidatesOnComplete,
  );

  const candidates = asCandidatesWithProfileImages(
    data?.grad_profile || [],
    showPhoto ? imagesData?.grad_profile || [] : [],
  );

  const allCandidates = asCandidatesWithProfileImages(
    dataAllCandidates?.grad_profile || [],
    showPhoto ? allCandidatesImage?.grad_profile || [] : [],
  );

  const candidateQueryCount = data?.grad_profile_aggregate?.aggregate?.count || 0;

  const editSelection = (candidate: CandidateType, add: boolean) => {
    setAreAllSelected(false);
    if (add) {
      setSelectedCandidates((e: CandidateType[]) => [...e, candidate]);
    } else {
      setSelectedCandidates((e: CandidateType[]) => e.filter(({ userId }) => userId !== candidate.userId));
    }
  };

  const clearSelection = () => removeAllCandidates();

  const cvsCount = dataCountResumes?.grad_upload_doc_aggregate?.aggregate?.count;

  useEffect(() => {
    const userIds = selectedCandidates.map((cand) => cand.userId);
    fetchCVsCountByUsersId({
      variables: {
        userIds,
      },
    });
  }, [selectedCandidates, fetchCVsCountByUsersId, orderOption.direction, orderOption.orderBy]);

  const selectAllCandidates = () => {
    setAreAllSelected(true);
    setSelectedCandidates(allCandidates);
  };

  const removeAllCandidates = () => {
    setAreAllSelected(false);
    setSelectedCandidates([]);
  };

  return (
    <CandidatesList
      setOrder={setOrderOption}
      refetch={refetch}
      currentOrder={{ direction: orderOption.direction, orderBy: orderOption.orderBy }}
      pagination={{
        count: candidateQueryCount,
        limit: getCandidatesVariables.limit || PAGE_SIZE,
        offset: getCandidatesVariables.offset || INITIAL_OFFSET,
      }}
      onClickDownloadCvs={handleOnDownloadCvsClick}
      onChangePaginationOffset={setOffSet}
      candidates={candidates}
      cvsCount={cvsCount ? cvsCount : 0}
      loadingCandidates={loading}
      downloadingCVs={downloadingCVs}
      editSelection={editSelection}
      clearSelection={clearSelection}
      selectedCandidates={selectedCandidates}
      selectAllCandidates={selectAllCandidates}
      removeAllCandidates={removeAllCandidates}
      allCandidatesSelected={areAllSelected}
    />
  );
};

export default CandidatesListController;
