import React from 'react';
import {
  Card,
  FullName,
  Company,
  ContactInfo,
  Separator,
  CardHeader,
  InformationRow,
  InfoIcon,
  SendIcon,
} from './RecruiterListItem.styled';
import RecruiterImpersonationDialogController from './RecruiterImpersonationDialog';

export interface RecruiterListItemProps {
  fullName: string;
  email: string;
  company: string;
  twilio_phone: string;
  id: string;
}

const RecruiterListItem: React.FC<RecruiterListItemProps> = ({ fullName, email, company, twilio_phone, id }) => {
  return (
    <Card>
      <CardHeader>
        <Company>{company}</Company>
        <FullName>{fullName}</FullName>
      </CardHeader>
      <Separator />
      <InformationRow>
        {twilio_phone && (
          <>
            <InfoIcon />
            <ContactInfo> {twilio_phone}</ContactInfo>
          </>
        )}
      </InformationRow>
      <InformationRow>
        {email && (
          <>
            <SendIcon />
            <ContactInfo>
              <a href={`mailto:${email}`}>{email}</a>
            </ContactInfo>
          </>
        )}
      </InformationRow>
      <RecruiterImpersonationDialogController fullname={fullName} userId={id} />
    </Card>
  );
};

export default RecruiterListItem;
