import React from 'react';
import {
  CandidatePreviousDegree,
  CandidateWorkExperience,
  CandidateNoTests,
  CandidateNoDegrees,
  CandidateNoWorkExperiences,
} from '../../../components/candidate';

import { getDegreesSortedByCompleteDate } from '../../../types/Degree';
import CandidateType from '../../../types/Candidate';
import CandidateTestScoreSat from '../../../components/candidate/CandidateTestScoreSat';
import CandidateTestScoreOthers from '../../../components/candidate/CandidateTestScoreOthers';
import { getTestsSortedByPriority } from '../../../types/Test';
import { formatMonthYear } from '../../../utils/dateUtils';
import { AcademicHonorIcon } from '../../../assets/icons';

import Avatar from '../../../components/candidate/Avatar';
import { getMajorNameFilter, getAcademicHonor } from '../../../types/Degree';
import CollapsibleSection from '../../../components/candidates/CollapsibleCardSection';
import { hasProfessionalDesignation, getProfessionalDesignations } from '../../../transformers/awards';

import StudentInformation from './StudentInformation';
import StudentInformationLink from './StudentInformation/StudentInformationLink';
import StudentInformationExtensible from './StudentInformation/StudentInformationExtensible';

import {
  Container,
  AvatarContainer,
  HeaderContainer,
  Name,
  DegreeDate,
  DegreeName,
  GPA,
  GPAoutOf,
  University,
  AcademicHonor,
  AcademicHonorIconContainer,
  ScoresColumn,
  SectionTitle,
  StudentInformationColumn,
  StudentInformationSection,
  InterestSection,
  ExperiencesColumn,
  ExperienceSection,
  WorkAuthorization,
  ExperienceTitle,
  EmptySectionPlaceholder,
  Spacing,
} from './CandidateDetail.styled';
import CandidateCardInterests from '../../../components/candidate/CandidateCardInterests';

interface CandidateProps {
  candidate: CandidateType;
  isAdmin?: boolean;
}

const CandidateDetail: React.FC<CandidateProps> = ({ candidate }) => {
  const [currentDegree, ...previousDegrees] = getDegreesSortedByCompleteDate(candidate.degrees);
  const [satAct, ...otherTests] = getTestsSortedByPriority(candidate.tests);
  return (
    <Container>
      <AvatarContainer>
        <Avatar photo={candidate.photo} isSelected />
      </AvatarContainer>

      <HeaderContainer>
        <Name>{`${candidate.fullname} ${candidate.title ? `, ${candidate.title}` : ''}`}</Name>
        {!currentDegree.gpaNotApplicable && (
          <GPA>
            {currentDegree.gpa}
            <GPAoutOf>{`/${currentDegree.gpaOutOf} GPA`}</GPAoutOf>
          </GPA>
        )}
        <University>{currentDegree.university?.name || ''}</University>
        {currentDegree.completeDate && <DegreeDate>{formatMonthYear(currentDegree.completeDate)}</DegreeDate>}
        <DegreeName>
          {`${currentDegree.degreeName} in ${getMajorNameFilter(currentDegree.concentrations)}`}{' '}
          {getAcademicHonor(currentDegree.awards) && (
            <AcademicHonor>
              <AcademicHonorIconContainer src={AcademicHonorIcon} />
              {getAcademicHonor(currentDegree.awards)}
            </AcademicHonor>
          )}
          {candidate.authorizedToWork && <WorkAuthorization>AUTHORIZED TO WORK</WorkAuthorization>}
        </DegreeName>
      </HeaderContainer>

      <ScoresColumn>
        {satAct && <CandidateTestScoreSat satTest={satAct} big />}
        {otherTests.length > 0 && <CandidateTestScoreOthers tests={otherTests} big />}
        {candidate.tests.length === 0 && (
          <EmptySectionPlaceholder>
            <CandidateNoTests />
          </EmptySectionPlaceholder>
        )}

        {(Boolean(candidate.employerPreferences.length) ||
          Boolean(candidate.funFacts.length) ||
          Boolean(candidate.spokenLanguages.length) ||
          candidate.studentAthlete ||
          Boolean(candidate.volunteers.length)) && (
          <>
            <SectionTitle>Student’s Information</SectionTitle>
            <StudentInformationSection>
              {Boolean(candidate.mobile) && <StudentInformationLink title="Phone" values={[candidate.mobile || '']} />}
              {Boolean(candidate.email) && (
                <StudentInformationLink email title="Email" values={[candidate.email || '']} />
              )}
              {Boolean(candidate.comm_preference) && (
                <StudentInformation title="Communication Preference" values={[candidate.comm_preference || '']} />
              )}
              {Boolean(candidate.employerPreferences.length) && (
                <StudentInformation title="In an Employer I look for" values={candidate.employerPreferences} />
              )}
              {Boolean(candidate.funFacts.length) && (
                <StudentInformation title="What I want an Employer to know about me" values={candidate.funFacts} />
              )}
              {hasProfessionalDesignation(candidate.awards) && (
                <StudentInformation
                  title="Professional designations"
                  values={getProfessionalDesignations(candidate.awards)}
                />
              )}
              {Boolean(candidate.spokenLanguages.length) && (
                <StudentInformation title="Other Languages" values={candidate.spokenLanguages} />
              )}
              {candidate.studentAthlete && <StudentInformation title="Student athlete" values={['Yes']} />}
              {Boolean(candidate.achievements.length) && (
                <StudentInformationExtensible title="Personal achievements" values={candidate.achievements} />
              )}
              {Boolean(candidate.volunteers.length) && (
                <StudentInformationExtensible title="Extracurricular activities" values={candidate.volunteers} />
              )}
            </StudentInformationSection>
          </>
        )}
      </ScoresColumn>

      <StudentInformationColumn>
        <InterestSection>
          {Boolean(candidate.interestFirmTypes.length) && (
            <CandidateCardInterests title="At these types of Firms" interests={candidate.interestFirmTypes} />
          )}
          {Boolean(candidate.interestJobTypes.length) && (
            <CandidateCardInterests title="I would like this type of position" interests={candidate.interestJobTypes} />
          )}
        </InterestSection>

        <SectionTitle noLeftMargin>In this type of role</SectionTitle>
        <StudentInformationSection noMargin>
          {Boolean(candidate.interestRoles.length) && (
            <StudentInformation title="Desire role" values={candidate.interestRoles} />
          )}
        </StudentInformationSection>

        <SectionTitle noLeftMargin>Location</SectionTitle>
        <StudentInformationSection noMargin>
          <InterestSection>
            {Boolean(candidate.interestRegions.length) && (
              <CandidateCardInterests
                title="I would like to work in these regions"
                interests={candidate.interestRegions}
              />
            )}
          </InterestSection>

          {Boolean(candidate.hometown) && <StudentInformation title="Hometown" values={[candidate.hometown || '']} />}
        </StudentInformationSection>
        <Spacing />
        <InterestSection>
          {Boolean(candidate.computerLanguages.length) && (
            <CandidateCardInterests title="Technical languages" interests={candidate.computerLanguages} />
          )}
        </InterestSection>
      </StudentInformationColumn>

      <ExperiencesColumn>
        <ExperienceSection>
          {Boolean(candidate.workExperiences.length) ? (
            <CollapsibleSection title={<ExperienceTitle>Work Experience</ExperienceTitle>} defaultCollapsed={false}>
              {candidate.workExperiences.map((workExperience) => (
                <CandidateWorkExperience key={workExperience.id} workExperience={workExperience} big />
              ))}
            </CollapsibleSection>
          ) : (
            <>
              <ExperienceTitle isEmptySection>Work Experience</ExperienceTitle>
              <CandidateNoWorkExperiences />
            </>
          )}
        </ExperienceSection>
        <ExperienceSection>
          {Boolean(previousDegrees.length) ? (
            <CollapsibleSection title={<ExperienceTitle>Previous Degree</ExperienceTitle>} defaultCollapsed={false}>
              {previousDegrees.map((degree) => (
                <CandidatePreviousDegree key={degree.id} degree={degree} big />
              ))}
            </CollapsibleSection>
          ) : (
            <>
              <ExperienceTitle isEmptySection>Previous Degree</ExperienceTitle>
              <CandidateNoDegrees />
            </>
          )}
        </ExperienceSection>
      </ExperiencesColumn>
    </Container>
  );
};

export default CandidateDetail;
