import React from 'react';
import Tag from '../MarkupTag';
import { Container } from './MarkupTagList.styled';

interface MarkupTagListProps {
  markupList: { name: string; value: string }[];
  onInsertMarkup: (markup: string) => void;
}

const MarkupTagList: React.FC<MarkupTagListProps> = ({ markupList, onInsertMarkup }) => {
  return (
    <Container>
      {markupList.map((markup) => (
        <Tag key={markup.name} name={markup.name} value={markup.value} onClick={onInsertMarkup} />
      ))}
    </Container>
  );
};

export default MarkupTagList;
