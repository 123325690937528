import React, { useState } from 'react';
import styled from 'styled-components';

import Recipient from '../../../types/Recipient';
import RecipientSelectorController from '../recipient/RecipientSelectorController';
import MessageSenderController from '../message/MessageSenderController';

const StyledNewChat = styled.div`
  display: grid;
  grid-template-rows: auto 13rem;
  height: 95vh;
  overflow-y: scroll;

  border: 1px solid transparent;
`;

const NewChat: React.FC<{ recipients?: Recipient[]; setModalOpen: (status: boolean) => void }> = ({
  recipients = [],
  setModalOpen,
}) => {
  const [selectedRecipients, setSelectedRecipients] = useState(recipients);
  const [selectedGroups, setSelectedGroups] = useState([]);

  return (
    <StyledNewChat>
      <RecipientSelectorController
        setSelectedRecipients={setSelectedRecipients}
        selectedRecipients={selectedRecipients}
        selectedGroups={selectedGroups}
        setSelectedGroups={setSelectedGroups}
      />
      <MessageSenderController
        popperId="new-chat-template-popper"
        candidates={
          selectedRecipients.map(({ id, firstname }) => {
            return {
              id,
              firstname,
            };
          }) || []
        }
        setModalOpen={setModalOpen}
        hasMarkup
      />
    </StyledNewChat>
  );
};

export default NewChat;
