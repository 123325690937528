import React from 'react';
import styled from 'styled-components';
import colors from '../../theme/colors';
import { useFilterClear } from '../../utils/useFilter';
import { usePopperCloser } from '../../utils/usePopper';

const StyledClearFilters = styled.div`
  margin: auto 0 auto auto;
  padding: 0.3rem 1.5rem;

  font-family: 'RobotoBold';
  font-size: 1.2rem;
  color: ${colors.white};

  background-color: ${colors.red};
  border-radius: 10rem;

  cursor: pointer;
`;

const FilterClear: React.FC = () => {
  const { clearFilters } = useFilterClear();
  const { closePoppers } = usePopperCloser();

  const handleClick = () => {
    clearFilters();
    closePoppers();
  };
  return <StyledClearFilters onClick={handleClick}>Clear</StyledClearFilters>;
};

export default FilterClear;
