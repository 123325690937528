import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ChevronDown } from '../../../assets/vectors';
import Group from '../../../types/Group';
import { useGetCandidatesByGroupLazyQuery } from '../../../generated/graphql';
import { colors } from '../../../theme';
import RecipientSelectorList from './RecipientSelectorList';
import SpinnerIsoType from '../../../components/SpinnerIsoType';
import { asRecipient } from '../../../transformers/recipient';
import Recipient from '../../../types/Recipient';
import { RecipientParts } from '../../../generated/RecipientParts';
import CheckBox from './Checkbox';

const GroupName = styled.div`
  font-family: 'Roboto';
  font-size: 1.6rem;
  line-height: 130%;
`;

const ChevronDownStyled = styled(ChevronDown)<{ open: boolean }>`
  max-height: 2.4rem;
  max-width: 2.4rem;

  min-height: 2.4rem;
  min-width: 2.4rem;
  transform: ${({ open }) => (open ? 'rotate(180deg)' : '')};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const EmptyText = styled.div`
  font-family: 'Roboto';
  font-size: 1.6rem;
  color: ${colors.beta_lighten_60};
  margin-left: 5rem;
`;

const StyledGroupSelectorListItem = styled.div<{ open: boolean }>`
  display: grid;
  grid-template-columns: 2rem 1fr 2.5rem;
  grid-template-rows: ${({ open }) => (open ? '3rem 1fr' : '3rem')};
  padding: 1rem 0;
  margin: 0 3rem;
  cursor: pointer;
  align-items: center;
`;

interface RecipientSelectorListItemProps {
  group: Group;
  removeRecipient: (recipient: Recipient) => void;
  addRecipient: (recipient: Recipient) => void;
  selectedRecipients: Recipient[];
  checked: boolean;
  addGroup: (group: Group, recipients: Recipient[]) => void;
  removeGroup: (group: Group, recipients: Recipient[]) => void;
}

const RecipientSelectorListItem: React.FC<RecipientSelectorListItemProps> = ({
  group,
  selectedRecipients,
  addRecipient,
  removeRecipient,
  checked,
  addGroup,
  removeGroup,
}) => {
  const [open, setOpen] = useState(false);
  const [getCandidatesFromGroup, { data, loading }] = useGetCandidatesByGroupLazyQuery({
    fetchPolicy: 'network-only',
  });

  const candidates =
    data?.recruit_grad_group?.[0]?.grad_group_members.map((candidate) =>
      asRecipient(candidate?.profile as RecipientParts),
    ) || [];

  useEffect(() => {
    if (data) {
      const candidates =
        data?.recruit_grad_group?.[0]?.grad_group_members.map((candidate) =>
          asRecipient(candidate?.profile as RecipientParts),
        ) || [];
      checked && addGroup(group, candidates);
    }
  }, [data]);

  const handleArrowClick = () => {
    if (!open) {
      getCandidatesFromGroup({ variables: { groupId: group?.id } });
    }
    setOpen(!open);
  };

  const handleCheckboxClick = async () => {
    if (!candidates) {
      getCandidatesFromGroup({
        variables: { groupId: group?.id },
      });
    } else {
      checked ? removeGroup(group, candidates) : addGroup(group, candidates);
    }
  };

  return (
    <Container>
      <StyledGroupSelectorListItem open={open}>
        <CheckBox onChange={handleCheckboxClick} checked={checked} />
        <GroupName onClick={handleArrowClick}>{group?.name}</GroupName>
        <ChevronDownStyled onClick={handleArrowClick} open={open} />
      </StyledGroupSelectorListItem>
      {open && (
        <>
          {loading ? (
            <SpinnerIsoType text={'Loading candidates'} />
          ) : (
            <>
              {candidates && candidates?.length > 0 ? (
                <RecipientSelectorList
                  addRecipient={addRecipient}
                  removeRecipient={removeRecipient}
                  recipients={candidates as Recipient[]}
                  loading={false}
                  embebed
                  selectedRecipients={selectedRecipients}
                />
              ) : (
                <EmptyText>This group is empty</EmptyText>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default RecipientSelectorListItem;
