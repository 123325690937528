import React, { useEffect } from 'react';
import { WhereQueryInterface } from '../../../queries/queryUtils';
import { useFilter } from '../../../utils/useFilter';
import BooleanFilterItem from '../BooleanFilterItem';

export const generateQuery = (enabled: boolean): WhereQueryInterface => {
  const funFactsFilter: WhereQueryInterface = {
    enabled: enabled,
    operator: '_and',
    whereQuery: { fun_facts: { description: { _eq: 'First-generation college student' } } },
  };
  return funFactsFilter;
};

const FunFactsFilter = ({ name }: { name: string }) => {
  const { storeFilterStatus, filterValue } = useFilter(name);

  const setFilterValue = (enabled: boolean) => storeFilterStatus(generateQuery(enabled), enabled);

  useEffect(() => setFilterValue(!!filterValue), []) // eslint-disable-line

  return (
    <BooleanFilterItem
      on={!!filterValue}
      filterTitle={'First Generation College Student'}
      setFilterValue={setFilterValue}
      filterActive={!!filterValue}
    />
  );
};

export default FunFactsFilter;
