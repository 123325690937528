import { ApolloClient, HttpLink, split } from '@apollo/client';
import { defaultDataIdFromObject, InMemoryCache, NormalizedCacheObject } from '@apollo/client/cache';
import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { addImpersonToHeaders, injectImpersonHeaders } from './impersonate';

const JWT_EXPIRED_ERROR_PATTERN = /^.*: JWTExpired$/;

interface ImpersonationProps {
  userID: string | null;
  role: string | null;
}

const createClient = (
  authToken: string | null,
  onAuthError: () => void,
  role: string,
  impersonationProps: ImpersonationProps,
  userID?: string,
): ApolloClient<NormalizedCacheObject> => {
  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URI,
  });

  const authLink = setContext((_, { headers }) => {
    if (
      impersonationProps.userID &&
      impersonationProps.role &&
      !process.env.REACT_APP_ON_ADMIN_SITE &&
      role === 'support-admin'
    ) {
      return addImpersonToHeaders(headers, impersonationProps.userID, impersonationProps.role, authToken);
    }

    return {
      headers: {
        ...headers,
        authorization: authToken ? `Bearer ${authToken}` : '',
        'x-hasura-role': role,
        'X-Hasura-User-Id': userID,
      },
    };
  });

  const socketLink = new WebSocketLink({
    uri: process.env.REACT_APP_GRAPHQL_URI_WS || '',
    options: {
      reconnect: true,
      connectionParams: {
        headers: {
          Authorization: `Bearer ${authToken}`,
          ...injectImpersonHeaders(impersonationProps?.userID, impersonationProps?.role),
        },
      },
    },
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`); // eslint-disable-line no-console
        if (message.match(JWT_EXPIRED_ERROR_PATTERN)) onAuthError();
      });
    }
    if (networkError) console.error(`[Network error]: ${networkError}`); // eslint-disable-line no-console
  });

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    errorLink.concat(authLink.concat(socketLink)),
    errorLink.concat(authLink.concat(httpLink)),
  );

  return new ApolloClient({
    connectToDevTools: process.env.NODE_ENV === 'development',
    cache: new InMemoryCache({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dataIdFromObject: (object: any) => {
        switch (object.__typename) {
          case 'grad_award':
            return object.award_id;
          case 'grad_concentration':
            return object.concentration_id;
          case 'grad_degree':
            return object.degree_id;
          case 'grad_interest_firm':
            return object.interest_firm_id;
          case 'grad_interest_job_type':
            return object.interest_job_type_id;
          case 'grad_interest_region':
            return object.interest_region_id;
          case 'grad_interest_region_selection':
            return object.interest_region_selection_id;
          case 'grad_profile':
            return object.user_id;
          case 'grad_raise_hand':
            return object.raise__hand_id;
          case 'grad_test_score':
            return object.test_score_id;
          case 'grad_test_score_detail':
            return object.test_score_detail_id;
          case 'grad_university_selection':
            return object.university_selection_id;
          case 'grad_university_stat':
            return null;
          case 'grad_work_experience':
            return object.work_experience_id;
          case 'recruit_job_posting':
            return object.job_posting_id;
          case 'recruit_job_requirement':
            return object.job_requirement_id;
          case 'grad_major_selection':
            return object.major_selection_id;
          case 'grad_fun_fact':
            return object.fun_fact_id;
          case 'recruit_sms_correspondent':
            return object.sms_correspondent_id;
          case 'recruit_job_requirement_value':
            return object.value_id;
          case 'grad_degree_selection':
            return object.degree_selection_id;
          case 'grad_interest_firm_selection':
            return object.interest_firm_selection_id;
          case 'grad_interest_job_type_selection':
            return object.interest_job_type_selection_id;
          case 'grad_achievement':
            return object.achievement_id;
          case 'grad_volunteer':
            return object.volunteer_id;
          case 'grad_computer_language_selection':
            return object.computer_language;
          case 'recruit_grad_group':
            return object.grad_group_id;
          case 'recruit_grad_group_member':
            return null;
          case 'recruit_sms_message':
            return object.sms_message_id;
          case 'recruit_sms_template':
            return object.sms_template_id;
          case 'recruit_sms_message_mutation_response':
            return object.sms_message_id;
          case 'grad_degree_fill_in_university':
            return object.degree_id + object.user_id;
          case 'grad_interest_role_selection':
            return object.interest_role_selection_id;
          case 'grad_interest_role':
            return object.interest_role_id;
          case 'recruit_account':
            return object.account_id;
          case 'recruit_company':
            return object.company_id;
          case 'recruit_company_subindustry':
            return object.company_subindustry_id;
          case 'recruit_recruiter':
            return object.user_id;
          case 'grad_spoken_language':
            return object.spoken_language_id;
          case 'recruit_event':
            return object.event_id;
          case 'grad_employer_preference':
            return null;
          case 'recruit_company_subindustry_mutation_response':
            return null;
          case 'recruit_job_requirement_mutation_response':
            return null;
          case 'recruit_job_posting_aggregate_fields':
            return null;
          case 'recruit_job_posting_aggregate':
            return null;
          case 'recruit_sms_correspondent_mutation_response':
            return null;
          case 'recruit_sms_message_correspondent':
            return null;
          case 'recruit_grad_group_member_mutation_response':
            return null;
          case 'recruit_job_posting_mutation_response':
            return null;
          case 'grad_profile_aggregate':
            return null;
          case 'recruit_recruiter_aggregate':
            return null;
          case 'recruit_recruiter_aggregate_fields':
            return null;
          case 'grad_profile_aggregate_fields':
            return null;
          case 'grad_upload_doc_aggregate':
            return null;
          case 'grad_upload_doc_aggregate_fields':
            return null;
          case 'recruit_event_aggregate':
            return null;

          default:
            if (process.env.NODE_ENV === 'development') {
              console.warn(`Unhandled __typename = ${object.__typename} for caching`); // eslint-disable-line no-console
              // console.warn(object); // eslint-disable-line no-console
            }
            return defaultDataIdFromObject(object);
        }
      },
    }),
    link,
  });
};

export { createClient };
