import gql from 'graphql-tag';
import { TemplateParts } from './fragments.gql';

export default gql`
  mutation InsertTemplate($recruiterId: String!, $content: String!, $title: String!) {
    insert_recruit_sms_template(objects: { text: $content, title: $title, recruiter_user_id: $recruiterId }) {
      returning {
        ...TemplateParts
      }
    }
  }
  ${TemplateParts}
`;
