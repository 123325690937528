import React from 'react';

import { useAuth } from '../../../src/utils/react-auth';

import { AppointmentIcon } from '../../../src/assets/icons';
import { useFilterGetter } from '../../../src/utils/useFilter';

import ViewTitle from '../ViewTitle';
import FiltersGroup from '../filters/FiltersGroup';
import FilterItem from '../filters/FilterItem';
import {
  ACTFilter,
  GPAFilter,
  GroupFilter,
  GMATFilter,
  UniversityFilter,
  MajorFilter,
  InterestRegionsFilter,
  InterestFirmsFilter,
  ProfessionalDesignationFilter,
  UndergradDegreeDateFilter,
  GraduateDegreeDateFilter,
  AthleteFilter,
  LanguageFilter,
  InterestRolesFilter,
  ComputerLanguageFilter,
  GREFilter,
  RelevantYearsOfWorkFilter,
  GroupedDegreeFilter,
  NameFilter,
  LastNameFilter,
  SATFilterDetailed,
  CombineFilter,
  SponsorshipFilter,
  BlockedFilter,
  HiddenFilter,
} from '../filters/queryFilters';
import FilterClear from '../filters/FilterClear';
import InterestJobTypesToggleFilter from '../filters/queryFilters/InterestJobTypesToggleFilter';

import { Wrapper, ChevronDownRed, TitleSection } from './FilterSideBar.styled';

import { isAdmin } from '../../../src/utils/auth0Roles';
import CommunicationPreferenceFilter from '../filters/queryFilters/CommunicationPreferenceFilter';
import FunFactsFilter from '../filters/queryFilters/FunFactsFilter';

const FilterSideBar: React.FC = () => {
  const { filterActiveWithId } = useFilterGetter();
  const { role } = useAuth();

  return (
    <Wrapper>
      <TitleSection>
        <ViewTitle>Filters</ViewTitle>
        <FilterClear />
      </TitleSection>

      <FiltersGroup name="Quick Filters" noSeparator>
        <CombineFilter name={'CombineFilter'} placeholder="Keyword" />
        <NameFilter name={'NameFilter'} placeholder="First Name" />
        <LastNameFilter name={'LastNameFilter'} placeholder="Last Name" />
        <InterestJobTypesToggleFilter name={'InterestJobTypesToggleFilter'} />
        {isAdmin(role) && (
          <>
            <BlockedFilter name={'BlockedFilter'} />
            <HiddenFilter name={'HiddenFilter'} />
          </>
        )}
        <FunFactsFilter name={'FunFacstFilter'} />
      </FiltersGroup>

      {!isAdmin(role) && (
        <FiltersGroup noRuler>
          <FilterItem text="Groups" icon={ChevronDownRed} filterActive={filterActiveWithId('GroupFilter')}>
            <GroupFilter name={'GroupFilter'} />
          </FilterItem>
        </FiltersGroup>
      )}

      <FiltersGroup name="Interest">
        <FilterItem text="Firm Types" icon={ChevronDownRed} filterActive={filterActiveWithId('InterestFirmsFilter')}>
          <InterestFirmsFilter name={'InterestFirmsFilter'} />
        </FilterItem>
        <FilterItem text="Roles" icon={ChevronDownRed} filterActive={filterActiveWithId('InterestRolesFilter')}>
          <InterestRolesFilter name={'InterestRolesFilter'} />
        </FilterItem>
        <FilterItem text="Regions" icon={ChevronDownRed} filterActive={filterActiveWithId('InterestRegionsFilter')}>
          <InterestRegionsFilter name={'InterestRegionsFilter'} />
        </FilterItem>
      </FiltersGroup>

      <FiltersGroup name="Academic">
        <AthleteFilter name={'AthleteFilter'} />
        <FilterItem text="Schools" icon={ChevronDownRed} filterActive={filterActiveWithId('UniversityFilter')}>
          <UniversityFilter name={'UniversityFilter'} />
        </FilterItem>
        <FilterItem text="Degree" icon={ChevronDownRed} filterActive={filterActiveWithId('DegreeFilter')}>
          <GroupedDegreeFilter name={'DegreeFilter'} />
        </FilterItem>
        <FilterItem
          text="Undergrad Graduation Date"
          icon={AppointmentIcon}
          filterActive={filterActiveWithId('UndergradDegreeDateFilter')}
        >
          <UndergradDegreeDateFilter name={'UndergradDegreeDateFilter'} />
        </FilterItem>
        <FilterItem
          text="Advanced Degree Graduation Date"
          icon={AppointmentIcon}
          filterActive={filterActiveWithId('GraduateDegreeDateFilter')}
        >
          <GraduateDegreeDateFilter name={'GraduateDegreeDateFilter'} />
        </FilterItem>
        <FilterItem text="GPA" icon={ChevronDownRed} filterActive={filterActiveWithId('GPAFilter')}>
          <GPAFilter name={'GPAFilter'} nameNonApplicable={'GPAFilterNotApplicable'} />
        </FilterItem>
        <FilterItem text="Major" icon={ChevronDownRed} filterActive={filterActiveWithId('MajorFilter')}>
          <MajorFilter name={'MajorFilter'} />
        </FilterItem>

        <FilterItem
          text="Professional Designation"
          icon={ChevronDownRed}
          filterActive={filterActiveWithId('ProfessionalDesignationFilter')}
        >
          <ProfessionalDesignationFilter name={'ProfessionalDesignationFilter'} />
        </FilterItem>

        <FilterItem text="Other languages" icon={ChevronDownRed} filterActive={filterActiveWithId('LanguageFilter')}>
          <LanguageFilter name={'LanguageFilter'} />
        </FilterItem>
        <FilterItem
          text="Technical Languages"
          icon={ChevronDownRed}
          filterActive={filterActiveWithId('ComputerLanguageFilter')}
        >
          <ComputerLanguageFilter name={'ComputerLanguageFilter'} />
        </FilterItem>
      </FiltersGroup>

      <FiltersGroup name="Test">
        <FilterItem text="SAT" icon={ChevronDownRed} filterActive={filterActiveWithId('SATFilterDetailed')}>
          <SATFilterDetailed name={'SATFilterDetailed'} />
        </FilterItem>
        <FilterItem text="ACT" icon={ChevronDownRed} filterActive={filterActiveWithId('ACTFilter')}>
          <ACTFilter name={'ACTFilter'} nameNonApplicable={'ACTFilterNotApplicable'} />
        </FilterItem>
        <FilterItem text="GMAT" icon={ChevronDownRed} filterActive={filterActiveWithId('GMATFilter')}>
          <GMATFilter name={'GMATFilter'} />
        </FilterItem>
        <FilterItem text="GRE" icon={ChevronDownRed} filterActive={filterActiveWithId('GREFilter')}>
          <GREFilter name={'GREFilter'} nameNonApplicable={'GREFilterNotApplicable'} />
        </FilterItem>
      </FiltersGroup>

      <FiltersGroup name="Work Experience Prior internship">
        <FilterItem
          text="Years of experience"
          icon={ChevronDownRed}
          filterActive={filterActiveWithId('RelevantYearsOfWorkFilter')}
        >
          <RelevantYearsOfWorkFilter name={'RelevantYearsOfWorkFilter'} />
        </FilterItem>
      </FiltersGroup>

      <FiltersGroup name="Communication">
        <FilterItem
          text="Communication Preference"
          icon={ChevronDownRed}
          filterActive={filterActiveWithId('Communication Preference')}
        >
          <CommunicationPreferenceFilter name={'Communication Preference'} />
        </FilterItem>
      </FiltersGroup>

      <FiltersGroup name="Legal">
        <SponsorshipFilter name={'SponsorshipFilter'} />
      </FiltersGroup>
    </Wrapper>
  );
};

export default FilterSideBar;
