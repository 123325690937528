import styled from 'styled-components';
import { colors } from '../../../theme';
import { SortDown, SortUp } from '../../../assets/vectors';

export const StyledOrderBy = styled.div`
  padding: 0 2rem 1rem;
  display: flex;
  align-items: center;
`;

export const StyledOrderByTitle = styled.span`
  margin-right: 0.5rem;
  font-size: 1.2rem;
  line-height: 1.2rem;

  color: ${colors.gray_dark};

  &:after {
    content: ': ';
  }
`;

export const StyledOrderByOption = styled.span<{ selected?: boolean }>`
  padding: 0.2rem 0.4rem 0.2rem;

  background-color: ${({ selected }) => (selected ? colors.red : colors.white)};
  color: ${({ selected }) => (selected ? colors.white : colors.gray_dark)};

  border-radius: 2px;
  font-size: 1rem;
  font-weight: 900;

  line-height: 1.1rem;
  text-transform: uppercase;

  cursor: pointer;
`;

export const OrderIcon = styled.div`
  margin-left: 1rem;
  cursor: pointer;
  max-height: 1.5rem;
  max-width: 1.5rem;

  min-height: 1.5rem;
  min-width: 1.5rem;
`;

export const OrderText = styled.span`
  margin-right: 1rem;
  font-size: 1.2rem;
  line-height: 1.2rem;
`;

export const OrderUp = styled(SortUp)``;

export const OrderDown = styled(SortDown)``;
