import gql from 'graphql-tag';

import { GroupParts } from './fragments.gql';

export default gql`
  mutation CreateGroup($recruiter_user_id: String!, $group_name: String!) {
    insert_recruit_grad_group(objects: { recruiter_user_id: $recruiter_user_id, group_name: $group_name }) {
      returning {
        ...GroupParts
      }
    }
  }
  ${GroupParts}
`;
