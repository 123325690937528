import React from 'react';
import styled from 'styled-components';

import { NoPreviousDegreePlaceholder } from '../../assets/icons';
import { colors } from '../../theme';

const StyledNoDegrees = styled.div`
  display: flex;

  padding: 1.8rem 1rem;

  border-radius: 4px;

  color: ${colors.neo_gray};
`;

const StyledImage = styled.img`
  width: 5rem;
  height: 5rem;
`;

const StyledText = styled.div`
  display: flex;
  margin-left: 0.5rem;
  align-items: center;

  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.2rem;
`;

const CandidateNoDegrees: React.FC = () => {
  return (
    <StyledNoDegrees>
      <StyledImage src={NoPreviousDegreePlaceholder} />
      <StyledText>No previous degrees</StyledText>
    </StyledNoDegrees>
  );
};

export default CandidateNoDegrees;
