import React from 'react';
import styled from 'styled-components';

import { colors } from '../theme';
import WorkExperience, { isCurrentWork } from '../types/WorkExperience';

const StyledTag = styled.span<{ inCard?: boolean }>`
  display: inline-block;
  padding: 0.2rem 0.4rem 0.2rem;

  background-color: ${colors.red};
  border-radius: 2px;

  font-size: 1rem;
  font-weight: 900;

  color: ${colors.white};
  line-height: 1.1rem;
  text-transform: uppercase;

  ${({ inCard }) => inCard && `font-size: 0.8rem; padding: 0 0.2rem }`};
`;

export interface CurrentJobProps {
  workExperience: WorkExperience;
  inCard?: boolean;
}

const CurrentJobTag: React.FC<CurrentJobProps> = ({ workExperience, inCard, ...props }) => {
  const opacity = isCurrentWork(workExperience) ? 1 : 0;

  return (
    <StyledTag inCard={inCard} style={{ opacity }} {...props}>
      Current
    </StyledTag>
  );
};

export default CurrentJobTag;
