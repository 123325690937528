import React from 'react';

import { StudentInformationContainer, StudentInformationValueLink } from './StudentInformation.styled';

interface StudentInformationLinkProps {
  title: string;
  values: string[];
  email?: boolean;
}

const generateValueString = (value: string, position: number, maxPosition: number) =>
  `${value}${position < maxPosition - 1 ? ',' : ''}`;

const StudentInformationLink: React.FC<StudentInformationLinkProps> = ({ title, values, email }) => {
  return (
    <StudentInformationContainer>
      {title}:
      {values.map((value, index) => (
        <StudentInformationValueLink href={email ? `mailto:${[values?.[0]]}` : `tel:${[values?.[0]]}`} key={value}>
          {generateValueString(value, index, values.length)}
        </StudentInformationValueLink>
      ))}
    </StudentInformationContainer>
  );
};

export default StudentInformationLink;
