import gql from 'graphql-tag';

import { MessageParts } from './fragments.gql';

export default gql`
  subscription GetMessages($candidateId: String) {
    recruit_sms_message(
      where: { correspondents: { correspondent_user_id: { _eq: $candidateId } } }
      order_by: { timestamp: asc }
    ) {
      ...MessageParts
      correspondents(where: { correspondent_user_id: { _eq: $candidateId } }) {
        error_message
      }
    }
  }
  ${MessageParts}
`;
