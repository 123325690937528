import React from 'react';
import { useHistory } from 'react-router-dom';

import CandidateHeaderButton from './CandidateHeaderButton';
import { Send } from '../../../assets/icons';

const CandidateMessageButton: React.FC<{ messagingPath: string }> = ({ messagingPath }) => {
  const history = useHistory();
  const handleOnClickAddGroup = () => history.push(messagingPath);
  return <CandidateHeaderButton onClick={handleOnClickAddGroup} text={'Send a message'} icon={Send} />;
};

export default CandidateMessageButton;
