import React, { useEffect, useState } from 'react';
import { WhereQueryInterface } from '../../../queries/queryUtils';
import { useFilter } from '../../../utils/useFilter';

import FreeTextFilter from '../FreeTextFilter';

export interface NameFilterProps {
  name: string;
  placeholder?: string;
}

export const generateQuery = (value: string): WhereQueryInterface => {
  const queryNameFilter: WhereQueryInterface = {
    enabled: !!value.length,
    operator: '_and',
    whereQuery: { firstname: { _ilike: `%${value}%` } },
  };

  return queryNameFilter;
};

const NameFilter: React.FC<NameFilterProps> = ({ name, placeholder = '' }) => {
  const { storeFilterStatus, filterValue } = useFilter(name);
  // this state purpose is to intoduce a delay to avoid server overload
  const [filter, setFilter] = useState<string>((filterValue as string) || '');

  useEffect(() => {
    const timeoutId = setTimeout(() => storeFilterStatus(generateQuery(filter), filter), 700);
    return () => clearTimeout(timeoutId);
  }, [filter]);

  useEffect(() => {
    !filterValue && setFilter('');
  }, [filterValue]);

  return (
    <FreeTextFilter placeholder={placeholder} defaultValue={(filter as string) || ''} setFilterValue={setFilter} />
  );
};

export default NameFilter;
