import gql from 'graphql-tag';

import { MessageParts } from './fragments.gql';

export default gql`
  mutation InsertSMSMessage(
    $content: String
    $recruiter_user_id: String
    $correspondents: [recruit_sms_message_correspondent_insert_input!]!
  ) {
    insert_recruit_sms_message(
      objects: {
        content: $content
        incoming: false
        recruiter_user_id: $recruiter_user_id
        correspondents: {
          data: $correspondents
          on_conflict: { constraint: sms_message_correspondent_pkey, update_columns: [] }
        }
      }
    ) {
      returning {
        ...MessageParts
      }
    }
  }
  ${MessageParts}
`;
