import React from 'react';
import styled, { css } from 'styled-components';

import colors from '../theme/colors';

import { CircleChecked, CircleUnchecked } from '../assets/icons';

const StyledCheckboxCard = styled.span`
  position: relative;

  width: 1.8rem;
  height: 1.8rem;

  cursor: pointer;
`;

interface StyledCustomCheckboxCardProps {
  checked?: boolean;
}

const StyledCircleCut = styled.div`
  position: absolute;
  bottom: 47%;
  left: 63%;
  width: 0.8rem;
  height: 1rem;

  background-color: white;

  transform: rotate(45deg);
`;

const StyledCustomCheckboxCard = styled.span<StyledCustomCheckboxCardProps>`
  position: absolute;
  top: 0;
  left: 0;

  width: 1.8rem;
  height: 1.8rem;

  border-radius: 50%;
  border: solid 2px;
  border-color: ${({ checked }) => (checked ? colors.green : colors.red)};

  :after {
    position: absolute;
    content: '';
    bottom: 0.5rem;
    right: 0.2rem;
    height: 0;
    width: 0;

    border-radius: 50%;
    border: solid ${colors.green};
    border-width: 0 2px 2px 0;
    transform: rotate(0deg) scale(0);
    opacity: 1;

    ${({ checked }) =>
      checked &&
      css`
        transform: rotate(45deg) scale(1.2);
        width: 0.3rem;
        height: 0.9rem;
        border-radius: 0;
      `}
  }
`;

const CheckboxIcon = styled.img<{ checked: boolean }>`
  width: 1.8rem;
  height: 1.8rem;
  color: ${({ checked }) => (checked ? colors.red : colors.neo_gray)};
`;

export interface CheckboxCardProps {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
}

const CheckboxCard: React.FC<CheckboxCardProps> = ({ checked, onChange = () => undefined, ...props }) => {
  return (
    <StyledCheckboxCard {...props}>
      <CheckboxIcon
        checked={Boolean(checked)}
        src={checked ? CircleChecked : CircleUnchecked}
        onClick={() => onChange(!checked)}
      />
    </StyledCheckboxCard>
  );
};

export default CheckboxCard;
