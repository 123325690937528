import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import auth0, { WebAuth, Auth0DecodedHash, Auth0Error, AuthOptions, Auth0UserProfile } from 'auth0-js';
import { useImpersonation } from '../utils/useImpersonation';

export interface User {
  sub: string;
  nickname?: string;
  name?: string;
  picture?: string;
  updatedAt?: string;
  email?: string;
  roles: string[];
  isManager: boolean;
}

type HasuraClaim = {
  'x-hasura-allowed-roles': string[];
  'x-hasura-default-role': string;
  'x-hasura-user-id': string;
};

// The AccessTokenPayload's interface from auth0-js
interface HasuraAuth0UserProfile extends Auth0UserProfile {
  'https://hasura.io/jwt/claims'?: HasuraClaim;
}

const initialContext = {
  user: null,
  error: null,
  authenticating: false,
  authToken: null,
  checkingSession: true,
  webAuthClient: null,
  loginRequired: false,
  doLogin: () => undefined,
  doLogout: () => undefined,
  doUniversalLogin: () => undefined,
  role: '',
  isManager: false,
};

const transformAccessTokenPayloadToUser = (auth0User: HasuraAuth0UserProfile): User => {
  const { nickname, name, picture, updated_at, email, sub, 'https://hasura.io/jwt/claims': hasuraClaims } = auth0User;
  const roles = hasuraClaims?.['x-hasura-allowed-roles'] || [];

  const isManager = roles.includes('manager');

  return {
    nickname,
    name,
    picture,
    updatedAt: updated_at,
    email,
    sub,
    roles,
    isManager,
  };
};

const createWebAuthClient = (clientID: string, domain: string, audience?: string): WebAuth => {
  const redirectUri = `${window.location.origin}/auth/callback`;

  const client = new auth0.WebAuth({
    domain,
    redirectUri,
    clientID,
    audience,
    responseType: 'token id_token',
    scope: 'openid profile email',
  });

  return client;
};

export const Auth0Context = React.createContext<{
  user: User | null;
  error: Auth0Error | null;
  authenticating: boolean;
  authToken: string | null;
  checkingSession: boolean;
  loginRequired: boolean;
  webAuthClient: WebAuth | null;
  doLogin: (email: string, password: string) => void;
  doLogout: () => void;
  doUniversalLogin: () => void;
  role: string;
}>(initialContext);

export const useAuth = () => useContext(Auth0Context); // eslint-disable-line @typescript-eslint/explicit-module-boundary-types

interface Auth0ProviderProps extends Pick<AuthOptions, 'domain' | 'clientID' | 'audience'> {
  callbackPath: string;
  loginPath: string;
  logoutPath: string;
  validRoles: string[];
}

export const Auth0Provider: React.FC<Auth0ProviderProps> = ({
  clientID,
  domain,
  audience,
  callbackPath,
  loginPath,
  logoutPath,
  children,
  validRoles,
}) => {
  const authenticatingDefault = window.location.pathname === callbackPath ? true : initialContext.authenticating;

  const [authenticating, setAuthenticating] = useState(authenticatingDefault);
  const [authToken, setAuthToken] = useState<string | null>(initialContext.authToken);
  const [checkingSession, setCheckingSession] = useState(initialContext.checkingSession);
  const [error, setError] = useState<Auth0Error | null>(initialContext.error);
  const [loginRequired, setLoginRequired] = useState(initialContext.loginRequired);
  const [user, setUser] = useState<User | null>(initialContext.user);
  const [role, setRole] = useState<string>(initialContext.role);
  const { getImpersonationProps } = useImpersonation();

  const doUserCheck = (user: User) => validRoles.length > 0 && user.roles.some((r) => validRoles.includes(r));

  const findBestValidRole = (user: User) => user.roles.find((ur) => validRoles.some((r) => r == ur));

  const webAuthClient = useMemo<WebAuth>(() => {
    const client = createWebAuthClient(clientID, domain, audience);

    client.parseHash((err: Auth0Error | null, data: Auth0DecodedHash | null) => {
      if (!err && !data) {
        return;
      }

      setAuthenticating(false);

      if (err) {
        setError(err);
      }

      if (data?.accessToken) {
        webAuthClient.client.userInfo(data.accessToken, (err, user) => {
          if (err) {
            setError(err);
          }
          const loggedInUser = transformAccessTokenPayloadToUser(user);
          if (doUserCheck(loggedInUser)) {
            const bestRole = findBestValidRole(loggedInUser);
            if (bestRole) setRole(bestRole);
            setAuthToken(data.accessToken || null);
            const impersonationProps = getImpersonationProps();
            // if impersonation is running on localhost we replace the admin id with the impersonated one
            setUser({
              ...loggedInUser,
              sub:
                !process.env.REACT_APP_ON_ADMIN_SITE && impersonationProps.userID && bestRole === 'support-admin'
                  ? impersonationProps.userID
                  : loggedInUser.sub,
            });
          } else {
            setRole('');
            setUser(null);
            setAuthToken(null);
            setError({ error: 'Wrong account type' });
          }
        });
      }
    });

    return client;
  }, [clientID, domain, audience]);

  const doLogin = useCallback(
    (email, password) => {
      setAuthenticating(true);
      setError(null);

      webAuthClient.login(
        {
          username: email,
          password,
          realm: 'Username-Password-Authentication',
        },
        (err: Auth0Error | null) => {
          setAuthenticating(false);
          setError(err);
        },
      );
    },
    [webAuthClient],
  );

  const doUniversalLogin = useCallback(() => webAuthClient.authorize(), [webAuthClient]);

  const doLogout = useCallback(() => {
    const logoutReturnToUri = `${window.location.origin}${loginPath}`;

    webAuthClient.logout({
      returnTo: logoutReturnToUri,
    });
  }, [webAuthClient, loginPath]);

  useEffect(() => {
    if (!webAuthClient) {
      return;
    }

    if (window.location.pathname === callbackPath || window.location.pathname === logoutPath) {
      setCheckingSession(false);
      return;
    }

    webAuthClient.checkSession({}, (err: Auth0Error | null, result: Auth0DecodedHash) => {
      if (err) {
        // discard the error because we'll force the user to login later
        setLoginRequired(true);
        setCheckingSession(false);
        return;
      }
      setAuthToken(result.accessToken || null);

      if (result?.accessToken) {
        webAuthClient.client.userInfo(result.accessToken, (err, user) => {
          if (err) {
            // discard the error because we'll force the user to login later
            setLoginRequired(true);
            setCheckingSession(false);
            return;
          }
          const loggedInUser = transformAccessTokenPayloadToUser(user);
          if (doUserCheck(loggedInUser)) {
            const bestRole = findBestValidRole(loggedInUser);
            if (bestRole) setRole(bestRole);

            const impersonProps = getImpersonationProps();

            // if impersonation is running on localhost we replace the admin id with the impersonated one
            setUser({
              ...loggedInUser,
              sub:
                !process.env.REACT_APP_ON_ADMIN_SITE && impersonProps.userID && bestRole === 'support-admin'
                  ? impersonProps.userID
                  : loggedInUser.sub,
            });
            setCheckingSession(false);
          } else {
            doLogout();
          }
        });
      }
    });
  }, [webAuthClient, callbackPath, logoutPath, doLogout]);

  return (
    <Auth0Context.Provider
      value={{
        user,
        authenticating,
        authToken,
        checkingSession,
        error,
        webAuthClient,
        doLogin,
        doLogout,
        doUniversalLogin,
        loginRequired,
        role,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
