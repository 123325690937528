import gql from 'graphql-tag';

import { CandidateParts, CandidatePartsAdmin } from './fragments.gql';

export default gql`
  query GetCandidates(
    $admin: Boolean!
    $dynamicQuery: grad_profile_bool_exp
    $offset: Int
    $limit: Int
    $order_by: grad_profile_order_by!
  ) {
    grad_profile_aggregate(where: $dynamicQuery) {
      aggregate {
        count
      }
    }
    grad_profile(order_by: [$order_by], where: $dynamicQuery, offset: $offset, limit: $limit) {
      ... on grad_profile @include(if: $admin) {
        ...CandidatePartsAdmin
      }

      ... on grad_profile @skip(if: $admin) {
        ...CandidateParts
      }
    }
  }
  ${CandidateParts}
  ${CandidatePartsAdmin}
`;
