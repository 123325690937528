import React from 'react';
import styled from 'styled-components';

import Modal from '../../../components/Modal';

import RecipientDisplay from '../recipient/RecipientDisplay';
import Recipient from '../../../types/Recipient';
import MessageSenderController from './MessageSenderController';
import SpinnerIsoType from '../../SpinnerIsoType';
import { useMutation } from '@apollo/client';
import UPDATE_JOB_STATUS_ACTIVE from '../../../queries/updateJobStatusActive.gql';
import { UpdateJobsStatusActive, UpdateJobsStatusActiveVariables } from 'src/generated/UpdateJobsStatusActive';

export interface MessageNewModalProps {
  loading: boolean;
  recipients: Recipient[];
  setModalOpen: (status: boolean) => void;
  onSend?: () => void;
  jobId: string;
  handleDeleteRecipient: (recipientId: string) => void;
}

const StyledTitle = styled.div`
  margin: 2rem 1rem;
  font-size: 1.8rem;
  line-height: 2.1rem;
  margin-left: 3.5rem;
`;

const Container = styled.div`
  margin: 2rem 0;
`;

const MessageNewModal: React.FC<MessageNewModalProps> = ({
  jobId,
  setModalOpen,
  onSend,
  recipients,
  handleDeleteRecipient,
}) => {
  const [updateJobStatusActivate, { loading }] = useMutation<UpdateJobsStatusActive, UpdateJobsStatusActiveVariables>(
    UPDATE_JOB_STATUS_ACTIVE,
  );

  const dismissJob = () => {
    const variables = {
      active: false,
      jobId: jobId,
    };
    updateJobStatusActivate({ variables });
  };

  const handleOnSend = () => {
    setModalOpen(false);
    dismissJob();
    if (onSend !== undefined) onSend();
  };

  return (
    <Modal heightAuto={true} setModalOpen={setModalOpen}>
      <StyledTitle>Send Message</StyledTitle>
      {loading ? (
        <SpinnerIsoType text={'Loading recipients'} />
      ) : (
        <Container>
          <RecipientDisplay recipients={recipients} handleDeleteRecipient={handleDeleteRecipient} />
          <MessageSenderController
            popperId="message-new-modal-template-popper"
            onSend={handleOnSend}
            candidates={recipients.map(({ id, firstname }) => {
              return { id, firstname };
            })}
            hasMarkup
            big
          />
        </Container>
      )}
    </Modal>
  );
};

export default MessageNewModal;
