import React from 'react';
import { colors } from '../../../theme';
import styled from 'styled-components';

import Template from '../../../types/Template';
import SpinnerIsoType from '../../SpinnerIsoType';
import TemplateListItemController from './TemplateListItemController';

interface TemplateListProps {
  templates: Template[];
  templateToEdit: number;
  loading: boolean;
  editMode: boolean;
  setEditMode: (arg: boolean) => void;
  setTemplateToEdit: (arg: number) => void;
}

const StyledTemplateList = styled.ul`
  list-style: none;
  height: 52rem;
  max-height: 52rem;
  overflow-y: scroll;
  padding: 3rem;
`;

const FooterContainer = styled.div`
  height: 7.4rem;
  background-color: ${colors.gray_light_l10};
`;

const TemplateList: React.FC<TemplateListProps> = ({
  templates,
  loading,
  editMode,
  setEditMode,
  templateToEdit,
  setTemplateToEdit,
}) => {
  return (
    <>
      {editMode ? (
        <TemplateListItemController
          setEditMode={setEditMode}
          editMode={editMode}
          template={templates?.[templateToEdit]}
          setTemplateToEdit={() => setTemplateToEdit(0)}
        />
      ) : (
        <>
          <StyledTemplateList>
            {loading ? (
              <SpinnerIsoType text={'Loading templates'} />
            ) : (
              templates.map((t, index) => (
                <li key={t.id}>
                  <TemplateListItemController
                    setTemplateToEdit={() => setTemplateToEdit(index)}
                    setEditMode={setEditMode}
                    editMode={editMode}
                    template={t}
                  />
                </li>
              ))
            )}
          </StyledTemplateList>
          <FooterContainer />
        </>
      )}
    </>
  );
};

export default TemplateList;
