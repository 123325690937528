import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import WithSeparator from 'react-with-separator';

import FilterTitle from './FilterTitle';
import MultiCheckGroupedFilterGroup from './MultiCheckGroupedFilterGroup';
import { colors } from '../../theme';
import { FilterOption } from '../../types/FilterValueType';
import HorizontalRuler from '../HorizontalRuler';
import SearchInputText from '../../components/SearchInputText';

const StyledHorizontalRuler = styled(HorizontalRuler)`
  margin: 0 0 1rem;
`;

const StyledSearchInputText = styled(SearchInputText)`
  margin-bottom: 1.3rem;

  border: 1px solid ${colors.gray_light};
  border-radius: 8px;

  background-color: ${colors.white};
`;

const StyledGroupOptions = styled.div`
  width: 100%;

  display: inline-block;

  overflow-y: auto;
  overflow-x: hidden;
`;

const StyledNoneAll = styled.div`
  float: right;

  margin-left: 1rem;
  margin-bottom: 0.5rem;

  color: ${colors.red};
  cursor: pointer;
  text-decoration: underline;
`;

export type GroupedFilterOptions = {
  undergrad: FilterOption[];
  masters: FilterOption[];
  doctoral: FilterOption[];
};

export interface MultiCheckGroupedFilterProps {
  filterTitle: string;
  options: GroupedFilterOptions;
  defaultOptions: FilterOption[];
  setFilterValue: (options: FilterOption[]) => void;
  clearFilter: () => void;
  allSelection?: boolean;
}

const MultiCheckGroupedFilter: React.FC<MultiCheckGroupedFilterProps> = ({
  options,
  setFilterValue,
  filterTitle,
  defaultOptions,
  clearFilter,
  allSelection = true,
}) => {
  const [textFilter, setTextFilter] = useState('');
  const [selectedOptions, setSelectedOptions] = useState<FilterOption[]>(defaultOptions || []);

  const generateToggleOption = (option: FilterOption) => {
    return (add: boolean) => {
      const newSelectedOptions = add
        ? [...selectedOptions, option]
        : selectedOptions.filter(({ id }) => id !== option.id);
      setSelectedOptions(newSelectedOptions);
      if (newSelectedOptions.length) setFilterValue(newSelectedOptions);
      else clearFilter();
    };
  };

  const flatOptions: FilterOption[] = useMemo(
    () => (Object.values(options) as unknown as FilterOption[]).flat(),
    [options],
  );

  const filterOptions = (options: FilterOption[]) =>
    options.filter((u) => {
      const filteredByText = u.displayValue.toLowerCase().includes(textFilter.toLowerCase());
      const filteredBySelection = selectedOptions.some(({ id }) => u.id === id);
      return !filteredBySelection && filteredByText;
    });

  const handleOnClickAll = () => {
    setSelectedOptions(flatOptions);
    setFilterValue(flatOptions);
  };

  const handleOnClickNone = () => {
    setSelectedOptions([]);
    clearFilter();
  };

  const isCompleteSectionSelected = (sectionOptions: FilterOption[]) => {
    return sectionOptions.every((option) => selectedOptions.some((selectedOption) => selectedOption.id === option.id));
  };

  const isSelectedOption = (option: FilterOption) => {
    return selectedOptions.some((selectedOption) => selectedOption.id === option.id);
  };

  const cleanSectionOptions = (sectionOptions: FilterOption[]) => {
    const newSelectedOptions = selectedOptions.filter((selectedOption) =>
      sectionOptions.every((sectionOption) => sectionOption.id !== selectedOption.id),
    );

    setSelectedOptions(newSelectedOptions);
    setFilterValue(newSelectedOptions);
    !newSelectedOptions.length && clearFilter();
  };

  const checkAllSectionOptions = (sectionOptions: FilterOption[]) => {
    const nonSelectedOptions = sectionOptions.filter((sectionOption) => !isSelectedOption(sectionOption));
    const newSelectedOptions = [...selectedOptions, ...nonSelectedOptions];
    setSelectedOptions(newSelectedOptions);
    setFilterValue(newSelectedOptions);
  };

  const onSelectFullSection = (sectionOptions: FilterOption[]) => {
    isCompleteSectionSelected(sectionOptions)
      ? cleanSectionOptions(sectionOptions)
      : checkAllSectionOptions(sectionOptions);
  };

  return (
    <div>
      <FilterTitle title={filterTitle} />
      <StyledSearchInputText placeholder="Search" value={textFilter} onChange={setTextFilter} />
      <span>
        <StyledNoneAll onClick={handleOnClickNone}>None</StyledNoneAll>
        {allSelection && <StyledNoneAll onClick={handleOnClickAll}>All</StyledNoneAll>}
      </span>
      <StyledGroupOptions>
        <WithSeparator separator={<StyledHorizontalRuler />}>
          <MultiCheckGroupedFilterGroup
            options={filterOptions(options.undergrad)}
            selectedOptions={selectedOptions.filter(({ id }) => options.undergrad.some((u) => u.id === id))}
            generateToggleOption={generateToggleOption}
            groupTitle={'Undergrad'}
            defaultCollapsed={true}
            isSelectedAll={isCompleteSectionSelected(options.undergrad)}
            onSelectAll={() => onSelectFullSection(options.undergrad)}
          />
          <MultiCheckGroupedFilterGroup
            options={filterOptions(options.masters)}
            selectedOptions={selectedOptions.filter(({ id }) => options.masters.some((u) => u.id === id))}
            generateToggleOption={generateToggleOption}
            groupTitle={'Master’s'}
            defaultCollapsed={true}
            isSelectedAll={isCompleteSectionSelected(options.masters)}
            onSelectAll={() => onSelectFullSection(options.masters)}
          />
          <MultiCheckGroupedFilterGroup
            options={filterOptions(options.doctoral)}
            selectedOptions={selectedOptions.filter(({ id }) => options.doctoral.some((u) => u.id === id))}
            generateToggleOption={generateToggleOption}
            groupTitle={'Doctoral'}
            defaultCollapsed={true}
            isSelectedAll={isCompleteSectionSelected(options.doctoral)}
            onSelectAll={() => onSelectFullSection(options.doctoral)}
          />
        </WithSeparator>
      </StyledGroupOptions>
    </div>
  );
};

export default MultiCheckGroupedFilter;
