import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import GET_RECIPIENTS_BY_NAME from '../../../queries/getRecipientsByName.gql';
import GET_GROUPS_BY_NAME from '../../../queries/getGroupsByName.gql';
import { asRecipient } from '../../../transformers/recipient';
import { GetRecipientsByName, GetRecipientsByNameVariables } from '../../../generated/GetRecipientsByName';
import Recipient from '../../../types/Recipient';
import RecipientSelector from './RecipientSelector';
import { GetGroups } from 'src/generated/GetGroups';
import { asGroup } from '../../../transformers/groups';
import Group from 'src/types/Group';

interface RecipientSelectorControllerProps {
  selectedRecipients?: Recipient[];
  selectedGroups?: Group[];
  setSelectedRecipients: (recipients: Recipient[]) => void;
  setSelectedGroups: (recipients: Group[]) => void;
}

const RecipientSelectorController: React.FC<RecipientSelectorControllerProps> = ({
  setSelectedRecipients,
  selectedRecipients = [],
  selectedGroups = [],
  setSelectedGroups,
}) => {
  const [localLoading, setLocalLoading] = useState(true);

  const [getNames, { data }] = useLazyQuery<GetRecipientsByName, GetRecipientsByNameVariables>(GET_RECIPIENTS_BY_NAME, {
    onCompleted: () => {
      setLocalLoading(false);
    },
    onError: () => {
      setLocalLoading(false);
    },
  });

  const [getGroups, { data: dataGroups, loading: loadingGroups }] = useLazyQuery<GetGroups>(GET_GROUPS_BY_NAME);

  useEffect(() => {
    getNames({ variables: { name: `% %`, limit: 100 } });
    getGroups({ variables: { name: `% %` } });
  }, [getNames, getGroups]);

  const recipients = data?.grad_profile.map(asRecipient) || [];
  const groups = dataGroups?.recruit_grad_group.map((gp) => asGroup(gp)) || [];

  const handleChangeSearchText = (value: string) => {
    setLocalLoading(true);
    setTimeout(() => {
      setLocalLoading(true);
      getNames({ variables: { name: `%${value}%`, limit: 100 } });
      getGroups({ variables: { name: `%${value}%` } });
    }, 1000);
  };

  const addRecipient = (recipient: Recipient) => {
    if (selectedRecipients.some(({ id }) => recipient.id === id)) return;

    setSelectedRecipients([...selectedRecipients, recipient]);
  };

  const removeRecipient = (recipient: Recipient) => {
    setSelectedRecipients(selectedRecipients.filter(({ id }) => recipient.id !== id));
  };

  const filterArray = (arrayToFilter: Recipient[], originArray: Recipient[]) =>
    arrayToFilter.filter(function (item) {
      return !originArray.some((e) => e?.id === item?.id);
    });

  const addGroup = (group: Group, recipients: Recipient[]) => {
    if (!selectedGroups.includes(group)) setSelectedGroups([...selectedGroups, group]);
    const recipientsToAdd = recipients.filter((recipient) => !selectedRecipients.some((r) => r?.id === recipient?.id));
    setSelectedRecipients([...selectedRecipients, ...recipientsToAdd]);
  };

  const removeGroup = (group: Group, recipients: Recipient[]) => {
    setSelectedGroups(selectedGroups.filter(({ id }) => group.id !== id));
    setSelectedRecipients(filterArray(selectedRecipients, recipients));
  };

  return (
    <RecipientSelector
      onChangeSearchText={handleChangeSearchText}
      removeRecipient={removeRecipient}
      addRecipient={addRecipient}
      selectedRecipients={selectedRecipients}
      loadingRecipients={localLoading}
      recipients={recipients}
      groups={groups}
      loadingGroups={loadingGroups}
      selectedGroups={selectedGroups}
      addGroup={addGroup}
      removeGroup={removeGroup}
    />
  );
};

export default RecipientSelectorController;
