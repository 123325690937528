import gql from 'graphql-tag';

import { InterestFirmSelectorParts } from './fragments.gql';

export default gql`
  query GetInterestFirmSelection($firm_type: String) {
    grad_interest_firm_selection(where: { value: { _ilike: $firm_type } }, order_by: { value: asc }) {
      ...InterestFirmSelectorParts
    }
  }
  ${InterestFirmSelectorParts}
`;
