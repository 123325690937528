import React, { useState } from 'react';

import { StudentInformationContainer, StudentInformationValue, ReadMore } from './StudentInformation.styled';

interface StudentInformationProps {
  title: string;
  values: string[];
}

const generateValueString = (value: string, position: number, maxPosition: number) =>
  `${value}${position < maxPosition - 1 ? ',' : ''}`;

const isShortContent = (content: string): boolean => content.length < 40;

const StudentInformationExtensible: React.FC<StudentInformationProps> = ({ title, values }) => {
  const [isExtended, setExtended] = useState<boolean>(false);

  return (
    <StudentInformationContainer>
      {title}:
      {values.map((value, index) => {
        const content = generateValueString(value, index, values.length);

        return (
          <React.Fragment key={value}>
            <StudentInformationValue key={value}>
              {!isExtended && !isShortContent(content) ? `${content.slice(0, 40)}...` : content}
            </StudentInformationValue>
            {!isShortContent(content) && (
              <ReadMore onClick={() => setExtended(!isExtended)}>{isExtended ? 'show less' : 'see more'}</ReadMore>
            )}
          </React.Fragment>
        );
      })}
    </StudentInformationContainer>
  );
};

export default StudentInformationExtensible;
