import React from 'react';
import styled from 'styled-components';

import Recipient from '../../../types/Recipient';
import SpinnerIsoType from '../../SpinnerIsoType';
import RecipientSelectorListItem from './RecipientSelectorListItem';

const StyledRecipientSelectorList = styled.div<{ embebed: boolean }>`
  overflow-y: scroll;
  margin: ${({ embebed }) => (embebed ? '1rem' : '1rem 0')};
`;

const StyledTitle = styled.span`
  font-family: 'RobotoBold';
  font-size: 1.8rem;
  line-height: 140%;
  margin-left: 3rem;
  margin-top: 1rem;
`;

interface RecipientSelectorListProps {
  recipients?: Recipient[];
  loading: boolean;
  embebed: boolean;
  removeRecipient: (recipient: Recipient) => void;
  addRecipient: (recipient: Recipient) => void;
  selectedRecipients: Recipient[];
}

const RecipientSelectorList: React.FC<RecipientSelectorListProps> = ({
  addRecipient,
  removeRecipient,
  recipients = [],
  loading,
  embebed,
  selectedRecipients,
}) => {
  return (
    <StyledRecipientSelectorList embebed={embebed}>
      {!embebed && <StyledTitle>Individual</StyledTitle>}
      {loading ? (
        <SpinnerIsoType text={'Searching candidates'} />
      ) : (
        recipients.map((recipient) => (
          <div style={{ width: '100%' }} key={embebed ? `group-${recipient?.id}` : recipient.id}>
            <RecipientSelectorListItem
              addRecipient={addRecipient}
              removeRecipient={removeRecipient}
              recipient={recipient}
              selected={selectedRecipients.some(({ id }) => recipient.id === id)}
            />
          </div>
        ))
      )}
    </StyledRecipientSelectorList>
  );
};

export default RecipientSelectorList;
