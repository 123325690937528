import React from 'react';
import styled from 'styled-components';

import { DefaultCandidatePhoto } from '../../../assets/images';
import colors from '../../../theme/colors';
import Recipient from '../../../types/Recipient';

import { AvatarShape, AvatarPlaceholder } from '../../../assets/vectors';
import CheckBox from './Checkbox';

export const ImageShadow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 5.5rem;
  height: 5.5rem;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 1)),
    url(${AvatarPlaceholder});
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
`;

export const Photo = styled.img`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  mask: url(${AvatarShape});
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 92%;
  top: 0.15rem;
  position: absolute;
`;

const PortraitContainer = styled.div`
  position: relative;

  max-width: 9rem;
`;

const RecipientFullName = styled.div`
  font-family: 'Roboto';
  font-size: 1.8rem;
  line-height: 2.1rem;
`;

const StyledCandidateInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  margin: 0 1rem;
`;

const StyledTextLow = styled.div`
  font-family: 'Roboto';
  font-size: 1.2rem;
  line-height: 1.2rem;

  color: ${colors.beta_lighten_45};
`;

const StyledRecipientSelectorListItem = styled.div`
  display: grid;
  grid-template-columns: 3rem 6rem 1fr;
  grid-template-rows: 6rem;
  padding: 0.8rem 0;
  margin: 0 3rem;
  cursor: pointer;
  align-items: center;
`;

interface RecipientSelectorListItemProps {
  recipient: Recipient;
  removeRecipient: (recipient: Recipient) => void;
  addRecipient: (recipient: Recipient) => void;
  selected: boolean;
}

const RecipientSelectorListItem: React.FC<RecipientSelectorListItemProps> = ({
  recipient,
  removeRecipient,
  addRecipient,
  selected,
}) => {
  const handleOnClickRecipient = () => {
    selected ? removeRecipient(recipient) : addRecipient(recipient);
  };

  return (
    <StyledRecipientSelectorListItem>
      <CheckBox onChange={handleOnClickRecipient} checked={selected} />
      <PortraitContainer>
        <ImageShadow>
          <Photo src={recipient.photo || DefaultCandidatePhoto} />
        </ImageShadow>
      </PortraitContainer>
      <StyledCandidateInformation>
        <RecipientFullName>{recipient.fullname}</RecipientFullName>
        <StyledTextLow>
          {recipient.title ? `${recipient.title}, ` : ''}
          {recipient?.degrees?.[0]?.degree} {recipient?.degrees?.[0]?.gpa ? `, ${recipient?.degrees?.[0]?.gpa}` : ''}
          {recipient?.degrees?.[0]?.university?.location_name
            ? `, ${recipient?.degrees?.[0]?.university?.location_name}`
            : ''}
        </StyledTextLow>
      </StyledCandidateInformation>
    </StyledRecipientSelectorListItem>
  );
};

export default RecipientSelectorListItem;
