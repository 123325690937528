import styled from 'styled-components';
import { AvatarShape, AvatarPlaceholder, AvatarPlaceholderCardUnselected } from '../../../assets/vectors';

export const ImageShadow = styled.div<{ hasImage?: boolean; isSelected?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 7.4rem;
  height: 8rem;

  ${({ hasImage, isSelected }) =>
    hasImage &&
    (isSelected
      ? `background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 1)), url(${AvatarPlaceholder});
      background-size: cover;
      background-repeat: no-repeat;`
      : `background-image: url(${AvatarPlaceholderCardUnselected});
      background-size: cover;
      background-repeat: no-repeat;`)}
`;

export const Photo = styled.img<{ selected?: boolean; hasImage?: boolean }>`
  width: 8.5rem;
  height: 8.5rem;
  margin: 0 auto;

  ${({ hasImage }) => hasImage && `mask: url(${AvatarShape})`};
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 80%;

  &:hover {
    opacity: 0.4;
  }
`;
