import { colors } from 'elitegrad-common/src/theme';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-rows: 10rem 1fr;
  width: 100%;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
`;

export const StyledMessaging = styled.div<{ studentProfileOpen: boolean }>`
  display: grid;

  grid-template-columns: ${({ studentProfileOpen }) => (studentProfileOpen ? '36rem 1fr 30rem' : '36rem 1fr')};
  grid-template-rows: 1fr;

  & > * {
    height: 100vh;
  }
`;

export const StyledLeft = styled.div`
  display: grid;

  height: 100%;

  border: 1px solid ${colors.gray_light};
  max-height: 100vh;
  overflow: auto;
`;

export const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.span`
  font-size: 18px;
  line-height: 21px;
  font-family: 'RobotoMedium';
  margin-left: 3rem;
`;

export const CloseIcon = styled.span`
  cursor: pointer;
  margin-right: 3rem;
`;
