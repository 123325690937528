import React from 'react';
import styled from 'styled-components';

import Test from '../../types/Test';
import { colors } from '../../theme';

const StyledTestDetails = styled.div`
  display: flex;
  flex-direction: column;

  margin: 1rem;
  background-color: ${colors.gray_extraLight};
  border-radius: 4px;
`;

const StyledScoreDetail = styled.span<{ big: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: ${({ big }) => (big ? '1.2rem 1.3rem' : '1rem 0.8rem')};

  font-size: ${({ big }) => (big ? '1.5rem' : '1.3rem')};
  line-height: 1.1rem;

  text-transform: capitalize;
`;

const SpaceSeparator = styled.div`
  padding: 0.4rem 0;
  background-color: ${colors.white};
`;

const ScoreType = styled.div``;

const ScoreValue = styled.div`
  font-family: 'RobotoBold';
`;

const ScoreValueLimit = styled.div`
  opacity: 0.3;
`;

const ScoresValueContainer = styled.div`
  display: flex;
`;

const Container = styled.div``;

interface CandidateTestScoreOthersProps {
  tests: Test[];
  big?: boolean;
}

const CandidateTestScoreOthers: React.FC<CandidateTestScoreOthersProps> = ({ tests, big = false }) => {
  return (
    <StyledTestDetails>
      {tests.map((d) => (
        <Container key={d.id}>
          <StyledScoreDetail big={big} key={d.id}>
            <ScoreType>{d.type}:</ScoreType>
            <ScoresValueContainer>
              <ScoreValue>{d.score}</ScoreValue>
              <ScoreValueLimit>/{d.outof}</ScoreValueLimit>
            </ScoresValueContainer>
          </StyledScoreDetail>
          <SpaceSeparator />
        </Container>
      ))}
    </StyledTestDetails>
  );
};

export default CandidateTestScoreOthers;
