import gql from 'graphql-tag';

import { UniversityPartsStat } from './fragments.gql';

export default gql`
  query GetUniversityStat($location_name: String!) {
    grad_university_stat(
      order_by: { degree_count: desc }
      where: { university_selection: { location_name: { _ilike: $location_name } } }
    ) {
      ...UniversityPartsStat
    }
  }
  ${UniversityPartsStat}
`;
