import React, { useState, SyntheticEvent, useCallback } from 'react';
import WithSeparator from 'react-with-separator';
import { StyledDotMenuIcon, StyledMenu, StyledOpenMenu } from './CandidateCardDotMenu.styled';

import HorizontalRuler from 'elitegrad-common/src/components/HorizontalRuler';
import Candidate from 'elitegrad-common/src/types/Candidate';
import DownloadCVMenuOptionController from 'elitegrad-common/src/components/candidates/DownloadCVMenuOptionController';
import BlockMenuOption from 'elitegrad-common/src/components/candidates/BlockMenuOption';
import { ToggleBlockMenuModalController } from 'elitegrad-common/src/components/block';

const CandidateCardDotMenu: React.FC<{ candidate: Candidate; refetch: () => void }> = ({ candidate, refetch }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [blockedModalOpen, setBlockedModalOpen] = useState(false);

  const handlePropagation = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  const handleMenuClick = () => setMenuOpen((m) => !m);

  const handleOnBlur = () => setMenuOpen(false);

  return (
    <StyledMenu onClick={handlePropagation} onMouseLeave={handleOnBlur}>
      <StyledDotMenuIcon onClick={handleMenuClick} props={{ lay: menuOpen }} />
      {menuOpen && (
        <StyledOpenMenu>
          <WithSeparator separator={<HorizontalRuler />}>
            <DownloadCVMenuOptionController
              onClick={() => setMenuOpen(false)}
              candidateId={candidate.userId}
              cvExists={Boolean(candidate.hasCV)}
            />
          </WithSeparator>
          <WithSeparator separator={<HorizontalRuler />}>
            <BlockMenuOption
              onClick={() => setBlockedModalOpen(!blockedModalOpen)}
              blocked={candidate.blocked || false}
            />
          </WithSeparator>
        </StyledOpenMenu>
      )}
      {blockedModalOpen && (
        <ToggleBlockMenuModalController
          refetch={refetch}
          setModalOpen={setBlockedModalOpen}
          candidateId={candidate.userId}
          candidateBlocked={candidate.blocked || false}
          blockReason={candidate.blockedReason || ''}
        />
      )}
    </StyledMenu>
  );
};

export default CandidateCardDotMenu;
