import { StylesConfig } from 'react-select';

import colors from './colors';
import { CSSProperties } from 'styled-components';

const selectorStyles: StylesConfig = {
  control: (provided: CSSProperties) => ({
    ...provided,
    borderColor: colors.gray_l1,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    fontFamily: 'Roboto',
  }),
  menuList: (provided: CSSProperties) => ({
    ...provided,
    maxHeight: '18rem',
    color: colors.gray_dark,
    textAlign: 'left',
    fontFamily: 'Roboto',
  }),
  dropdownIndicator: (provided: CSSProperties) => ({
    ...provided,
    color: colors.black,
  }),
  clearIndicator: (provided: CSSProperties) => ({
    ...provided,
    color: colors.black,
    height: '30px',
    width: '30px',
    padding: '5.5px',
  }),
};

export default selectorStyles;
