import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';

import DELETE_TEMPLATE from '../../../queries/deleteTemplate.gql';
import GET_TEMPLATES from '../../../queries/getTemplates.gql';
import UPDATE_TEMPLATE from '../../../queries/updateTemplate.gql';
import { GetTemplates } from '../../../generated/GetTemplates';
import { DeleteTemplate, DeleteTemplateVariables } from '../../../generated/DeleteTemplate';
import { UpdateTemplateVariables, UpdateTemplate } from '../../../generated/UpdateTemplate';

import Template from '../../../types/Template';
import TemplateListItem from './TemplateListItem';
import TemplateListItemEdit from './TemplateListItemEdit';

interface TemplateListItemControllerProps {
  template: Template;
  editMode: boolean;
  setEditMode: (arg: boolean) => void;
  setTemplateToEdit: () => void;
}

const TemplateListItemController: React.FC<TemplateListItemControllerProps> = ({
  template,
  editMode,
  setEditMode,
  setTemplateToEdit,
}) => {
  const [removeTemplate, { loading: loadingRemove }] = useMutation<DeleteTemplate, DeleteTemplateVariables>(
    DELETE_TEMPLATE,
    {
      variables: { templateId: template.id },
      update: (cache) => {
        const getTemplates = cache.readQuery<GetTemplates>({ query: GET_TEMPLATES });
        if (!getTemplates) return;
        const newTemplates = getTemplates.recruit_sms_template.filter(
          ({ sms_template_id }) => sms_template_id !== template.id,
        );
        cache.writeQuery({ query: GET_TEMPLATES, data: { recruit_sms_template: newTemplates } });
      },
    },
  );

  const [updateTemplate, { loading: loadingUpdate }] = useMutation<UpdateTemplate, UpdateTemplateVariables>(
    UPDATE_TEMPLATE,
    {
      onCompleted: () => {
        setEditMode(false);
      },
      update: (cache, { data: createData }) => {
        const updatedTemplate = createData?.update_recruit_sms_template?.returning[0];
        if (!updatedTemplate) return;

        const getTemplates = cache.readQuery<GetTemplates>({ query: GET_TEMPLATES });
        if (!getTemplates) return;

        const newTemplates = [
          ...getTemplates.recruit_sms_template.filter(({ sms_template_id }) => sms_template_id !== template.id),
          updatedTemplate,
        ];
        cache.writeQuery({ query: GET_TEMPLATES, data: { recruit_sms_template: newTemplates } });
      },
    },
  );

  const handleOnClickSave = useCallback(
    (content: string, title: string) => {
      updateTemplate({ variables: { templateId: template.id, content, title } });
    },
    [template.id, updateTemplate],
  );

  const handleOnClickTrash = useCallback(removeTemplate, [removeTemplate]);

  return editMode && !loadingRemove ? (
    <TemplateListItemEdit
      componentTitle={'Edit template'}
      disable={loadingUpdate}
      onClickTrash={handleOnClickTrash}
      template={template}
      onClickSave={handleOnClickSave}
      disableSaveMode={() => setEditMode(false)}
    />
  ) : (
    <TemplateListItem
      enableSaveMode={() => setEditMode(true)}
      disable={loadingRemove}
      setTemplateToEdit={setTemplateToEdit}
      onClickTrash={handleOnClickTrash}
      template={template}
    />
  );
};

export default TemplateListItemController;
