import React from 'react';
import { languagesNames } from '../../../data/languages';
import { WhereQueryInterface } from '../../../queries/queryUtils';
import { FilterOption } from '../../../types/FilterValueType';
import { useFilter } from '../../../utils/useFilter';
import MultiCheckFilter from '../MultiCheckFilter';

export interface LanguageFilterProps {
  name: string;
}

export const generateQuery = (options: FilterOption[]): WhereQueryInterface => {
  const values = options.map(({ filterValue }) => filterValue);
  const queryLanguageFilter: WhereQueryInterface = {
    enabled: !!values.length,
    operator: '_and',
    whereQuery: { spoken_languages: { language: { _in: values } } },
  };

  return queryLanguageFilter;
};

const LanguageFilter: React.FC<LanguageFilterProps> = ({ name }) => {
  const { storeFilterStatus, filterValue, clearFilter } = useFilter(name);

  const setFilterValue = (options: FilterOption[]) => storeFilterStatus(generateQuery(options), options);

  const optionsFirmTypes: FilterOption[] = languagesNames.map((lang) => ({
    filterValue: lang,
    displayValue: lang,
    id: lang,
  }));

  return (
    <MultiCheckFilter
      filterTitle={'Other Languages'}
      options={optionsFirmTypes}
      defaultOptions={(filterValue as FilterOption[]) || []}
      setFilterValue={setFilterValue}
      clearFilter={clearFilter}
    />
  );
};

export default LanguageFilter;
