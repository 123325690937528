import gql from 'graphql-tag';

import { GroupParts } from './fragments.gql';

export default gql`
  query GetGroupsByName($name: String) {
    recruit_grad_group(where: { group_name: { _ilike: $name } }) {
      ...GroupParts
    }
  }
  ${GroupParts}
`;
