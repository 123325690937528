import React, { useState } from 'react';
import styled from 'styled-components';

import SearchInputText from '../../../components/SearchInputText';
import { colors } from '../../../theme';

const StyledSearchInputText = styled(SearchInputText)`
  grid-column: 1 / 3;
  margin: 1.3rem 0;

  border: 1px solid ${colors.gray_light};
  border-radius: 8px;
  background-color: ${colors.white};
  font-size: 1.6rem;
  line-height: 1.9rem;

  ::placeholder {
    color: ${colors.gray_g3};
  }
`;

interface ConversationListSearchProps {
  onChange: (text: string) => void;
}

const ConversationListSearch: React.FC<ConversationListSearchProps> = ({ onChange }) => {
  const [searchInput, setSearchInput] = useState('');

  const handleChange = (text: string) => {
    setSearchInput(text);
    onChange(text);
  };

  return (
    <StyledSearchInputText placeholder="Search..." value={searchInput} onChange={handleChange}></StyledSearchInputText>
  );
};

export default ConversationListSearch;
