export const IMPERSONATION_ID = 'imperson-id';
export const IMPERSONATION_ROLE = 'imperson-role';

export const addImpersonToHeaders = (headers: any, userID: string, role: string, authToken: string | null) => {
  return {
    headers: {
      ...headers,
      'X-Hasura-Impersonate-User-Id': userID,
      'X-Hasura-User-Id': userID,
      'X-Hasura-Impersonate-Role': role,
      authorization: authToken ? `Bearer ${authToken}` : '',
    },
  };
};

export const injectImpersonHeaders = (userID: string | null, role: string | null) => {
  if (!userID || !role) return undefined;

  return {
    'X-Hasura-Impersonate-User-Id': userID,
    'X-Hasura-Impersonate-Role': role,
  };
};
