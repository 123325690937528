import gql from 'graphql-tag';

import { GroupParts } from './fragments.gql';

export default gql`
  mutation RemoveGroups($groupsIds: [String!]!) {
    delete_recruit_grad_group(where: { grad_group_id: { _in: $groupsIds } }) {
      returning {
        ...GroupParts
      }
    }
  }
  ${GroupParts}
`;
