import React from 'react';
import styled from 'styled-components';
import colors from 'elitegrad-common/src/theme/colors';
import Page from '../Page';
import FilterSide, { FilterSideProps } from './FilterSide';
import RecruiterList from './RecruiterList';

import { RecruiterListProps } from './RecruiterList/RecruiterList';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.gray_light_l4};
`;

const Loading = styled.div`
  margin-left: 4.6rem;
`;

const Title = styled.span`
  font-family: 'Roboto';
  font-size: 3.9rem;
  line-height: 4.6rem;
  margin-left: 4.6rem;
  font-weight: bold;
  margin-top: 3rem;
  margin-bottom: 2rem;
`;

const Description = styled.span`
  font-family: 'Roboto';
  font-size: 1.6rem;
  line-height: 2.1rem;
  margin-left: 4.6rem;
  max-width: 80%;
  margin-bottom: 2rem;
`;
interface RecruitersProps extends RecruiterListProps, FilterSideProps {
  loadingRecruiters: boolean;
}

const Recruiters: React.FC<RecruitersProps> = ({
  recruiters,
  onApplyFilter,
  onClearFilter,
  loadingRecruiters,
  pagination,
  onChangePaginationOffset,
}) => {
  const headerComponent = (
    <Header>
      <Title>Recruiters</Title>
      <Description>
        Here you can see the list of your Hiring Managers. You can impersonate them to make a follow up of their
        accounts.
      </Description>
    </Header>
  );
  return (
    <Page
      headerComponent={headerComponent}
      extraSidebar={<FilterSide onApplyFilter={onApplyFilter} onClearFilter={onClearFilter} />}
      backgroundColor={colors.gray_light_l4}
    >
      {loadingRecruiters && <Loading>Loading...</Loading>}
      {!loadingRecruiters && (
        <RecruiterList
          recruiters={recruiters}
          onChangePaginationOffset={onChangePaginationOffset}
          pagination={pagination}
        />
      )}
    </Page>
  );
};

export default Recruiters;
