import React from 'react';
import { useQuery } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import GET_INTEREST_FIRM_SELECTION from '../../../queries/getInterestFirmSelection.gql';
import { GetInterestFirmSelection } from '../../../generated/GetInterestFirmSelection';
import { WhereQueryInterface } from '../../../queries/queryUtils';
import { selectionAsInterestFirm } from '../../../transformers/interestFirmsSelection';
import { FilterOption } from '../../../types/FilterValueType';
import { useFilter } from '../../../utils/useFilter';
import MultiCheckFilter from '../MultiCheckFilter';
import InterestFirm from '../../../types/InterestFirm';

export interface InterestFirmsFilterProps {
  name: string;
}

export const generateQuery = (options: FilterOption[]): WhereQueryInterface => {
  const optionsValues = options.map(({ filterValue }) => filterValue);
  const queryGroupFilter: WhereQueryInterface = {
    enabled: !!optionsValues.length,
    operator: '_and',
    whereQuery: { interest_firms: { firm_type: { _in: optionsValues } } },
  };
  return queryGroupFilter;
};

const InterestFirmsFilter: React.FC<InterestFirmsFilterProps> = ({ name }) => {
  const { storeFilterStatus, filterValue, clearFilter } = useFilter(name);

  const { data, loading } = useQuery<GetInterestFirmSelection>(GET_INTEREST_FIRM_SELECTION);

  const setFilterValue = (options: FilterOption[]) => storeFilterStatus(generateQuery(options), options);

  const firmTypes: InterestFirm[] = data?.grad_interest_firm_selection.map((gp) => selectionAsInterestFirm(gp)) || [];
  const options: FilterOption[] = firmTypes.map(({ id, firmType }) => ({
    filterValue: firmType,
    displayValue: firmType,
    id: id,
  }));

  return loading ? (
    <Skeleton  height={357} />
  ) : (
    <MultiCheckFilter
      filterTitle={'Firms of interest'}
      options={options}
      defaultOptions={(filterValue as FilterOption[]) || []}
      setFilterValue={setFilterValue}
      clearFilter={clearFilter}
    />
  );
};
export default InterestFirmsFilter;
