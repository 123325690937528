import gql from 'graphql-tag';

const query = gql`
  query GetCVsCountByUsersId($userIds: [String!]) {
    grad_upload_doc_aggregate(where: { user_id: { _in: $userIds } }) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`;

export default query;
