import React from 'react';
import styled from 'styled-components';

import { DefaultCandidatePhoto } from '../../../assets/images';
import Conversation from '../../../types/Conversation';
import colors from '../../../theme/colors';
import { toHoursDate } from '../../../utils/dateUtils';

import { AvatarShape, AvatarPlaceholder } from '../../../assets/vectors';

const ImageShadow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5.3rem;
  background: transparent;
  background-image: url(${AvatarPlaceholder});
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
`;

const Photo = styled.img`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  mask: url(${AvatarShape});
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 92%;
  position: absolute;
`;

const StyledRadioDot = styled.div`
  width: 1rem;
  width: 12px;
  height: 12px;
  margin-top: 1rem;
  border-radius: 6px;
  border-width: 0px;
  line-height: 12px;
  background-color: ${colors.red};
`;

const StyledCandidateInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: 0 1rem;
`;

const HourText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.2rem;
  font-family: 'RobotoMedium';
  line-height: 1.4rem;
  margin-top: 1rem;
  color: ${colors.beta_lighten_60};
`;

const RecipientFullName = styled.div`
  font-family: 'RobotoMedium';
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: ${colors.beta_lighten_15};
  margin-bottom: 0.1rem;
`;

const StyledTextLow = styled.div`
  font-size: 1.2rem;
  line-height: 1.2;

  color: ${colors.beta_lighten_45};
`;

const StyledConversationListItem = styled.div`
  display: grid;
  grid-template-columns: 6rem 1fr 4rem;
  grid-template-rows: 6rem;
  margin: 1rem;
  cursor: pointer;
`;

interface ConversationListItemProps {
  conversation: Conversation;
}

const ConversationListItem: React.FC<ConversationListItemProps> = ({ conversation }) => {
  const recipient = conversation.recipient;
  return (
    <StyledConversationListItem>
      <ImageShadow>
        <Photo src={recipient.photo || DefaultCandidatePhoto} />
      </ImageShadow>

      <StyledCandidateInformation>
        <RecipientFullName>{recipient.fullname}</RecipientFullName>
        <StyledTextLow>
          {recipient.title ? `${recipient.title}, ` : ''}
          {recipient?.degrees?.[0]?.degree} {recipient?.degrees?.[0]?.gpa ? ` - ${recipient?.degrees?.[0]?.gpa}` : ''}
          {recipient?.degrees?.[0]?.university?.location_name
            ? ` - ${recipient?.degrees?.[0]?.university?.location_name}`
            : ''}
        </StyledTextLow>
      </StyledCandidateInformation>
      <HourText>
        {conversation.lastMessageDate && toHoursDate(conversation.lastMessageDate)}{' '}
        {!conversation.seen && <StyledRadioDot />}
      </HourText>
    </StyledConversationListItem>
  );
};

export default ConversationListItem;
