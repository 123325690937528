import gql from 'graphql-tag';

import { MajorSelectorParts } from './fragments.gql';

export default gql`
  query GetMajorSelection($major_name: String!) {
    grad_major_selection(where: { major: { _ilike: $major_name } }) {
      ...MajorSelectorParts
    }
  }
  ${MajorSelectorParts}
`;
