import React from 'react';
import Recipient from 'src/types/Recipient';
import styled from 'styled-components';

import Group from '../../../types/Group';
import SpinnerIsoType from '../../SpinnerIsoType';
import GroupSelectorListItem from './GroupSelectorListItem';

const StyledRecipientSelectorList = styled.div`
  overflow-y: scroll;
  margin: 1rem 0;
`;

const StyledTitle = styled.span`
  font-family: 'RobotoBold';
  font-size: 1.8rem;
  line-height: 140%;
  margin-left: 3rem;
  margin-top: 1rem;
`;

interface RecipientSelectorListProps {
  groups?: Group[];
  loading: boolean;
  removeRecipient: (recipient: Recipient) => void;
  addRecipient: (recipient: Recipient) => void;
  selectedRecipients: Recipient[];
  selectedGroups?: Group[];
  addGroup: (group: Group, recipients: Recipient[]) => void;
  removeGroup: (group: Group, recipients: Recipient[]) => void;
}

const RecipientSelectorList: React.FC<RecipientSelectorListProps> = ({
  removeRecipient,
  addRecipient,
  groups = [],
  loading,
  selectedRecipients,
  selectedGroups,
  addGroup,
  removeGroup,
}) => {
  return (
    <StyledRecipientSelectorList>
      <StyledTitle>Groups</StyledTitle>
      {loading ? (
        <SpinnerIsoType text={'Searching Groups'} />
      ) : (
        groups.map((group) => (
          <div key={group.id}>
            <GroupSelectorListItem
              selectedRecipients={selectedRecipients}
              group={group}
              addRecipient={addRecipient}
              removeRecipient={removeRecipient}
              checked={selectedGroups?.some(({ id }) => group.id === id) || false}
              addGroup={addGroup}
              removeGroup={removeGroup}
            />
          </div>
        ))
      )}
    </StyledRecipientSelectorList>
  );
};

export default RecipientSelectorList;
