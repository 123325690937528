import JobType from './JobType';

interface WorkExperience {
  id: string;
  city: string | null;
  company: string;
  startDate: Date | null;
  endDate: Date | null;
  jobType: JobType | null;
  position: string;
  state: string | null;
  userId: string;
  country: string | null;
}

const isCurrentWork = (workExperience: WorkExperience): boolean => {
  return !workExperience.endDate;
};

const sortByDate = (workExperiences: WorkExperience[]): WorkExperience[] => {
  const result = [...workExperiences];

  return result.sort((a, b) => {
    if (a.endDate === null) {
      return -1;
    } else if (b.endDate === null) {
      return 1;
    } else {
      return b.endDate.getTime() - a.endDate.getTime();
    }
  });
};

export { isCurrentWork, sortByDate };

export default WorkExperience;
