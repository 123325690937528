import React, { useState } from 'react';
import styled from 'styled-components';

import Modal from '../../components/Modal';

import { colors } from '../../theme';
import SecondaryButton from '../../components/SecondaryButton';
import PrimaryButton from '../../components/PrimaryButton';
import BlockReasonSelector from './BlockReasonSelector';

const StyledTitle = styled.div`
  padding: 2rem 1rem;

  background: ${colors.gray_light_l1};
  border-bottom: 1px solid ${colors.gray_light};

  font-size: 2rem;
  font-weight: 700;
`;

const StyledModal = styled.div`
  &&& > * > * {
    height: 30rem;
  }
`;

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3rem;
`;

const StyledButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  margin: 3rem 0;
`;

export interface BlockMenuModalProps {
  onClick: (reason: string) => void;
  setModalOpen: (status: boolean) => void;
}

const BlockMenuModal: React.FC<BlockMenuModalProps> = ({ setModalOpen, onClick }) => {
  const [reason, setReason] = useState('');

  const onBlock = () => {
    if (!reason) return;
    onClick(reason);
    setModalOpen(false);
  };

  const handleChange = (reason: string) => setReason(reason);

  return (
    <StyledModal>
      <Modal setModalOpen={setModalOpen}>
        <StyledTitle>You are about to block a candidate</StyledTitle>
        <StyledModalContent>
          Please submit the reason for blocking the candidate
          <BlockReasonSelector value={reason} onChange={handleChange} />
          <StyledButtons>
            Are you sure you want to block this candidate
            <SecondaryButton big onClick={() => setModalOpen(false)} text={'No, Cancel'} />
            <PrimaryButton disabled={!reason} dark big onClick={onBlock} text={'Yes, Block'} />
          </StyledButtons>
        </StyledModalContent>
      </Modal>
    </StyledModal>
  );
};

export default BlockMenuModal;
