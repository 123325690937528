import React, { useState, useEffect } from 'react';
import { WhereQueryInterface } from '../../../queries/queryUtils';
import { useFilter } from '../../../utils/useFilter';

import FreeTextFilter from '../FreeTextFilter';

export interface LastNameFilterProps {
  name: string;
  placeholder?: string;
}

export const generateQuery = (value: string): WhereQueryInterface => {
  const queryLastNameFilter: WhereQueryInterface = {
    enabled: !!value.length,
    operator: '_and',
    whereQuery: { lastname: { _ilike: `%${value}%` } },
  };

  return queryLastNameFilter;
};

const LastNameFilter: React.FC<LastNameFilterProps> = ({ name, placeholder }) => {
  const { storeFilterStatus, filterValue } = useFilter(name);
  // this state purpose is to intoduce a delay to avoid server overload
  const [filter, setFilter] = useState<string>((filterValue as string) || '');

  useEffect(() => {
    const timeoutId = setTimeout(() => storeFilterStatus(generateQuery(filter), filter), 700);
    return () => clearTimeout(timeoutId);
  }, [filter]);

  useEffect(() => {
    !filterValue && setFilter('');
  }, [filterValue]);

  return (
    <FreeTextFilter placeholder={placeholder} defaultValue={(filter as string) || ''} setFilterValue={setFilter} />
  );
};

export default LastNameFilter;
