import styled from 'styled-components';
import { PlusSign } from '../assets/vectors';

const ExitCross = styled(PlusSign)<{ top?: string; right?: string }>`
  position: absolute;
  top: ${({ top }) => (top ? top : '2rem')};
  right: ${({ right }) => (right ? right : '2rem')};
  width: 2.4rem;

  cursor: pointer;
  transform: rotate(45deg);
`;

export default ExitCross;
