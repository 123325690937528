import React from 'react';
import FreeTextFilter from 'elitegrad-common/src/components/filters/FreeTextFilter';
export interface FullNameFilterProps {
  text: string;
  setText: (string) => void;
  placeholder?: string;
}

const TextFilter: React.FC<FullNameFilterProps> = ({ text, setText, placeholder }) => {
  const setValues = (value: string) => {
    setText(value);
  };
  return <FreeTextFilter placeholder={placeholder} defaultValue={text} setFilterValue={setValues} />;
};

export default TextFilter;
