import { GradAward, GradConcentration } from 'src/generated/graphql';
import Award from './Award';
import AwardCategory from './AwardCategory';
import Concentration from './Concentration';
import ConcentrationType from './ConcentrationType';
import DegreeLevel from './DegreeLevel';
import University from './University';

export const MIN_GPA_OUT_OF = 3;
export const MAX_GPA_OUT_OF = 5;
export const DEFAULT_GPA_OUT_OF = 4;
export const gpaName = 'GPA';

export interface Degree {
  id: string;
  startDate: Date | null;
  completeDate: Date | null;
  degreeLevel: DegreeLevel;
  degreeName: string;
  gpa: number | null;
  gpaOutOf: number | null;
  awards: Award[];
  concentrations: Concentration[];
  university: University | null;
  userId: string;
  gpaNotApplicable: boolean;
}

const getAwardName = (award: Pick<GradAward, 'award_category' | 'award_name'>): string | null => {
  if (!award?.award_category || !award?.award_name) return null;
  return award.award_category === AwardCategory.ACADEMIC_HONOR ? award.award_name : null;
};

const getMajorName = (concentration: Pick<GradConcentration, 'type' | 'val'>): string | null => {
  if (!concentration?.type || !concentration?.val) return null;
  return concentration.type === ConcentrationType.MAJOR ? concentration.val : null;
};

const getMajorNameFilter = (concentrations: Pick<any, 'type' | 'name'>[]): string | undefined =>
  concentrations.filter((concentration) => concentration.type === ConcentrationType.MAJOR)[0]?.name;

const getAcademicHonor = (awards: Pick<any, 'category' | 'name'>[]): string | undefined =>
  awards.filter((award) => award.category === AwardCategory.ACADEMIC_HONOR)[0]?.name;

const getDegreesSortedByCompleteDate = (degrees: Degree[]): Degree[] => {
  return degrees.sort((a, b) => {
    if (a.completeDate === null) {
      return -1;
    } else if (b.completeDate === null) {
      return 1;
    } else {
      return b.completeDate.getTime() - a.completeDate.getTime();
    }
  });
};

export { getAwardName, getMajorName, getDegreesSortedByCompleteDate, getMajorNameFilter, getAcademicHonor };

export default Degree;
