import React from 'react';
import { Container } from './MarkupTag.styled';

export interface MarkupTagProps {
  name: string;
  value: string;
  onClick: (markup: string) => void;
}

const MarkupTag: React.FC<MarkupTagProps> = ({ name, value, onClick }) => {
  return <Container onClick={() => onClick(value)}>{name}</Container>;
};

export default MarkupTag;
