import React from 'react';
import { useQuery } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import GET_INTEREST_REGION_SELECTION from '../../../queries/getInterestRegionSelection.gql';
import { GetInterestRegionSelection } from '../../../generated/GetInterestRegionSelection';
import { WhereQueryInterface } from '../../../queries/queryUtils';
import { selectionAsInterestRegion } from '../../../transformers/interestRegionsSelection';
import { FilterOption } from '../../../types/FilterValueType';
import { useFilter } from '../../../utils/useFilter';
import MultiCheckFilter from '../MultiCheckFilter';
import InterestRegion from '../../../types/InterestRegion';

export interface InterestRegionsFilterProps {
  name: string;
}

export const generateQuery = (options: FilterOption[]): WhereQueryInterface => {
  const optionsValues = options.map(({ filterValue }) => filterValue);
  const queryGroupFilter: WhereQueryInterface = {
    enabled: !!optionsValues.length,
    operator: '_and',
    whereQuery: { interest_regions: { region: { _in: optionsValues } } },
  };
  return queryGroupFilter;
};

const InterestRegionsFilter: React.FC<InterestRegionsFilterProps> = ({ name }) => {
  const { storeFilterStatus, filterValue, clearFilter } = useFilter(name);

  const { data, loading } = useQuery<GetInterestRegionSelection>(GET_INTEREST_REGION_SELECTION);

  const setFilterValue = (options: FilterOption[]) => storeFilterStatus(generateQuery(options), options);

  const regions: InterestRegion[] =
    data?.grad_interest_region_selection.map((gp) => selectionAsInterestRegion(gp)) || [];
  const optionsInterestRegions: FilterOption[] = regions.map(({ id, region }) => ({
    filterValue: region,
    displayValue: region,
    id: id,
  }));

  return loading ? (
      <Skeleton  height={387} />
  ) : (
    <MultiCheckFilter
      filterTitle={'Regions of interest'}
      options={optionsInterestRegions}
      defaultOptions={(filterValue as FilterOption[]) || []}
      setFilterValue={setFilterValue}
      clearFilter={clearFilter}
    />
  );
};

export default InterestRegionsFilter;
