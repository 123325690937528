import gql from 'graphql-tag';
import { TemplateParts } from './fragments.gql';

export default gql`
  mutation DeleteTemplate($templateId: Int!) {
    delete_recruit_sms_template(where: { sms_template_id: { _eq: $templateId } }) {
      returning {
        ...TemplateParts
      }
    }
  }
  ${TemplateParts}
`;
