import gql from 'graphql-tag';

import { GroupMembershipParts } from './fragments.gql';

export default gql`
  mutation InsertGroupMembers($groupMembers: [recruit_grad_group_member_insert_input!]!) {
    insert_recruit_grad_group_member(objects: $groupMembers) {
      returning {
        ...GroupMembershipParts
      }
    }
  }
  ${GroupMembershipParts}
`;
