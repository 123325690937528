import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../theme';

import Message from '../../../types/Message';
import moment from 'moment';
import MessageListItem from '../message/MessageListItem';
import MessageSenderController from '../message/MessageSenderController';
import ChatRecipientIdentityController from './ChatRecipientIdentityController';

const StyledMessageList = styled.div`
  display: grid;
  grid-template-rows: 10rem 1fr 14rem;

  height: 100%;
`;

const StyledMessages = styled.ol`
  padding: 1rem;
  overflow-y: scroll;

  list-style-type: none;

  background: ${colors.gray_l2};
  border-top: 1px solid ${colors.gray_light};
`;

const DateBlob = styled.div`
  position: relative;

  height: 2.4rem;
  margin: auto;
  width: fit-content;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.4rem;
  padding: 0.5rem 2rem;
  border-radius: 4px;
  background-color: ${colors.beta200};
  box-shadow: 0px 2px 6px rgba(10, 22, 70, 0.1);
`;

const blobRequired = (first: Message | undefined, second: Message): boolean =>
  first === undefined ? true : !moment(first.dateSent).isSame(second.dateSent, 'days');

const getBlobText = (first: Message) =>
  moment(first.dateSent).calendar(0, {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    lastDay: '[Yesterday]',
    sameElse: 'MM/DD/YYYY',
  });

interface MessageCandidateInfo {
  id: string;
  firstname: string;
}

interface ChatProps {
  messages?: Message[];
  candidateInfo: MessageCandidateInfo;
  generateCandidatePath: (candidateId: string) => string;
  onSend?: () => void;
  setStudentProfileOpen: (arg: boolean) => void;
}

const Chat: React.FC<ChatProps> = ({
  messages = [],
  candidateInfo,
  onSend,
  generateCandidatePath,
  setStudentProfileOpen,
}) => {
  return (
    <StyledMessageList>
      <ChatRecipientIdentityController
        setStudentProfileOpen={setStudentProfileOpen}
        candidateId={candidateInfo.id}
        generateCandidatePath={generateCandidatePath}
      />

      <StyledMessages>
        {messages.map((message, index, array) => (
          <li key={message.id + 'date'}>
            {blobRequired(array[index - 1], message) && index !== 1 && <DateBlob>{getBlobText(message)}</DateBlob>}
            <MessageListItem key={message.id} message={message} />
          </li>
        ))}
      </StyledMessages>

      <MessageSenderController popperId="chat-template-popper" onSend={onSend} candidates={[candidateInfo]} hasMarkup />
    </StyledMessageList>
  );
};

export default Chat;
