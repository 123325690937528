import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import colors from '../theme/colors';
import { BackArrow } from '../assets/vectors';

const StyledBackLink = styled.a`
  display: flex;
  align-content: center;
  padding: 0.5rem 1rem;

  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${colors.black};

  :focus {
    outline: none;
  }
`;

const BackArrowContainer = styled.img`
  height: 2rem;
  width: 2rem;

  margin: auto 2rem auto 0;
`;

const BackLink: React.FC = () => {
  const history = useHistory();

  return (
    <StyledBackLink onClick={history.goBack}>
      <BackArrowContainer src={BackArrow} /> Back
    </StyledBackLink>
  );
};

export default BackLink;
