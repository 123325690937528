import React from 'react';
import { order_by } from '../../../generated/globalTypes';
import {
  StyledOrderBy,
  StyledOrderByOption,
  StyledOrderByTitle,
  OrderDown,
  OrderUp,
  OrderIcon,
  OrderText,
} from './CandidateListOrderBy.styled';

export interface CandidateListOrderByProps {
  setOrder: (filter: { orderBy: string; direction: DirectionsTypes }) => void;
  currentOrder: { orderBy: string; direction: DirectionsTypes };
}

const ORDER_ASC = order_by.asc_nulls_last;
const ORDER_DES = order_by.desc_nulls_last;

export type DirectionsTypes = 'des' | 'asc';
export const ASC: DirectionsTypes = 'asc';
export const DES: DirectionsTypes = 'des';

export const ORDER_BY_OPTIONS = {
  creation_timestamp: {
    id: 'creation_timestamp',
    text: 'Date Added',
    asc: { creation_timestamp: ORDER_ASC },
    des: { creation_timestamp: ORDER_DES },
  },
  degree_latest: {
    id: 'degree_latest',
    text: 'GPA',
    asc: { degree_latest: { gpa: ORDER_ASC }, user_id: ASC },
    des: { degree_latest: { gpa: ORDER_DES }, user_id: ASC },
  },
};

const CandidateListOrderBy: React.FC<CandidateListOrderByProps> = ({ currentOrder, setOrder }) => {
  return (
    <StyledOrderBy>
      <StyledOrderByTitle>Sort By</StyledOrderByTitle>
      {Object.entries(ORDER_BY_OPTIONS).map(([key, value]) => (
        <StyledOrderByOption
          selected={currentOrder.orderBy === value.id}
          key={key}
          onClick={() => setOrder({ ...currentOrder, orderBy: value.id })}
        >
          {value.text}
        </StyledOrderByOption>
      ))}
      <OrderIcon>
        {currentOrder.direction === ASC && <OrderUp onClick={() => setOrder({ ...currentOrder, direction: DES })} />}
        {currentOrder.direction === DES && <OrderDown onClick={() => setOrder({ ...currentOrder, direction: ASC })} />}
      </OrderIcon>
      <OrderText>{currentOrder.direction}</OrderText>
    </StyledOrderBy>
  );
};

export default CandidateListOrderBy;
