import React, { useState } from 'react';
import styled from 'styled-components';
import SearchInputText from '../../../components/SearchInputText';

import { colors } from '../../../theme';

import Recipient from '../../../types/Recipient';
import CrossBlue from '../../../assets/vectors/CrossBlue.svg';
import RecipientSelectorList from './RecipientSelectorList';
import GroupSelectorList from './GroupSelectorList';

import Group from 'src/types/Group';

const StyledRecipientSelector = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr;
  max-height: 100%;
  overflow-y: scroll;
`;

const StyledTitle = styled.div`
  font-family: 'RobotoMedium';
  font-size: 1.8rem;
  line-height: 2.1rem;
  padding: 2rem;
  padding-top: 0;
  border-bottom: 1px solid ${colors.beta_lighten_75};
`;

const StyledRecipientSearchInput = styled.div`
  min-height: 10rem;

  margin: 2rem 0;
  margin-bottom: 0rem;
`;

const StyledSearchInputText = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 2rem;
  margin-top: 1.3rem;

  border: 1px solid ${colors.gray_light};
  border-radius: 8px;
`;

const StyledRecipientTag = styled.div`
  display: inline-block;
  flex-grow: 1;

  height: 2.8rem;
  min-width: 6rem;
  max-width: 22rem;
  margin: 0.5rem;
  padding: 0.4rem 2.1rem;
  border-radius: 64px;

  font-family: 'Roboto';
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  color: ${colors.black};
  background-color: ${colors.beta_200};
  background-image: url(${CrossBlue});
  background-repeat: no-repeat;
  background-position: 97%;

  cursor: pointer;
`;

const StyledSearchInput = styled(SearchInputText)`
  flex-grow: 1;
  width: auto;
  background-color: ${colors.white};
  border-radius: 8px;
  height: 100%;
`;

interface RecipientSelectorProps {
  onChangeSearchText: (text: string) => void;
  removeRecipient: (recipient: Recipient) => void;
  addRecipient: (recipient: Recipient) => void;
  recipients?: Recipient[];
  selectedRecipients: Recipient[];
  selectedGroups?: Group[];
  loadingRecipients: boolean;
  loadingGroups: boolean;
  groups: Group[];
  addGroup: (group: Group, recipients: Recipient[]) => void;
  removeGroup: (group: Group, recipients: Recipient[]) => void;
}

const RecipientSelector: React.FC<RecipientSelectorProps> = ({
  onChangeSearchText,
  removeRecipient,
  addRecipient,
  selectedRecipients = [],
  recipients,
  loadingRecipients,
  loadingGroups,
  selectedGroups,
  groups,
  addGroup,
  removeGroup,
}) => {
  const [searchInput, setSearchInput] = useState('');

  const handleSearchTextChange = (text: string) => {
    setSearchInput(text);
    onChangeSearchText(text);
  };

  return (
    <StyledRecipientSelector>
      <StyledRecipientSearchInput>
        <StyledTitle>New Chat</StyledTitle>
        <StyledSearchInputText>
          <StyledSearchInput
            placeholder="Search Students or Groups"
            value={searchInput}
            onChange={handleSearchTextChange}
          />
        </StyledSearchInputText>
      </StyledRecipientSearchInput>
      <div style={{ overflowY: 'auto', maxHeight: '100%' }}>
        <GroupSelectorList
          groups={groups}
          loading={loadingGroups}
          selectedRecipients={selectedRecipients}
          selectedGroups={selectedGroups}
          addRecipient={addRecipient}
          removeRecipient={removeRecipient}
          addGroup={addGroup}
          removeGroup={removeGroup}
        />
        <RecipientSelectorList
          recipients={recipients}
          embebed={false}
          loading={loadingRecipients}
          selectedRecipients={selectedRecipients}
          addRecipient={addRecipient}
          removeRecipient={removeRecipient}
        />
      </div>
    </StyledRecipientSelector>
  );
};

export default RecipientSelector;
