import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, useSubscription } from '@apollo/client';

import Chat from './Chat';
import { asMessage } from '../../../transformers/message';

import GET_RECIPIENT_BY_ID from '../../../queries/getRecipientById.gql';
import UPDATE_CONVERSATION_TIME from '../../../queries/updateConversationSeenTime.gql';
import { GetRecipientById, GetRecipientByIdVariables } from '../../../generated/GetRecipientById';
import {
  UpdateConversationSeenTime,
  UpdateConversationSeenTimeVariables,
} from '../../../generated/UpdateConversationSeenTime';
import GET_MESSAGES from '../../../queries/getMessages.gql';
import { GetMessages, GetMessagesVariables } from '../../../generated/GetMessages';
import { User, useAuth } from '../../../utils/react-auth';
import SpinnerIsoType from '../../SpinnerIsoType';

const ChatController: React.FC<{
  candidateId?: string;
  generateCandidatePath: (candidateId: string) => string;
  setStudentProfileOpen: (arg: boolean) => void;
}> = ({ candidateId, generateCandidatePath, setStudentProfileOpen }) => {
  const { user } = useAuth() as { user: User };
  const { id } = useParams<{ id: string }>();

  const [updateSeenTime] = useMutation<UpdateConversationSeenTime, UpdateConversationSeenTimeVariables>(
    UPDATE_CONVERSATION_TIME,
    {
      variables: {
        recruiter_user_id: user.sub,
        correspondent_user_id: candidateId || id || '',
        last_read_timestamp: new Date().toISOString(),
      },
    },
  );

  const { data: candidateData, loading: candidateLoading } = useQuery<GetRecipientById, GetRecipientByIdVariables>(
    GET_RECIPIENT_BY_ID,
    {
      skip: !(candidateId || id),
      variables: { userId: candidateId || id },
    },
  );

  const candidate = candidateData?.grad_profile[0];
  const candidateInfo = {
    id: candidateId || id,
    firstname: candidate?.firstname || '',
  };

  useEffect(() => {
    const interval = setInterval(updateSeenTime, 1000);
    return () => clearInterval(interval);
  }, [updateSeenTime]);

  const { data, loading } = useSubscription<GetMessages, GetMessagesVariables>(GET_MESSAGES, {
    skip: !(candidateId || id),
    fetchPolicy: 'network-only',
    variables: {
      candidateId: candidateId || id,
    },
  });

  if (loading || candidateLoading) return <SpinnerIsoType text={'Loading conversations'} />;

  const messages = data?.recruit_sms_message.map((sms) => asMessage(sms));

  return (
    <Chat
      setStudentProfileOpen={setStudentProfileOpen}
      messages={messages}
      candidateInfo={candidateInfo}
      generateCandidatePath={generateCandidatePath}
    />
  );
};

export default ChatController;
