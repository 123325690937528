import University from '../types/University';

import { UniversityPartsStat } from '../generated/UniversityPartsStat';
import { FillInUniversityParts } from '../generated/FillInUniversityParts';

const asUniversity = (parts: UniversityPartsStat | any): University => {
  return {
    id: parts.university_selection?.university_selection_id || parts.university_selection_id,
    name: parts.university_selection?.location_name || parts.location_name,
    logoUrl: parts.university_selection?.logo_url || parts.logo_url,
  };
};

const asFillUniversity = (parts: FillInUniversityParts): University => {
  return {
    id: parts.degree_id,
    name: parts.institution_name,
    logoUrl: null,
  };
};

export { asUniversity, asFillUniversity };
