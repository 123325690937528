import styled from 'styled-components';
import { colors } from '../../../theme';

export const Container = styled.div`
  display: grid;
  justify-content: center;
  justify-items: center;
  grid-template-areas:
    'avatar head head'
    'col1 col2 col3';
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
  margin: 8rem 16rem 0;
  grid-gap: 1.5rem 0;
`;

export const AvatarContainer = styled.div`
  grid-area: avatar;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 17rem;
  width: 15rem;

  & > div {
    height: 16.5rem;
    width: 15.2rem;
  }

  & > div > img {
    height: 18rem;
    width: 26rem;
  }
`;

export const HeaderContainer = styled.div`
  grid-area: head;
  padding: 1rem;
  width: 100%;
`;

export const Name = styled.div`
  font-size: 3.5rem;
  font-family: 'RobotoBold';
`;

export const GPA = styled.span`
  font-size: 3.5rem;
  font-family: 'RobotoBold';
  color: ${colors.red};
  margin-right: 1rem;
`;

export const GPAoutOf = styled.span`
  font-family: 'Roboto';
  font-size: 1.7rem;
`;

export const University = styled.span`
  font-size: 2rem;
  font-weight: bold;

  margin-right: 1rem;
`;

export const DegreeDate = styled.span`
  font-size: 1.5rem;
  font-weight: normal;
  color: ${colors.gray_dark};
`;

export const DegreeName = styled.div`
  display: flex;
  align-items: center;
  font-size: 2rem;
  margin-top: 0.8rem;
`;

export const MajorName = styled.span`
  font-size: 2rem;
`;

export const AcademicHonor = styled.span`
  background-color: ${colors.gray_extraLight};
  color: ${colors.gray};
  border-radius: 0.6rem;

  font-size: 1.2rem;
  font-weight: normal;
  padding: 0.5rem;
  margin-left: 1rem;
`;

export const AcademicHonorIconContainer = styled.img`
  margin-right: 0.5rem;
  vertical-align: middle;
`;

export const WorkAuthorization = styled.span`
  font-family: 'RobotoBold';
  font-size: 0.9rem;
  margin-left: 1rem;
  color: ${colors.white};
  background-color: ${colors.gray};
  border-radius: 0.3rem;
  padding: 0.5rem 0.5rem;
`;

export const ScoresColumn = styled.div`
  grid-area: col1;
  width: 100%;
  border-right: solid 1px ${colors.neo_gray};
  padding: 0 1rem;
`;

export const StudentInformationColumn = styled.div`
  grid-area: col2;
  width: 100%;

  border-right: solid 1px ${colors.neo_gray};
  padding: 0 2rem;
`;

export const StudentInformationSection = styled.div<{ noMargin?: boolean }>`
  background-color: ${colors.gray_extraLight};
  border-radius: 0.4rem;
  padding: 1rem;
  margin: ${({ noMargin }) => (noMargin ? '0' : '1rem')};

  div:last-child {
    margin-bottom: 0;
  }
`;

export const SectionTitle = styled.div<{ noLeftMargin?: boolean }>`
  font-family: 'RobotoBlack';
  font-size: 1.4rem;
  margin: 3rem 0 1.5rem 1rem;
  ${({ noLeftMargin }) => noLeftMargin && 'margin-left: 0'};
`;

export const InterestSection = styled.div`
  div {
    font-family: 'RobotoBlack';
    font-size: 1.4rem;
  }

  & > div:nth-child(2n) {
    display: flex;
    align-items: center;
    margin: 1.5rem 0 2.8rem;
  }

  div > div {
    font-family: 'Roboto';
    font-size: 1.2rem;
    height: auto;
  }
`;

export const ExperiencesColumn = styled.div`
  grid-area: col3;
  width: 100%;
  padding: 0 1rem;

  border-right: solid 1px ${colors.neo_gray};
`;

export const ExperienceSection = styled.div`
  margin-bottom: 3rem;
`;

export const ExperienceTitle = styled.div<{ isEmptySection?: boolean }>`
  font-family: 'RobotoBlack';
  font-size: 1.4rem;
  color: ${({ isEmptySection }) => (isEmptySection ? colors.gray_light_l2 : colors.black)};
`;

export const EmptySectionPlaceholder = styled.div`
  display: flex;
  justify-content: center;
`;

export const Spacing = styled.div`
  margin-top: 3rem;
`;
