import React from 'react';
import styled from 'styled-components';

import Header from '../../Header';

const StyledOptions = styled.div`
  display: flex;
  align-items: center;
`;

const CandidateHeader: React.FC = ({ children }) => (
  <Header backLink dark>
    <StyledOptions>{children}</StyledOptions>
  </Header>
);
export default CandidateHeader;
