import { WhereQueryInterface } from '../../../queries/queryUtils';
import { FilterValueType } from '../../../types/FilterValueType';

import { generateQuery as generateQueryUniversityFilter } from './UniversityFilter';
import { generateQuery as generateQuerySATFilter } from './SATFilter';
import { generateQuery as generateQueryACTFilter } from './ACTFilter';
import { generateQuery as generateQueryGPAFilter } from './GPAFilter';
import { generateQuery as generateQueryGMATFilter } from './GMATFilter';
import { generateQuery as generateQueryMajorFilter } from './MajorFilter';
import { generateQuery as generateQueryInterestRegionsFilter } from './InterestRegionsFilter';
import { generateQuery as generateQueryInterestFirmsFilter } from './InterestFirmsFilter';
import { generateQuery as generateQueryInterestJobTypesFilter } from './InterestJobTypesFilter';
import { generateQuery as generateQueryDegreeFilter } from './DegreeFilter';
import { generateQuery as generateQueryProfessionalDesignationFilter } from './ProfessionalDesignationFilter';
import { generateQuery as generateQueryUndergradDegreeDateFilter } from './UndergradDegreeDateFilter';
import { generateQuery as generateQueryGraduateDegreeDateFilter } from './GraduateDegreeDateFilter';
import { generateQuery as generateQueryLanguageFilter } from './LanguageFilter';
import { generateQuery as generateQueryBlockedFilter } from './BlockedFilter';
import { generateQuery as generateQueryAthleteFilter } from './AthleteFilter';
import { generateQuery as generateQuerySponsorshipFilter } from './SponsorshipFilter';
import { generateQuery as generateComputerLanguageFilter } from './ComputerLanguageFilter';
import { generateQuery as generateGREFilter } from './GREFilter';
import { generateQuery as generateRelevantYearsOfWorkFilter } from './RelevantYearsOfWorkFilter';
import { generateQuery as generateNameFilter } from './NameFilter';
import { generateQuery as generateLastNameFilter } from './LastNameFilter';
import { generateQuery as generateInterestJobTypesToggleFilter } from './InterestJobTypesToggleFilter';
import { generateQuery as generateQuerySATFilterDetailed } from './SATFilterDetailed';
import { generateQuery as generateCombineFilter } from './CombineFilter';
import { generateQuery as generateQueryFunFactsFilter } from './FunFactsFilter';
import { generateQuery as generateQueryHiddenFilter } from './HiddenFilter';

type queryGeneratorType = (type: FilterValueType) => WhereQueryInterface;

const queryGeneratorMap: { [key: string]: queryGeneratorType } = {
  UniversityFilter: generateQueryUniversityFilter as queryGeneratorType,
  SATFilter: generateQuerySATFilter as queryGeneratorType,
  ACTFilter: generateQueryACTFilter as queryGeneratorType,
  GPAFilter: generateQueryGPAFilter as queryGeneratorType,
  GMATFilter: generateQueryGMATFilter as queryGeneratorType,
  MajorFilter: generateQueryMajorFilter as queryGeneratorType,
  InterestRegionsFilter: generateQueryInterestRegionsFilter as queryGeneratorType,
  InterestFirmsFilter: generateQueryInterestFirmsFilter as queryGeneratorType,
  InterestJobTypesFilter: generateQueryInterestJobTypesFilter as queryGeneratorType,
  DegreeFilter: generateQueryDegreeFilter as queryGeneratorType,
  ProfessionalDesignationFilter: generateQueryProfessionalDesignationFilter as queryGeneratorType,
  UndergradDegreeDateFilter: generateQueryUndergradDegreeDateFilter as queryGeneratorType,
  GraduateDegreeDateFilter: generateQueryGraduateDegreeDateFilter as queryGeneratorType,
  LanguageFilter: generateQueryLanguageFilter as queryGeneratorType,
  BlockedFilter: generateQueryBlockedFilter as queryGeneratorType,
  AthleteFilter: generateQueryAthleteFilter as queryGeneratorType,
  ComputerLanguageFilter: generateComputerLanguageFilter as queryGeneratorType,
  GREFilter: generateGREFilter as queryGeneratorType,
  RelevantYearsOfWorkFilter: generateRelevantYearsOfWorkFilter as queryGeneratorType,
  NameFilter: generateNameFilter as queryGeneratorType,
  LastNameFilter: generateLastNameFilter as queryGeneratorType,
  InterestJobTypesToggleFilter: generateInterestJobTypesToggleFilter as queryGeneratorType,
  SATFilterDetailed: generateQuerySATFilterDetailed as queryGeneratorType,
  CombineFilter: generateCombineFilter as queryGeneratorType,
  SponsorshipFilter: generateQuerySponsorshipFilter as queryGeneratorType,
  FunFacstFilter: generateQueryFunFactsFilter as queryGeneratorType,
  HiddenFilter: generateQueryHiddenFilter as queryGeneratorType,
};

export default queryGeneratorMap;
