import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../theme';

const StyledCandidateHeaderButtonIcon = styled.div`
  & > svg {
    width: 2.4rem;
    height: 2.4rem;
  }

  & > svg > path {
    fill: ${colors.black};
  }
`;

const CandidateHeaderButtonIcon: React.FC<{ Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> }> = ({
  Icon,
}) => {
  return (
    <StyledCandidateHeaderButtonIcon>
      <Icon />
    </StyledCandidateHeaderButtonIcon>
  );
};

export default CandidateHeaderButtonIcon;
