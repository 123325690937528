import React from 'react';
import styled from 'styled-components';

import Test from '../../types/Test';
import CandidateTestScoreDetails from './CandidateTestScoreDetails';

const StyledTestScores = styled.div<{ big: boolean }>`
  font-size: ${({ big }) => (big ? '2rem' : '1.6rem')};

  padding: 0.5rem;
  min-height: 4.9rem;
`;

const SatContainer = styled.div<{ big: boolean }>`
  display: flex;
  justify-content: center;

  margin: 0.5rem 0;
  font-size: ${({ big }) => (big ? '2.8rem' : '2.3rem')};
`;

const StyledTestType = styled.span<{ big: boolean }>`
  font-size: ${({ big }) => (big ? '1.2rem' : '1rem')};
  line-height: 1.5rem;
  font-weight: 700;
`;

const StyledScore = styled.span`
  margin-left: 0.8rem;

  line-height: 1.5rem;
  font-family: 'RobotoBold';
`;

const StyledOutOf = styled.span`
  margin-right: 0.8rem;
  margin-left: 0.2rem;

  line-height: 1.5rem;
  opacity: 0.3;
`;

interface CandidateTestScoreSatProps {
  satTest: Test;
  big?: boolean;
}

const CandidateTestScoreSat: React.FC<CandidateTestScoreSatProps> = ({ satTest, big = false }) => {
  return (
    <StyledTestScores big={big} key={satTest.id}>
      <SatContainer big={big}>
        <StyledTestType big={big}>{satTest.type}:</StyledTestType>
        <StyledScore>{satTest.score}</StyledScore>
        <StyledOutOf>/{satTest.outof}</StyledOutOf>
      </SatContainer>
      <CandidateTestScoreDetails big={big} details={satTest.details} />
    </StyledTestScores>
  );
};

export default CandidateTestScoreSat;
