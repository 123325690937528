import React, { useState } from 'react';
import { FilterButtonsContainer, FilterSideBar, ButtonApply, ButtonClear, FilterTitle } from './FilterSide.styled';
import ViewTitle from 'elitegrad-common/src/components/ViewTitle';
import TextFilter from './TextFilter';

export interface FilterProps {
  fullname: string | null;
  email: string | null;
  company: string | null;
}

export interface FilterSideProps {
  onApplyFilter: (args: FilterProps) => void;
  onClearFilter: () => void;
}

interface FiltersProps {
  filters: FilterProps;
  values: {
    fullname: string;
    email: string;
    company: string;
  };
}

const initialValues = {
  filters: {
    company: null,
    email: null,
    fullname: null,
  },
  values: { company: '', email: '', fullname: '' },
};

const FilterSide: React.FC<FilterSideProps> = ({ onApplyFilter, onClearFilter }) => {
  const [filter, setFilter] = useState<FiltersProps>(initialValues);
  const setFilterValues = (fieldName: 'company' | 'email' | 'fullname', value: string) => {
    setFilter({
      filters: { ...filter.filters, [fieldName]: value ? `%${value}%` : null },
      values: { ...filter.values, [fieldName]: value },
    });
  };

  return (
    <FilterSideBar>
      <ViewTitle>Filter</ViewTitle>
      <FilterTitle first>Full Name</FilterTitle>
      <TextFilter
        placeholder="Search"
        setText={(value: string) => setFilterValues('fullname', value)}
        text={filter.values.fullname}
      />
      <FilterTitle>Company</FilterTitle>
      <TextFilter
        placeholder="Search"
        setText={(value: string) => setFilterValues('company', value)}
        text={filter.values.company}
      />
      <FilterTitle>Email</FilterTitle>
      <TextFilter
        placeholder="Search"
        setText={(value: string) => setFilterValues('email', value)}
        text={filter.values.email}
      />
      <FilterButtonsContainer>
        <ButtonApply onClick={() => onApplyFilter(filter?.filters)}>Apply</ButtonApply>
        <ButtonClear
          onClick={() => {
            onClearFilter();
            setFilter(initialValues);
          }}
        >
          Clear
        </ButtonClear>
      </FilterButtonsContainer>
    </FilterSideBar>
  );
};

export default FilterSide;
