import React from 'react';
import styled from 'styled-components';

import colors from '../../theme/colors';

const StyledInterestTag = styled.div`
  display: inline-block;

  min-width: 3.5rem;
  max-width: 25rem;
  height: 2rem;

  overflow: hidden;

  margin: 0.2rem 0.5rem 0.2rem 0;
  padding: 0.3rem 0.5rem;
  border-radius: 3px;

  font-size: 1rem;
  line-height: 1.6rem;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;

  color: ${colors.white};
  background-color: ${colors.gray};
`;

const TagsContainer = styled.div`
  margin: 1rem;
`;

const Title = styled.div`
  font-size: 1rem;
  font-weight: 900;
  line-height: 1.4rem;

  text-transform: capitalize;
`;

interface CandidateInterestProps {
  interests: string[];
  title: string;
}

const InterestTag: React.FC<{ interest: string }> = ({ interest }) => {
  return <StyledInterestTag>{interest}</StyledInterestTag>;
};

const CandidateCardInterests: React.FC<CandidateInterestProps> = ({ interests, title }) => {
  return (
    <>
      <Title>{title}</Title>
      <TagsContainer>
        {interests
          .sort((a, b) => a.length - b.length)
          .map((interest, i) => (
            <InterestTag key={i} interest={interest} />
          ))}
      </TagsContainer>
    </>
  );
};

export default CandidateCardInterests;
