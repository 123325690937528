import React from 'react';
import styled from 'styled-components';

import { colors } from '../../theme';
import TextAreaInput from '../TextAreaInput';
import TextInput from '../TextInput';
import { Lens } from '../../assets/images';

const StyledSearchInputText = styled(TextInput)`
  margin-top: 1rem;
  border: 1px solid ${colors.gray_light};
  border-radius: 4px;

  background-color: ${colors.white};
`;

const StyledTextAreaInput = styled(TextAreaInput)`
  height: 3rem;
  min-height: 3rem;
  margin-top: 1rem;
  border: 1px solid ${colors.gray_light};
  border-radius: 8px;

  background-color: ${colors.white};

  resize: vertical;

  font: inherit;

  border: none;

  font-size: 1.6rem;
  line-height: 1.9rem;
  color: ${colors.gray};

  background-image: url(${Lens});
  background-repeat: no-repeat;
  background-position: 1rem 1rem;
  padding-left: 3rem;
`;

export interface FreeTextFilterProps {
  defaultValue: string;
  setFilterValue: (value: string) => void;
  textArea?: boolean;
  placeholder?: string;
}

const FreeTextFilter: React.FC<FreeTextFilterProps> = ({
  setFilterValue,
  defaultValue = '',
  textArea = false,
  placeholder = '',
}) => {
  return (
    <div>
      {textArea ? (
        <StyledTextAreaInput placeholder={placeholder} value={defaultValue} onChange={setFilterValue} />
      ) : (
        <StyledSearchInputText placeholder={placeholder} value={defaultValue} onChange={setFilterValue} />
      )}
    </div>
  );
};

export default FreeTextFilter;
