import styled from 'styled-components';

export const StyledCandidatesGrid = styled.div<{ hasCVCards?: boolean }>`
  display: grid;
  grid-template-columns: repeat(auto-fit, 250px);
  grid-auto-flow: dense;

  justify-content: ${({ hasCVCards }) => (hasCVCards ? 'flex-start' : 'center')};

  grid-gap: 2rem;
  margin: ${({ hasCVCards }) => (hasCVCards ? '3rem' : '1rem')};
`;
