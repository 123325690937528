import React from 'react';
import styled, { css } from 'styled-components';
import Sidebar from 'views/sidebar';

const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ScrollContent = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;

  overflow: hidden;

  & > * {
    overflow: scroll;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Content = styled.div<{ backgroundColor?: string }>`
  position: relative;
  flex-grow: 1;

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `}
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export interface PageProps {
  extraSidebar?: React.ReactNode;
  headerComponent?: React.ReactNode;
  footer?: React.ReactNode;
  backgroundColor?: string;
}

const Page: React.FC<PageProps> = ({ extraSidebar, headerComponent, children, footer, backgroundColor }) => {
  return (
    <PageContainer>
      <Sidebar />
      <FlexColumn>
        <ScrollContent>
          {extraSidebar}
          <Content backgroundColor={backgroundColor}>
            <Header>{headerComponent}</Header>
            {children}
          </Content>
        </ScrollContent>
        {footer}
      </FlexColumn>
    </PageContainer>
  );
};

export default Page;
