import React from 'react';
import styled from 'styled-components';

import Degree, { getMajorNameFilter } from '../../types/Degree';
import { colors } from '../../theme';
import { formatMonthYear } from '../../utils/dateUtils';

const StyledDegree = styled.div`
  padding: 1rem;

  overflow-y: auto;
  overflow-x: hidden;
  overflow-wrap: break-word;
`;

const StyledDatePeriod = styled.div<{ big: boolean }>`
  font-size: ${({ big }) => (big ? '1.2rem' : '0.8rem')};
  line-height: ${({ big }) => (big ? '1.3rem' : '1.1rem')};
  margin-bottom: 0.3rem;

  color: ${colors.gray};
`;

const StyledMajor = styled.div<{ big: boolean }>`
  font-size: ${({ big }) => (big ? '1.6rem' : '1.1rem')};
  line-height: ${({ big }) => (big ? '1.8rem' : '1.6rem')};
  font-weight: bold;
`;

const StyledUniversity = styled.div<{ big: boolean }>`
  display: flex;
  font-size: ${({ big }) => (big ? '1.4rem' : '1rem')};
  line-height: ${({ big }) => (big ? '1.8rem' : '1.2rem')};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  margin-bottom: 0.2rem;
`;

export const CurrentGPA = styled.div`
  display: flex;
  align-items: flex-end;
  color: ${colors.red};
  font-size: 1.8rem;
  font-weight: bold;
  padding-bottom: 0.8rem;
`;

export const LimitGPA = styled.div`
  display: flex;
  justify-content: center;
  color: ${colors.red};
  font-size: 1.2rem;
  font-weight: normal;
  margin-left: 0.3rem;
  margin-bottom: 0.1rem;
`;

export const GPANotApplicable = styled.div`
  font-size: 1.4rem;
`;

interface CandidatePreviousDegreeProps {
  degree: Degree;
  big?: boolean;
}

const CandidatePreviousDegree: React.FC<CandidatePreviousDegreeProps> = ({ degree, big = false }) => {
  return (
    <StyledDegree>
      <StyledDatePeriod big={big}>
        {degree.startDate && `From ${formatMonthYear(degree.startDate)}`}
        {degree.startDate && degree.completeDate && ' to '}
        {degree.completeDate && `${formatMonthYear(degree.completeDate)}`}
      </StyledDatePeriod>
      <StyledMajor big={big}>{getMajorNameFilter(degree.concentrations)}</StyledMajor>
      <StyledUniversity big={big}>{degree.degreeName + ' ' + (degree.university?.name || '')}</StyledUniversity>

      <CurrentGPA>
        {!degree.gpaNotApplicable ? (
          <>
            {degree.gpa}
            <LimitGPA>/{degree.gpaOutOf} GPA</LimitGPA>
          </>
        ) : (
          <GPANotApplicable>GPA is not applicable</GPANotApplicable>
        )}
      </CurrentGPA>
    </StyledDegree>
  );
};

export default CandidatePreviousDegree;
