import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { GetTemplates } from '../../../generated/GetTemplates';
import GET_TEMPLATES from '../../../queries/getTemplates.gql';
import { asTemplate } from '../../../transformers/templates';
import TemplatePicker from './TemplatePicker';

const TemplatePickerController: React.FC<{ handleClick: (content: string) => void; popperId: string }> = ({
  handleClick,
  popperId,
}) => {
  const { data, loading } = useQuery<GetTemplates>(GET_TEMPLATES);
  const templates = useMemo(() => {
    return data?.recruit_sms_template.map(asTemplate) || [];
  }, [data]);

  return <TemplatePicker popperId={popperId} loading={loading} templates={templates} onTemplateClick={handleClick} />;
};

export default TemplatePickerController;
