import React, { useState } from 'react';

import CandidateHeaderButton from './header/CandidateHeaderButton';
import { BlockCandidate, UnblockCandidate } from '../../assets/vectors';
import { ToggleBlockMenuModalController } from '../../components/block';

interface ToggleBlockMenuOptionProps {
  candidateBlocked: boolean;
  candidateId: string;
  reason: string | null;
  refetch: () => void;
}
const ToggleBlockMenuOption: React.FC<ToggleBlockMenuOptionProps> = ({
  candidateId,
  candidateBlocked,
  reason,
  refetch,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <CandidateHeaderButton
      text={`${candidateBlocked ? 'Unblock' : 'Block'} Candidate`}
      icon={candidateBlocked ? UnblockCandidate : BlockCandidate}
      onClick={() => {
        setModalOpen(true);
      }}
    >
      {modalOpen && (
        <ToggleBlockMenuModalController
          refetch={refetch}
          setModalOpen={setModalOpen}
          candidateId={candidateId}
          candidateBlocked={candidateBlocked}
          blockReason={reason}
        />
      )}
    </CandidateHeaderButton>
  );
};

export default ToggleBlockMenuOption;
