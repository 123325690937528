import React from 'react';
import CandidateCardDotMenuOption from './CandidateCardDotMenuOption';
import { UnblockCandidate, BlockCandidate } from '../../assets/vectors';

interface DownloadCVMenuOptionProps {
  onClick: () => void;
  blocked: boolean;
}

const DownloadCVMenuOption: React.FC<DownloadCVMenuOptionProps> = ({ onClick, blocked }) => {
  return (
    <CandidateCardDotMenuOption
      disabled={false}
      text={blocked ? 'Unblock Candidate' : 'Block Candidate'}
      icon={blocked ? UnblockCandidate : BlockCandidate}
      onClick={onClick}
    />
  );
};

export default DownloadCVMenuOption;
