import React from 'react';
import styled from 'styled-components';

import { colors } from '../../../theme';

import Recipient from '../../../types/Recipient';
import CrossBlue from '../../../assets/vectors/CrossBlue.svg';

const StyledRecipientTag = styled.div`
  display: inline-block;
  flex-grow: 1;

  height: 2.5rem;
  min-width: 18rem;
  max-width: 24rem;
  margin: 0.5rem;
  padding: 0.4rem 2.6rem;
  border-radius: 100px;

  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  color: ${colors.black};
  background-color: ${colors.blue};
  background-image: url(${CrossBlue});
  background-repeat: no-repeat;
  background-position: 97%;

  cursor: pointer;
`;

const StyledSearchRecipientDisplay = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 0 3.5rem 2rem;
  max-height: 4rem;
  overflow: scroll;

  border-radius: 8px;
  border: 1px solid ${colors.gray_light};
  background-color: ${colors.gray_light_l1};
`;

const StyledRecipientCount = styled.div`
  position: sticky;
  right: 0rem;
  padding: 0rem 1.5rem;
  align-items: center;
  display: flex;
  width: 8%;
  color: ${colors.gray_dark};
  background: ${colors.gray_light_l1};
  border-left: 1px solid ${colors.gray_light};
`;

const RecipientTagContainer = styled.div`
  width: 92%;
  display: flex;
  overflow-x: auto;
`;

const RecipientDisplay: React.FC<{ recipients?: Recipient[]; handleDeleteRecipient: (recipientId: string) => void }> =
  ({ recipients = [], handleDeleteRecipient }) => {
    return (
      <StyledSearchRecipientDisplay>
        <RecipientTagContainer>
          {recipients.map((recipient) => (
            <StyledRecipientTag
              onClick={() => recipients.length > 1 && handleDeleteRecipient(recipient.id)}
              key={recipient.id}
            >
              {recipient.fullname}
            </StyledRecipientTag>
          ))}
        </RecipientTagContainer>
        {recipients.length > 0 && <StyledRecipientCount>{recipients.length}</StyledRecipientCount>}
      </StyledSearchRecipientDisplay>
    );
  };

export default RecipientDisplay;
