import React, { useEffect } from 'react';
import { WhereQueryInterface } from '../../../queries/queryUtils';
import { useFilter } from '../../../utils/useFilter';
import BooleanFilterItem from '../BooleanFilterItem';

export const generateQuery = (enabled: boolean): WhereQueryInterface => {
  const queryBlockedFilter: WhereQueryInterface = {
    enabled: true,
    operator: '_and',
    whereQuery: { blocked: { _eq: enabled } },
  };
  return queryBlockedFilter;
};

const BlockedFilter: React.FC<{ name: string }> = ({ name }) => {
  const { storeFilterStatus, filterValue } = useFilter(name);

  const setFilterValue = (enabled: boolean) => storeFilterStatus(generateQuery(enabled), enabled);

  useEffect(() => setFilterValue(!!filterValue), []) // eslint-disable-line

  return (
    <BooleanFilterItem
      on={!!filterValue}
      filterTitle={'Blocked candidates'}
      setFilterValue={setFilterValue}
      filterActive={!!filterValue}
    />
  );
};

export default BlockedFilter;
