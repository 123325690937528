import moment from 'moment';
import { format, parse } from 'date-fns';

export interface BackendCompleteDate {
  complete_year: number | null;
  complete_month_numeric: number | null;
}

export interface BackendEndDate {
  end_year: number | null;
  end_month_numeric: number | null;
}

export interface BackendStartDate {
  start_year: number | null;
  start_month_numeric: number | null;
}

const formatMonthYear = (date: Date): string => {
  return moment(date).format('MMMM YYYY');
};

const formatYear = (date: Date): string => moment(date).format('YYYY');

const toHoursDate = (date: Date): string => {
  return moment(date).format('HH:mm');
};

const toDateHour = (date: Date): string => moment(date).format('MM/DD/YYYY HH:mm');

const parseBackendCompleteDate = (date: BackendCompleteDate): Date | null => {
  return date.complete_year && date.complete_month_numeric
    ? new Date(date.complete_year, date.complete_month_numeric - 1)
    : null;
};

const parseBackendEndDate = (date: BackendEndDate): Date | null => {
  return date.end_year && date.end_month_numeric ? new Date(date.end_year, date.end_month_numeric - 1) : null;
};

const parseBackendStartDate = (date: BackendStartDate): Date | null => {
  return date.start_year && date.start_month_numeric ? new Date(date.start_year, date.start_month_numeric - 1) : null;
};

const toMonthName = (monthNumber: number): string => {
  return format(parse(`${monthNumber}`, 'M', new Date()), 'MMMM');
};

export {
  formatMonthYear,
  formatYear,
  parseBackendCompleteDate,
  parseBackendEndDate,
  parseBackendStartDate,
  toMonthName,
  toHoursDate,
  toDateHour,
};
