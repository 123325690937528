export const getMarkups = () => {
  return [{ name: 'First name', value: '<<firstname>>' }];
};

export const hasCustomMarkups = (text: string): boolean =>
  getMarkups().some((markup) => text.search(markup.value) >= 0);

export const replaceMarkup = (text: string, markupName: string, markupValue: string): string => {
  const markup = getMarkups().find((markup) => markup.name === markupName);
  if (!markup || !markupValue) {
    return text;
  }

  return text.replaceAll(markup.value, markupValue);
};
