import React, { ReactNode, useState } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../theme';
import { usePopper } from '../../utils/usePopper';
import ExitCross from '../ExitCross';

import FilterIcon from './FilterIcon';
import FilterPopper from './FilterPopper';

const StyledFilterItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 0.5rem 0;

  cursor: pointer;
`;

const StyledText = styled.div<{ filterActive?: boolean }>`
  font-size: 1.3rem;
  line-height: 2.1rem;
  font-family: 'RobotoMedium';
  color: ${colors.gray_dark};

  ${({ filterActive }) =>
    filterActive &&
    css`
      font-weight: 700;
      color: ${colors.red};
    `}
  ::selection {
    background: none;
  }
`;

interface FilterItemProps {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  text: string;
  children?: ReactNode;
  filterActive?: boolean;
}

const FilterItem: React.FC<FilterItemProps> = ({ icon, text, children, filterActive }) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);

  const { popperOpen, togglePopper } = usePopper(text);

  return (
    <>
      <StyledFilterItem onClick={togglePopper} ref={setReferenceElement}>
        <StyledText filterActive={filterActive}>{text}</StyledText>
        <FilterIcon active={popperOpen} Icon={icon} />
      </StyledFilterItem>
      {React.Children.count(children) > 0 ? (
        <FilterPopper show={popperOpen} referenceElement={referenceElement}>
          <ExitCross onClick={togglePopper} />
          {children}
        </FilterPopper>
      ) : null}
    </>
  );
};

export default FilterItem;
