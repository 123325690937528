import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { EGIsotype, Logout, Candidates, Recruiters, MessagingRed } from 'elitegrad-common/src/assets/vectors';
import colors from 'elitegrad-common/src/theme/colors';

import { Paths } from 'routes';

const SIDEBAR_WIDTH = '6.6rem';

const StyledNonFixedWrapper = styled.div`
  min-width: ${SIDEBAR_WIDTH};
`;

const SidebarIsotype = styled(EGIsotype)`
  grid-area: isotype;

  width: 3rem;
  height: 3rem;
  margin: auto;

  & * {
    fill: ${colors.white};
    stroke: ${colors.white};
  }
`;

const StyledLogout = styled(Logout)`
  display: block;

  width: 3rem;
  margin: 0 auto;

  & * {
    stroke: ${colors.white};
  }
`;

const StyledSidebar = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 6rem auto 6rem;
  grid-template-areas:
    'isotype'
    'top-area'
    'bottom-area';

  height: 100vh;

  font-size: 1rem;
  line-height: 1.2rem;

  color: ${colors.white};
  background-color: ${colors.red};
`;

const StyledTopArea = styled.div`
  grid-area: top-area;
`;

const StyledBottomArea = styled.div`
  grid-area: bottom-area;
  align-self: end;
`;

interface StyledSidebarItemProps {
  active?: boolean;
}

const StyledSidebarItem = styled.div<StyledSidebarItemProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: ${SIDEBAR_WIDTH};
  height: 6.1rem;
  margin: 0 auto;
  padding: 1rem;

  ${({ active }) =>
    active &&
    css`
      color: ${colors.red};

      background-color: ${colors.white};
    `}

  &:hover {
    cursor: pointer;
  }
`;

const StyledSidebarItemIcon = styled.div<{ styles: { active: boolean; fill: boolean; stroke: boolean } }>`
  & > {
    width: 100%;
    height: 2.1rem;
  }
  & > svg {
    height: 100%;
  }

  & > svg > path {
    ${({ styles: { fill, active } }) =>
      fill &&
      css`
        fill: ${active ? colors.red : colors.white};
      `}

    ${({ styles: { stroke, active } }) =>
      stroke &&
      css`
        stroke: ${active ? colors.red : colors.white};
      `}
  }
`;

const StyledSidebarItemText = styled.div`
  display: block;
  text-align: center;
`;

interface SidebarItemProps {
  Image: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  fill?: boolean;
  stroke?: boolean;
  text: string;
  to: string;
}

const SidebarItem: React.FC<SidebarItemProps> = ({ Image, fill, stroke, text, to, ...props }) => {
  const active = !!useRouteMatch(to);

  return (
    <Link to={to} {...props}>
      <StyledSidebarItem {...{ active }}>
        <StyledSidebarItemIcon styles={{ active, fill: !!fill, stroke: !!stroke }}>
          <Image />
        </StyledSidebarItemIcon>
        <StyledSidebarItemText>{text}</StyledSidebarItemText>
      </StyledSidebarItem>
    </Link>
  );
};

const Sidebar: React.FC = () => {
  return (
    <StyledNonFixedWrapper>
      <StyledSidebar>
        <SidebarIsotype />
        <StyledTopArea>
          <SidebarItem to={Paths.candidates} Image={Candidates} text="Candidates" fill stroke />
          <SidebarItem to={Paths.recruiters} Image={Recruiters} text="Recruiters" fill stroke />
          <SidebarItem to={Paths.conversations} Image={MessagingRed} text="Messaging" stroke />
        </StyledTopArea>
        <StyledBottomArea>
          <SidebarItem to={Paths.authLogout} Image={StyledLogout} text="Logout" stroke />
        </StyledBottomArea>
      </StyledSidebar>
    </StyledNonFixedWrapper>
  );
};

export default Sidebar;
