import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { GroupTrashGray, Undo } from '../../assets/vectors';
import Group from '../../types/Group';
import { colors } from '../../theme';
import Checkbox from '../../components/Checkbox';

const StyledMenuOption = styled.div<{ toDelete: boolean }>`
  position: relative;

  display: grid;
  grid-template-columns: 3.4rem auto 3.4rem;
  grid-template-rows: 3.4rem;
  grid-template-areas: 'radio name trash';
  align-items: center;

  margin: 2rem 0;

  font-size: 1.4rem;
  line-height: 3.4rem;

  & > * {
    position: relative;
  }

  ${({ toDelete }) =>
    toDelete &&
    css`
      & :not(:last-child) {
        opacity: 0.3;
      }
      & :not(:last-child):after {
        position: absolute;
        content: '';
        left: 0;
        top: 50%;
        right: 0;
        border-top: 2px solid;
        border-color: ${colors.gray_light_l2};
      }
    `}
`;

const StyledGroupName = styled.div`
  grid-area: name;
  font-family: 'Roboto';
  font-size: 1.4rem;
  cursor: pointer;
`;

const StyledTrashUndo = styled.div`
  grid-area: trash;

  margin: auto;
  width: 2.4rem;

  font-size: 1.4rem;
  line-height: 2.8rem;

  cursor: pointer;
`;

export interface GroupModalOptionProps {
  group: Group;
  isSelected: boolean;
  toggleSelect: (select: boolean) => void;
  toggleDelete: (isDelete: boolean) => void;
}

interface StyledCustomCheckboxProps {
  checked?: boolean;
}
const StyledSelector = styled.div<StyledCustomCheckboxProps>`
  grid-area: radio;
  margin: auto;
  border-radius: 50%;
  border: 2px solid ${colors.black};
  height: 1.6rem;
  width: 1.6rem;
  transform: scale(0.75);
  position: relative;
  cursor: pointer;
  background-color: ${colors.white};
  ${({ checked }) =>
    checked &&
    css`
      background-color: ${colors.black};
    `}
`;

const GroupModalOption: React.FC<GroupModalOptionProps> = ({ group, isSelected, toggleSelect, toggleDelete }) => {
  const [toDelete, setToDelete] = useState(false);

  const handleDeleteToggle = () => {
    setToDelete((e: boolean) => !e);
    toggleDelete(toDelete);
  };

  const handleOnClick = () => {
    if (!toDelete) toggleSelect(!isSelected);
  };

  return (
    <StyledMenuOption onClick={handleOnClick} toDelete={toDelete}>
      <StyledSelector checked={isSelected} onChange={handleOnClick} />
      <StyledGroupName>{group.name}</StyledGroupName>
      <StyledTrashUndo onClick={handleDeleteToggle}>{toDelete ? <Undo /> : <GroupTrashGray />}</StyledTrashUndo>
    </StyledMenuOption>
  );
};

export default GroupModalOption;
