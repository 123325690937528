import Template from '../types/Template';
import { TemplateParts } from '../generated/TemplateParts';

const asTemplate = (templateParts: TemplateParts): Template => {
  return {
    id: templateParts.sms_template_id,
    title: templateParts.title,
    content: templateParts.text,
    creationDate: templateParts.creation_timestamp,
  };
};

export { asTemplate };
