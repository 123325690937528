import React from 'react';

import { ImageShadow, Photo } from './Avatar.styled';
import { AvatarPlaceholder, AvatarPlaceholderCardUnselected } from '../../../assets/vectors';

interface AvatarProps {
  photo?: string | null;
  isSelected?: boolean;
}

const Avatar: React.FC<AvatarProps> = ({ photo, isSelected }) => {
  const hasImage = Boolean(photo);

  return (
    <ImageShadow hasImage={hasImage} isSelected={isSelected}>
      <Photo
        selected={isSelected}
        src={hasImage ? photo : isSelected ? AvatarPlaceholder : AvatarPlaceholderCardUnselected}
        hasImage={hasImage}
      />
    </ImageShadow>
  );
};

export default Avatar;
