import React from 'react';
import styled, { css } from 'styled-components';

const StyledFilterIcon = styled.div<{ active: boolean }>`
  & > * {
    width: 2.4rem;
    height: 2.4rem;
    vertical-align: middle;
    transition: transform 0.2s ease-in-out;

    ::selection {
      background: none;
    }

    ${({ active }) =>
      active &&
      css`
        transform: rotate(-90deg);
      `}
  }
`;

const FilterIcon: React.FC<{ active: boolean; Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> }> = ({
  Icon,
  active,
}) => {
  return (
    <StyledFilterIcon active={active}>
      <Icon />
    </StyledFilterIcon>
  );
};

export default FilterIcon;
