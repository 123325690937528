import gql from 'graphql-tag';

import { GroupMembershipParts } from './fragments.gql';

export default gql`
  mutation RemoveGroupMembers($recruiterId: String!, $candidatesIds: [String!]!) {
    delete_recruit_grad_group_member(
      where: { recruiter_user_id: { _eq: $recruiterId }, student_user_id: { _in: $candidatesIds } }
    ) {
      returning {
        ...GroupMembershipParts
      }
    }
  }
  ${GroupMembershipParts}
`;
