import React from 'react';

import HeaderContainer from '../header/CandidateHeader';
import SendMessageButton from '../header/CandidateMessageButton';
import AddGroupButton from '../header/CandidateGroupButton';
import DownloadCVButton from '../header/DownloadCVHeaderButton';

import CandidateType from '../../../../src/types/Candidate';
import ToggleBlockMenuOption from '../ToggleBlockMenuOption';

interface CandidateHeaderProps {
  candidate: CandidateType;
  messagingPath?: string;
  refetch?: () => void;
  isAdmin?: boolean;
}

const CandidateHeader: React.FC<CandidateHeaderProps> = ({ candidate, messagingPath, refetch, isAdmin }) => {
  return (
    <HeaderContainer>
      {!isAdmin && refetch && <AddGroupButton candidate={candidate} refetch={refetch} />}
      {!isAdmin && messagingPath && <SendMessageButton messagingPath={messagingPath} />}
      <DownloadCVButton candidateId={candidate.userId} />
      {isAdmin && refetch && candidate.blocked !== undefined && candidate.blockedReason !== undefined && (
        <ToggleBlockMenuOption
          refetch={refetch}
          candidateId={candidate.userId}
          candidateBlocked={candidate.blocked}
          reason={candidate.blockedReason}
        />
      )}
    </HeaderContainer>
  );
};

export default CandidateHeader;
