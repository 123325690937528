import React, { useState, useCallback } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';

import DOWNLOAD_CV from '../../../queries/downloadCV.gql';
import EXIST_CV from '../../../queries/existsCV.gql';

import { DownloadCV, DownloadCVVariables } from '../../../generated/DownloadCV';
import { ExistCV, ExistCVVariables } from '../../../generated/ExistCV';
import CandidateHeaderButton from './CandidateHeaderButton';
import { DownloadCV as DownloadCVIcon } from '../../../assets/icons';

interface DownloadCVHeaderButtonProps {
  candidateId: string;
}

const DownloadCVHeaderButton: React.FC<DownloadCVHeaderButtonProps> = ({ candidateId }) => {
  const [localLoading, setLocalLoading] = useState(false);

  const { data: existsData, loading: loadingExistsCV } = useQuery<ExistCV, ExistCVVariables>(EXIST_CV, {
    variables: { userId: candidateId },
  });

  const [downloadCV] = useLazyQuery<DownloadCV, DownloadCVVariables>(DOWNLOAD_CV, {
    variables: { userId: candidateId },
    onCompleted: (data) => {
      const { hex_content: content, filename } = data?.grad_upload_doc[0] || {};
      if (!content || !filename) return;
      const a = document.createElement('a');
      a.href = content;
      a.download = filename;
      setLocalLoading(false);
      a.click();
    },
    onError: () => {
      setLocalLoading(false);
    },
  });

  const handleOnClick = useCallback(() => {
    downloadCV();
  }, [downloadCV]);

  const count = existsData?.grad_upload_doc_aggregate.aggregate?.count || 0;
  const cvExists = !loadingExistsCV && count > 0;

  return (
    <CandidateHeaderButton
      disabled={localLoading || !cvExists}
      onClick={handleOnClick}
      text={'Download Resume'}
      icon={DownloadCVIcon}
    />
  );
};

export default DownloadCVHeaderButton;
