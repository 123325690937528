import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled, { css } from 'styled-components';

import { colors } from '../../../theme';
import PrimaryButton from '../../../components/PrimaryButton';
import SecondaryButton from '../../../components/SecondaryButton';
import Template from '../../../types/Template';
import TextAreaInput from '../../../components/TextAreaInput';
import TextInput from '../../../components/TextInput';

const StyledTemplateItem = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;

  margin-top: 1rem;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 0.8rem 1.6rem;
  font-size: 1.8rem;
  font-family: 'RobotoMedium';
  border-bottom: 1px solid ${colors.gray_light_l10};
`;

const StyledTitleInput = styled(TextInput)`
  font-size: 1.6rem;
  line-height: 1.9rem;
  height: 25rem;
  height: 3.4rem;
  border: 1px solid ${colors.gray_light};

  ::placeholder {
    color: ${colors.gray_g3};
  }
`;

const StyledContentInput = styled(TextAreaInput)`
  margin: 1rem 0;
  height: 25rem;
  overflow: scroll;
  resize: none;
  font-size: 1.6rem;
  line-height: 1.9rem;
  border: 1px solid ${colors.gray_light};

  ::placeholder {
    color: ${colors.gray_g3};
  }
`;

const Title = styled.span`
  font-family: 'RobotoMedium';
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin-bottom: 1rem;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 3rem;
`;

const FooterContainer = styled.div`
  height: 7.4rem;
  background-color: ${colors.gray_light_l10};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 2rem;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: 1rem;
  background-color: ${colors.light_black};
`;

interface TemplateListItemEditProps {
  template?: Template;
  disable: boolean;
  componentTitle: string;
  onClickTrash: () => void;
  onClickSave: (content: string, title: string) => void;
  disableSaveMode: () => void;
}

const TemplateListItemEdit: React.FC<TemplateListItemEditProps> = ({
  template,
  disable,
  componentTitle,
  onClickSave,
  disableSaveMode,
}) => {
  const ref = useRef(null);

  const [title, setTitle] = useState(template?.title || '');
  const [content, setContent] = useState(template?.content || '');

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (!(ref.current as any).contains(e.target)) {
        disableSaveMode();
      }
    },
    [disableSaveMode],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, handleClickOutside]);

  return (
    <StyledTemplateItem ref={ref} disabled={disable}>
      <StyledTitle>{componentTitle}</StyledTitle>
      <FormContainer>
        <Title>Title</Title>
        <StyledTitleInput placeholder={'Name'} value={title} onChange={setTitle} />
        <StyledContentInput placeholder={'Message Template'} value={content} onChange={setContent} />
      </FormContainer>
      <FooterContainer>
        <SecondaryButton big text="Cancel" onClick={() => disableSaveMode()} />
        <StyledPrimaryButton big submit text="Save" onClick={() => onClickSave(content, title)} />
      </FooterContainer>
    </StyledTemplateItem>
  );
};

export default TemplateListItemEdit;
