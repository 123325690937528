import styled from 'styled-components';
import { colors } from 'elitegrad-common/src/theme';

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledCandidateDetails = styled.div`
  margin: 1rem 0 0.3rem;

  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;

  overflow: hidden;

  &:hover {
    opacity: 0.4;
  }
`;

export const StyledDegreeYear = styled.div`
  text-align: center;

  font-size: 1.2rem;
  font-weight: normal;
  margin-bottom: 0.5rem;

  color: ${colors.gray_dark};
`;

export const StyledTags = styled.div`
  grid-area: tags;

  & > * {
    margin-right: 0.4rem;
  }

  & > *:last-child {
    margin-right: 0;
  }
`;

export const StyledTopBar = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
`;

export const HiddenContainer = styled.div`
  display: none;
  height: 0;
`;

export const CurrentDegreeName = styled.div`
  text-align: center;
  color: ${colors.red};
  font-weight: normal;
  font-size: 1.2rem;
  margin-bottom: 0.2rem;
`;

export const CurrentDegreeUniversity = styled.span`
  font-weight: normal;
  font-size: 1.2rem;
  color: ${colors.black};
`;
