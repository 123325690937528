import React, { useCallback, useMemo } from 'react';

import { ApolloProvider } from '@apollo/client';

import { createClient as createApolloClient } from './apollo';
import { useAuth } from './react-auth';
import { useImpersonation } from '../utils/useImpersonation';

const Provider: React.FC = ({ children }) => {
  const { authToken, doLogout, role, user } = useAuth();

  const { getImpersonationProps } = useImpersonation();

  const handleGraphQLError = useCallback(() => {
    doLogout();
  }, [doLogout]);

  const client = useMemo(() => {
    return createApolloClient(authToken, handleGraphQLError, role, getImpersonationProps(), user?.sub);
  }, [authToken, handleGraphQLError, role, user]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export { Provider };
