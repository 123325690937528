import React from 'react';
import styled from 'styled-components';

import { NoApplicableTestIcon } from '../../assets/vectors';
import { colors } from '../../theme';

const StyledNoTests = styled.div`
  display: flex;

  padding: 2.3rem 1rem;

  border-radius: 4px;

  color: ${colors.neo_gray};
`;

const StyledImage = styled.img`
  width: 5rem;
  height: 5rem;
`;
const StyledText = styled.div`
  display: flex;
  margin-left: 0.5rem;
  align-items: center;

  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.2rem;
`;

const CandidateNoTests: React.FC = () => {
  return (
    <StyledNoTests>
      <StyledImage src={NoApplicableTestIcon} />
      <StyledText>No applicable Test scores</StyledText>
    </StyledNoTests>
  );
};

export default CandidateNoTests;
