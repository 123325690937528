import React from 'react';
import styled from 'styled-components';

import Message, { MessageStatus } from '../../../types/Message';
import { colors } from '../../../theme';

const StyledError = styled.div`
  font-size: 1rem;
  color: ${colors.red};
`;

const StyledSending = styled.div`
  font-size: 1rem;

  color: ${colors.green};
`;

const StyledIndicator = styled.div`
  position: relative;

  height: 1.8rem;
`;

interface MessageStatusIndicatorProps {
  message: Message;
}

const MessageStatusIndicator: React.FC<MessageStatusIndicatorProps> = ({ message }) => {
  return (
    <>
      {message.status === MessageStatus.SENDING || message.status === MessageStatus.ERROR ? (
        <StyledIndicator>
          {message.status === MessageStatus.SENDING && <StyledSending>{'Sending'}</StyledSending>}
          {message.status === MessageStatus.ERROR && (
            <StyledError>{`Error sending message: ${message.error}`}</StyledError>
          )}
        </StyledIndicator>
      ) : null}
    </>
  );
};

export default MessageStatusIndicator;
