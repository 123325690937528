import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import { NewGroup } from '../../types/Group';
import GroupModalNewGroup from './GroupModalNewGroup';

import GET_GROUPS from '../../queries/getGroups.gql';
import CREATE_GROUP from '../../queries/createGroup.gql';
import { CreateGroup, CreateGroupVariables } from '../../generated/CreateGroup';
import { GetGroups } from '../../generated/GetGroups';
import { useAuth, User } from '../../utils/react-auth';

interface GroupModalNewGroupControllerProps {
  addSelectedGroupId: (id: string) => void;
}
const GroupModalNewGroupController: React.FC<GroupModalNewGroupControllerProps> = ({ addSelectedGroupId }) => {
  const { user } = useAuth() as { user: User };

  const [create, { loading: loadingNewGroup }] = useMutation<CreateGroup, CreateGroupVariables>(CREATE_GROUP, {
    update: (cache, { data: createData }) => {
      const getGroupData = cache.readQuery<GetGroups>({
        query: GET_GROUPS,
      });

      if (!getGroupData) {
        return;
      }

      const createdGroups = createData?.insert_recruit_grad_group?.returning || [];

      const newGroups = [...getGroupData.recruit_grad_group, ...createdGroups];

      cache.writeQuery({
        query: GET_GROUPS,
        data: {
          recruit_grad_group: newGroups,
        },
      });
    },
  });

  const handleOnSave = useCallback(
    async (group: NewGroup) => {
      const variables = {
        recruiter_user_id: user.sub,
        group_name: group.name,
      };

      await create({ variables });
    },
    [create, user],
  );

  return (
    <GroupModalNewGroup
      onSave={handleOnSave}
      setSelectedGroupId={addSelectedGroupId}
      loadingNewGroup={loadingNewGroup}
    />
  );
};

export default GroupModalNewGroupController;
