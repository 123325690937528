import { IMPERSONATION_ID, IMPERSONATION_ROLE } from './impersonate';

export const useImpersonation = () => {
  const getImpersonationProps = () => {
    return {
      userID: window.sessionStorage.getItem(IMPERSONATION_ID),
      role: window.sessionStorage.getItem(IMPERSONATION_ROLE),
    };
  };

  const setImpersonationProps = (userID: string, role: string): void => {
    window.sessionStorage.setItem(IMPERSONATION_ID, userID);
    window.sessionStorage.setItem(IMPERSONATION_ROLE, role);
  };

  const isImpersonationRunning = (): boolean => {
    const impersonProps = getImpersonationProps();

    return Boolean(impersonProps?.userID && impersonProps?.role);
  };

  const removeImpersonationProps = () => {
    window.sessionStorage.removeItem(IMPERSONATION_ID);
    window.sessionStorage.removeItem(IMPERSONATION_ROLE);
  };

  return { getImpersonationProps, setImpersonationProps, isImpersonationRunning, removeImpersonationProps };
};
