import styled from 'styled-components';

import { Group, Send, DownloadCV } from '../../../assets/icons';
import { colors } from '../../../theme';
import PrimaryButton from '../../../components/PrimaryButton';

export const Wrapper = styled.div`
  position: fixed;
  height: 6rem;
  width: 100vw;
  background: ${colors.gray_extraLight};
  box-shadow: 0px -6px 16px rgba(10, 22, 70, 0.1);
  display: flex;
  align-items: center;
  bottom: 0;
  left: 0;
  margin-left: 6rem;
`;

export const StyledMenuMoreAddGroupWhite = styled(Group)`
  height: 2.4rem;
  width: 2.4rem;
  margin-right: 1rem;

  vertical-align: bottom;
`;

export const StyledMenuMoreDownloadCVWhite = styled(DownloadCV)`
  height: 2.4rem;
  width: 2.4rem;
  margin-right: 1rem;
  color: white;

  vertical-align: bottom;
`;

export const StyledMenuMoreSendMessageWhite = styled(Send)`
  height: 2.4rem;
  width: 2.4rem;
  margin-right: 1rem;

  vertical-align: bottom;
`;

export const ButtonGroupContainer = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: center;
`;

export const ButtonContainer = styled.div`
  flex: 0 0 auto;
  flex-wrap: wrap;
  margin: 0.5rem 1rem;
  line-height: 2.2rem;
`;

export const StyledSecondaryButton = styled(PrimaryButton)`
  background-color: ${colors.gray_dark_2};
  border-radius: 8px;
  padding: 0.5rem 2rem;
`;

export const ClearSelection = styled.div`
  font-size: 1.5rem;
  width: fit-content;
  word-break: normal;
  color: ${colors.red};
  text-decoration: underline;
  cursor: pointer;
  margin-left: 2rem;
`;

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
`;
