import styled from 'styled-components';
import { colors } from '../../../src/theme';
import { ChevronDown } from '../../../src/assets/vectors';

export const Wrapper = styled.div`
  height: 100vh;
  width: 23rem;
  min-width: 23rem;
  padding: 1.8rem 1.4rem;

  background-color: ${colors.gray_light_l1};

  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid ${colors.gray_light_l1};
    background-color: rgba(0, 0, 0, 0.5);
  }

  & > * {
    margin: 2rem 0;
  }

  & > *:first-child {
    margin: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const ChevronDownRed = styled(ChevronDown)`
  max-height: 2.4rem;
  max-width: 2.4rem;

  min-height: 2.4rem;
  min-width: 2.4rem;

  & * {
    stroke: ${colors.red};
  }
`;

export const TitleSection = styled.div`
  display: flex;
  justify-content: space-around;
`;
