import React, { useState, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';

import DOWNLOAD_CV from '../../queries/downloadCV.gql';

import { DownloadCV, DownloadCVVariables } from '../../generated/DownloadCV';
import DownloadCVMenuOption from './DownloadCVMenuOption';

interface DownloadCVMenuOptionControllerProps {
  candidateId: string;
  cvExists: boolean;
  onClick: () => void;
}

const DownloadCVMenuOptionController: React.FC<DownloadCVMenuOptionControllerProps> = ({
  candidateId,
  onClick,
  cvExists,
}) => {
  const [localLoading, setLocalLoading] = useState(false);

  const [downloadCV] = useLazyQuery<DownloadCV, DownloadCVVariables>(DOWNLOAD_CV, {
    variables: { userId: candidateId },
    onCompleted: (data) => {
      const { hex_content: content, filename } = data?.grad_upload_doc[0] || {};
      if (!content || !filename) return;
      const a = document.createElement('a');
      a.href = content;
      a.download = filename;
      setLocalLoading(false);
      a.click();
    },
    onError: () => {
      setLocalLoading(false);
    },
  });

  const handleOnClick = useCallback(() => {
    onClick();
    downloadCV();
  }, [onClick, downloadCV]);

  return <DownloadCVMenuOption cvExists={cvExists} downloadingCV={localLoading} onClick={handleOnClick} />;
};

export default DownloadCVMenuOptionController;
