import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../theme';

const StyledFilterTitle = styled.div<{ filterActive?: boolean }>`
  margin: 0.5rem 0 0;

  font-size: 1.3rem;
  line-height: 2rem;
  font-family: 'RobotoMedium';
  text-align: left;

  color: ${colors.gray_dark};

  ${({ filterActive }) =>
    filterActive &&
    css`
      font-weight: 700;
      color: ${colors.red};
    `}
`;

const FilterTitle: React.FC<{ title: string; filterActive?: boolean }> = ({ title, filterActive }) => {
  return <StyledFilterTitle filterActive={filterActive}>{title}</StyledFilterTitle>;
};

export default FilterTitle;
