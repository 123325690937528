import styled, { css } from 'styled-components';

import colors from '../theme/colors';

interface HorizontalRulerProps {
  highlighted?: boolean;
  light?: boolean;
}
const HorizontalRuler = styled.hr<HorizontalRulerProps>`
  border: solid 0 ${colors.neo_gray};
  border-bottom-width: 1px;

  ${({ light = false }) =>
    light &&
    css`
      border-color: ${colors.gray_extraLight};
    `}

  ${({ highlighted = false }) =>
    highlighted &&
    css`
      border-color: ${colors.red};
    `}
`;

export default HorizontalRuler;
