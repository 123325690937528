import React from 'react';

import { CandidateNoTests, CandidateWorkExperience } from 'elitegrad-common/src/components/candidate';
import CollapsibleCardSection from 'elitegrad-common/src/components/candidates/CollapsibleCardSection';

import CandidateType from 'elitegrad-common/src/types/Candidate';
import { getDegreesSortedByCompleteDate } from 'elitegrad-common/src/types/Degree';
import { getTestsSortedByPriority } from 'elitegrad-common/src/types/Test';
import TypeWorkExperience, { sortByDate as sortWorkExperiencesByDate } from 'elitegrad-common/src/types/WorkExperience';
import CandidateTestScoreSat from 'elitegrad-common/src/components/candidate/CandidateTestScoreSat';
import CandidateTestScoreOthers from 'elitegrad-common/src/components/candidate/CandidateTestScoreOthers';
import {
  CandidateNoWorkExperiences,
  CandidateNoTechnical,
  CandidateNoFirmTypes,
} from 'elitegrad-common/src/components/candidate';
import CandidateCardHeader from './CandidateHeaderInChat';
import CandidateCardInterests from 'elitegrad-common/src/components/candidate/CandidateCardInterests';

import {
  StyledCandidateCardBorder,
  StyledCandidateCard,
  CurrentGPA,
  LimitGPA,
  NoGPA,
  Spacing,
  ExperienceTitle,
} from './CandidateProfileInChat.styled';
import styled from 'styled-components';
import HorizontalRuler from 'elitegrad-common/src/components/HorizontalRuler';

const StyledHorizontalRuler = styled(HorizontalRuler)`
  margin-bottom: 0.5rem;
`;

export interface CandidateCardProps {
  candidate: CandidateType;
}

const CandidateCard: React.FC<CandidateCardProps> = ({ candidate }) => {
  const [currentDegree] = getDegreesSortedByCompleteDate(candidate.degrees);
  const workExperiences = sortWorkExperiencesByDate(candidate.workExperiences) as TypeWorkExperience[];
  const [satAct, ...otherTests] = getTestsSortedByPriority(candidate.tests);

  return (
    <StyledCandidateCardBorder>
      <StyledCandidateCard isExpanded>
        <CandidateCardHeader candidate={candidate} currentDegree={currentDegree} />
        <CurrentGPA>
          {!currentDegree.gpaNotApplicable ? (
            <>
              {currentDegree.gpa}
              <LimitGPA>/{currentDegree.gpaOutOf} GPA</LimitGPA>
            </>
          ) : (
            <NoGPA>N / A</NoGPA>
          )}
        </CurrentGPA>
        <StyledHorizontalRuler />
        <CollapsibleCardSection title="Test score">
          {satAct && <CandidateTestScoreSat satTest={satAct} />}
          {otherTests.length > 0 && <CandidateTestScoreOthers tests={otherTests} />}
          {candidate.tests.length === 0 && <CandidateNoTests />}
        </CollapsibleCardSection>
        {candidate.computerLanguages?.length > 0 ? (
          <>
            <StyledHorizontalRuler />
            <CandidateCardInterests title="Technical languages" interests={candidate.computerLanguages} />
          </>
        ) : (
          <>
            <StyledHorizontalRuler />
            <Spacing />
            <ExperienceTitle isEmptySection>Technical languages</ExperienceTitle>
            <CandidateNoTechnical />
          </>
        )}

        {Boolean(workExperiences.length) ? (
          <>
            <StyledHorizontalRuler />
            <CollapsibleCardSection title="Work Experience">
              {workExperiences.map((we) => (
                <CandidateWorkExperience workExperience={we} key={we.id} />
              ))}
            </CollapsibleCardSection>
          </>
        ) : (
          <>
            <StyledHorizontalRuler />
            <Spacing />
            <ExperienceTitle isEmptySection>Work Experience</ExperienceTitle>
            <CandidateNoWorkExperiences />
          </>
        )}

        {candidate.interestFirmTypes?.length > 0 ? (
          <>
            <StyledHorizontalRuler />
            <CandidateCardInterests title="Firms of Interest" interests={candidate.interestFirmTypes} />
          </>
        ) : (
          <>
            <StyledHorizontalRuler />
            <Spacing />
            <ExperienceTitle isEmptySection>Firm Types</ExperienceTitle>
            <CandidateNoFirmTypes />
          </>
        )}
        <Spacing />
      </StyledCandidateCard>
    </StyledCandidateCardBorder>
  );
};

export default CandidateCard;
