import React from 'react';
import communicationPreferences from '../../../data/communicationPreferences';
import { WhereQueryInterface } from '../../../queries/queryUtils';
import { FilterOption } from '../../../types/FilterValueType';
import { useFilter } from '../../../utils/useFilter';
import MultiCheckFilter from '../MultiCheckFilter';

export interface CommunicationPreferenceFilterProps {
  name: string;
}

export const generateQuery = (options: FilterOption[]): WhereQueryInterface => {
  const values = options.map(({ filterValue }) => filterValue);
  const queryCommPreferenceFilter: WhereQueryInterface = {
    enabled: !!values.length,
    operator: '_and',
    whereQuery: { comm_preference: { _in: values } },
  };

  return queryCommPreferenceFilter;
};

const CommunicationPreferenceFilter: React.FC<CommunicationPreferenceFilterProps> = ({ name }) => {
  const { storeFilterStatus, filterValue, clearFilter } = useFilter(name);

  const setFilterValue = (options: FilterOption[]) => storeFilterStatus(generateQuery(options), options);

  const optionsCommunication: FilterOption[] = communicationPreferences.map((pref) => ({
    filterValue: pref,
    displayValue: pref,
    id: pref,
  }));

  return (
    <MultiCheckFilter
      filterTitle={'Communication Preferences'}
      options={optionsCommunication}
      defaultOptions={(filterValue as FilterOption[]) || []}
      setFilterValue={setFilterValue}
      clearFilter={clearFilter}
      sortSelected={false}
    />
  );
};

export default CommunicationPreferenceFilter;
